class in_order_comparator_c#(type TYPE=uvm_object) extends uvm_component;

  `uvm_component_param_utils(in_order_comparator_c#(TYPE))

  uvm_analysis_export#(TYPE) expected_export;
  uvm_analysis_export#(TYPE) actual_export;

  uvm_analysis_port#(TYPE) ap;

  protected uvm_tlm_analysis_fifo#(TYPE) expected_fifo;
  protected uvm_tlm_analysis_fifo#(TYPE) actual_fifo;

  protected int num_matches;
  protected int num_mismatches;

  protected uvm_event reset_event;

  protected bit no_compare_error_enabled;

  function new(string name="in_order_comparator_c#(TYPE)", uvm_component parent=null);
    super.new(name, parent);
  endfunction

  virtual function void build_phase(uvm_phase phase);
    expected_export = new("expected_export", this);
    actual_export = new("actual_export", this);
    ap = new("ap", this);

    expected_fifo = new("expected", this);
    actual_fifo  = new("actual", this);

    reset_event = new("reset_event");

    no_compare_error_enabled = 1;
  endfunction

  virtual function void connect_phase(uvm_phase phase);
    super.connect_phase(phase);

    expected_export.connect(expected_fifo.analysis_export);
    actual_export.connect(actual_fifo.analysis_export);
  endfunction

  virtual task run_phase(uvm_phase phase);
    super.run_phase(phase);

    forever begin
      fork begin
        fork
          reset_event.wait_trigger();
          forever begin
            TYPE expected_item;
            TYPE actual_item;

            expected_fifo.get(expected_item);
            actual_fifo.get(actual_item);

            compare_transactions(expected_item, actual_item);
          end
        join_any

        disable fork;
      end join
    end
  endtask

  virtual task compare_transactions(TYPE expected_item, TYPE actual_item);
    if(!actual_item.compare(expected_item)) begin
      num_mismatches++;
      `uvm_error_begin(get_type_name(), "Actual does not match expected.")
        `uvm_message_add_object(actual_item, "Actual")
        `uvm_message_add_object(expected_item, "Expected")
      `uvm_error_end
    end else begin
      num_matches++;
      `uvm_info(get_type_name(), { "Actual matches expected: ", actual_item.convert2string() }, UVM_MEDIUM)
      ap.write(actual_item);
    end
  endtask

  virtual function void reset();
    expected_fifo.flush();
    actual_fifo.flush();
    reset_event.trigger();
  endfunction

  virtual function void check_phase(uvm_phase phase);
    TYPE expected_item;
    TYPE actual_item;

    super.check_phase(phase);

    while (expected_fifo.try_get(expected_item)) begin
      `uvm_error(get_type_name(), { "Actual transaction missing. expected={", expected_item.convert2string(), "}" })
    end

    while (actual_fifo.try_get(actual_item)) begin
      `uvm_error(get_type_name(), { "Extra actual transaction found. actual={", actual_item.convert2string(), "}" })
    end

    if (no_compare_error_enabled) begin
      if ((num_matches + num_mismatches) == 0) begin
        `uvm_error(get_type_name(), "Did not compare any transactions.")
      end
    end
  endfunction

  virtual function void set_no_compare_error_enabled(bit enable);
    no_compare_error_enabled = enable;
  endfunction

  virtual function string convert2string();
    return $sformatf("num_matches=%0d, num_mismatches=%0d", num_matches, num_mismatches);
  endfunction

endclass
