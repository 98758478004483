virtual task drive_ready();
  forever begin
    wait ((num_pending_data_items + {{ bfmBase.numActiveTransactionsVariable.identifier }}) > 0);

    fork begin
      fork
        forever begin
          {{> (partial "snippets/bfm-drive-slave-ports-todo")}}

          // do begin
          //   @{{ interface.slaveClockingBlock.name }};
          // end while (/* beat invalid */);
        end
        wait ((num_pending_data_items + {{ bfmBase.numActiveTransactionsVariable.identifier }}) == 0);
      join_any

      disable fork;
    end join

    initialize_signals();
  end
endtask
