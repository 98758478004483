import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import UvmSequence from "@/models/uvm/uvm-sequence";
import Handle from "@/models/handle";

const UvcSlaveRequestSequenceBase = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("sequence")
  ],
  created () {
    this.master = false;
    this.channel = "request";
    this.partialInheritance = [
      { for: ["me"], partial: "killed-by-reset" }
    ];
  },
  computed: {
    superclass () {
      if (this.sequenceItem) {
        return UvmSequence.singleton(this.sequenceItem);
      } else {
        return undefined;
      }
    },
    isTopSlaveSequence () {
      return !this.hasSlaveResponse;
    },
    type () {
      const parts = [this.uvc.rootName];
      parts.push(this.protocolRoleWord);
      if (this.hasSlaveResponse) {
        parts.push("request");
      }
      parts.push("sequence");
      if (this.slaveRequestSequence) {
        parts.push("base");
      }
      parts.push("c");
      return parts.join("_");
    },
    computedInstanceVariables () {
      return [
        this.configHandle
      ]
        .filter(o => o);
    },
    sequenceItemHandle () {
      return this.superclass && this.superclass.reqHandle;
    },
    randomObjectDoHandles () {
      return [
        this.sequenceItemHandle
      ]
        .filter((h) => h);
    },
    computedPolicyHandles () {
      return [
        this.sequenceItemHandle && this.slaveRequestPolicy && new Handle({
          propsData: {
            parent: this,
            klass: this.slaveRequestPolicy,
            relations: {
              randomObjectDoHandle: this.sequenceItemHandle
            }
          }
        })
      ]
        .filter(h => h);
    }
  }
};

export default defineReactiveModel(UvcSlaveRequestSequenceBase);
