import { defineReactiveModel } from "vue-app-utils";
import ParameterAssignmentMixin, { EXPRESSION_UNSET } from "@/models/mixins/parameter-assignment";
import CreatePersistableOnUpdate from "@/models/mixins/create-persistable-on-update";

const ParameterAssignment = {
  mixins: [
    ParameterAssignmentMixin,
    CreatePersistableOnUpdate
  ],
  props: {
    parent: {
      type: Object,
      required: true
    },
    parameter: {
      type: Object,
      required: true
    },
    expressionOverride: {
      type: String,
      default: EXPRESSION_UNSET
    }
  },
  created () {
    this.computed = true;
    this.envUvcInstance = this.parent;
  },
  methods: {
    createPersistable (data) {
      return this.envUvcInstance.createParameterAssignment({
        parameterId: this.parameter.id,
        ...data
      })
        .then(id => {
          return new Promise(resolve => {
            this.findPersistable(id, resolve);
          });
        });
    },
    findPersistable (id, resolve) {
      const persistable = this.envUvcInstance.parameterAssignments.find(parameterAssignment => parameterAssignment.id === id);
      if (persistable) {
        resolve(persistable);
      } else {
        setTimeout(() => {
          this.findPersistable(id, resolve);
        }, 10);
      }
    }
  }
};

export default defineReactiveModel(ParameterAssignment);
