import sortByLength from "@/utils/sort-by-length";

export default function replaceParametersWithIds (value, parameterIdentifiers, parametersByName) {
  let encodedValue = value;
  sortByLength(parameterIdentifiers).forEach((name) => {
    if (name in parametersByName) {
      encodedValue = encodedValue.replace(new RegExp(name, "g"), `__UVMGEN_ID_${parametersByName[name].id}__`);
    }
  });
  return encodedValue;
}
