import { defineReactiveModel } from "vue-app-utils";
import PortMixin from "@/models/mixins/port";

const Port = {
  mixins: [
    PortMixin
  ],
  props: {
    parent: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    defaultValue: {
      type: String,
      default: ""
    },
    // kind: PortKindEnum.getValue("auto"),
    master: {
      type: Boolean,
      default: true
    },
    direction: {
      type: String,
      default: ""
    },
    relations: {
      type: Object,
      default: function () {
        return {};
      }
    }
  }
};

export default defineReactiveModel(Port);
