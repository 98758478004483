function create (hasOneItemName, hasOneCollectionName, hasOneClassName) {
  return function (id = undefined, additionalData = {}, startSnapshot = false) {
    const { doc, collection, setDoc } = this.dbFns;

    let onCreatePromise;
    const HasOneModel = this.getModel(hasOneItemName);
    const data = {
      ...additionalData,
      createdAt: this.currentTime()
    };
    if (!this.docType) {
      throw new Error("docType is not defined.");
    } else if (this.docType === "session") { // session updating user
      if (!id) {
        throw new Error("User id must be provided.");
      }
      data.userId = id;
    } else if (this.docType === "user") { // user updating profile
      data.userId = this.id;
    } else { // profile updating sub-profile
      data.userId = this.user.id;
    }

    if (this[`onCreate${hasOneClassName}`]) {
      onCreatePromise = this[`onCreate${hasOneClassName}`](data);
    } else {
      onCreatePromise = Promise.resolve(data);
    }

    return onCreatePromise.then((data) => {
      let docRef;
      if (id) {
        docRef = doc(collection(this.db, hasOneCollectionName), id);
      } else {
        docRef = doc(collection(this.db, hasOneCollectionName));
      }
      return this.$store.dispatch(
        "trackTransaction",
        setDoc(docRef, data, { merge: true })
          .then(() => {
            const propsData = {
              docRef
            };
            if (!this.docType) {
              throw new Error("docType is not defined.");
            } else if (this.docType === "session") ; else if (this.docType === "user") { // user creating profile/settings
              propsData.user = this;
              propsData.parent = this;
            } else { // profile creating sub-profile
              // if (!this.type) {
              //   throw new Error("say what");
              // }
              propsData.user = this.user;
              propsData[this.docType] = this;
              propsData.parent = this;
            }
            propsData.startSnapshot = startSnapshot;
            propsData.docType = hasOneItemName;
            const obj = new HasOneModel({ propsData });
            if (startSnapshot) {
              return Promise.all([obj.promiseOnSnapshot, ...obj.prereqPromises]).then(() => {
                return obj;
              });
            } else {
              return obj;
            }
          })
          .catch((e) => {
            const error = new Error("utils::createFn::set: " + e.message + ` (${hasOneClassName})`);
            error.name = e.name;
            throw error;
          })
      );
    });
  };
}

export { create as default };
