`ifndef {{ compileGuard }}
`define {{ compileGuard }}

{{#if CASE_ARISES_WHERE_INCDIR_LIST_NOT_USED}}
{{#each externalIncludes tn=true}}
`include "{{ fileName }}"
{{/each}}
{{/if}}
package {{ type }};

  timeunit {{or timeUnit user.setting.defaultTimeUnit}} / {{or timePrecision user.setting.defaultTimePrecision}};

{{#each staticImports tn=true}}
  import {{ type }}::*;
{{/each}}
{{#each dynamicUvcImports tn=true}}
  import {{ type }}::*;
{{/each}}
{{#each dynamicEnvImports tn=true}}
  import {{ type }}::*;
{{/each}}
{{#each dynamicTestImports tn=true}}
  import {{ type }}::*;
{{/each}}
{{#each userImports tn=true}}
  import {{ type }}::*;
{{/each}}
{{#each allParameters tn=true}}
  parameter {{ name }} = {{ defaultValue }};
{{/each}}
{{#each enumTypes}}
  typedef enum {
  {{#each namedValues delimeter=","}}
    {{name}}{{#if value}}={{ value }}{{/if}}
  {{/each}}
  } {{ type }};

{{/each}}
{{#if typedefs.length}}
{{#each typedefs delimeter="\n"}}
  {{> (partial "snippets/typedef")}}
{{/each}}

{{/if}}
{{#each modelPairs tn=true}}
{{#if (addNewlineInPackageFile previous current)}}

{{/if}}
  `include "{{ current.packageFilePath }}"
{{/each}}
endpackage

`endif
