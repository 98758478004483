{{#with context}}
{{#if interface.hasResetAndOtherPorts}}
fork begin
  fork
  {{#if (and isMonitor isPipelined)}}
    {{ abstractBfmHandle.identifier }}.wait_for_reset();
  {{else}}
    begin
      {{ abstractBfmHandle.identifier }}.wait_for_reset();
      {{ responseSequenceItemHandle.identifier }}.{{ responseSequenceItemHandle.klass.killedByResetVariable.identifier }} = 1;
    end
  {{/if}}
    {{> (partial "snippets/call-bfm") context=. task=../task}}
  join_any
  disable fork;
end join
{{else}}
{{> (partial "snippets/call-bfm") context=. task=../task}}
{{/if}}
{{/with}}
