import { defineReactiveModel } from "vue-app-utils";
import ParameterMixin from "@/models/mixins/parameter";

const Parameter = {
  mixins: [
    ParameterMixin
  ],
  props: {
    id: {
      type: String,
      default: null
    },
    parent: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    defaultValueOverride: {
      type: String,
      default: ""
    },
    defaultValueParameterId: {
      type: String,
      default: ""
    },
    minOrMaxValueOverride: {
      type: String,
      default: ""
    }
  },
  created () {
    this.computed = true;
    this.typeReadonly = true;
    this.nameReadonly = true;
    this.defaultValueReadonly = true;
  }
};

export default defineReactiveModel(Parameter);
