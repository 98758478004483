import { defineReactiveModel } from "vue-app-utils";
import VariableCommon from "@/models/mixins/variable-common";
import get from "lodash.get";
import createLinkedList from "@/utils/create-linked-list";

const VariableBase = {
  mixins: [
    VariableCommon
  ],
  props: {
    id: {
      type: String,
      default: ""
    },
    parent: {
      type: Object,
      required: true
    },
    defaultValue: {
      type: String,
      default: ""
    },
    preRandomizeValue: {
      type: String,
      default: ""
    },
    postRandomizeValue: {
      type: String,
      default: ""
    },
    constraintRequired: {
      type: Boolean,
      default: false
    },
    fieldMacro: {
      type: String,
      default: ""
    },
    fieldMacroFlagIds: {
      type: Array,
      default: function () { return []; }
    },
    setter: {
      type: Boolean,
      default: null
    },
    setterOnly: {
      type: Boolean,
      default: false
    },
    subEnvConfigHandlesWithoutDimensions: {
      type: Array,
      default: function () { return []; }
    },
    subEnvConfigHandlesWithDimensions: {
      type: Array,
      default: function () { return []; }
    },
    setterCallRequired: {
      type: Boolean,
      default: null
    },
    appendedSetterArgsOverride: {
      type: Array,
      default: function () { return []; }
    },
    prependedSetterPartials: {
      type: Array,
      default: function () {
        return [];
      }
    },
    setterPartials: {
      type: Array,
      default: function () {
        return [];
      }
    },
    appendedSetterPartials: {
      type: Array,
      default: function () {
        return [];
      }
    },
    getter: {
      type: Boolean,
      default: false
    },
    getWord: {
      type: String,
      default: "get"
    },
    unsetValueOverride: {
      type: String,
      default: ""
    },
    coverpointOverride: {
      type: String,
      default: null
    },
    convert2stringOverride: {
      type: Boolean,
      default: null
    },
    relations: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data () {
    return {
      constraints: []
    };
  },
  created () {
    this.computed = true;
    this.typeReadonly = true;
    this.nameReadonly = true;
  },
  computed: {
    rootProject () {
      return this.parent.rootProject;
    },
    uvcInstance () {
      return this.relations.uvcInstance || {};
    },
    envInstance () {
      return this.relations.envInstance || {};
    },
    uvcOrEnvInstance () {
      return this.relations.uvcInstance || this.relations.envInstance || {};
    },
    sequencerHandle () {
      return this.sequencerHandleOverride ||
        get(this.parent, "virtualSequenceBase.sequencerHandles", []).find(sequencerHandle => {
          return sequencerHandle.uvcInstance === this.relations.uvcInstance;
        });
    },
    hasDimensions () {
      return this.uvcOrEnvInstance.hasDimensions || false;
    },
    numDimensions () {
      return this.uvcOrEnvInstance.numDimensions || 0;
    },
    iterationPropertiesList () {
      const iterationVariables = this.iterationVariables(this.iterationCharacter);
      return createLinkedList(this.numDimensions, this.iterationProperties.bind(this), iterationVariables);
    }
  },
  methods: {
    iterationProperties (index, iterationVariables) {
      const dimension = this.uvcOrEnvInstance.unpackedDimensions[index];
      const multiTermNewExpression = (!!dimension.rhs.constantExpression) || dimension.lhs.multipleTerms;
      return {
        multiTermNewExpression,
        newExpression: this.computeNewExpression(dimension),
        iterationVariable: iterationVariables[index],
        iterationVariables: iterationVariables.slice(0, index + 1),
        brackets: this.formatAsBrackets(iterationVariables.slice(0, index + 1))
      };
    },
    computeNewExpression (dimension) {
      const lhsExpression = this.replaceParametersWithVariables(dimension.lhs);
      if (dimension.rhs.constantExpression) {
        const rhsExpression = this.replaceParametersWithVariables(dimension.rhs);
        const start = (dimension.rhs.multipleTerms ? ("(" + rhsExpression + ")") : rhsExpression);
        const end = (dimension.lhs.multipleTerms ? ("(" + lhsExpression + ")") : lhsExpression);
        return (end + " - " + start);
      } else {
        return lhsExpression;
      }
    },
    replaceParametersWithVariables (lhsOrRhs) {
      let expression = lhsOrRhs.constantExpression;
      const envParametersPath = this.parent.envParametersPath;
      lhsOrRhs.parameterIdentifiers.forEach(parameterName => {
        const parameterVariable = (this.parent.envParameters && this.parent.envParameters.parameterVariablesByParameterName[parameterName]);
        if (parameterVariable) {
          expression = expression.replace(new RegExp(parameterName, "g"), `${envParametersPath}.${parameterVariable.name}`);
        }
      });
      return expression;
    },
    iterationVariables (iterationCharacter) {
      if (this.uvcOrEnvInstance.iterationVariables) {
        return this.uvcOrEnvInstance.iterationVariables(iterationCharacter);
      } else {
        return [];
      }
    },
    formatAsBrackets (variables) {
      return variables.map(str => `[${str}]`).join("");
    }
  }
};

export default defineReactiveModel(VariableBase);
