import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import GeneratableFile from "@/models/mixins/generatable-file";
import Policy from "@/models/mixins/policy";
import config from "@/config";
import { path } from "@/utils/node-VITE_APP_PLATFORM";

const UserPolicyTemplate = {
  extends: Class,
  mixins: [
    GeneratableFile,
    Policy
  ],
  props: {
    parent: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  created () {
    this.userFile = true;
    this.appendedDeclarationPartials = [
      "snippets/todo-constraints"
    ];
    this.todoInstanceVariablePrefix = "object.";
  },
  computed: {
    packageFilePath () {
      return path.join(config.userPoliciesPath(), this.fileName);
    },
    subject () {
      return this.parent;
    },
    rootProject () {
      return this.parent.rootProject;
    },
    project () {
      return this.parent.project;
    },
    todoRandomInstanceVariables () {
      return this.parent.reachableRandomInstanceVariables;
    }
  }
};

export default defineReactiveModel(UserPolicyTemplate);
