{{#if isPipelinedWrite}}
virtual task run_phase(uvm_phase phase);
  super.run_phase(phase);

  fork
    {{ receiveDataTask.identifier }}();
    {{ monitorBfmReceiveResponsesTask.identifier }}();
  join_none
endtask
{{/if}}
