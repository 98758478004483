export default function (titlizedUserType, TemplateClass) {
  const methods = {};
  methods[`create${titlizedUserType}`] = function (data) {
    const template = new TemplateClass({
      propsData: {
        parent: this,
        type: data.type
      }
    });

    return this.rootProject.package.createUserClass({
      packageFilePath: template.packageFilePath,
      fileContentsFn: () => {
        return template.fileContents;
      }
    });
  };

  return {
    methods: {
      ...methods
    }
  };
}
