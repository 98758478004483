import config from "@/config";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import DimensionProperties from "@/models/mixins/dimension-properties";
import Handle from "@/models/handle";
import ParameterAssignment from "@/models/parameter-assignment";
import PortConnection from "@/models/port-connection";
import get from "lodash.get";

export default {
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    DimensionProperties
  ],
  computed: {
    uvc () {
      throw new Error("uvc must be overridden.");
    },
    name () {
      return this.interfaceInstanceName;
    },
    interfaceInstanceName () {
      return `${this.instanceNamePrefix}_if`;
    },
    allParameterAssignmentsWithExpression () {
      return this.allParameterAssignments.filter(parameterAssignment => parameterAssignment.expression.length);
    },
    allParameterAssignments () {
      let parameterIds = this.computedParameterAssignments.map(parameterAssignment => parameterAssignment.parameter.id).concat(this.parameterAssignments.map(parameterAssignment => parameterAssignment.parameter.id));
      parameterIds = [...new Set(parameterIds)];
      return parameterIds.map(parameterId => {
        return this.parameterAssignments.find(parameterAssignment => parameterAssignment.parameter.id === parameterId) ||
          this.computedParameterAssignments.find(parameterAssignment => parameterAssignment.parameter.id === parameterId);
      });
    },
    computedParameterAssignments () {
      return get(this, "uvc.interface.allNonLocalparams", []).map(parameter => {
        return new ParameterAssignment({
          propsData: {
            parent: this,
            parameter
          }
        });
      });
    },
    allPortConnections () {
      let portIds = this.computedPortConnections.map(portConnection => portConnection.port.id).concat(this.portConnections.map(portConnection => portConnection.port.id));
      portIds = [...new Set(portIds)];
      return portIds.map(portId => {
        return this.portConnections.find(portConnection => portConnection.port.id === portId) ||
          this.computedPortConnections.find(portConnection => portConnection.port.id === portId);
      });
    },
    computedPortConnections () {
      return get(this, "uvc.interface.ports", []).map(port => {
        return new PortConnection({
          propsData: {
            parent: this,
            port
          }
        });
      });
    },
    portConnectionWithLargestNumberOfDimensions () {
      return this.allPortConnections.find(portConnection => {
        return (portConnection.unpackedDimensions.length === this.maxNumDimensions);
      });
    },
    unpackedDimensions () {
      return get(this, "env.interfaceHarness.allParameters.length")
        ? get(this, "portConnectionWithLargestNumberOfDimensions.unpackedDimensions", [])
        : [];
    },
    maxNumDimensions () {
      return Math.max(...this.allPortConnections.map(portConnection => portConnection.unpackedDimensions.length));
    },
    instanceNamePrefix () {
      return [
        this.uvc && this.uvc.name,
        this.hasSlave && this.protocolRoleWord,
        this.suffix
      ]
        .filter(s => s)
        .join("_");
    },
    protocolCheckerInstanceName () {
      return `${this.instanceNamePrefix}_protocol_checker`;
    },
    sequenceItemHandle () {
      return this.sequenceItem
        ? new Handle({
          propsData: {
            parent: this,
            klass: this.sequenceItem,
            relations: {
              uvcInstance: this
            }
          }
        })
        : null;
    },
    suggestedDutParameterNames () {
      return get(this.env, "interfaceHarness.allParameters", [])
        .map(parameter => parameter.name);
    },
    suggestedDutConnections () {
      return get(this.env, "dut.ports", [])
        .map(port => port.data.name);
    },
    suggestedDutClockConnections () {
      return get(this.env, "dut.ports", [])
        .filter(port => port.data.name.match(config.clockRegExp))
        .map(port => port.data.name);
    },
    suggestedDutResetConnections () {
      return get(this.env, "dut.ports", [])
        .filter(port => port.data.name.match(config.resetRegExp))
        .map(port => port.data.name);
    },
    suggestedDutMasterConnections () {
      return get(this.env, "dut.ports", [])
        .filter(port => (port.data.direction === "output") && !(port.data.name.match(config.clockRegExp) || port.data.name.match(config.resetRegExp)))
        .map(port => port.data.name);
    },
    suggestedDutSlaveConnections () {
      return get(this.env, "dut.ports", [])
        .filter(port => (port.data.direction === "input") && !(port.data.name.match(config.clockRegExp) || port.data.name.match(config.resetRegExp)))
        .map(port => port.data.name);
    }
  },
  methods: {
    dimensionBrackets (numberOfDimensions) {
      if (numberOfDimensions) {
        const variables = [];
        if (this.maxNumDimensions === 1) {
          variables.push("[d]");
        } else {
          for (let n = 0; n < numberOfDimensions; n++) {
            variables.push(`[d${n}]`);
          }
        }
        return variables.join("");
      } else {
        return "";
      }
    }
  }
};
