import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";
import EnvGetters from "@/models/mixins/env-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Dut from "@/models/persistable/mixins/dut";
import standaloneDutDefinitionHbs from "@/handlebars/standalone-dut-definition.hbs";
import handlebarsRuntimeOptions from "@/handlebars/runtime-options";
import Parameter from "@/models/parameter";
import Port from "@/models/port";
import arrayMax from "@/utils/array-max";
import get from "lodash.get";

const UvcStandaloneDut = {
  extends: Model,
  mixins: [
    EnvGetters,
    GeneratableFile,
    Persistable,
    Dut
  ],
  data () {
    return {
      timeUnit: "",
      timePrecision: ""
    };
  },
  computed: {
    fileName () {
      return `${this.type}.sv`;
    },
    inputModel () {
      return {
        type: this.inputType,
        allParameters: this.inputAllParameters,
        localparams: [],
        ports: this.inputPorts
      };
    },
    inputType () {
      return this.rootProject.rootName;
    },
    inputAllParameters () {
      const parameters = get(this.env, "interfaceHarness.uvcInstancesToInstantiate", [])
        .map(uvcInstance => {
          return get(uvcInstance, "interface.allParameters", []);
        })
        .flat();
      let names = parameters.map(parameter => parameter.name);
      names = [...new Set(names)];
      return names.map(name => {
        const parameter = parameters.find(parameter => parameter.name === name);
        if (this.resetPort && (name === "ACTIVE_LOW")) {
          return new Parameter({
            propsData: {
              parent: this,
              type: parameter.type,
              name: parameter.name,
              defaultValueOverride: this.resetPort.activeLow ? "1" : "0"
            }
          });
        } else {
          return parameter;
        }
      });
    },
    inputPorts () {
      return [
        this.clockPort,
        this.resetPort,
        ...this.slavePorts,
        ...this.masterPorts
      ]
        .filter(p => p);
    },
    clockPort () {
      if (get(this.rootProject, "interface.clockPort")) {
        return new Port({
          propsData: {
            parent: this,
            type: this.rootProject.interface.clockPort.type,
            name: this.rootProject.interface.clockPort.name,
            direction: "input"
          }
        });
      } else {
        return null;
      }
    },
    resetPort () {
      if (get(this.rootProject, "interface.resetPort")) {
        return new Port({
          propsData: {
            parent: this,
            type: this.rootProject.interface.resetPort.type,
            name: this.rootProject.interface.resetPort.name,
            direction: "input"
          }
        });
      } else {
        return null;
      }
    },
    masterAndSlavePorts () {
      return get(this.rootProject, "interface.ports", [])
        .filter(port => !port.isClock && !port.isReset)
        .map(port => {
          return {
            slave: new Port({
              propsData: {
                parent: this,
                type: port.type,
                name: `${port.name}_slv`,
                direction: port.master ? "input" : "output",
                master: port.master,
                relations: {
                  port
                }
              }
            }),
            master: new Port({
              propsData: {
                parent: this,
                type: port.type,
                name: `${port.name}_mst`,
                direction: port.master ? "output" : "input",
                master: !port.master,
                relations: {
                  port
                }
              }
            })
          };
        });
    },
    slavePorts () {
      return this.masterAndSlavePorts.map(ports => ports.slave);
    },
    masterPorts () {
      return this.masterAndSlavePorts.map(ports => ports.master);
    },
    code () {
      return standaloneDutDefinitionHbs(this, handlebarsRuntimeOptions).slice(0, -1);
    },
    content () {
      return this.code;
    },
    portsTypeMaxLength () {
      return arrayMax(this.inputPorts, "type");
    },
    portsNameMaxLength () {
      return arrayMax(this.inputPorts, "name");
    }
  }
};

export default defineReactiveModel(UvcStandaloneDut);
