import parseSystemVerilog from "@/utils/parse-system-verilog";

// [TODO] what about making classes computed with a generic persistable class as override?
// Would have to use update, immediateUpdate as well as createManyModel method. Would probably have to replicate all the persistable methods, such as find*, findOrCreate*.
// I think it could be done.
export default {
  data () {
    return {
      type: "",
      identifier: "",
      prototype: "",
      errorMessage: ""
    };
  },
  created () {
    if (this.content) {
      this.validateContent(this.content);
    }
  },
  computed: {
    needsAttention () {
      return (this.computed && this.mandatory) || !this.identifier;
    }
  },
  methods: {
    validateContent (value) {
      if (value === "") {
        this.type = "";
        this.identifier = "";
        this.prototype = "";
        this.errorMessage = "";
        return true;
      }
      try {
        const validatedInfo = this.parseContent(value);
        if (this.requiredIdentifier && (this.requiredIdentifier !== validatedInfo.identifier)) {
          throw new Error(`Expected subroutine to be called '${this.requiredIdentifier}'.`);
        }
        this.type = validatedInfo.type;
        this.identifier = validatedInfo.identifier;
        this.prototype = validatedInfo.prototype;
        this.errorMessage = "";
      } catch (e) {
        this.errorMessage = e.message;
      }
      if (this.errorMessage) {
        return this.errorMessage;
      } else {
        return true;
      }
    },
    parseContent (value) {
      if (this.requiredArgs) {
        return parseSystemVerilog(value, "Subroutine", { requiredArgs: this.requiredArgs });
      } else {
        return parseSystemVerilog(value, "Subroutine");
      }
    }
  }
};
