import { defineReactiveModel, Persistable, UserMixin, hasMany } from "vue-app-utils";
import Model from "@/models/model";
import UserPlatformMixin from "@/models/persistable/mixins/user-VITE_APP_PLATFORM";
import PolicyLib from "@/models/policy-lib";
import ComparatorLib from "@/models/comparator-lib";

const softwareLicenseAgreementMd = __APP_MARKDOWN_INFO__["software-license-agreement"].jsonLd.articleBody;
const mitLicenseMd = __APP_MARKDOWN_INFO__["mit-license"].jsonLd.articleBody;

const User = {
  extends: Model,
  mixins: [
    Persistable,
    UserMixin,
    UserPlatformMixin,
    hasMany("uvcs", {
      orderBy: ["name", "createdAt"]
    }),
    hasMany("formClasses", {
      orderBy: ["createdAt"]
    })
  ],
  data () {
    return {
      agreedToTerms: false,
      role: ""
    };
  },
  computed: {
    softwareLicenseAgreement () {
      return {
        absoluteFilePath: this.$store.getters.topDir + "/" + this.setting.relVipDir + "/LICENSE",
        collectionName: "",
        projectFilePath: "LICENSE",
        fileContents: (import.meta.env.VITE_APP_PLATFORM === "desktop")
          ? "Software License Agreement\n\n" + softwareLicenseAgreementMd
          : mitLicenseMd
      };
    },
    libs () {
      return [
        this.policyLib,
        this.comparatorLib
      ];
    },
    policyLib () {
      return new PolicyLib({
        propsData: {
          user: this
        }
      });
    },
    comparatorLib () {
      return new ComparatorLib({
        propsData: {
          user: this
        }
      });
    }
  }
};

export default defineReactiveModel(User);
