import { hasMany, hasOne } from "vue-app-utils";
import { StripeUserMixin } from "vue-app-utils-web";

export default {
  mixins: [
    StripeUserMixin,
    hasMany("licenses", {
      orderBy: ["createdAt"]
    }),
    hasOne("priceSheet", {
      overrideId: "price-sheet-id"
    })
  ],
  computed: {
    allCarts () {
      return [
        this.cart,
        ...this.licenses.map(license => license.cart)
      ]
        .filter(c => c);
    }
  },
  methods: {
    async inAppAssets () {
      return [];
    }
  }
};
