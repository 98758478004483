virtual task receive_data();
  {{ sequenceItemHandle.klass.type }} {{ sequenceItemHandle.identifier }};

  forever begin
    // do begin
    //   @{{ interface.slaveClockingBlock.name }};
    // end while (/* beat invalid */);

    // {{ sequenceItemHandle.identifier }} = get_transaction(/* id */);

    {{> (partial "snippets/bfm-capture-master-data-ports-todo")}}

    {{ sequenceItemHandle.identifier }}.{{ sequenceItemHandle.klass.beatIndexVariable.identifier }}++;
    {{#if isPipelinedWrite}}

    // if ({{ sequenceItemHandle.identifier }}.{{ sequenceItemHandle.klass.lastVariable.identifier }}) begin
    //   {{ sequenceItemHandle.identifier }}.{{ sequenceItemHandle.klass.lenVariable.identifier }} = {{ sequenceItemHandle.identifier }}.{{ sequenceItemHandle.klass.beatIndexVariable.identifier }};
    //   {{ sequenceItemHandle.identifier }}.data = new[{{ sequenceItemHandle.identifier }}.{{ sequenceItemHandle.klass.lenVariable.identifier }}]({{ sequenceItemHandle.identifier }}.data);
    // end
    {{/if}}
  end
endtask
