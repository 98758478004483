import { defineReactiveModel, Persistable } from "vue-app-utils";
import Class from "@/models/class";
import Test from "@/models/mixins/test";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import session from "@/models/session-VITE_APP_PLATFORM";
import PolicyBase from "@/models/policy-base";
import config from "@/config";
import UvmSequence from "@/models/uvm/uvm-sequence";
import Handle from "@/models/handle";
import { path } from "@/utils/node-VITE_APP_PLATFORM";
import get from "lodash.get";

const FormClass = {
  extends: Class,
  mixins: [
    Test,
    GeneratableFile,
    Persistable,
    // belongsTo("uvcPackage")
    Overridable("form-class")
  ],
  data () {
    return {
      formType: "",
      type: "",
      subjectUserId: "",
      subjectUniqueId: "",
      subjectUser: null,
      subjectRootCollection: "",
      subjectRootId: "",
      subjectProjectUniqueId: "",
      rootCollection: "", // for locating package
      rootId: "", // for locating package
      projectUniqueId: "", // for locating package
      master: null,
      subject: null
    };
  },
  created () {
    this.computed = false;
    this.isFormClass = true;

    this.prereqPromises = [];

    const unwatchUvc = this.$watch("subjectProject.nonUserClassesByUniqueId", (byUniqueId) => {
      if (byUniqueId) {
        if (!this.subjectUniqueId) {
          throw new Error("Expected subjectUniqueId to be set.");
        }
        const subject = byUniqueId[this.subjectUniqueId];
        if (subject && !this.subject) {
          this.subject = subject;
        }
        if (this.subject) {
          this.$nextTick(() => { unwatchUvc(); });
        }
      }
    }, { immediate: true });
  },
  watch: {
    subjectUserId: {
      handler: function (subjectUserId /*, oldSubjectUserId */) {
        if (subjectUserId) {
          session.findUserById(subjectUserId).then(subjectUser => {
            this.subjectUser = subjectUser;
          });
        }
      }
    }
  },
  computed: {
    isMaster () {
      return !!this.master;
    },
    isSlave () {
      return !this.isMaster;
    },
    rootProject () {
      if (this.rootCollection) {
        return this.user[this.rootCollection].find(root => root.id === this.rootId);
      } else {
        return null;
      }
    },
    subjectRoot () {
      if (this.subjectUser && this.subjectRootCollection) {
        return this.subjectUser[this.subjectRootCollection].find(root => root.id === this.subjectRootId);
      } else {
        return null;
      }
    },
    subjectProject () {
      if (this.subjectRoot) {
        if (this.subjectRoot.uniqueId === this.subjectProjectUniqueId) {
          return this.subjectRoot;
        } else if (get(this.subjectRoot, "standaloneEnv.uniqueId") === this.subjectProjectUniqueId) {
          return this.subjectRoot.standaloneEnv;
        } else if (get(this.subjectRoot, "standaloneTb.uniqueId") === this.subjectProjectUniqueId) {
          return this.subjectRoot.standaloneTb;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    project () {
      if (this.rootProject) {
        if (this.rootProject.uniqueId === this.projectUniqueId) {
          return this.rootProject;
        } else if (get(this.rootProject, "standaloneEnv.uniqueId") === this.projectUniqueId) {
          return this.rootProject.standaloneEnv;
        } else if (get(this.rootProject, "standaloneTb.uniqueId") === this.projectUniqueId) {
          return this.rootProject.standaloneTb;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    firstChildOfRoot () {
      if (this.subject) {
        if (this.subject.type && this.subject.type.match(/^uvm_sequence#/)) {
          return this.package;
        } else if (this.project.isUvc) {
          return this.subject.firstChildOfRoot; // will return either package or interface for uvc.
        } else {
          return this.package;
        }
      } else {
        return null;
      }
    },
    package () {
      return get(this, "project.package");
    },
    isSubclass () {
      return !this.isSequence && !this.isPolicy;
    },
    isPolicy () {
      return this.formType === "policy";
    },
    superclass () {
      if (this.formType === "subclass") {
        return this.subject || this.defaultSuperclass;
      } else if (this.formType === "policy") {
        return PolicyBase.singleton(this.subject);
      }
    },
    classToSearchForInstanceVariables () {
      return this.isPolicy
        ? this.subject
        : null;
    },
    forwardTypedefs () {
      return [
        (this.formType === "policy") && this.subject && this.subject.isSequence && this.subject
      ]
        .filter(o => o);
    },
    computedInstanceVariables () {
      return [
        this.computedConfigHandle
      ]
        .filter(o => o);
    },
    computedConfigHandle () {
      if (!this.configHandle && this.rootProject && this.rootProject.isUvc && this.rootProject.sequenceItem && (this.superclass === UvmSequence.singleton(this.rootProject.sequenceItem)) && this.rootProject.config) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.rootProject.config,
            setter: true,
            convert2stringOverride: false
          }
        });
      }
    },
    configHandle () {
      return this.instanceVariables.find(iv => (iv.name === "cfg") || (iv.name === "env_cfg"));
    },
    userPackageFilePath () {
      if (this.isSequence) {
        return path.join(config.userSequencesPath(), this.fileName);
      } else if (this.isSubclass) {
        return path.join(config.userDir, this.fileName);
      } else if (this.isPolicy) {
        return path.join(config.userPoliciesPath(), this.fileName);
      } else {
        throw new Error("Unknown formClass type.");
      }
    },
    reqHandle () {
      if (this.superclass && (this.superclass.rootType === "uvm_sequence")) {
        return this.superclass.reqHandle;
      }
    },
    randomObjectDoHandles () {
      if (this.randomObjectCreateHandles.length > 0) {
        return this.randomObjectCreateHandles;
      } else {
        return [
          this.reqHandle
        ]
          .filter(o => o);
      }
    }
  },
  methods: {
    convertToUserFile () {
      return this.project.package.createUserClass({
        packageFilePath: this.userPackageFilePath,
        fileContentsFn: () => {
          return `${this.headerForWriteableFiles()}${this.code}`;
        }
      })
        .then(() => {
          return this.destroy();
        });
    }
  }
};

export default defineReactiveModel(FormClass);
