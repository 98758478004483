import findById from './find-by-id.js';
import create from './create.js';
import pluralize from 'pluralize-esm';

const data = function (hasOneLazyName) {
  const data = {};
  data[hasOneLazyName] = null;
  return data;
};

const lazyFindOrCreateFn = function (hasOneClassName, hasOneLazyName, hasOnePromiseName) {
  const hasOneLazyCalledName = hasOneLazyName + "Called";
  return function () {
    if (!this[hasOneLazyCalledName] && this.allowCRUD) {
      this[hasOneLazyCalledName] = true;
      const startSnapshot = true;
      this[hasOnePromiseName] = this[`findOrCreate${hasOneClassName}`](startSnapshot)
        .then((model) => {
          this[hasOneLazyName] = model;
          return model;
        });
    }
    return this[hasOneLazyName];
  };
};

const findByIdFn = function (hasOneItemName, hasOneCollectionName, hasOneClassName) {
  const fn = findById(hasOneItemName, hasOneCollectionName);
  return function (id, startSnapshot = false) {
    return fn.call(this, id, startSnapshot);
  };
};

const createFn = function (hasOneItemName, hasOneCollectionName, hasOneClassName, overrideId) {
  const fn = create(hasOneItemName, hasOneCollectionName, hasOneClassName);
  return function (additionalData = {}, startSnapshot = false) {
    return fn.call(this, overrideId || this.id, additionalData, startSnapshot);
  };
};

const findOrCreateFn = function (hasOneClassName, findByIdName, createByIdName, overrideId) {
  return function (startSnapshot = false) {
    return this[findByIdName](overrideId || this.id, startSnapshot)
      .then((model) => {
        if (model) {
          return model;
        } else {
          return this[createByIdName]({}, startSnapshot);
        }
      });
  };
};

// [TODO] remove after soak
// const createOrUpdateFn = function (hasOneCollectionName, hasOneClassName) {
//   return function (additionalData = {}) {
//     // [TODO] must be updated
//     const docRef = this.db.collection(hasOneCollectionName).doc(this.id);
//     return this.$store.dispatch(
//       "trackTransaction",
//       docRef.get()
//         .then((docSnapshot) => {
//           if (docSnapshot.exists()) {
//             return docRef
//               .set(additionalData, { merge: true })
//               .catch((e) => {
//                 const error = new Error("utils::createOrUpdateFn::set1: " + e.message);
//                 error.name = e.name;
//                 throw error;
//               });
//           } else {
//             let onCreatePromise;
//
//             const data = {
//               ...additionalData,
//               createdAt: this.currentTime()
//             };
//             if (!this.docType) {
//               throw new Error("docType is not defined.");
//             } else if (this.docType === "session") { // session updating user
//               data.userId = this.id;
//             } else if (this.docType === "user") { // user updating profile
//               data.userId = this.id;
//             } else { // profile updating sub-profile
//               data.userId = this.user.id;
//             }
//
//             // TODO determine if doc already exists before calling onCreate hook
//             if (this[`onCreate${hasOneClassName}`]) {
//               onCreatePromise = this[`onCreate${hasOneClassName}`](data);
//             } else {
//               onCreatePromise = Promise.resolve(data);
//             }
//
//             return onCreatePromise.then((data) => {
//               return docRef
//                 .set(data, { merge: true })
//                 .catch((e) => {
//                   const error = new Error("utils::createOrUpdateFn::set2: " + e.message);
//                   error.name = e.name;
//                   throw error;
//                 });
//             });
//           }
//         })
//         .catch((e) => {
//           const error = new Error("utils::createOrUpdateFn::get: " + e.message);
//           error.name = e.name;
//           throw error;
//         })
//     );
//   };
// };

const hasOneForModel = function (hasOneItemName, opts = {}) {
  const hasOneClassName = hasOneItemName.charAt(0).toUpperCase() + hasOneItemName.substr(1);
  const hasOneCollectionName = pluralize(hasOneItemName);
  const hasOnePromiseName = "promise" + hasOneClassName;
  const hasOneLazyName = "lazy" + hasOneClassName;
  const findByIdName = `find${hasOneClassName}ById`;
  const createByIdName = `create${hasOneClassName}`;
  const findOrCreateByIdName = `findOrCreate${hasOneClassName}`;

  const methods = {};
  methods[findByIdName] = findByIdFn(hasOneItemName, hasOneCollectionName);
  methods[createByIdName] = createFn(hasOneItemName, hasOneCollectionName, hasOneClassName, opts.overrideId);
  // [TODO] remove after soak
  // methods[`createOrUpdate${hasOneClassName}`] = createOrUpdateFn(hasOneCollectionName, hasOneClassName);
  methods[findOrCreateByIdName] = findOrCreateFn(hasOneClassName, findByIdName, createByIdName, opts.overrideId);

  const computed = {};
  computed[hasOneItemName] = lazyFindOrCreateFn(hasOneClassName, hasOneLazyName, hasOnePromiseName);

  return {
    data () {
      return data(hasOneLazyName);
    },
    beforeCreate () {
      this[`optsFor${hasOneClassName}`] = opts;

      if (this.hasOneItemNames === undefined) {
        this.hasOneItemNames = [];
      }
      this.hasOneItemNames.push(hasOneItemName);
    },
    computed,
    methods
  };
};

var hasOne = hasOneForModel;

export { hasOne as default };
