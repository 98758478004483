import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import UvmComponent from "@/models/uvm/uvm-component";
import Handle from "@/models/handle";

const UvcAbstractBfm = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage")
  ],
  created () {
    this.virtual = true;
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-abstract-bfm/pure-methods" },
      { for: ["me"], partial: "uvc-abstract-bfm/drive" },
      { for: ["me"], partial: "uvc-abstract-bfm/receive" },
      { for: ["me"], partial: "uvc-abstract-bfm/respond" },
      { for: ["me"], partial: "uvc-abstract-bfm/monitor" }
    ];
  },
  computed: {
    isBfm () {
      return true;
    },
    forwardTypedefs () {
      return [
        this.config,
        this.sequenceItem,
        this.hasMonitor && this.monitor
      ].filter((o) => o);
    },
    superclass () {
      return UvmComponent.singleton();
    },
    type () {
      return `abstract_${this.uvc.rootName}_bfm_c`;
    },
    computedInstanceVariables () {
      return [
        this.configHandle,
        this.monHandle
      ]
        .filter(o => o);
    },
    monHandle () {
      if (this.monitor) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.monitor,
            nameOverride: "mon",
            setter: true,
            setterCallRequired: false
          }
        });
      } else {
        return null;
      }
    }
  }
};

export default defineReactiveModel(UvcAbstractBfm);
