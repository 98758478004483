{{#if hasMaster}}
clocking {{ masterClockingBlock.name }}{{#if clockPort}} @(posedge {{ clockPort.identifier }} iff {{ posedgeParameter.name }}, negedge {{ clockPort.identifier }} iff !{{ posedgeParameter.name }}){{else}}(){{/if}};
{{#if hasResetOnly}}
{{#each resetPorts}}
  output {{ identifier }};
{{/each}}
{{/if}}
{{#each masterAndSlavePorts}}
  {{#if isMaster}}output{{else}}input{{/if}} {{ identifier }};
{{/each}}
endclocking
{{/if}}
{{#if hasSlave}}
{{#if hasMaster}}

{{/if}}
clocking {{ slaveClockingBlock.name }}{{#if clockPort}} @(posedge {{ clockPort.identifier }} iff {{ posedgeParameter.name }}, negedge {{ clockPort.identifier }} iff !{{ posedgeParameter.name }}){{else}}(){{/if}};
{{#if hasResetOnly}}
{{#each resetPorts}}
  input {{ identifier }};
{{/each}}
{{/if}}
{{#each masterAndSlavePorts}}
  {{#if isSlave}}output{{else}}input{{/if}} {{ identifier }};
{{/each}}
endclocking
{{/if}}
{{#if hasMonitor}}
{{#if (or hasMaster hasSlave)}}

{{/if}}
clocking {{ monitorClockingBlock.name }}{{#if clockPort}} @(posedge {{ clockPort.identifier }} iff {{ posedgeParameter.name }}, negedge {{ clockPort.identifier }} iff !{{ posedgeParameter.name }}){{else}}(){{/if}};
{{#if hasResetOnly}}
{{#each resetPorts}}
  input {{ identifier }};
{{/each}}
{{/if}}
{{#each masterAndSlavePorts}}
  input {{ identifier }};
{{/each}}
endclocking
{{/if}}
