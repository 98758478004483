`ifndef {{ compileGuard }}
`define {{ compileGuard }}

{{#with inputModel}}
module {{ type }}
  {{> partials/snippets/parameter-and-port-declarations}}
{{/with}}

  timeunit {{or timeUnit user.setting.defaultTimeUnit}} / {{or timePrecision user.setting.defaultTimePrecision}};

{{#each masterAndSlavePorts}}
{{#if slave.master}}
  assign {{ master.identifier }} = {{ slave.identifier }};
{{else}}
  assign {{ slave.identifier }} = {{ master.identifier }};
{{/if}}
{{/each}}

endmodule

`endif
