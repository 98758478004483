import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Policy from "@/models/mixins/policy";
import Handle from "@/models/handle";
import Constraint from "@/models/constraint";
import get from "lodash.get";

const UvcMasterRequestPolicy = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    UvcProtocolRoleGetters,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("policy"),
    Policy
  ],
  created () {
    this.master = true;
  },
  computed: {
    subject () {
      return this.sequenceItem;
    },
    type () {
      const parts = [this.uvc.rootName];
      if (this.hasSlave) {
        parts.push(this.protocolRoleWord);
      }
      if (this.isPipelined) {
        parts.push("request");
      }
      parts.push("policy");
      parts.push("c");
      return parts.join("_");
    },
    computedInstanceVariables () {
      return [
        this.refItemHandle
      ]
        .filter(o => o);
    },
    refItemHandle () {
      if (this.sequenceItem && this.isPipelinedWrite) {
        return new Handle({
          propsData: {
            parent: this,
            isProt: true,
            klass: this.sequenceItem,
            nameOverride: "ref_item",
            setter: true
          }
        });
      } else {
        return undefined;
      }
    },
    computedConstraints () {
      const constraints = [
        this.phaseConstraint,
        this.dataConstraint,
        this.strobeConstraint,
        this.idConstraint,
        this.lenConstraint,
        this.lastConstraint
      ]
        .filter(c => c);

      this.masterConstraints.concat(this.slaveConstraints).forEach(constraint => {
        if (!constraints.find(c => c.instanceVariable.id === constraint.instanceVariable.id)) {
          constraints.push(constraint);
        }
      });
      return constraints;
    },
    phaseConstraint () {
      return (this.sequenceItem && this.sequenceItem.phaseEnumVariable && !this.constraints.find(constraint => get(constraint, "instanceVariable.identifier") === this.sequenceItem.phaseEnumVariable.identifier))
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.phaseEnumVariable,
            content: `object.${this.sequenceItem.phaseEnumVariable.identifier} == ${this.sequenceItem.phaseEnumVariable.klass.request.name};`
          }
        })
        : null;
    },
    dataConstraint () {
      return (this.sequenceItem && this.sequenceItem.dataVariables.length === 1 && this.sequenceItem.writeVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.dataVariables[0],
            content: `if (object.${this.sequenceItem.writeVariable.identifier} == 0) {\n  object.${this.sequenceItem.dataVariables[0].identifier} == '0;\n}`
          }
        })
        : null;
    },
    strobeConstraint () {
      return (this.sequenceItem && this.sequenceItem.strobeVariable && this.sequenceItem.writeVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.strobeVariable,
            content: `if (object.${this.sequenceItem.writeVariable.identifier}) {\n  object.${this.sequenceItem.strobeVariable.identifier} != '0;\n} else {\n  object.${this.sequenceItem.strobeVariable.identifier} == '0;\n}`
          }
        })
        : null;
    },
    masterConstraints () {
      if (this.refItemHandle) {
        const masterIvs = get(this.sequenceItem, "almostAllInstanceVariables", []).filter(iv => iv.relatedPort && iv.isMaster);

        return masterIvs.map(iv => {
          let content;
          if (get(iv, "nameInfo.unpacked.dimensions[0].type") === "dynamicArray") {
            content = `object.${iv.identifier}.size == 0;`;
          } else {
            content = `object.${iv.identifier} == ${this.refItemHandle.identifier}.${iv.identifier};`;
          }
          return new Constraint({
            propsData: {
              parent: this,
              instanceVariable: iv,
              content
            }
          });
        });
      } else {
        return [];
      }
    },
    slaveConstraints () {
      const slaveIvs = get(this.sequenceItem, "instanceVariables", []).filter(iv => iv.relatedPort && iv.isSlave);

      return slaveIvs.map(iv => {
        let dynamicArray = false;
        if (iv.nameInfo.unpacked.dimensions.length && iv.nameInfo.unpacked.dimensions[0].type === "dynamicArray") {
          dynamicArray = true;
        }
        return new Constraint({
          propsData: {
            parent: this,
            instanceVariable: iv,
            content: `object.${iv.identifier}${dynamicArray ? ".size" : ""} == 0;`
          }
        });
      });
    },
    idConstraint () {
      return (this.sequenceItem && this.sequenceItem.idVariable && this.sequenceItem.configHandle && this.config && this.config.activeIdQVariable && !this.isPipelinedWrite)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.idVariable,
            content: `!(object.${this.sequenceItem.idVariable.identifier} inside { object.${this.sequenceItem.configHandle.identifier}.${this.config.activeIdQVariable.identifier} });`
          }
        })
        : null;
    },
    lenConstraint () {
      return (this.sequenceItem && this.sequenceItem.lenVariable && !this.isPipelinedWrite)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.lenVariable,
            content: `object.${this.sequenceItem.lenVariable.identifier} > 0;`
          }
        })
        : null;
    },
    lastConstraint () {
      return (this.sequenceItem && this.sequenceItem.lastVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.lastVariable,
            content: `object.${this.sequenceItem.lastVariable.identifier} == 0;`
          }
        })
        : null;
    }
  }
};

export default defineReactiveModel(UvcMasterRequestPolicy);
