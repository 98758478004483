import { defineReactiveModel } from "vue-app-utils";
import VariableBase from "@/models/variable-base";

const Variable = {
  extends: VariableBase,
  props: {
    type: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    iterationCharacter: {
      type: String,
      required: false
    }
  },
  computed: {
    configDbKey () {
      return this.identifier;
    }
  }
};

export default defineReactiveModel(Variable);
