
// {{ type }}

{{> partials/encapsulator/instance-variables}}
{{> partials/encapsulator/setters}}
{{> partials/encapsulator/getters}}
{{> partials/encapsulator/partials}}
{{> partials/encapsulator/subroutines}}

// <TODO>...
{{#each ports}}
// {{ name }}
{{/each}}
