import { loadStripe } from '@stripe/stripe-js';
import defineReactiveModel from './utils/define-reactive-model.js';

const StripeManager = defineReactiveModel({
  props: {
    publishableKey: {
      type: String,
      required: true
    },
    store: {
      type: Object,
      required: true
    },
    router: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      stripe: null,
      paymentMethodsLoaded: false,
      latestSetupIntentClientSecret: "",
      placeOrderButtonDisabled: false,
      oldPaymentIntentUpdatedAt: null,
      addPaymentErrorMessage: "",
      microdepositType: ""
    };
  },
  watch: {
    user: {
      handler: function (user) {
        if (user) {
          this.user.privateProfile; // eslint-disable-line no-unused-expressions
          this.user.promisePrivateProfile.then(() => {
            this.onAuth();
          });
        } else {
          this.onUnAuth();
        }
      },
      immediate: true
    }
  },
  created () {
    this.setCurrencyFormatter("USD");
  },
  computed: {
    paymentIntentUpdated () {
      return !this.oldPaymentIntentUpdatedAt || (
        this.cart &&
        this.cart.result &&
        this.cart.result.paymentIntentUpdatedAt &&
        (this.oldPaymentIntentUpdatedAt.toDate().getTime() < this.cart.result.paymentIntentUpdatedAt.toDate().getTime())
      );
    },
    user () {
      return this.store.getters.user;
    },
    route () {
      return this.router.currentRoute.value;
    },
    cart () {
      if (this.route.name === "cartsEdit") {
        const cart = this.user.allCarts.find(cart => cart.id === this.route.params.id);
        cart && cart.result; // eslint-disable-line no-unused-expressions
        cart && cart.readonlyCart; // eslint-disable-line no-unused-expressions
        return cart;
      } else {
        return null;
      }
    },
    loadedPaymentMethods () {
      return this.user ? this.user.paymentMethods.filter(paymentMethod => paymentMethod.isLoaded) : [];
    },
    defaultPaymentMethod () {
      return this.user.privateProfile
        ? this.loadedPaymentMethods.find(paymentMethod => paymentMethod.id === this.user.privateProfile.defaultPaymentMethodId)
        : null;
    }
  },
  methods: {
    onUnAuth () {
      this.stripe = null;
      this.paymentMethodsLoaded = false;
      this.latestSetupIntentClientSecret = "";
    },
    onAuth () {
      loadStripe(this.publishableKey).then(stripe => {
        this.stripe = stripe;

        const setupIntentClientSecret = new URLSearchParams(window.location.search).get(
          "setup_intent_client_secret"
        );
        const paymentIntentClientSecret = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        );

        if (!setupIntentClientSecret && !paymentIntentClientSecret) {
          this.pollForNewSetupIntentClientSecret();
          this.user.paymentMethods; // eslint-disable-line no-unused-expressions
          this.user.promisePaymentMethods.then(() => {
            this.paymentMethodsLoaded = true;
          });
        } else if (setupIntentClientSecret) {
          // API complete
          this.stripe.retrieveSetupIntent(setupIntentClientSecret).then(({ error, setupIntent }) => {
            // If confirmSetup redirect, errors are handled here
            if (error) {
              this.user.privateProfile; // eslint-disable-line no-unused-expressions
              this.user.promisePrivateProfile.then(() => {
                this.user.privateProfile.immediateUpdate({ setupIntentErrorMessage: this.handleStripeIntentError(error, "setup") });
              });
            } else {
              this.onSetupIntentConfirmed(setupIntent);
            }
          });
        } else {
          // API complete
          this.stripe.retrievePaymentIntent(paymentIntentClientSecret).then(({ error, paymentIntent }) => {
            // If confirmPayment redirect, errors are handled here
            if (error) {
              this.cart.result.immediateUpdate({ paymentIntentErrorMessage: this.handleStripeIntentError(error, "payment") });
            } else {
              this.onPaymentIntentConfirmed(paymentIntent);
            }
          });
        }
      });
    },
    async onSetupIntentConfirmed (setupIntent) {
      const verifyWithMicrodeposits = (setupIntent.status === "requires_action") && (setupIntent.next_action && (setupIntent.next_action.type === "verify_with_microdeposits"));
      if (setupIntent.status === "succeeded") {
        this.latestSetupIntentClientSecret = "";
        this.user.paymentMethods; // eslint-disable-line no-unused-expressions
        await this.user.promisePaymentMethods;

        // [TODO] could use webhook instead of creating paymentMethod. possibly other things could be replaced with webhook
        if (!this.user.paymentMethods.find(paymentMethod => paymentMethod.stripePaymentMethodId === setupIntent.payment_method)) {
          this.user.createPaymentMethod({ private: true, stripePaymentMethodId: setupIntent.payment_method }).then(id => {
            this.paymentMethodsLoaded = true;
            if (setupIntent.status === "succeeded") {
              this.user.privateProfile.immediateUpdate({ setupIntentErrorMessage: "", defaultPaymentMethodId: id, microdepositType: "" });
            }
          });
        } else {
          this.paymentMethodsLoaded = true;
        }

        const oldSetupIntentClientSecret = setupIntent.client_secret;
        this.pollForNewSetupIntentClientSecret(oldSetupIntentClientSecret);
      } else if (verifyWithMicrodeposits) {
        this.user.privateProfile.immediateUpdate({ setupIntentErrorMessage: "", microdepositType: setupIntent.next_action.verify_with_microdeposits.microdeposit_type });
      } else {
        // requires_payment_method
        //   I don't think this will happen because to get here you have to input your payment method.
        //   Payment method errors will be handled earlier and you won't get here.
        // requires_confirmation
        //   Won't happen because we just confirmed
        // requires_action
        //   Like for 3d secure authentication, I believe actions will already be handled by confirmSetup.
        //   This could happen when confirmation happens server-side for something like stripe.paymentIntents.create({ confirm: true }).
        // processing
        //   I don't believe this response ever happens for credit cards.
        //   If so, you need to handle a webhook from stripe in order to know when it completes.
        //   https://stripe.com/docs/payments/payment-methods#payment-notification
        // canceled
        await this.user.privateProfile.immediateUpdate({ setupIntentErrorMessage: `Unhandled setupIntent.status occurred (${setupIntent.status}): ${setupIntent.description}` });
        throw new Error(`Unhandled setupIntent.status: ${setupIntent.status} (${JSON.stringify(setupIntent)})`);
      }
    },
    pollForNewSetupIntentClientSecret (oldClientSecret) {
      if (
        this.user &&
        this.user.privateProfile &&
        this.user.privateProfile.readonlyProfile &&
        this.user.privateProfile.readonlyProfile.stripeSetupIntentClientSecret &&
        (!oldClientSecret || (oldClientSecret !== this.user.privateProfile.readonlyProfile.stripeSetupIntentClientSecret))
      ) {
        this.latestSetupIntentClientSecret = this.user.privateProfile.readonlyProfile.stripeSetupIntentClientSecret;
      } else {
        setTimeout(() => {
          this.pollForNewSetupIntentClientSecret(oldClientSecret);
        }, 100);
      }
    },
    returnUrl () {
      const url = new URL(window.location.href);
      url.searchParams.delete("dialog");
      return url.href;
    },
    waitForPaymentIntentUpdate () {
      if (this.cart) {
        this.oldPaymentIntentUpdatedAt = this.cart.result.paymentIntentUpdatedAt;
      }
    },
    onPlaceYourOrder () {
      this.placeOrderButtonDisabled = true;
      this.$nextTick(async () => {
        await this.cart.promiseReadonlyCart;
        const { error, paymentIntent } = await this.stripe.confirmPayment({
          // API complete
          clientSecret: this.cart.readonlyCart.stripePaymentIntentClientSecret,
          confirmParams: {
            return_url: this.returnUrl()
          },
          redirect: "if_required"
        });

        // If not redirect, errors are handled here.
        if (error) {
          this.cart.result.immediateUpdate({ paymentIntentErrorMessage: this.handleStripeIntentError(error, "payment") });
          this.placeOrderButtonDisabled = false;
        } else {
          this.onPaymentIntentConfirmed(paymentIntent);
        }
      });
    },
    onPaymentIntentConfirmed (paymentIntent) {
      if ((paymentIntent.status === "succeeded") || (paymentIntent.status === "processing")) {
        this.cart.result.immediateUpdate({ paymentIntentErrorMessage: "" });
        this.$nextTick(async () => {
          this.router.replace({ name: "ordersShow", params: { id: paymentIntent.id.replace(/^pi_/, "") } })
            .then(() => {
              this.placeOrderButtonDisabled = false;
            })
            .catch((e) => {
              if (!e.message.match(/navigation guard/)) {
                throw e;
              }
            });
        });
      } else {
        // requires_payment_method
        //   I don't think this will happen because to get here you have to input your payment method.
        //   Payment method errors will be handled earlier and you won't get here.
        // requires_confirmation
        //   Won't happen. confirmation just happened.
        // requires_action
        //   Like for 3d secure authentication, I believe actions will already be handled by confirmPayment.
        //   This could happen when confirmation happens server-side for something like stripe.paymentIntents.create({ confirm: true }).
        // processing
        //   I don't believe this response ever happens for credit cards.
        //   If so, you need to handle a webhook from stripe in order to know when it completes.
        //   https://stripe.com/docs/payments/payment-methods#payment-notification
        // canceled
        this.cart.result.immediateUpdate({ paymentIntentErrorMessage: `Unhandled paymentIntent.status occurred (${paymentIntent.status}): ${paymentIntent.description}` });
        throw new Error(`Unhandled paymentIntent.status: ${paymentIntent.status} (${JSON.stringify(paymentIntent)})`);
      }
    },
    handleStripeIntentError (error, type) {
      if (error.type === "card_error") {
        // Can happen on card decline.
        return error.message;
      } else if ((error.type === "invalid_request_error") && (error.code === `${type}_intent_authentication_failure`)) {
        // Can happen when 3d secure authentication fails.
        return error.message;
      } else if ((error.type === "invalid_request_error") && (error.code === `${type}_intent_unexpected_state`)) {
        // Can happen after 3d secure authentication fails and then user tries to submit again without changing payment method.
        return error.message;
      } else {
        // Unhandled error.type:
        //   api_error API errors cover any other type of problem (e.g., a temporary problem with Stripe's servers), and are extremely uncommon.
        //   idempotency_error Idempotency errors occur when an Idempotency-Key is re-used on a request that does not match the first request's API endpoint and parameters.
        throw new Error(`Unhandled ${type}Intent error.type: ${error.type} (${JSON.stringify(error)})`);
      }
    },
    setCurrencyFormatter (currency) {
      this.currencyFormatter = Intl.NumberFormat("en-US", {
        style: "currency",
        currency
      });
    },
    formatPrice (amount) {
      return (amount === null)
        ? ""
        : this.currencyFormatter.format(amount);
    }
  }
});

var StripeManager$1 = StripeManager;

export { StripeManager$1 as default };
