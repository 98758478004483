SHELL=/bin/bash
.PHONY:	run clean

ifeq ($(GUI), 1)
	GUI_ARG := -gui
endif

ifdef UVM_VERBOSITY
	UVM_VERBOSITY_ARG := +UVM_VERBOSITY=$(UVM_VERBOSITY)
endif

ifdef SEED
	SEED_ARG := +ntb_random_seed=$(SEED)
endif

ifdef {{ tb.testParameterPackageMacroName }}
  DEFINE_{{ tb.testParameterPackageMacroName }} := +define+{{ tb.testParameterPackageMacroName }}=$({{ tb.testParameterPackageMacroName }})
endif

# cadence and vcs require compile switch "-timescale 1ns/1ns"
# cadence xcelium requires compile switches "-default_port_array_wire" for parameterized designs.
# vcs requires compile switch "-sverilog"

# -genimage name	Generate a compiled image for later execution; do not run anything.
# -image name	Run the previously compiled image.
# -work path	Set path where DSim will create a directory with temporary files and images.
dsim: incdir.list
	dsim -sv -timescale 1ns/1ns +acc +vpi \
	$(DEFINE_{{ tb.testParameterPackageMacroName }}) \
	+incdir+$${UVM_HOME:?}/src $${UVM_HOME:?}/src/uvm_pkg.sv $${UVM_HOME:?}/src/uvm_macros.svh \
	-sv_lib $${UVM_HOME:?}/src/dpi/libuvm_dpi.so \
	$(shell grep incdir incdir.list) \
	+UVM_TESTNAME=$${UVM_TESTNAME:?} \
	+UVM_MAX_QUIT_COUNT=10,NO
	# $${UVM_HOME:?}/src/dpi/uvm_dpi.cc $(GUI_ARG) $(runopts)

dsim_run: dsim
	dsim -image image -sv_lib $${UVM_HOME:?}/src/dpi/libuvm_dpi.so $(runopts)

simv: incdir.list
	vcs -sverilog -timescale=1ns/1ns +acc +vpi \
	$(DEFINE_{{ tb.testParameterPackageMacroName }}) \
	+incdir+$${UVM_HOME:?}/src $${UVM_HOME:?}/src/uvm.sv $${UVM_HOME:?}/src/uvm_macros.svh \
	$(shell grep incdir incdir.list) \
	-cm line+cond+fsm+branch+tgl -cm_dir ./coverage.vdb \
	$${UVM_HOME:?}/src/dpi/uvm_dpi.cc -CFLAGS -DVCS -R $(GUI_ARG) $(runopts)

run: simv
	./simv $(UVM_VERBOSITY_ARG) +UVM_TESTNAME=$${UVM_TESTNAME:?} $(SEED_ARG) -l vcs.log

clean:
	rm -rf coverage.vdb csrc DVEfiles inter.vpd simv simv.daidir ucli.key vc_hdrs.h vcs.log .inter.vpd.uvm urgReport
