virtual task receive({{ sequenceItemHandle.type }} {{ sequenceItemHandle.identifier }});
{{#if isPipelined}}
  wait_for_num_clocks({{ sequenceItemHandle.identifier }}.{{ sequenceItem.preTransferDelayVariable.identifier }});

  {{> (partial "snippets/bfm-drive-slave-ports-todo")}}

{{/if}}
  // do begin
  //   @{{ interface.slaveClockingBlock.name }};
  // end while (/* request invalid */);

  {{> (partial "snippets/bfm-capture-master-ports-todo")}}
{{#if isPipelined}}

  initialize_signals();
{{/if}}
endtask
