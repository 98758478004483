{{#with context}}
{{#if isDriverPipelined}}
seq_item_port.get(req);
accept_tr(req);
void'(begin_tr(req));
{{else}}
seq_item_port.get_next_item(req);
{{/if}}

if (!$cast(rsp, req.clone())) begin
  `uvm_fatal(get_type_name(), "Could not cast.")
end
rsp.set_id_info(req);

{{#if isDriverPipelined}}
fork
  automatic {{ responseSequenceItemHandle.type }} {{ responseSequenceItemHandle.identifier }} = rsp;
  begin
    {{> (partial "snippets/call-bfm-with-reset") context=. task=../bfmTask}}

    seq_item_port.put({{ responseSequenceItemHandle.identifier }});
    end_tr({{ responseSequenceItemHandle.identifier }});
  end
join_none
{{else}}
{{> (partial "snippets/call-bfm-with-reset") context=. task=../bfmTask}}

seq_item_port.item_done(rsp);
{{/if}}
{{/with}}
