import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";
import { StripePaymentMixin } from "vue-app-utils-web";

const Payment = {
  extends: Model,
  mixins: [
    Persistable,
    StripePaymentMixin
  ],
  data () {
    return {
      licenseId: "",
      licenseTermStartTimestamp: null,
      licenseTermEndTimestamp: null,
      termPaymentId: ""
    };
  },
  computed: {
    isUpgradeOrder () {
      return this.cart.orderType === "upgrade";
    },
    isRenewalOrder () {
      return this.cart.orderType === "renewal";
    },
    isInitialOrder () {
      return !this.isUpgradeOrder && !this.isRenewalOrder;
    },
    termPayment () {
      if (this.isUpgradeOrder) {
        return this.user.payments.find(payment => payment.id === this.termPaymentId);
      } else {
        return this;
      }
    },
    licenseTermStartDate () {
      return this.licenseTermStartTimestamp.toDate();
    },
    licenseTermEndDate () {
      if (this.isUpgradeOrder) {
        return this.termPayment && this.termPayment.licenseTermEndDate;
      } else {
        return this.licenseTermEndTimestamp.toDate();
      }
    },
    type () {
      if (this.isUpgradeOrder) {
        return "Upgrade";
      } else if (this.isRenewalOrder) {
        return "Renewal";
      } else {
        return "License order";
      }
    },
    fullType () {
      if (this.isInitialOrder) {
        return this.type;
      } else {
        return "License " + this.type.toLowerCase();
      }
    },
    license () {
      return this.user.licenses.find(license => license.id === this.licenseId);
    },
    expired () {
      const today = Date.now();
      return this.licenseTermEndDate && (today > this.licenseTermEndDate.getTime());
    },
    latest () {
      return this.license && this.license.latestTermPayment && (this.license.latestTermPayment === this.termPayment);
    },
    active () {
      return this.success && this.latest && !this.expired;
    }
  }
};

export default defineReactiveModel(Payment);
