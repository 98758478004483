{{#with context}}
{{#with ../uvcInstance}}
{{#with (or (and ../isInterfaceHarness interface) protocolChecker)}}
{{ type }}
{{/with}}
  {{#if allParameterAssignmentsWithExpression.length}}
  #(
  {{#each allParameterAssignmentsWithExpression delimeter=","}}
    .{{ parameter.name }} ({{ expression }})
  {{/each}}
  )
  {{/if}}
  {{or (and ../isInterfaceHarness interfaceInstanceName) protocolCheckerInstanceName}} (
    {{#each allPortConnections delimeter=","}}
    .{{ port.identifier }} ({{ expression }})
    {{/each}}
  );
{{/with}}
{{/with}}
