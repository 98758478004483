{{#if interface.hasResetAndOtherPorts}}
virtual task run_phase(uvm_phase phase);
  super.run_phase(phase);

  fork
    initialize_on_reset();
  join_none
{{#if (and false (and isPipelined (and isMaster isResponse)))}}

  fork
    receive_beats();
    drive_ready();
  join_none
{{/if}}
endtask
{{/if}}
