import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";
import TypedefMixin from "@/models/mixins/typedef";

const Typedef = {
  extends: Model,
  mixins: [
    Persistable,
    TypedefMixin
  ],
  data () {
    return {
      type: "",
      name: ""
    };
  }
};

export default defineReactiveModel(Typedef);
