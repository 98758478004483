import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";

const UvcAbstractBfmCreator = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage")
  ],
  created () {
    this.virtual = true;
    this.configDbKey = "bfm_creator";
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-abstract-bfm-creator/pure-methods" }
    ];
  },
  computed: {
    superclass () {
      return null;
    },
    type () {
      return `abstract_${this.uvc.rootName}_bfm_creator_c`;
    }
  }
};

export default defineReactiveModel(UvcAbstractBfmCreator);
