import { defineReactiveModel } from "vue-app-utils";
import SubroutineMixin from "@/models/mixins/subroutine";
import CreatePersistableOnUpdate from "@/models/mixins/create-persistable-on-update";

const Subroutine = {
  mixins: [
    SubroutineMixin,
    CreatePersistableOnUpdate
  ],
  props: {
    parent: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    hasRequiredIdentifier: {
      type: Boolean,
      default: true
    },
    requiredArgs: {
      type: Array,
      default: function () {
        return [];
      }
    },
    requiresUserOverride: {
      type: Boolean,
      default: true
    },
    content: {
      type: String,
      default: ""
    }
  },
  created () {
    this.computed = true;
  },
  computed: {
    mandatory () {
      return this.requiresUserOverride;
    },
    requiredIdentifier () {
      return this.hasRequiredIdentifier
        ? this.identifier
        : "";
    },
    placeholder () {
      return this.content;
    }
  },
  methods: {
    createPersistable (data) {
      return this.parent.createSubroutine({
        overrideId: this.id,
        ...data
      })
        .then(id => {
          return new Promise(resolve => {
            this.findPersistable(id, resolve);
          });
        });
    },
    findPersistable (id, resolve) {
      const persistable = this.parent.subroutines.find(subroutine => subroutine.id === id);
      if (persistable) {
        resolve(persistable);
      } else {
        setTimeout(() => {
          this.findPersistable(id, resolve);
        }, 10);
      }
    }
  }
};

export default defineReactiveModel(Subroutine);
