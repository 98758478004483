{{#foreach handle.identifier iterationPropertiesList}}
  {{> (partial "snippets/create-object") handle=handle iterationPropertiesList=iterationPropertiesList.next nameOverride=nameOverride declareType=declareType assignedToInstanceVariable=assignedToInstanceVariable disableSetterAssignments=disableSetterAssignments appendPartial=appendPartial}}
{{else}}
{{#with handle}}
{{#if ../declareType}}{{ type }} {{/if}}{{#if ../declareType}}{{ shortName }}{{else}}{{ identifier }}{{ iterationPropertiesList.last.brackets }}{{/if}} = {{ type }}::type_id::create({{#if ../assignedToInstanceVariable}}{{#if hasDimensions}}$sformatf("{{ identifier }}{{ uvcOrEnvInstance.formatBrackets }}", {{#each iterationPropertiesList.last.iterationVariables delimeter=", "}}{{ . }}{{/each}}){{else}}"{{ identifier }}"{{/if}}{{else}}"{{or ../nameOverride fullName}}"{{/if}}{{#if klass.isSequence}}, {{#if sequencersUsedInFuture}}{{ sequencerHandle.identifier }}{{else}}, get_full_name(){{/if}}{{else if klass.isComponent}}, this{{/if}});
{{#if klass.isVirtualSequence}}
{{ setSequencersFunctionName }}({{#if ../declareType}}{{ shortName }}{{else}}{{ identifier }}{{/if}}{{#each iterationPropertiesList.last.iterationVariables lc=true delimeter=", "}}{{ . }}{{/each}});
{{/if}}
{{#unless ../disableSetterAssignments}}
{{#each klass.handlesWithSetters}}
{{#unless (eq (lookup ../setterAssignmentOverrides [identifier]) false)}}
{{#if ../../declareType}}{{ ../shortName }}{{else}}{{ ../identifier }}{{ ../iterationPropertiesList.last.brackets }}{{/if}}.{{ setterName }}({{or (lookup ../setterAssignmentOverrides [identifier]) identifier}});
{{/unless}}
{{/each}}
{{/unless}}
{{#if ../appendPartial}}
{{> (lookup .. "appendPartial")}}
{{/if}}
{{/with}}
{{/foreach}}
