{{#with interface.printContextHandle}}
pure virtual function void {{ setterName }}({{ type }} {{ identifier }});
{{/with}}
{{#each bfms}}
pure virtual function {{ ../abstractBfmHandle.type }} {{ createMethod }}(string name, uvm_component parent);
{{/each}}
{{#each bfmsWithSubclasses ln=true}}
pure virtual function uvm_object_wrapper {{ getTypeFunction }}();
{{/each}}
{{#each bfmDeepSubclasses ln=true}}
pure virtual function uvm_object_wrapper {{ getTypeFunction }}();
{{/each}}
