import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import createsUserFilesFromTemplate from "@/models/persistable/mixins/creates-user-files-from-template";
import UvcUserClassCoverageTemplate from "@/models/uvc/uvc-user-class-coverage-template";
import UvmSubscriber from "@/models/uvm/uvm-subscriber";

const UvcCoverage = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("component"),
    // Overrides default subclass template
    createsUserFilesFromTemplate("UserSubclass", UvcUserClassCoverageTemplate)
  ],
  created () {
    this.appendedDeclarationPartials = [
      "snippets/covergroup"
    ];
    this.constructorPartials = [
      "snippets/construct-covergroup"
    ];
    // [TODO] converge
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-coverage/write" }
    ];
  },
  computed: {
    superclass () {
      if (this.subscribedTransaction) {
        return UvmSubscriber.singleton(this.subscribedTransaction);
      } else {
        return undefined;
      }
    },
    subscribedTransaction () {
      return this.sequenceItem;
    },
    type () {
      return `${this.uvc.rootName}_coverage_c`;
    },
    computedInstanceVariables () {
      return [
        this.configHandle
      ]
        .filter(o => o);
    }
  }
};

export default defineReactiveModel(UvcCoverage);
