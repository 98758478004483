import { defineReactiveModel } from "vue-app-utils";
import ParameterMixin from "@/models/mixins/parameter";

const MinOrMaxPackageParameter = {
  mixins: [
    ParameterMixin
  ],
  props: {
    parameter: {
      type: Object,
      required: true
    }
  },
  created () {
    this.type = "parameter";
    this.computed = true;
    this.typeReadonly = true;
    this.nameReadonly = true;
  },
  computed: {
    id () {
      return `static-id-minmax-${this.parameter.id}`;
    },
    name () {
      return [
        this.parameter.parent.rootProject.rootName.toUpperCase(),
        this.parameter.name.toUpperCase(),
        this.parameter.isMsb ? "MAX" : "MIN"
      ].join("_");
    },
    defaultValue () {
      return this.parameter.minOrMaxValue;
    }
  },
  methods: {
    update (data) {
      return this.parameter.update({
        minOrMaxValueOverride: data.defaultValueOverride
      });
    }
  }
};

export default defineReactiveModel(MinOrMaxPackageParameter);
