import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Handle from "@/models/handle";
import Variable from "@/models/variable";
import get from "lodash.get";

const UvcMasterVirtualSequence = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("virtual-sequence")
  ],
  created () {
    this.master = true;
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-master-virtual-sequence/body" }
    ];
  },
  computed: {
    superclass () {
      return this.virtualSequenceBase;
    },
    type () {
      return `${this.uvc.name}_${this.protocolRoleWord}_virtual_sequence_c`;
    },
    computedInstanceVariables () {
      return [
        this.numTransactionsVariable,
        this.killedByResetVariable
      ]
        .filter(o => o);
    },
    numTransactionsVariable () {
      return this.requestSequenceHandle && new Variable({
        propsData: {
          parent: this,
          type: "int",
          name: this.requestSequenceHandle.numTransactionsVariableName,
          setter: true,
          unsetValueOverride: "0"
        }
      });
    },
    killedByResetVariable () {
      return new Variable({
        propsData: {
          parent: this,
          type: "bit",
          name: "killed_by_reset"
        }
      });
    },
    requestSequenceHandle () {
      return this.isPipelinedWrite
        ? this.requestVirtualSequenceHandle
        : this.requestSequenceBaseHandle;
    },
    requestSequenceBaseHandle () {
      const requestSequencerHandle = get(this, "virtualSequenceBase.requestSequencerHandle");
      return this.masterRequestSequenceBase && requestSequencerHandle && new Handle({
        propsData: {
          parent: this,
          klass: this.masterRequestSequenceBase,
          nameOverride: "req_seq",
          sequencerHandleOverride: requestSequencerHandle
        }
      });
    },
    requestVirtualSequenceHandle () {
      return this.masterRequestVirtualSequence && new Handle({
        propsData: {
          parent: this,
          klass: this.masterRequestVirtualSequence,
          nameOverride: "req_vseq"
        }
      });
    },
    responseSequenceHandle () {
      const responseSequencerHandle = get(this, "virtualSequenceBase.responseSequencerHandle");
      return this.requestItemHandle && this.masterResponseSequenceBase && responseSequencerHandle && new Handle({
        propsData: {
          parent: this,
          klass: this.masterResponseSequenceBase,
          nameOverride: "rsp_seq",
          createRandomFunctionArgumentHandles: [
            this.requestItemHandle,
            this.dataItemHandle
          ]
            .filter(h => h),
          sequencerHandleOverride: responseSequencerHandle
        }
      });
    },
    createRandomResponseSequenceArgs () {
      const args = {};
      if (this.isPipelinedWrite && this.requestItemHandle && get(this.requestVirtualSequenceHandle, "klass.requestSequenceHandle")) {
        args[this.requestItemHandle.identifier] = `${this.requestVirtualSequenceHandle.identifier}.${this.requestVirtualSequenceHandle.klass.requestSequenceHandle.identifier}.rsp`;
        args[this.dataItemHandle.identifier] = `${this.requestVirtualSequenceHandle.identifier}.${this.requestVirtualSequenceHandle.klass.dataSequenceHandle.identifier}.${this.requestVirtualSequenceHandle.klass.dataSequenceHandle.klass.dataItemHandle.identifier}`;
      } else if (this.requestItemHandle && this.requestSequenceHandle) {
        args[this.requestItemHandle.identifier] = `${this.requestSequenceHandle.identifier}.rsp`;
      }
      return args;
    },
    requestItemHandle () {
      return this.sequenceItem && new Handle({
        propsData: {
          parent: this,
          klass: this.sequenceItem,
          nameOverride: "request_item"
        }
      });
    },
    dataItemHandle () {
      return this.sequenceItem && this.isPipelinedWrite && new Handle({
        propsData: {
          parent: this,
          klass: this.sequenceItem,
          nameOverride: "data_item"
        }
      });
    },
    randomObjectCreateHandles () {
      return [
        this.requestSequenceHandle,
        this.responseSequenceHandle
      ]
        .filter(o => o);
    },
    randomObjectDoHandles () {
      return this.randomObjectCreateHandles;
    }
  }
};

export default defineReactiveModel(UvcMasterVirtualSequence);
