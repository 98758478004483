{{#if (not subclasses.length)}}
covergroup cg() with function sample({{#with subscribedTransactionHandle}}{{ type }} {{ identifier }}{{/with}});

  option.per_instance = 1;
  option.name = get_full_name();

{{#each (or (and subscribedTransactionHandle subscribedTransactionHandle.klass.allCoverableInstanceVariables) allCoverableInstanceVariables)}}
{{#if coverpoint}}
  {{#eachLine coverpoint}}
  {{.}}
  {{/eachLine}}
{{else}}
{{#if ../userFile}}
  // {{ identifier }} : coverpoint {{#with ../subscribedTransactionHandle}}{{ identifier }}.{{/with}}{{ name }};
{{/if}}
{{/if}}
{{/each}}

endgroup
{{/if}}
