import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";
import { StripeCartMixin } from "vue-app-utils-web";

const Cart = {
  extends: Model,
  mixins: [
    Persistable,
    StripeCartMixin
  ],
  data () {
    return {
      orderType: "",
      licenseId: "",
      newLicenseBundleCreated: false,
      upgradeLicenseBundleCreated: false,
      licenseDurationInMonths: 12,
      tbQuantity: null,
      envQuantity: null,
      uvcQuantity: null,
      uvcPipelinedQuantity: null,
      uvcDevTbQuantity: null,
      envInstanceQuantity: null,
      uvcInstanceQuantity: null
    };
  },
  computed: {
    isUpgradeOrder () {
      return this.orderType === "upgrade";
    },
    isRenewalOrder () {
      return this.orderType === "renewal";
    },
    isInitialOrder () {
      return !this.isUpgradeOrder && !this.isRenewalOrder;
    }
  }
};

export default defineReactiveModel(Cart);
