import { defineReactiveModel } from "vue-app-utils";
import VariableBase from "@/models/variable-base";
import pluralize from "pluralize-esm";

const Handle = {
  extends: VariableBase,
  props: {
    // [TODO] not well named because it's used for references to classes but
    // also to enum definitions. low priority. ref would be a good name.
    klass: {
      type: Object,
      required: true
    },
    nameOverride: {
      type: String,
      default: ""
    },
    shortHandleName: {
      type: String,
      default: ""
    },
    isRand: {
      type: Boolean,
      default: false
    },
    isProt: {
      type: Boolean,
      default: false
    },
    isRandomRefItem: {
      type: Boolean,
      default: false
    },
    parameters: {
      type: Array,
      default: function () {
        return [];
      }
    },
    priority: {
      type: String,
      default: null
    },
    setterAssignmentOverrides: {
      type: Object,
      default: function () {
        return {};
      }
    },
    createRandomFunctionArgumentHandles: {
      type: Array,
      default: function () {
        return [];
      }
    },
    sequencerHandleOverride: {
      type: Object,
      default: null
    },
    useSpecificNameOverride: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    configDbKey () {
      return this.klass.configDbKey;
    },
    type () {
      const identifiers = [this.rootTypeWithParameters];
      if (this.isRand) {
        identifiers.unshift("rand");
      } else if (this.isProt) {
        identifiers.unshift("protected");
      }
      return identifiers.join(" ");
    },
    rootTypeWithParameters () {
      if (this.parameters.length) {
        return `${this.klass.type}#(${this.parameters.join(", ")})`;
      } else {
        return this.klass.type;
      }
    },
    name () {
      if (this.nameOverride) {
        return this.nameOverride;
      } else {
        let name = this.useSpecificName ? this.specificName : this.genericName;
        name = this.hasDimensions ? pluralize(name) : name;
        return this.uvcOrEnvInstance.unpackedDeclarationBrackets ? `${name}${this.uvcOrEnvInstance.unpackedDeclarationBrackets}` : name;
      }
    },
    useSpecificName () {
      return (this.parent.isVirtualSequence && this.klass.isSequence && (this.klass.rootProject !== this.parent.rootProject) && !this.klass.isPerUvcInstanceTbClass) || this.useSpecificNameOverride;
      // return (this.parent.isVirtualSequence && (this.klass.isSequence && !this.klass.isVirtualSequence)) || this.useSpecificNameOverride;
    },
    // [TODO] all the properties below which are based on klass might need to be moved to variable-common so that it can be used by persistable/instance-variable.
    //  Waiting for a need though. When it is moved, anytime klass is used it needs to be guarded by (this.klass && this.klass.property) because variables
    //  like int's will not have klass defined.
    specificName () {
      // [TODO] is this a performance issue?
      return this.fullName
        .replace(/_[ce]$/, "")
        .replace(/_env_parameters$/, "_params")
        .replace(/_parameters$/, "_params")
        // .replace(/_env_config$/, "_env_cfg")
        .replace(/_config$/, "_cfg")
        .replace(/_sequence_item$/, "_item")
        .replace(/_virtual_sequence$/, "_vseq")
        .replace(/_virtual_sequence_base$/, "_vseq")
        .replace(/_sequence$/, "_seq")
        .replace(/_sequence_base$/, "_seq")
        .replace(/_sequencer$/, "_seqr")
        .replace(/_monitor$/, "_mon")
        .replace(/_master_driver$/, "_drv")
        .replace(/_master_request_driver$/, "_req_drvr")
        .replace(/_master_data_driver$/, "_data_drvr")
        .replace(/_master_response_receiver$/, "_rsp_rcvr")
        .replace(/_slave_driver$/, "_drv")
        .replace(/_slave_request_receiver$/, "_req_rcvr")
        .replace(/_slave_data_receiver$/, "_data_rcvr")
        .replace(/_slave_response_driver$/, "_rsp_drvr")
        .replace(/_driver$/, "_drv")
        .replace(/_agent$/, "_agt")
        .replace(/_scoreboard$/, "_sb")
        // .replace(/_predictor_base$/, "_predictor")
        .replace(/_coverage$/, "_cov")
        // .replace(/_bfm_creator$/, "_creator")
        .replace(/_protocol_checker_accessor$/, "_accessor")
        .replace(/_signal_checker_accessor$/, "_accessor")
        .replace(/^abstract_/, "");
    },
    fullName () {
      const name = this.klass.type.replace(/_c$/, "");
      if (this.uvcOrEnvInstance.suffix && !this.klass.isPerUvcInstanceTbClass) {
        if (name.match(/_virtual_sequence$/)) {
          return name.replace(/_virtual_sequence$/, "_" + this.uvcOrEnvInstance.suffix + "_virtual_sequence");
        } else if (name.match(/_virtual_sequence_base$/)) {
          return name.replace(/_virtual_sequence_base$/, "_" + this.uvcOrEnvInstance.suffix + "_virtual_sequence_base");
        } else if (name.match(/_sequence$/)) {
          return name.replace(/_sequence$/, "_" + this.uvcOrEnvInstance.suffix + "_sequence");
        } else if (name.match(/_sequence_base$/)) {
          return name.replace(/_sequence_base$/, "_" + this.uvcOrEnvInstance.suffix + "_sequence_base");
        } else if (name.match(/_predictor$/)) {
          return name.replace(/_predictor$/, "_" + this.uvcOrEnvInstance.suffix + "_predictor");
        } else {
          return name;
        }
      } else {
        return name;
      }
    },
    genericName () {
      return this.klass.rootProject
        ? this.specificName.replace(new RegExp(`^(abstract_)?${this.klass.rootProject.name}_`), "")
        : this.specificName.replace(/^[a-zA-Z0-9]+_/, "");
    },
    iterationCharacter () {
      return this.klass.reachableIterationCharacter;
    },
    setSequencersFunctionName () {
      if (!this.parent.project.isUvc && this.klass.project.isUvc) {
        return [
          "set",
          this.klass.rootProject.name,
          this.klass.master ? "master" : "slave",
          this.uvcOrEnvInstance.suffix,
          "sequencers"
        ]
          .filter(s => s)
          .join("_");
      } else {
        return "set_sequencers";
      }
    },
    startSequenceTaskName () {
      return `start_${this.klass.isVirtualSequence ? this.identifier : pluralize(this.identifier)}`;
    },
    writeFunctionName () {
      return `write_${this.klass.typeSuffix}`;
    },
    numTransactionsVariableName () {
      return [
        `num_${this.klass.rootProject.name}`,
        this.uvcOrEnvInstance.suffix,
        "transactions"
      ]
        .filter(s => s)
        .join("_");
    }
  }
};

export default defineReactiveModel(Handle);
