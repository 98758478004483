import { initializeApp } from 'firebase/app';

let firebaseApp = null;

const getFirebaseApp = function () {
  if (!firebaseApp) {
    const config = {
      apiKey: import.meta.env.VITE_APP_FIRESTORE_API_KEY,
      authDomain: import.meta.env.VITE_APP_FIRESTORE_AUTH_DOMAIN,
      databaseURL: import.meta.env.VITE_APP_FIRESTORE_DATABASE_URL,
      projectId: import.meta.env.VITE_APP_FIRESTORE_PROJECT_ID,
      storageBucket: import.meta.env.VITE_APP_FIRESTORE_STORAGE_BUCKET,
      messagingSenderId: import.meta.env.VITE_APP_FIRESTORE_MESSAGING_SENDER_ID,
      appId: import.meta.env.VITE_APP_FIRESTORE_APP_ID,
      measurementId: import.meta.env.VITE_APP_FIRESTORE_MEASUREMENT_ID
    };

    firebaseApp = initializeApp(config);
  }
  return firebaseApp;
};

export { getFirebaseApp };
