import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";
import ConstraintMixin from "@/models/mixins/constraint";

const Constraint = {
  extends: Model,
  mixins: [
    ConstraintMixin,
    Persistable
    // belongsTo("instanceVariable")
  ],
  data () {
    return {
      overrideName: "",
      content: "",
      overrideId: "",
      instanceVariableId: "",
      deleted: false
    };
  },
  /*
  created () {
    this.prereqPromises = [
      new Promise(resolve => {
        this.instanceVariableResolve = resolve;
      })
    ];

    const unwatch = this.$watch("instanceVariable", (instanceVariable) => {
      if (instanceVariable) {
        this.instanceVariableResolve(instanceVariable);
        this.$nextTick(() => { unwatch(); });
      }
    }, { immediate: true });
  },
  */
  computed: {
    instanceVariable () {
      return this.instanceVariableId
        ? (this.parent.classToSearchForInstanceVariables || this.parent)
          .allReachableInstanceVariables.find(iv => iv.id === this.instanceVariableId)
        : null;
    },
    placeholder () {
      return this.overriddenConstraint
        ? this.overriddenConstraint.content
        : (this.instanceVariable ? `${this.parent.isSequenceItem ? "" : "object."}${this.instanceVariable.identifier} == 0;` : "");
    },
    overriddenConstraint () {
      return this.parent.computedConstraints.find(computedConstraint => computedConstraint.id === this.overrideId);
    }
  }
};

export default defineReactiveModel(Constraint);
