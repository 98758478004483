import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";
import GeneratableFile from "@/models/mixins/generatable-file";

const Include = {
  extends: Model,
  mixins: [
    GeneratableFile,
    Persistable
  ],
  data () {
    return {
      fileNameSuffix: "",
      content: ""
    };
  },
  created () {
    this.isInclude = true;
  },
  computed: {
    type () {
      return `${this.parent.type}_${this.fileNameSuffix}`;
    },
    rootProject () {
      return this.parent.rootProject;
    },
    project () {
      return this.parent.project;
    },
    code () {
      return this.content;
    },
    // todo () {
    //   return "Add subroutine";
    // },
    placeholder () {
      return "";
    }
  }
};

export default defineReactiveModel(Include);
