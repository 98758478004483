const waitForSnapshot = function (obj, startSnapshot) {
  if (startSnapshot) {
    // return obj.promiseOnSnapshot.then(() => {
    return Promise.all([obj.promiseOnSnapshot, ...obj.prereqPromises]).then(() => {
      return obj;
    });
  } else {
    return obj;
  }
};

function findById (hasOneItemName, hasOneCollectionName, hasOneClassName) {
  return function (id, startSnapshot = false) {
    const { getDoc, doc, collection } = this.dbFns;
    const HasOneModel = this.getModel(hasOneItemName);
    const docRef = doc(collection(this.db, hasOneCollectionName), id);

    return this.$store.dispatch(
      "trackTransaction",
      getDoc(docRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const propsData = {
              docRef: docSnapshot.ref
            };
            // [TODO] remove onFindPromise related comments after soak. Was just being used for updating ip location data. Remove onFinUser in ip-data-mixin too. Not crucial to keep updating.
            // let onFindPromise;
            if (!this.docType) {
              throw new Error("docType is not defined.");
            } else if (this.docType === "session") ; else if (this.docType === "user") { // user finding profile
              propsData.user = this;
              propsData.parent = this;
            } else { // profile finding sub-profile
              propsData.user = this.user;
              propsData[this.docType] = this;
              propsData.parent = this;
            }
            propsData.startSnapshot = startSnapshot;
            propsData.docType = hasOneItemName;

            // if (this[`onFind${hasOneClassName}`]) {
            //   onFindPromise = this[`onFind${hasOneClassName}`](docSnapshot.data());
            // } else {
            //   onFindPromise = Promise.resolve({});
            // }

            // return onFindPromise.then((additionalData) => {
            //   if ((Object.entries(additionalData).length > 0) &&
            //     (
            //       (!this.$store.getters.user && (hasOneCollectionName === "users")) || // creating authenticated user object
            //       (this.$store.getters.user && (this.$store.getters.user.id === docSnapshot.data().userId)) // ensuring ownership/writability of document
            //     )
            //   ) {
            //     return setDoc(docRef, additionalData, { merge: true })
            //       .then(() => {
            //         const obj = new HasOneModel({ propsData });
            //         return waitForSnapshot(obj, startSnapshot);
            //       })
            //       .catch((e) => {
            //         const error = new Error("utils::findFn::set: " + e.message);
            //         error.name = e.name;
            //         throw error;
            //       });
            //   } else {
            const obj = new HasOneModel({ propsData });
            return waitForSnapshot(obj, startSnapshot);
            //   }
            // });
          }
        })
        .catch((e) => {
          if ((e.name === "FirebaseError") && (e.message.match(/^Failed to get document because the client is offline.$/))) {
            console.error(e);
          } else {
            const error = new Error(`utils::findFn::get for ${hasOneCollectionName} (${id}): ${e.message}`);
            error.name = e.name;
            throw error;
          }
        })
    );
  };
}

export { findById as default };
