{{#if isPipelinedWrite}}
virtual task receive_responses();
{{else}}
virtual task receive_beats();
{{/if}}
  {{ sequenceItemHandle.klass.type }} {{ sequenceItemHandle.identifier }};

  forever begin
    // do begin
    //   @{{ interface.masterClockingBlock.name }};
    // end while (/* beat invalid */);

    // {{ sequenceItemHandle.identifier }} = get_transaction(/* id */);

    {{> (partial "snippets/bfm-capture-slave-response-ports-todo")}}

  {{#if isPipelinedWrite}}
    item.resp_received();
  {{else}}
    {{ sequenceItemHandle.identifier }}.{{ sequenceItemHandle.klass.beatIndexVariable.identifier }}++;
  {{/if}}
  end
endtask
