import { VIcon } from 'vuetify/components';
import { h } from 'vue';

function createSignInComponent (icon, title, message) {
  return {
    components: {
      VIcon
    },
    render: function () {
      // <v-icon size="75" color="primary">mdi-message-alert</v-icon>
      const vIcon = h(
        VIcon,
        {
          size: "75",
          color: "primary",
          icon
        }
      );

      const vIconDiv = h(
        "div",
        {},
        [
          vIcon
        ]
      );

      // <div class="pt-6 text-h5">
      //   Send feedback
      // </div>
      const titleDiv = h(
        "div",
        {
          class: {
            "pt-6": true,
            "text-h5": true
          }
        },
        [
          title
        ]
      );

      // <div class="pt-4 pb-6 px-2 text-body-1 font-weight-light">
      //   You must sign in as non-guest to get a reply.
      // </div>
      const messageDiv = h(
        "div",
        {
          class: {
            "pt-4": true,
            "pb-6": true,
            "px-2": true,
            "text-body-1": true,
            "font-weight-light": true
          }
        },
        [
          message
        ]
      );

      const topDiv = h(
        "div",
        {
          class: {
            "text-center": true
          }
        },

        [
          vIconDiv,
          titleDiv,
          messageDiv
        ]
      );

      return topDiv;
    }
  };
}

export { createSignInComponent as default };
