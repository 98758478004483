import { defineReactiveModel } from "vue-app-utils";
import Lib from "@/models/lib";
import PolicyPackage from "@/models/policy-package";
import get from "lodash.get";

const PolicyLib = {
  extends: Lib,
  data () {
    return {
    };
  },
  created () {
    this.name = "policy";
  },
  computed: {
    rootGeneratableFileModels () {
      return [
        this.package
      ]
        .filter(m => m);
    },
    generatableFileModels () {
      return [
        ...get(this, "package.generatableFileModels", []),
        ...this.rootGeneratableFileModels
      ]
        .filter(m => m);
    },
    package () {
      return new PolicyPackage({
        propsData: {
          user: this.user,
          parent: this
        }
      });
    }
  }
};

export default defineReactiveModel(PolicyLib);
