import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";
import { StripeReadonlyCartMixin } from "vue-app-utils-web";

const ReadonlyCart = {
  extends: Model,
  mixins: [
    Persistable,
    StripeReadonlyCartMixin
  ],
  data () {
    return {
      priceAdjustmentFunction: ""
    };
  },
  computed: {
    priceAdjustmentFunctionPlaceholder () {
      if (this.cart.isRenewalOrder) {
        return `function ({ licenseDurationInMonths, monthlyPriceWithAllPreviousDiscounts: price }) {
  const priceAfterRenewalDiscount = price - (price * 0.10); // 10% off
  return +priceAfterRenewalDiscount.toFixed(2);
}`;
      } else {
        return `function ({ feature, licenseDurationInMonths, quantity, monthlyPriceWithGlobalDiscount: price }) {
  const priceAfterCartDiscount = price - (price * 0.10); // 10% off
  return +priceAfterCartDiscount.toFixed(2);
}`;
      }
    }
  }
};

export default defineReactiveModel(ReadonlyCart);
