import { defineReactiveModel } from "vue-app-utils";
import Model from "@/models/model";
import GeneratableFile from "@/models/mixins/generatable-file";
import Package from "@/models/mixins/package";
import uvmPackage from "@/models/uvm/uvm-package";
import PolicyBase from "@/models/policy-base";

const PolicyPackage = {
  extends: Model,
  mixins: [
    GeneratableFile,
    Package
  ],
  props: {
    user: {
      type: Object,
      required: true
    },
    parent: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    };
  },
  computed: {
    key () {
      return `${this.type}-93hgh8e409weg5`;
    },
    rootProject () {
      return this.parent;
    },
    project () {
      return this.parent;
    },
    type () {
      return "policy_pkg";
    },
    staticImports () {
      return [
        uvmPackage
      ];
    },
    baseGeneratableFileModels () {
      return [
        this.policyBase
      ]
        .filter(m => m);
    },
    generatableFileModels () {
      return [
        ...this.baseGeneratableFileModels
      ]
        .filter(m => m);
    },
    modelPairs () {
      let previous = null;
      return this.generatableFileModels
        .map(current => {
          const ret = {
            previous,
            current
          };
          previous = current;
          return ret;
        });
    },
    policyBase () {
      return new PolicyBase({
        propsData: {
          parent: this
        }
      });
    }
  }
};

export default defineReactiveModel(PolicyPackage);
