{{#if isPipelinedWrite}}
virtual task write_request_item({{ sequenceItem.type }} item);
  if (!{{ dataItemsHandle.identifier }}.exists(item.{{ sequenceItem.idVariable.identifier }})) begin
    {{ requestItemsHandle.identifier }}[item.{{ sequenceItem.idVariable.identifier }}] = item;
  end else begin
    {{#with dataItemHandle}}{{ type }} {{ identifier }}{{/with}} = {{ dataItemsHandle.identifier }}[item.{{ sequenceItem.idVariable.identifier }}];
    {{ dataItemsHandle.identifier }}.delete(item.{{ sequenceItem.idVariable.identifier }});
    send_response(item, {{ dataItemHandle.identifier }});
  end
endtask
{{/if}}
