import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import UvmSequencer from "@/models/uvm/uvm-sequencer";

const UvcSequencer = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage")
  ],
  created () {
    this.iterationCharacter = "s";
  },
  computed: {
    superclass () {
      return this.sequenceItem
        ? UvmSequencer.singleton(this.sequenceItem)
        : null;
    },
    type () {
      return `${this.uvc.rootName}_sequencer_c`;
    },
    computedInstanceVariables () {
      return [
        this.configHandle
      ]
        .filter(o => o);
    }
  }
};

export default defineReactiveModel(UvcSequencer);
