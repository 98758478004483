import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import UvmComponent from "./uvm-component";

const UvmAgent = defineReactiveModel({
  extends: Class,
  created () {
    this.isUvmBaseClass = true;
  },
  computed: {
    isAgent () {
      return true;
    },
    superclass () {
      return UvmComponent.singleton();
    },
    type () {
      return "uvm_agent";
    }
  }
});

let singleton;

UvmAgent.singleton = function () {
  singleton ||= new UvmAgent();
  return singleton;
};

export default UvmAgent;
