import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Policy from "@/models/mixins/policy";
import Constraint from "@/models/constraint";

const UvcSlaveOutOfOrderResponsePrioritiesPolicy = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    UvcProtocolRoleGetters,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("policy"),
    Policy
  ],
  created () {
    this.master = false;
  },
  computed: {
    subject () {
      return this.config;
    },
    type () {
      const parts = [this.uvc.rootName];
      parts.push(this.protocolRoleWord);
      parts.push("out_of_order_response");
      if (this.isPipelinedRead) {
        parts.push("priorities");
      }
      if (this.isPipelinedWrite) {
        parts.push("priority");
      }
      parts.push("policy");
      parts.push("c");
      return parts.join("_");
    },
    computedConstraints () {
      return [
        this.responsePriorityWindowWidthConstraint
      ]
        .filter(c => c);
    },
    responsePriorityWindowWidthConstraint () {
      if (this.config) {
        const variable = this.isPipelinedWrite
          ? this.config.responsePriorityWindowWidthVariable
          : this.config.beatPriorityWindowWidthVariable;
        const content = this.isPipelinedWrite
          ? `object.${variable.identifier} == (object.${this.config.numInProgressTransactionsMaxVariable.identifier} * 2);`
          : `object.${variable.identifier} == object.${this.config.numInProgressTransactionsMaxVariable.identifier};`;
        return variable && new Constraint({
          propsData: {
            parent: this,
            instanceVariable: variable,
            content
          }
        });
      } else {
        return null;
      }
    }
  }
};

export default defineReactiveModel(UvcSlaveOutOfOrderResponsePrioritiesPolicy);
