import { defineReactiveModel } from "vue-app-utils";
import ConstraintMixin from "@/models/mixins/constraint";
import CreatePersistableOnUpdate from "@/models/mixins/create-persistable-on-update";

const Constraint = {
  mixins: [
    ConstraintMixin,
    CreatePersistableOnUpdate
  ],
  props: {
    parent: {
      type: Object,
      default: null
    },
    instanceVariable: {
      type: Object,
      required: true
    },
    overrideName: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    }
  },
  created () {
    this.computed = true;
  },
  computed: {
    id () {
      return `${this.parent.uniqueId}--${this.instanceVariable.id}`;
    }
  },
  methods: {
    createPersistable (data) {
      return this.parent.createConstraint({
        overrideId: this.id,
        instanceVariableId: this.instanceVariable.id,
        ...data
      })
        .then(id => {
          return new Promise(resolve => {
            this.findPersistable(id, resolve);
          });
        });
    },
    findPersistable (id, resolve) {
      const persistable = this.parent.constraints.find(constraint => constraint.id === id);
      if (persistable) {
        resolve(persistable);
      } else {
        setTimeout(() => {
          this.findPersistable(id, resolve);
        }, 10);
      }
    }
  }
};

export default defineReactiveModel(Constraint);
