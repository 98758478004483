import { defineReactiveModel, Persistable, hasOne, hasMany } from "vue-app-utils";
import session from "@/models/session-VITE_APP_PLATFORM";
import Model from "@/models/model";
import userFileRelations from "@/models/persistable/mixins/user-file-relations-VITE_APP_PLATFORM";
import config from "@/config";
import store from "@/store";
import { path } from "@/utils/node-VITE_APP_PLATFORM";
import parseSystemVerilog from "@/utils/parse-system-verilog";
import Handle from "@/models/handle";
import get from "lodash.get";

const { hasMany: hasManyUserFiles } = userFileRelations;

const Env = {
  extends: Model,
  mixins: [
    Persistable,
    hasOne("envTb"),
    hasMany("envUvcInstances"), //, {
    //   orderBy: ["name"]
    // }),
    hasMany("envEnvInstances"),
    hasOne("envDut"),
    hasOne("envPackage"),
    // [TODO] remove when no default params tested on questa and vcs
    // hasOne("envDefaultParameterPackage"),
    hasOne("envInterfaceHarness"),
    hasOne("envProtocolCheckerHarness"),
    hasOne("envSignalChecker"),
    hasManyUserFiles("userIncludes", {
      directory: function () {
        return config.userIncludesPath();
      }
    })
  ],
  data () {
    return {
      isLoaded: false,
      hasTbOverride: null,
      hasEnvCoverageOverride: null,
      hasProtocolCheckerHarnessOverride: null,
      hasSignalCheckerOverride: null,
      numDownloads: 0,
      uvmgenUser: null
    };
  },
  watch: {
    // [TODO] Replaced with load happening in envUvcInstance and envEnvInstance as a prerequisitePromise. remove after soak.
    // envUvcInstances: {
    //   lazy: true,
    //   handler: function (envUvcInstances) {
    //     envUvcInstances.forEach(uvcInstance => {
    //       const loadTb = false;
    //       uvcInstance.uvcPromise().then(uvc => uvc.load(loadTb));
    //     });
    //   }
    // }
    // envEnvInstances: {
    //   // [TODO] this i don't think is right. need to fix
    //   lazy: true,
    //   handler: function (envEnvInstances) {
    //     console.log(envEnvInstances.length);
    //     envEnvInstances.forEach(envInstance => {
    //       envInstance.envPromise().then(env => console.log("did it") || env.load());
    //     });
    //   }
    // }
  },
  created () {
    this.isEnv = true;

    this.uvmgenUserPromise = new Promise(resolve => {
      session.findUserById("uvmgen-user-id").then(user => {
        this.uvmgenUser = user;
        resolve();
      });
    });
  },
  computed: {
    rootProject () {
      return this;
    },
    project () {
      return this;
    },
    // ${cwd}/vip/envs
    collectionDirectory () {
      return path.join(store.getters.topDir, store.getters.user.setting.relVipDir, this.collectionName);
    },
    collectionName () {
      return "envs";
    },
    // apb_relay
    projectDirectory () {
      return this.name;
    },
    dut () {
      return this.envDut;
    },
    name () {
      return get(this, "dut.type", "");
    },
    rootName () {
      return this.name;
    },
    hasName () {
      return !!this.name;
    },
    tb () {
      return this.hasTb
        ? this.envTb
        : null;
    },
    hasTb () {
      if (this.hasTbOverride !== null) {
        return this.hasTbOverride;
      } else {
        return this.hasTbDefault;
      }
    },
    hasTbDefault () {
      return false;
    },
    rootGeneratableFileModels () {
      return this.hasName
        ? [
          this.package,
          // this.defaultParameterPackage,
          this.interfaceHarness,
          this.protocolCheckerHarness,
          this.signalChecker
        ]
          .filter(m => m)
        : [];
    },
    // generating () {
    //   return (this.generatableFileModels.length === 0) || this.generatableFileModels.some(m => m.generating);
    // },
    generatableFileModels () {
      return this.hasName
        ? [
          ...get(this, "package.generatableFileModels", []),
          ...this.rootGeneratableFileModels
        ].filter(m => m)
        : [];
    },
    allGeneratableFileModels () {
      return this.generatableFileModels.concat(this.generatableFileModelsNotRendered);
    },
    generatableFileModelsNotRendered () {
      return this.deepUniqueUvcs
        .concat(this.deepUniqueEnvs)
        .map(project => project.generatableFileModels)
        .flat()
        .concat(this.user.policyLib ? this.user.policyLib.generatableFileModels : [])
        .concat((this.hasScoreboard && this.user.comparatorLib) ? this.user.comparatorLib.generatableFileModels : [])
        .concat(this.user.softwareLicenseAgreement);
    },
    computeGeneratableFileModelsNotRendered () {
      if (this.isLoaded) {
        this.generatableFileModelsNotRendered.forEach(model => {
          model.absoluteFilePath; // eslint-disable-line no-unused-expressions
          model.projectFilePath; // eslint-disable-line no-unused-expressions
          model.fileContents; // eslint-disable-line no-unused-expressions
        });
      }
    },
    nonUserClasses () {
      return [
        ...this.rootGeneratableFileModels,
        ...get(this, "package.baseGeneratableFileModels", []),
        ...get(this, "package.formClasses", [])
        // ...get(this, "package.formSubclasses", []),
        // ...get(this, "package.formMixins", []),
        // ...get(this, "package.formPolicies", []),
        // UvmSequence.singleton(this.sequenceItem),
        // UvmSequence.singleton(this.registerSequenceItem)
      ]
        .filter(m => m)
        .filter(model => model.isClass);
    },
    nonUserClassesByUniqueId () {
      const classesByUniqueId = {};
      this.nonUserClasses.forEach(c => {
        classesByUniqueId[c.uniqueId] = c;
      });
      return classesByUniqueId;
    },
    allClasses () {
      return this.classes
        .concat(this.uvcInstances.map(uvcInstance => uvcInstance.uvc && uvcInstance.uvc.classes).filter(a => a).flat());
    },
    classes () {
      return this.nonUserClasses
        .concat(get(this, "package.userClasses", []));
    },
    classesBySuperclassUniqueId () {
      const ret = {};
      this.classes.forEach(klass => {
        if (klass.superclass && klass.superclass.uniqueId) {
          ret[klass.superclass.uniqueId] = ret[klass.superclass.uniqueId] || [];
          ret[klass.superclass.uniqueId].push(klass);
        }
      });
      return ret;
    },
    policies () {
      return this.classes.filter(klass => klass.isPolicy);
    },
    classesByType () {
      const classesByType = {};
      this.allClasses.forEach((c) => {
        classesByType[c.type] = c;
      });
      return classesByType;
    },
    factoryOverrideBfmClasses () {
      return this.deepUniqueUvcs.map(uvc => uvc.package.formSubclasses).flat();
    },
    factoryOverrideSequenceClasses () {
      return this.deepUniqueUvcs.map(uvc => uvc.package.formSequences).flat();
    },
    factoryOverrideConfigurationClasses () {
      return this.deepUniqueEnvsAndMe.map(env => env.envConfig ? env.envConfig.deepSubclasses : []).flat();
    },
    factoryOverrides () {
      return this.generatableFileModels.map(model => {
        if (model.factoryOverride) {
          return {
            base: model,
            override: model.factoryOverride
          };
        } else {
          return null;
        }
      })
        .filter(o => o);
    },
    package () {
      return this.envPackage;
    },
    interfaceHarness () {
      return this.envInterfaceHarness;
    },
    protocolCheckerHarness () {
      return this.hasProtocolCheckerHarness
        ? this.envProtocolCheckerHarness
        : null;
    },
    hasProtocolCheckerHarness () {
      if (this.hasProtocolCheckerHarnessOverride !== null) {
        return this.hasProtocolCheckerHarnessOverride;
      } else {
        return !!get(this.envProtocolCheckerHarness, "uvcInstancesToInstantiate.length", 0);
      }
    },
    signalChecker () {
      return this.hasSignalChecker
        ? this.envSignalChecker
        : null;
    },
    signalCheckerAccessor () {
      return this.hasSignalChecker
        ? get(this, "signalChecker.envSignalCheckerAccessor")
        : null;
    },
    uvcInstances () {
      return [...this.envUvcInstances].sort((a, b) => {
        const dimensionPropertiesCriteriaA = a.dimensionKey();
        const dimensionPropertiesCriteriaB = b.dimensionKey();
        if (dimensionPropertiesCriteriaA > dimensionPropertiesCriteriaB) {
          return 1;
        } else if (dimensionPropertiesCriteriaA < dimensionPropertiesCriteriaB) {
          return -1;
        } else {
          if (get(a, "interface.ports.length", 0) > get(b, "interface.ports.length", 0)) {
            return 1;
          } else if (get(a, "interface.ports.length", 0) < get(b, "interface.ports.length", 0)) {
            return -1;
          } else {
            if (this.uvcSortCriteria(a) > this.uvcSortCriteria(b)) {
              return 1;
            } else if (this.uvcSortCriteria(a) < this.uvcSortCriteria(b)) {
              return -1;
            } else {
              return 0;
            }
          }
        }
      });
    },
    envInstances () {
      return [...this.envEnvInstances].sort((a, b) => {
        const dimensionPropertiesCriteriaA = a.dimensionKey();
        const dimensionPropertiesCriteriaB = b.dimensionKey();
        if (dimensionPropertiesCriteriaA > dimensionPropertiesCriteriaB) {
          return 1;
        } else if (dimensionPropertiesCriteriaA < dimensionPropertiesCriteriaB) {
          return -1;
        } else {
          if (this.envSortCriteria(a) > this.envSortCriteria(b)) {
            return 1;
          } else if (this.envSortCriteria(a) < this.envSortCriteria(b)) {
            return -1;
          } else {
            return 0;
          }
        }
      });
    },
    uniqueUvcs () {
      const uvcs = [];
      this.uvcInstances.forEach(uvcInstance => {
        if (uvcInstance.uvc && !uvcs.find(uvc => uvc.id === uvcInstance.uvc.id)) {
          uvcs.push(uvcInstance.uvc);
        }
      });
      return uvcs;
    },
    uniqueEnvs () {
      return this.findUniqueEnvs(this.envInstances);
    },
    // envInstancesAndMe () {
    //   return [this].concat(this.envInstances);
    // },
    deepEnvInstances () {
      return this.envInstances.concat(this.envInstances.map(s => s.deepEnvInstances).flat());
    },
    // deepEnvInstancesAndMe () {
    //   return [this].concat(this.deepEnvInstances);
    // },
    hasEnvInstances () {
      return !!this.envInstances.length;
    },
    deepUniqueUvcs () {
      return [...new Set(this.deepUniqueEnvsAndMe.map(env => env.uniqueUvcs).flat())];
    },
    deepUniqueEnvs () {
      return this.findUniqueEnvs(this.deepEnvInstances);
    },
    deepUniqueEnvsAndMe () {
      return [this].concat(this.deepUniqueEnvs);
    },
    clkUvcInstances () {
      return this.uvcInstances.filter(uvcInstance => uvcInstance.uvc && uvcInstance.uvc.isOfficialClkUvc);
    },
    rstUvcInstances () {
      return this.uvcInstances.filter(uvcInstance => uvcInstance.uvc && uvcInstance.uvc.isOfficialRstUvc);
    },
    masterUvcInstances () {
      return this.uvcInstances.filter(uvcInstance => {
        return uvcInstance.isMaster && uvcInstance.interface && (!uvcInstance.interface.hasClockOnly && !uvcInstance.interface.hasResetOnly);
      });
    },
    sidebandUvcInstance () {
      return this.uvcInstances.find(uvcInstance => uvcInstance.uvc && uvcInstance.uvc.isOfficialSidebandUvc);
    },
    uvcInstancesWithMonitor () {
      return this.uvcInstances.filter(uvcInstance => uvcInstance.hasMonitor);
    },
    uvcInstancesWithMonitorAndDrivenByVip () {
      return this.uvcInstancesWithMonitor
        .filter(uvcInstance => {
          return uvcInstance.isMaster || get(uvcInstance.uvc, "interface.hasSlavePorts");
        });
    },
    // uvcInstancesDrivenByVip () {
    //   return this.uvcInstances
    //     .filter(uvcInstance => {
    //       return uvcInstance.isMaster || get(uvcInstance.uvc, "interface.hasSlavePorts");
    //     });
    // },
    uvcInstancesWithMonitorAndDrivenByDut () {
      return this.uvcInstancesWithMonitor
        .filter(uvcInstance => {
          return uvcInstance.isSlave || get(uvcInstance.uvc, "interface.hasSlavePorts");
        });
    },
    uvcInstancesWithProtocolChecker () {
      return this.uvcInstances.filter(uvcInstance => uvcInstance.hasProtocolChecker);
    },
    uvcInstancesWithPhases () {
      return this.uvcInstances.filter(uvcInstance => uvcInstance.hasPhases);
    },
    abstractSignalCheckerAccessor () {
      return this.hasSignalChecker
        ? get(this, "package.envAbstractSignalCheckerAccessor")
        : null;
    },
    envParameters () {
      return this.hasEnvParameters
        ? get(this, "package.envEnvParameters")
        : null;
    },
    hasEnvParameters () {
      return !!get(this, "package.envEnvParameters.parametersSetToConfigDb.length", 0);
    },
    envConfig () {
      return get(this, "package.envEnvConfig");
    },
    defaultEnvConfig () {
      return this.hasPipelinedUvcs
        ? get(this, "package.envDefaultEnvConfig")
        : null;
    },
    inOrderEnvConfig () {
      return this.hasPipelinedUvcs
        ? get(this, "package.envInOrderEnvConfig")
        : null;
    },
    starvedEnvConfig () {
      return this.hasPipelinedUvcs
        ? get(this, "package.envStarvedEnvConfig")
        : null;
    },
    hasPipelinedUvcs () {
      return !!this.pipelinedUvcInstances.length;
    },
    pipelinedUvcInstances () {
      return this.uvcInstances.filter(uvcInstance => uvcInstance.isPipelined);
    },
    predictor () {
      return get(this, "package.envPredictor");
    },
    scoreboard () {
      return get(this, "package.envScoreboard");
    },
    coverage () {
      return this.hasEnvCoverage
        ? get(this, "package.envCoverage")
        : null;
    },
    envEnv () {
      return get(this, "package.envEnv");
    },
    envEnvHandle () {
      if (this.envEnv) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.envEnv,
            useSpecificNameOverride: true
          }
        });
      } else {
        return null;
      }
    },
    hasScoreboard () {
      return true;
    },
    hasEnvCoverage () {
      if (this.hasEnvCoverageOverride !== null) {
        return this.hasEnvCoverageOverride;
      } else {
        return false;
      }
    },
    hasSignalChecker () {
      if (this.hasSignalCheckerOverride !== null) {
        return this.hasSignalCheckerOverride;
      } else {
        return false;
      }
    },
    hasProtocolCheckersInSubEnvs () {
      return !!this.envInstancesWithProtocolCheckers.length;
    },
    hasSignalCheckersInSubEnvs () {
      return !!this.envInstancesWithSignalCheckers.length;
    },
    envInstancesWithProtocolCheckers () {
      return this.deepEnvInstances.filter(env => env.hasProtocolCheckerHarness);
    },
    envInstancesWithSignalCheckers () {
      return this.deepEnvInstances.filter(env => env.hasSignalChecker);
    },
    // In real-mode these things are located in the uvcInstance
    //   env can get them with env-getters pointing to here
    //   tb can get them with its getters pointing to here
    // In standalone the uvcInstances are computed and do not hold these persistables.
    //   uvcStandaloneEnv can override these computed
    clkFrequencyPolicies () {
      return this.clkUvcInstances.map(clkUvcInstance => {
        return clkUvcInstance.tbClkFrequencyPolicy;
      })
        .filter(p => p);
    },
    clkSequenceBases () {
      return this.clkUvcInstances.map(clkUvcInstance => {
        return clkUvcInstance.tbClkSequenceBase;
      })
        .filter(s => s);
    },
    clkSequences () {
      return this.clkUvcInstances.map(clkUvcInstance => {
        return clkUvcInstance.tbClkSequence;
      })
        .filter(s => s);
    },
    rstIntervalPolicies () {
      return this.rstUvcInstances.map(rstUvcInstance => {
        return rstUvcInstance.tbRstIntervalPolicy;
      })
        .filter(s => s);
    },
    rstPulseLengthPolicies () {
      return this.rstUvcInstances.map(rstUvcInstance => {
        return rstUvcInstance.tbRstPulseLengthPolicy;
      })
        .filter(s => s);
    },
    rstInitialSequenceBases () {
      return this.rstUvcInstances.map(rstUvcInstance => {
        return rstUvcInstance.tbRstInitialSequenceBase;
      })
        .filter(s => s);
    },
    rstInitialSequences () {
      return this.rstUvcInstances.map(rstUvcInstance => {
        return rstUvcInstance.tbRstInitialSequence;
      })
        .filter(s => s);
    },
    rstMidSimSequenceBases () {
      return this.rstUvcInstances.map(rstUvcInstance => {
        return rstUvcInstance.tbRstMidSimSequenceBase;
      })
        .filter(s => s);
    },
    rstMidSimSequences () {
      return this.rstUvcInstances.map(rstUvcInstance => {
        return rstUvcInstance.tbRstMidSimSequence;
      })
        .filter(s => s);
    },
    mainNumTransactionsPolicies () {
      return this.masterUvcInstances.map(masterUvcInstance => {
        return masterUvcInstance.tbMainNumTransactionsPolicy;
      })
        .filter(s => s);
    },
    parameterNames () {
      return get(this, "interfaceHarness.parameters", []).map(parameter => parameter.name);
    },
    generatedLicenseAssets () {
      return [
        ...this.destroyedLicenseAssets,
        ...this.deepUniqueEnvs.map(env => {
          return env.generatedLicenseAssets;
        })
          .flat(),
        ...this.deepUniqueUvcs.map(uvc => {
          return (uvc.userId === this.user.id) ? uvc.generatedLicenseAssets : [];
        })
          .flat()
      ];
    },
    destroyedLicenseAssets () {
      return [
        {
          id: this.id,
          type: "env"
        },
        ...this.envInstances.map(envInstance => {
          return {
            id: envInstance.id,
            type: "envInstance"
          };
        }),
        ...this.uvcInstances.map(uvcInstance => {
          return {
            id: uvcInstance.id,
            type: "uvcInstance"
          };
        })
      ];
    }
  },
  methods: {
    // [TODO] its possible that not loading everything, like not the last leaf documents, could make for a better load experience.
    async load () {
      await this.promiseOnSnapshot;
      this.user.uvcs; // eslint-disable-line no-unused-expressions
      this.user.envs; // eslint-disable-line no-unused-expressions

      await Promise.all([
        this.user.promiseUvcs,
        this.user.promiseEnvs
      ]);

      await Promise.all([
        [store.getters.user.setting, store.getters.user.promiseSetting],
        [this.envUvcInstances, this.promiseEnvUvcInstances],
        [this.envEnvInstances, this.promiseEnvEnvInstances],
        [this.dut, this.promiseEnvDut || this.promiseUvcStandaloneDut],
        [this.package, this.promiseEnvPackage],
        [this.interfaceHarness, this.promiseEnvInterfaceHarness],
        [this.signalChecker, this.promiseEnvSignalChecker]
      ]
        // .map((pair, index) => {
        //   if (!pair[1]) {
        //     console.log("A: theres a null at", index);
        //   }
        //   return pair[1];
        // }));
        .map(pair => pair[1]));

      await Promise.all([
        [null, this.loadEnvUvcInstances()],
        // ...this.envEnvInstances.map(envEnvInstance => [null, envEnvInstance.env.load()]),
        [this.abstractSignalCheckerAccessor, this.package.promiseEnvAbstractSignalCheckerAccessor],
        [this.envConfig, this.package.promiseEnvEnvConfig, "ecfg"],
        [this.predictor, this.package.promiseEnvPredictor, "pred"],
        [this.scoreboard, this.package.promiseEnvScoreboard, "sb"],
        [this.coverage, this.package.promiseEnvCoverage, "cov"],
        [this.envEnv, this.package.promiseEnvEnv],
        [this.envParameters, this.package.promiseEnvEnvParameters], // prereqs: envDut
        [this.signalCheckerAccessor, get(this.signalChecker, "promiseEnvSignalCheckerAccessor", null)] // prereqs: signalChecker
      ]
        // .map((pair, index) => {
        //   if (!pair[1]) {
        //     console.log("B: theres a null at", index, pair[2]);
        //   }
        //   return pair[1];
        // }));
        .map(pair => pair[1]));

      await Promise.all([
        [this.protocolCheckerHarness, this.promiseEnvProtocolCheckerHarness], // prereqs: envUvcInstances.uvc
        [this.defaultEnvConfig, this.package.promiseEnvDefaultEnvConfig], // prereqs envUvcInstances.uvc
        [this.inOrderEnvConfig, this.package.promiseEnvInOrderEnvConfig], // prereqs envUvcInstances.uvc
        [this.starvedEnvConfig, this.package.promiseEnvStarvedEnvConfig] // prereqs envUvcInstances.uvc
      ]
        // .map((pair, index) => {
        //   if (!pair[1]) {
        //     console.log("C: theres a null at", index);
        //   }
        //   return pair[1];
        // }));
        .map(pair => pair[1]));

      await Promise.all(
        this.generatableFileModels.map(model => {
          return [model && model.instanceVariables, model && model.promiseInstanceVariables];
        })
          // .map((pair, index) => {
          //   if (!pair[1]) {
          //     console.log("D: theres a null at", index);
          //   }
          //   return pair[1];
          // }));
          .map(pair => pair[1]));

      await Promise.all([
        [this.user.formClasses, this.user.promiseFormClasses]
      ]
        // .map((pair, index) => {
        //   if (!pair[1]) {
        //     console.log("D: theres a null at", index);
        //   }
        //   return pair[1];
        // }));
        .map(pair => pair[1]));

      // const t = (firstTime = false) => {
      //   if (this.name) {
      //     console.log(this.name, "env is loaded");
      //   } else {
      //     if (firstTime) {
      //       console.log("unnamed env is loaded");
      //     }
      //     setTimeout(t, 100);
      //   }
      // };
      // t(true);

      this.isLoaded = true;
    },
    loadEnvUvcInstances () {
      return Promise.all([
        ...this.envUvcInstances.map(envUvcInstance => {
          return Promise.all([
            [envUvcInstance.parameterAssignments, envUvcInstance.promiseParameterAssignments],
            [envUvcInstance.portConnections, envUvcInstance.promisePortConnections]
          ]
            // .map((pair, index) => {
            //   if (!pair[1]) {
            //     console.log("C: theres a null at", index);
            //   }
            //   return pair[1];
            // }));
            .map(pair => pair[1]));
        })
      ]);
    },
    updateHdlPath (envInstance, value) {
      const result = this.validateHdlPath(value);

      if (typeof result === "string") {
        const error = result;
        return error;
      } else {
        return envInstance.update({ hdlPathOverride: value });
      }
    },
    validateHdlPath (value) {
      let errorMessage;
      try {
        this.parseHdlPath(value);
        // const validatedInfo = this.parseHdlPath(value);
        // this.type = validatedInfo.type || "";
        // this.parameters = validatedInfo.parameterDeclarations || [];
        // this.ports = validatedInfo.ports || [];
      } catch (e) {
        errorMessage = e.message;
      }
      if (errorMessage) {
        return errorMessage;
      } else {
        return true;
      }
    },
    parseHdlPath (value) {
      return parseSystemVerilog(value, "HdlPath", { parameterNames: this.parameterNames });
    },
    uvcSortCriteria (uvcInstance) {
      return [
        get(uvcInstance, "uvc.name"),
        uvcInstance.suffix,
        !uvcInstance.master,
        uvcInstance.createdAt
      ];
    },
    envSortCriteria (envInstance) {
      return [
        get(envInstance, "env.name"),
        envInstance.suffix,
        envInstance.createdAt
      ];
    },
    findUniqueEnvs (envInstances) {
      const envs = [];
      envInstances.forEach(envInstance => {
        if (!envs.find(env => env.id === envInstance.env.id)) {
          envs.push(envInstance.env);
        }
      });
      return envs;
    },
    async beforeUnmount () {
      await this.promiseOnSnapshot;

      await Promise.all([
        [this.package, this.promiseEnvPackage],
        [this.user.formClasses, this.promiseFormClasses]
      ]
        // .map((pair, index) => {
        //   if (!pair[1]) {
        //     console.log("A: theres a null at", index);
        //   }
        //   return pair[1];
        // }));
        .map(pair => pair[1]));

      await Promise.all(this.package.formClasses ? this.package.formClasses.map(formClass => formClass.destroy()) : []);
    }
  }
};

export default defineReactiveModel(Env);
