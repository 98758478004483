export default {
  computed: {
  },
  methods: {
    immediateUpdate (data) {
      if (this.createPersistablePromise) {
        this.createPersistablePromise = this.createPersistablePromise.then(persistable => {
          return persistable.immediateUpdate(data).then(() => {
            return persistable;
          });
        });
      } else {
        this.createPersistablePromise = this.createPersistable(data);
      }
      return this.createPersistablePromise;
    },
    update (data) {
      if (this.createPersistablePromise) {
        this.createPersistablePromise = this.createPersistablePromise.then(persistable => {
          return new Promise(resolve => {
            persistable.update(data);
            resolve(persistable);
          });
        });
      } else {
        this.createPersistablePromise = this.createPersistable(data);
      }
    }
  }
};
