var settingsMixin = {
  mixins: [
  ],
  data () {
    return {
    };
  }
};

export { settingsMixin as default };
