import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Policy from "@/models/mixins/policy";
import Variable from "@/models/variable";
import Constraint from "@/models/constraint";
import get from "lodash/get";

const UvcSlaveResponsePriorityPolicy = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("policy"),
    Policy
  ],
  computed: {
    subject () {
      return this.slaveResponseSequenceBase;
    },
    type () {
      return `${this.uvc.rootName}_${this.protocolRoleWord}_response_priority_policy_c`;
    },
    responseWord () {
      return this.isPipelinedRead ? "beat" : "response";
    },
    computedInstanceVariables () {
      return [
        this.configHandle,
        this.responsePriorityUpperBoundVariable,
        this.responsePriorityLowerBoundVariable
      ]
        .filter(v => v);
    },
    responsePriorityUpperBoundVariable () {
      return this.configHandle && new Variable({
        propsData: {
          parent: this,
          type: "protected int",
          name: `${this.responseWord}_priority_upper_bound`,
          setter: false,
          preRandomizeValue: `${this.configHandle.identifier}.get_next_${this.responseWord}_priority_upper_bound()`
        }
      });
    },
    responsePriorityLowerBoundVariable () {
      return this.configHandle && new Variable({
        propsData: {
          parent: this,
          type: "protected int",
          name: `${this.responseWord}_priority_lower_bound`,
          setter: false,
          preRandomizeValue: `${this.configHandle.identifier}.get_${this.responseWord}_priority_lower_bound()`
        }
      });
    },
    computedConstraints () {
      return [
        this.responsePrioritiesConstraint
      ]
        .filter(c => c);
    },
    responsePrioritiesConstraint () {
      return get(this.slaveResponseSequenceBase, "responsePrioritiesVariable.identifier")
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.slaveResponseSequenceBase.responsePrioritiesVariable,
            content: this.isPipelinedWrite
              ? `object.${this.slaveResponseSequenceBase.responsePrioritiesVariable.identifier} inside { [${this.responsePriorityLowerBoundVariable.identifier}:${this.responsePriorityUpperBoundVariable.identifier}] };`
              : `foreach (object.${this.slaveResponseSequenceBase.responsePrioritiesVariable.identifier}[p]) {\n  object.${this.slaveResponseSequenceBase.responsePrioritiesVariable.identifier}[p] inside { [${this.responsePriorityLowerBoundVariable.identifier}:${this.responsePriorityUpperBoundVariable.identifier}] };\n}`
          }
        })
        : null;
    }
  }
};

export default defineReactiveModel(UvcSlaveResponsePriorityPolicy);
