const EXPRESSION_UNSET = "expression-unset-73e2f28";

export { EXPRESSION_UNSET };

export default {
  computed: {
    parameterName () {
      return this.parameter ? this.parameter.name : "";
    },
    expressionDefault () {
      return this.parameter.defaultValue;
    },
    expression () {
      if ((this.expressionOverride === "") || (this.expressionOverride !== EXPRESSION_UNSET)) {
        return this.expressionOverride;
      } else {
        if (this.possibilities.length === 1) {
          return this.possibilities[0];
        } else if (this.envUvcInstance.uvc.isOfficialRstUvc && (this.parameterName === "ACTIVE_LOW")) {
          const rstPortConnection = this.envUvcInstance.allPortConnections.find(connection => connection.port === this.envUvcInstance.uvc.interface.resetPort);
          if (rstPortConnection && rstPortConnection.activeLow(rstPortConnection.expression)) {
            return "1";
          } else {
            return "";
          }
        } else {
          return "";
        }
      }
    },
    suggestions () {
      if (this.parameter) {
        if (this.possibilities.length <= 1) {
          return this.envUvcInstance.suggestedDutParameterNames;
        } else {
          return this.filterList(this.possibilities);
        }
      } else {
        return [];
      }
    },
    possibilities () {
      return this.filterList(this.envUvcInstance.suggestedDutParameterNames);
    }
  },
  methods: {
    filterList (parameterNames) {
      const exactMatch = parameterNames.find(name => name === this.parameter.name);
      return exactMatch
        ? [exactMatch]
        : parameterNames.filter(name => name.match(this.parameter.name));
    }
  }
};
