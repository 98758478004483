{{#if isPipelined}}
virtual task run_phase(uvm_phase phase);
  super.run_phase(phase);

  fork
    {{ uvcSlaveDataBfmDriveReadyTask.identifier }}();
    {{ uvcSlaveDataBfmReceiveDataTask.identifier }}();
  join_none
endtask
{{/if}}
