import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import UvmSequence from "@/models/uvm/uvm-sequence";
import Handle from "@/models/handle";

const UvcSlaveSequence = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("sequence")
  ],
  created () {
    this.master = false;
    this.partialInheritance = [
      { for: ["me"], partial: "killed-by-reset" }
    ];
  },
  computed: {
    superclass () {
      return this.sequenceItem
        ? UvmSequence.singleton(this.sequenceItem)
        : null;
    },
    type () {
      return `${this.uvc.rootName}_slave_sequence_c`;
    },
    isTopSlaveSequence () {
      return this.hasSlaveResponse;
    },
    computedInstanceVariables () {
      return [
        this.configHandle,
        this.slaveRequestSequenceBaseHandle,
        this.slaveResponseSequenceBaseHandle
      ]
        .filter(o => o);
    },
    randomObjectDoHandles () {
      return [
        this.slaveRequestSequenceBaseHandle,
        this.slaveResponseSequenceBaseHandle
      ]
        .filter(h => h);
    },
    slaveRequestSequenceBaseHandle () {
      if (this.slaveRequestSequenceBase) {
        return new Handle({
          propsData: {
            id: "static-id-dfab688",
            parent: this,
            klass: this.slaveRequestSequenceBase,
            fieldMacro: "None",
            nameOverride: "req_seq"
          }
        });
      } else {
        return undefined;
      }
    },
    slaveResponseSequenceBaseHandle () {
      if (this.slaveResponseSequenceBase) {
        return new Handle({
          propsData: {
            id: "static-id-d301688",
            parent: this,
            klass: this.slaveResponseSequenceBase,
            fieldMacro: "None",
            nameOverride: "rsp_seq",
            setterAssignmentOverrides: {
              request_item: "req_seq.rsp"
            }
          }
        });
      } else {
        return undefined;
      }
    }
  }
};

export default defineReactiveModel(UvcSlaveSequence);
