import groupBy from "lodash.groupby";
import Handle from "@/models/handle";
import get from "lodash.get";

export default {
  data () {
    return {
      randomObjectHandleIdAndPolicyUniqueIdPairs: []
    };
  },
  computed: {
    allPolicyHandlesByRandomObjectHandleId () {
      this.allPolicyHandles.forEach(h => {
        if (!h.relations.randomObjectDoHandle.id) {
          throw new Error("policy.relations.randomObjectDoHandle must have an id. " + this.type + " " + h.type + " " + h.name);
        }
      });
      return groupBy(this.allPolicyHandles, "relations.randomObjectDoHandle.id");
    },
    hasPolicyHandles () {
      return !!(this.computedPolicyHandles.length || this.randomObjectHandleIdAndPolicyUniqueIdPairs.length);
    },
    allPolicyHandles () {
      return this.computedPolicyHandles
        .concat(this.policyHandles);
    },
    policyHandles () {
      return this.randomObjectHandleIdAndPolicyUniqueIdPairs.map(pair => {
        const randomObjectDoHandle = this.reachableRandomObjectDoHandles.find(randomObjectDoHandle => randomObjectDoHandle.id === pair.randomObjectHandleId);

        if (!randomObjectDoHandle) { return null; }

        const policy = get(randomObjectDoHandle.klass, "formPolicies", [])
          .concat(get(randomObjectDoHandle.klass, "userPolicies", []))
          .concat(get(this, "package.allPolicies", []))
          .find(policy => {
            return policy.uniqueId === pair.policyUniqueId;
          });

        if (!policy) { return null; }

        return new Handle({
          propsData: {
            // id: "static-id-dfef9db",
            parent: this,
            klass: policy,
            relations: {
              randomObjectDoHandle
            }
          }
        });
      })
        .filter(h => h);
    },
    computedPolicyHandles () {
      return [];
    },
    canApplyPolicies () {
      return this.docType && (this.isSequence || this.isEnvConfig);
    }
  },
  methods: {
    addPolicy (policy, randomObjectDoHandle) {
      if (!this.canApplyPolicies) {
        throw new Error("Can't apply policies.");
      }
      return this.immediateUpdate({
        randomObjectHandleIdAndPolicyUniqueIdPairs: this.randomObjectHandleIdAndPolicyUniqueIdPairs.concat([
          {
            policyUniqueId: policy.uniqueId,
            randomObjectHandleId: randomObjectDoHandle.id
          }
        ])
      });
    },
    removePolicy (policy, randomObjectDoHandle) {
      if (!this.canApplyPolicies) {
        throw new Error("Can't apply policies.");
      }
      this.immediateUpdate({
        randomObjectHandleIdAndPolicyUniqueIdPairs: this.randomObjectHandleIdAndPolicyUniqueIdPairs
          .filter(pair => !((pair.policyUniqueId === policy.uniqueId) && (pair.randomObjectHandleId === randomObjectDoHandle.id)))
      });
    }
  }
};
