import { defineReactiveModel } from "vue-app-utils";
import Lib from "@/models/lib";
import ComparatorPackage from "@/models/comparator-package";
import get from "lodash.get";

const ComparatorLib = {
  extends: Lib,
  data () {
    return {
    };
  },
  created () {
    this.name = "comparator";
  },
  computed: {
    rootGeneratableFileModels () {
      return [
        this.package
      ]
        .filter(m => m);
    },
    generatableFileModels () {
      return [
        ...get(this, "package.generatableFileModels", []),
        ...this.rootGeneratableFileModels
      ]
        .filter(m => m);
    },
    package () {
      return new ComparatorPackage({
        propsData: {
          user: this.user,
          parent: this
        }
      });
    }
  }
};

export default defineReactiveModel(ComparatorLib);
