export default function (...args) {
  const ret = [];
  const array = args[0];

  if (array === undefined) {
    return [];
  }

  const len = array.length;
  if (args.length === 3) {
    const prop1 = args[1];
    for (let d = 0; d < len; d++) {
      const item = array[d];
      if (!item[prop1]) {
        ret.push(item);
      }
    }
  } else if (args.length === 4) {
    const prop1 = args[1];
    const prop2 = args[2];
    for (let d = 0; d < len; d++) {
      const item = array[d];
      if (!item[prop1] && !item[prop2]) {
        ret.push(item);
      }
    }
  } else {
    throw new Error("number of arguments not implemented.");
  }

  return ret;
}
