import { path } from "@/utils/node-VITE_APP_PLATFORM";

// [TODO] can app-config be merged in here?
class Config {
  constructor () {
    this.userDir = "user";
    this.includesDir = "includes";
    this.sequencesDir = "seqs";
    this.policiesDir = "policies";
    this.includesDir = "includes";
    this.uvcStandaloneEnvDir = "env";
    this.uvcStandaloneTbDir = "tb";
    this.clockRegExp = /(clk|clock)/i;
    this.resetRegExp = /(rst|reset)/i;
    this.addressRegExp = /addr/i;
    this.dataRegExp = /data/i;
    this.respRegExp = /(resp|rsp)/i;
    this.lenRegExp = /len/i;
    this.idRegExp = /id/i;
    this.writeRegExp = /(write|rw)/i;
    this.strobeRegExp = /(strb|strobe)/i;
    this.edaPlaygroundMaxNumTransactionsPerReset = "25";
    this.edaPlaygroundMaxNumResetsPerTest = "5";
    this.suffixHint = "For example, a, b or c";
    this.onPremiseMarketingParagraph = "All UVCs created on uvmgen.com are publicly available for others on the web to view and download. To create private UVCs, you'll need to get an on-premise license.";
    this.uvcMarketingParagraph = "Developing a UVC has never been this easy. Simply add your interface signals and let UVMGen walk you through creating your sequence item, bus-level protocol and more. You'll have a world class UVC in minutes, not weeks.";
    this.envMarketingParagraph = "With UVMGen environments you can instantiate your UVCs and sub-environments with a single click. Integration work has gone from being time consuming and painful to being an absolute breeze.";
    this.tbMarketingParagraph = "Instantiate your DUT and environment in seconds. UVMGen ensures compatibility between your VIP making testbench development at the block, cluster and chip levels a snap.";
  }

  userIncludesPath () {
    return path.join(this.userDir, this.includesDir);
  }

  userSequencesPath () {
    return path.join(this.userDir, this.sequencesDir);
  }

  userPoliciesPath () {
    return path.join(this.userDir, this.policiesDir);
  }
}

const config = new Config();

export default config;
