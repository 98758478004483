virtual function uvm_sequence_item reg2bus(const ref uvm_reg_bus_op rw);
{{#with registerSequenceItemHandle}}
  {{> (partial "snippets/create-object") handle=. declareType=true assignedToInstanceVariable=false}}

  {{ doName }}(item);
  {{> (partial "snippets/randomize-object") context=. declareType=true}}

  return item;
{{/with}}
endfunction
