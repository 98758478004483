import createsUserFilesFromTemplate from "@/models/persistable/mixins/creates-user-files-from-template";
import UserSubclassTemplate from "@/models/user-subclass-template";

export default {
  mixins: [
    createsUserFilesFromTemplate("UserSubclass", UserSubclassTemplate)
  ],
  data () {
    return {
      factoryOverrideType: ""
    };
  },
  computed: {
    userSubclasses () {
      return this.deepSubclasses.filter(s => s.userFile);
    },
    factoryOverride () {
      return this.deepSubclassesByType[this.factoryOverrideType];
    }
  }
};
