import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import store from "@/store";
import UvmAgent from "@/models/uvm/uvm-agent";
import UvmAnalysisPort from "@/models/uvm/uvm-analysis-port";

const UvcAgent = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage")
  ],
  created () {
    this.iterationCharacter = "a";
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-agent/build-phase" },
      { for: ["me"], partial: "uvc-agent/connect-phase" }
    ];
  },
  computed: {
    superclass () {
      return UvmAgent.singleton();
    },
    type () {
      if (this.hasMaster && this.hasSlave) {
        return `${this.uvc.rootName}_${this.protocolRoleWord}_agent_c`;
      } else {
        return `${this.uvc.rootName}_agent_c`;
      }
    },
    computedInstanceVariables () {
      return [
        this.configHandle,
        this.requestSequencerHandle,
        this.dataSequencerHandle,
        this.responseSequencerHandle,
        this.requestDriverHandle,
        this.dataDriverHandle,
        this.responseDriverHandle,
        this.hasMonitor && this.monitorHandle,
        this.hasUvcCoverage && this.coverageHandle,
        this.hasMonitor && this.analysisPortHandle
      ]
        .filter(o => o);
    },
    requestDataAndResponseHandles () {
      return [
        this.requestHandles,
        this.isPipelinedWrite && this.dataHandles,
        this.isPipelined && this.responseHandles
      ]
        .filter(h => h);
    },
    requestHandles () {
      return {
        sequencerHandle: this.requestSequencerHandle,
        driverHandle: this.requestDriverHandle
      };
    },
    dataHandles () {
      return {
        sequencerHandle: this.dataSequencerHandle,
        driverHandle: this.dataDriverHandle,
        hasPrioritySequencer: this.isMaster
      };
    },
    responseHandles () {
      return {
        sequencerHandle: this.responseSequencerHandle,
        driverHandle: this.responseDriverHandle,
        hasPrioritySequencer: this.isSlave
      };
    },
    analysisPort () {
      return this.sequenceItem
        ? UvmAnalysisPort.singleton(this.sequenceItem)
        : null;
    }
  },
  methods: {
    createDeclarationName (uvcInstance) {
      let protocolWord;
      if (uvcInstance.isMaster) {
        if (this.hasSlave) {
          protocolWord = store.getters.user.setting.masterWord;
        }
      } else {
        if (this.hasMaster) {
          protocolWord = store.getters.user.setting.slaveWord;
        }
      }
      const name = [
        this.uvc.rootName,
        protocolWord,
        uvcInstance.suffix,
        uvcInstance.hasDimensions ? "agents" : "agent"
      ]
        .filter(str => str)
        .join("_");

      return uvcInstance.unpackedDeclarationBrackets ? `${name}${uvcInstance.unpackedDeclarationBrackets}` : name;
    }
  }
};

export default defineReactiveModel(UvcAgent);
