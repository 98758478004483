{{> partials/class-definition/forward-typedefs}}
{{#if virtual}}virtual {{/if}}class {{ typeDeclaration }}{{#if superclass}} extends {{ superclass.type }}{{/if}};

  {{> partials/class-definition/prepended-declaration-partials}}
  {{> partials/encapsulator/instance-variables}}
{{#if hasUvmUtilsMacro}}
  {{> partials/class-definition/uvm-utils-macros}}
{{/if}}
  {{> partials/class-definition/constraints}}
  {{> partials/class-definition/appended-declaration-partials}}
  {{> partials/class-definition/constructor}}
  {{> partials/encapsulator/setters}}
  {{> partials/encapsulator/getters}}
  {{> partials/class-definition/pre-randomize}}
  {{> partials/class-definition/post-randomize}}
{{#if commentblah}}
[TODO] is there room to somehow combine partials with subroutines so to be ordered together
and allow me to write pure-methods in individual partials. Or possibly creating the pure methods
is better done as subroutine objects.
{{/if}}
  {{> partials/encapsulator/partials}}
  {{> partials/encapsulator/subroutines}}
endclass
