import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Policy from "@/models/mixins/policy";
import Constraint from "@/models/constraint";

const UvcMasterOutOfOrderBeatPrioritiesPolicy = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    UvcProtocolRoleGetters,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("policy"),
    Policy
  ],
  created () {
    this.master = true;
  },
  computed: {
    subject () {
      return this.config;
    },
    type () {
      return `${this.uvc.rootName}_${this.protocolRoleWord}_out_of_order_beat_priorities_policy_c`;
    },
    computedConstraints () {
      return [
        this.beatPriorityWindowWidthConstraint
      ]
        .filter(c => c);
    },
    beatPriorityWindowWidthConstraint () {
      return this.config
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.config.beatPriorityWindowWidthVariable,
            content: `object.${this.config.beatPriorityWindowWidthVariable.identifier} == object.${this.config.numInProgressTransactionsMaxVariable.identifier};`
          }
        })
        : null;
    }
  }
};

export default defineReactiveModel(UvcMasterOutOfOrderBeatPrioritiesPolicy);
