import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import GeneratableFile from "@/models/mixins/generatable-file";
import PersistableUserFile from "@/models/persistable/mixins/persistable-user-file-VITE_APP_PLATFORM";
import Overridable from "@/models/persistable/mixins/overridable";
import parseSystemVerilog from "@/utils/parse-system-verilog";
import PolicyBase from "@/models/policy-base";

const UserClass = {
  extends: Class,
  mixins: [
    GeneratableFile,
    PersistableUserFile,
    Overridable("user-class")
  ],
  props: {
    parent: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      type: "",
      superclassType: "",
      superclassParameters: []
    };
  },
  created () {
    this.computed = false;
    this.userFile = true;
  },
  computed: {
    uniqueId () {
      return `static-id-${this.projectFilePath}`;
    },
    superclass () {
      if (this.superclassType) {
        if (this.superclassType === "policy_base_c") {
          return PolicyBase.singleton(this.rootProject.classesByType[this.superclassParameters[0]]);
        } else {
          return this.rootProject.classesByType[this.superclassType];
        }
      } else {
        return null;
      }
    },
    subject () {
      return this.isPolicy
        ? this.rootProject.classesByType[this.superclassParameters[0]]
        : this.superclass;
    },
    isSubclass () {
      return !this.isSequence && !this.isPolicy;
    },
    isSequence () {
      return this.superclass && this.superclass.isSequence;
    },
    classToSearchForInstanceVariables () {
      if (this.isPolicy) {
        return this.subject;
      } else {
        return null;
      }
    }
  },
  methods: {
    compare (other) {
      return other.type > this.type;
    },
    validateContent (value) {
      let errorMessage;
      try {
        const validatedInfo = this.parseContent(value);
        // this.virtual = validatedInfo.virtual;
        this.type = validatedInfo.type;
        this.superclassType = validatedInfo.superclass.type;
        this.superclassParameters = validatedInfo.superclass.parameters;
        // this.prototype = validatedInfo.prototype;
      } catch (e) {
        errorMessage = e.message;
      }
      if (errorMessage) {
        return errorMessage;
      } else {
        return true;
      }
    },
    parseContent (value) {
      return parseSystemVerilog(value, "FirstClassPrototype");
    }
  }
};

export default defineReactiveModel(UserClass);
