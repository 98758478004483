import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";

const PriceSheet = {
  extends: Model,
  mixins: [
    Persistable
  ],
  data () {
    return {
      waitlistMode: true,
      licenseDurations: [12, 18, 24, 36],
      features: [
        {
          id: "tb",
          price: null
        },
        {
          id: "env",
          price: null
        },
        {
          id: "uvc",
          price: null
        },
        {
          id: "uvcPipelined",
          price: null
        },
        {
          id: "uvcDevTb",
          price: null
        },
        {
          id: "envInstance",
          price: null
        },
        {
          id: "uvcInstance",
          price: null
        }
      ],
      priceAdjustmentFunction: ""
    };
  },
  created () {
    this.priceAdjustmentFunctionPlaceholder = `function ({ feature, licenseDurationInMonths, quantity }) {
  const priceAfterLicenseLengthDiscount = feature.price * (1 - ((licenseDurationInMonths - 6) * 0.01));
  const priceAfterQuantityDiscount = priceAfterLicenseLengthDiscount - (priceAfterLicenseLengthDiscount * (quantity * 0.00));
  // return +priceAfterQuantityDiscount.toFixed(2);
  return priceAfterQuantityDiscount;
}`;
  },
  computed: {
    featuresById () {
      const byId = {};
      this.features.forEach(feature => {
        byId[feature.id] = feature;
      });
      return byId;
    }
  }
};

export default defineReactiveModel(PriceSheet);
