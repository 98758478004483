virtual task drive({{ sequenceItemHandle.type }} {{ sequenceItemHandle.identifier }});
  wait_for_num_clocks({{ sequenceItemHandle.identifier }}.{{ sequenceItem.preTransferDelayVariable.identifier }});

  {{> (partial "snippets/bfm-drive-master-ports-todo")}}

  // do begin
  //   @{{ interface.masterClockingBlock.name }};
  // end while (/* transaction invalid */);

  {{> (partial "snippets/bfm-capture-slave-ports-todo") context=.}}

  initialize_signals();
endtask
