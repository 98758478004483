import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import GeneratableFile from "@/models/mixins/generatable-file";
import config from "@/config";
import { path } from "@/utils/node-VITE_APP_PLATFORM";

const UserSubclassTemplate = {
  extends: Class,
  mixins: [
    GeneratableFile
  ],
  props: {
    parent: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  created () {
    this.userFile = true;
  },
  computed: {
    packageFilePath () {
      if (this.isSequence) {
        return path.join(config.userSequencesPath(), this.fileName);
      } else {
        return path.join(config.userDir, this.fileName);
      }
    },
    subject () {
      return this.parent;
    },
    superclass () {
      return this.subject;
    },
    rootProject () {
      return this.parent.rootProject;
    },
    project () {
      return this.parent.project;
    }
  }
};

export default defineReactiveModel(UserSubclassTemplate);
