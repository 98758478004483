import { createStore } from "vuex";
import { StoreModule } from "vue-app-utils";
import StoreModulePlatform from "@/store/store-module-VITE_APP_PLATFORM";

const spec = {

  modules: {
    basic: StoreModule,
    platform: StoreModulePlatform
  },

  state: () => {
    return {
      isWeb: (import.meta.env.VITE_APP_PLATFORM === "web"),
      isDesktop: (import.meta.env.VITE_APP_PLATFORM === "desktop"),
      webToolEnabled: true,
      hasActiveFileCard: false
      // fileCardDeactivatedPromise: null,
      // fileCardDeactivatedResolve: null
    };
  },

  getters: {
    isWeb: state => state.isWeb,
    isDesktop: state => state.isDesktop,
    webToolEnabled: state => state.webToolEnabled,
    hasActiveFileCard: state => state.hasActiveFileCard
    // waitForFileCardToDeactivate: (state) => {
    //   if (state.fileCardDeactivatedPromise) {
    //     return state.fileCardDeactivatedPromise;
    //   } else {
    //     return Promise.resolve();
    //   }
    // }
  },

  mutations: {
    setWebToolEnabled: function (state, { enable }) {
      state.webToolEnabled = enable;
    },
    setFileCardActivated: function (state) {
      state.hasActiveFileCard = true;
      // state.fileCardDeactivatedPromise = new Promise((resolve) => {
      //   state.fileCardDeactivatedResolve = resolve;
      // });
    },
    setFileCardDeactivated: function (state) {
      state.hasActiveFileCard = false;
      // state.fileCardDeactivatedResolve();
    }
  },

  actions: {
  }

};

const store = createStore(spec);

export default store;
