import arrayMax from "@/utils/array-max";
import Variable from "@/models/variable";
import FieldMacroFlagEnum from "@/utils/field-macro-flag-enum";

export default {
  computed: {
    allInstanceVariables () {
      return this.almostAllInstanceVariables
        .concat(this.autoComputedInstanceVariables);
    },
    almostAllInstanceVariables () {
      return this.computedInstanceVariables
        .filter(iv => !iv.setterOnly)
        .concat(this.userConfigurableInstanceVariables);
    },
    computedInstanceVariables () {
      return [];
    },
    userConfigurableInstanceVariables () {
      return this.computedOverridableInstanceVariables
        .concat(this.instanceVariables.filter(iv => !iv.overrideId));
    },
    computedOverridableInstanceVariables () {
      return [];
    },
    instanceVariables () {
      return [];
    },
    instanceVariablesByOverrideId () {
      const byOverrideId = {};
      this.instanceVariables.forEach(iv => {
        if (iv.overrideId) {
          byOverrideId[iv.overrideId] = iv;
        }
      });
      return byOverrideId;
    },
    autoComputedInstanceVariables () {
      return [
        this.policiesQueueVariable
      ]
        .filter(v => v);
    },
    allUnprotectedInstanceVariables () {
      return this.allInstanceVariables
        .filter(iv => !(iv.isProtected || iv.isLocal) && (iv.id !== "static-id-abac21c"));
    },
    allInstanceVariablesWithCoverpoints () {
      return this.allCoverableInstanceVariables.filter(iv => iv.coverpoint);
    },
    allCoverableInstanceVariables () {
      return this.allUnprotectedInstanceVariables.filter(iv => ((iv.identifier !== "killed_by_reset") && (iv.identifier !== "beat_index")));
    },
    allPredictableInstanceVariables () {
      return this.allCoverableInstanceVariables.filter(iv => (!iv.fieldMacroFlagIds.find(id => id === 3 /* UVM_NOCOMPARE */)) || (iv.typeInfo && iv.typeInfo.packed && (iv.typeInfo.packed.numberOfDimensions > 0)));
    },
    localparamInstanceVariables () {
      return this.instanceVariableMap.isLocalparam;
    },
    constStaticInstanceVariables () {
      return this.instanceVariableMap.isConstStatic;
    },
    constInstanceVariables () {
      return this.instanceVariableMap.isConst;
    },
    staticInstanceVariables () {
      return this.instanceVariableMap.isStatic;
    },
    randomInstanceVariables () {
      return this.instanceVariableMap.isRandom;
    },
    randomInstanceVariablesSortedByConstraintRequired () {
      return this.instanceVariableMap.isRandom.filter(iv => iv.hasConstraintRequired)
        .concat(this.instanceVariableMap.isRandom.filter(iv => !iv.hasConstraintRequired));
    },
    protectedInstanceVariables () {
      return this.instanceVariableMap.isProtected;
    },
    remainingInstanceVariables () {
      return this.instanceVariableMap.remaining;
    },
    policiesQueueVariable () {
      if (this.hasPolicies) {
        return new Variable({
          propsData: {
            parent: this,
            type: `protected rand policy_base_c#(${this.thisType})`,
            name: "policies[$]",
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT"),
              FieldMacroFlagEnum.getValue("UVM_NOCOMPARE"),
              FieldMacroFlagEnum.getValue("UVM_REFERENCE")
            ]
          }
        });
      } else {
        return null;
      }
    },
    remainingInstanceVariablePairs () {
      return this.createPairs(this.remainingInstanceVariables);
    },
    protectedInstanceVariablePairs () {
      return this.createPairs(this.protectedInstanceVariables);
    },
    instanceVariableMap () {
      const map = {
        isLocalparam: [],
        isConstStatic: [],
        isStatic: [],
        isConst: [],
        isRandom: [],
        isProtected: [],
        remaining: []
      };
      for (let i = 0; i < (this.almostAllInstanceVariables || []).length; i++) {
        const iv = this.almostAllInstanceVariables[i];
        if (iv.isStatic) {
          if (iv.isConst) {
            map.isConstStatic.push(iv);
          } else {
            map.isStatic.push(iv);
          }
        } else if (iv.isLocalparam) {
          map.isLocalparam.push(iv);
        } else if (iv.isConst) {
          map.isConst.push(iv);
        } else if (iv.isRandom) {
          map.isRandom.push(iv);
        } else if (iv.isProtected) {
          map.isProtected.push(iv);
        } else {
          map.remaining.push(iv);
        }
      }
      return map;
    },
    allInstanceVariablesWithFieldMacro () {
      return this.allInstanceVariables.filter((iv) => iv.hasFieldMacro || iv.identifier === "edge");
    },
    allInstanceVariablesWithSetter () {
      return this.computedInstanceVariables
        .concat(this.instanceVariables)
        .concat(this.autoComputedInstanceVariables)
        .filter((iv) => iv.hasSetter);
    },
    allInstanceVariablesWithSetterCallRequired () {
      return this.allInstanceVariablesWithSetter.filter((iv) => iv.hasSetterCallRequired);
    },
    allInstanceVariablesWithGetter () {
      return this.allInstanceVariables.filter((iv) => iv.hasGetter);
    },
    allInstanceVariablesWithConstraintRequired () {
      return this.randomInstanceVariables.filter((iv) => iv.hasConstraintRequired);
    },
    instanceVariableTypeMaxLength () {
      return arrayMax(this.almostAllInstanceVariables, "type");
    },
    instanceVariableNameMaxLength () {
      return arrayMax(this.almostAllInstanceVariables, "name");
    },
    allSubroutines () {
      return this.filteredComputedSubroutines.concat(this.filteredSubroutines);
    },
    needsAttention () {
      return this.allSubroutines.some(subroutine => subroutine.needsAttention);
    },
    filteredComputedSubroutines () {
      return this.computedSubroutines.map(computedSubroutine => {
        if (this.subroutinesByOverrideId[computedSubroutine.id]) {
          return this.subroutinesByOverrideId[computedSubroutine.id];
        } else {
          return computedSubroutine;
        }
      });
    },
    filteredSubroutines () {
      return this.subroutines.filter(subroutine => {
        return !this.computedSubroutinesById[subroutine.overrideId];
      });
    },
    computedSubroutinesById () {
      const byId = {};
      for (const computedSubroutine of this.computedSubroutines) {
        byId[computedSubroutine.id] = computedSubroutine;
      }
      return byId;
    },
    subroutinesByOverrideId () {
      const byOverrideId = {};
      for (const subroutine of this.subroutines) {
        byOverrideId[subroutine.overrideId] = subroutine;
      }
      return byOverrideId;
    },
    subroutines () {
      return [];
    },
    computedSubroutines () {
      return [];
    },
    pureSubroutines: function () {
      return this.subroutineMap.pure;
    },
    nonPureSubroutines: function () {
      return this.subroutineMap.nonPure;
    },
    subroutineMap () {
      const map = {
        pure: [],
        nonPure: []
      };
      this.allSubroutines.forEach((subroutine) => {
        if (subroutine.prototype.match(/^pure /)) {
          map.pure.push(subroutine);
        } else {
          map.nonPure.push(subroutine);
        }
      });
      return map;
    },
    allSubroutinesAsPartialNames () {
      return this.allSubroutines.map(sub => sub.identifier.replace("_", "-"));
    }
  },
  methods: {
    newlineBefore (condition, previous, current) {
      return (!previous.klass[condition] && (current.klass && current.klass[condition]));
    },
    newlineAfter (condition, previous, current) {
      return (previous.klass[condition] && !(current.klass && current.klass[condition]));
    },
    newlineBeforeAndAfter (condition, previous, current) {
      return this.newlineBefore(condition, previous, current) || this.newlineAfter(condition, previous, current);
    },
    addNewlineInInstanceVariableDeclarations (previous, current) {
      if (!previous || !previous.klass) {
        return false;
      }

      return this.newlineBeforeAndAfter("isAgent", previous, current) ||
        this.newlineBeforeAndAfter("isUvmAnalysisImp", previous, current) ||
        this.newlineBeforeAndAfter("isUvmAnalysisExport", previous, current) ||
        this.newlineBeforeAndAfter("isUvmTlmAnalysisFifo", previous, current) ||
        this.newlineBeforeAndAfter("isUvcPhaseFilter", previous, current) ||
        this.newlineBeforeAndAfter("isComparator", previous, current) ||
        this.newlineBeforeAndAfter("isUvmAnalysisPort", previous, current) ||
        this.newlineBefore("isEnv", previous, current);
    },
    createPairs (variables) {
      let previous = null;
      return variables
        .map(current => {
          const ret = {
            previous,
            current
          };
          previous = current;
          return ret;
        });
    }
  }
};
