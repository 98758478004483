import { defineReactiveModel, hasOne } from "vue-app-utils";
import Env from "@/models/persistable/env/env";
import config from "@/config";
import EnvUvcInstance from "@/models/env/env-uvc-instance";

const UvcStandaloneEnv = {
  extends: Env,
  mixins: [
    hasOne("uvcStandaloneDut"),
    hasOne("tbClkFrequencyPolicy"),
    hasOne("tbClkSequenceBase"),
    hasOne("tbClkSequence"),
    hasOne("tbRstIntervalPolicy"),
    hasOne("tbRstPulseLengthPolicy"),
    hasOne("tbRstInitialSequenceBase"),
    hasOne("tbRstInitialSequence"),
    hasOne("tbRstMidSimSequenceBase"),
    hasOne("tbRstMidSimSequence"),
    hasOne("tbMainNumTransactionsPolicy")
  ],
  data () {
    return {
      clkUvc: null,
      rstUvc: null
    };
  },
  created () {
    this.isUvcStandaloneEnv = true;
  },
  computed: {
    rootProject () {
      return this.root;
    },
    subProjectDirectory () {
      return config.uvcStandaloneEnvDir;
    },
    dut () {
      return this.uvcStandaloneDut;
    },
    tb () {
      return this.envTb;
    },
    uvcInstances () {
      return [
        this.clkUvcInstance,
        this.rstUvcInstance,
        this.uvcMasterInstance,
        this.uvcSlaveInstance
      ]
        .filter(i => i);
    },
    hasEnvCoverage () {
      return false;
    },
    hasSignalChecker () {
      return false;
    },
    clkUvcInstance () {
      if (
        // get(this, "clkUvc.interface.clockPort") &&
        this.clkUvc && this.clkUvc.isLoaded
        // get(this, "dut.clockPort.name")
      ) {
        return new EnvUvcInstance({
          propsData: {
            parent: this,
            user: this.user,
            loadedUvc: this.clkUvc,
            master: true
          }
        });
      } else {
        return null;
      }
    },
    rstUvcInstance () {
      if (
        this.rstUvc && this.rstUvc.isLoaded
        // get(this, "rstUvc.interface.parameters") &&
        // get(this, "uvc.interface.resetPort") &&
        // get(this, "rstUvc.interface.clockPort") &&
        // get(this, "rstUvc.interface.resetPort") &&
        // get(this, "dut.clockPort.name") &&
        // get(this, "dut.resetPort.name")
      ) {
        // const activeLowParam = this.rstUvc.interface.parameters.find(param => param.name === "ACTIVE_LOW");
        // if (activeLowParam) {
        return new EnvUvcInstance({
          propsData: {
            parent: this,
            user: this.user,
            loadedUvc: this.rstUvc,
            master: true
          }
        });
        // } else {
        //   return null;
        // }
      } else {
        return null;
      }
    },
    uvc () {
      return this.rootProject;
    },
    uvcMasterInstance () {
      // if (
      //   // get(this, "uvc.interface.parameters") &&
      //   // get(this, "uvc.interface.clockPort") &&
      //   // get(this, "uvc.interface.resetPort") &&
      //   get(this, "dut.clockPort.name") &&
      //   get(this, "dut.resetPort.name") &&
      //   get(this, "dut.slavePorts.length") &&
      //   get(this, "dut.slavePorts").every(port => port.relations.port)
      // ) {
      return new EnvUvcInstance({
        propsData: {
          parent: this,
          user: this.user,
          loadedUvc: this.uvc,
          master: true
        }
      });
      // } else {
      //   return null;
      // }
    },
    uvcSlaveInstance () {
      // if (
      //   // get(this, "uvc.interface.parameters") &&
      //   // get(this, "uvc.interface.clockPort") &&
      //   // get(this, "uvc.interface.resetPort") &&
      //   get(this, "dut.clockPort") &&
      //   get(this, "dut.resetPort") &&
      //   get(this, "dut.masterPorts.length") &&
      //   get(this, "dut.masterPorts").every(port => port.relations.port)
      // ) {
      return new EnvUvcInstance({
        propsData: {
          parent: this,
          user: this.user,
          loadedUvc: this.uvc,
          master: false
        }
      });
      // } else {
      //   return null;
      // }
    },
    clkFrequencyPolicy () {
      return this.tbClkFrequencyPolicy;
    },
    clkSequenceBase () {
      return this.tbClkSequenceBase;
    },
    clkSequence () {
      return this.tbClkSequence;
    },
    rstIntervalPolicy () {
      return this.tbRstIntervalPolicy;
    },
    rstPulseLengthPolicy () {
      return this.tbRstPulseLengthPolicy;
    },
    rstInitialSequenceBase () {
      return this.tbRstInitialSequenceBase;
    },
    rstInitialSequence () {
      return this.tbRstInitialSequence;
    },
    rstMidSimSequenceBase () {
      return this.tbRstMidSimSequenceBase;
    },
    rstMidSimSequence () {
      return this.tbRstMidSimSequence;
    },
    mainNumTransactionsPolicy () {
      return this.tbMainNumTransactionsPolicy;
    },
    clkFrequencyPolicies () {
      return [
        this.clkFrequencyPolicy
      ]
        .filter(p => p);
    },
    clkSequenceBases () {
      return [
        this.clkSequenceBase
      ]
        .filter(s => s);
    },
    clkSequences () {
      return [
        this.clkSequence
      ]
        .filter(s => s);
    },
    rstIntervalPolicies () {
      return [
        this.rstIntervalPolicy
      ]
        .filter(p => p);
    },
    rstPulseLengthPolicies () {
      return [
        this.rstPulseLengthPolicy
      ]
        .filter(p => p);
    },
    rstInitialSequenceBases () {
      return [
        this.rstInitialSequenceBase
      ]
        .filter(s => s);
    },
    rstInitialSequences () {
      return [
        this.rstInitialSequence
      ]
        .filter(s => s);
    },
    rstMidSimSequenceBases () {
      return [
        this.rstMidSimSequenceBase
      ]
        .filter(s => s);
    },
    rstMidSimSequences () {
      return [
        this.rstMidSimSequence
      ]
        .filter(s => s);
    },
    mainNumTransactionsPolicies () {
      return [
        this.mainNumTransactionsPolicy
      ]
        .filter(s => s);
    }
  },
  methods: {
    async loadEnvUvcInstances () {
      await this.uvmgenUserPromise;

      this.uvmgenUser.uvcs; // eslint-disable-line no-unused-expressions

      await this.uvmgenUser.promiseUvcs;

      this.clkUvc = this.uvmgenUser.uvcs.find(uvc => uvc.isOfficialClkUvc);
      this.rstUvc = this.uvmgenUser.uvcs.find(uvc => uvc.isOfficialRstUvc);

      if (!this.clkUvc) {
        throw new Error("Expected to find clkUvc.");
      }

      if (!this.rstUvc) {
        throw new Error("Expected to find rstUvc.");
      }

      await Promise.all([
        this.clkUvc.load(false),
        this.rstUvc.load(false)
      ]);
    }
  }
};

export default defineReactiveModel(UvcStandaloneEnv);
