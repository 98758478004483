import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Subroutine from "@/models/subroutine";
import uvcDriveTaskPlaceholderHbs from "@/handlebars/partials/uvc-master-request-bfm/drive-task-placeholder.hbs";
import handlebarsRuntimeOptions from "@/handlebars/runtime-options";

const UvcMasterRequestBfm = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcInterface"),
    Overridable("bfm")
  ],
  created () {
    this.master = true;
    this.channel = "request";
    this.partialInheritance = [
    ];
  },
  computed: {
    superclass () {
      return this.bfmBase;
    },
    type () {
      const parts = [this.uvc.rootName];
      if (this.hasSlave) {
        parts.push(this.protocolRoleWord);
      }
      if (this.hasPipelinedMaster) {
        parts.push("request");
      }
      parts.push("bfm");
      parts.push("c");
      return parts.join("_");
    },
    createMethod () {
      const parts = ["create"];
      if (this.hasSlave) {
        parts.push(this.protocolRoleWord);
      }
      if (this.hasPipelinedMaster) {
        parts.push("request");
      }
      parts.push("bfm");
      return parts.join("_");
    },
    computedSubroutines () {
      return [
        this.computedDriveTask
      ];
    },
    driveTask () {
      return this.subroutines.find(subroutine => subroutine.overrideId === this.computedDriveTask.id) || this.computedDriveTask;
    },
    computedDriveTask () {
      return new Subroutine({
        propsData: {
          parent: this,
          id: "static-id-ccb2107",
          hasRequiredIdentifier: false,
          requiredArgs: [this.sequenceItem.type],
          content: uvcDriveTaskPlaceholderHbs(this, handlebarsRuntimeOptions).slice(0, -1)
        }
      });
    }
  }
};

export default defineReactiveModel(UvcMasterRequestBfm);
