import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Handle from "@/models/handle";
import get from "lodash.get";

const UvcMasterRequestVirtualSequence = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("virtual-sequence")
  ],
  created () {
    this.master = true;
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-master-request-virtual-sequence/body" },
      { for: ["me"], partial: "uvc-master-virtual-sequence/drive-request" },
      { for: ["me"], partial: "uvc-master-request-virtual-sequence/drive-data" }
    ];
  },
  computed: {
    superclass () {
      return this.virtualSequenceBase;
    },
    type () {
      return `${this.uvc.name}_${this.protocolRoleWord}_request_virtual_sequence_c`;
    },
    computedInstanceVariables () {
      return [
        this.refItemHandle,
        this.requestSequenceHandle,
        this.dataSequenceHandle
      ]
        .filter(o => o);
    },
    refItemHandle () {
      if (this.sequenceItem) {
        return new Handle({
          propsData: {
            id: "static-id-5c3723b",
            parent: this,
            klass: this.sequenceItem,
            nameOverride: "ref_item",
            isRandomRefItem: true
          }
        });
      } else {
        return undefined;
      }
    },
    requestSequenceHandle () {
      const requestSequencerHandle = get(this, "virtualSequenceBase.requestSequencerHandle");
      return this.masterRequestSequenceBase && requestSequencerHandle && new Handle({
        propsData: {
          id: "static-id-a13723b",
          parent: this,
          klass: this.masterRequestSequenceBase,
          nameOverride: "req_seq",
          sequencerHandleOverride: requestSequencerHandle
        }
      });
    },
    dataSequenceHandle () {
      const dataSequencerHandle = get(this, "virtualSequenceBase.dataSequencerHandle");
      return this.masterDataSequenceBase && dataSequencerHandle && new Handle({
        propsData: {
          id: "static-id-dc3723b",
          parent: this,
          klass: this.masterDataSequenceBase,
          nameOverride: "data_seq",
          sequencerHandleOverride: dataSequencerHandle
        }
      });
    },
    computedPolicyHandles () {
      return [
        this.masterRefItemPolicy && this.refItemHandle && new Handle({
          propsData: {
            parent: this,
            klass: this.masterRefItemPolicy,
            relations: {
              randomObjectDoHandle: this.refItemHandle
            }
          }
        }),
        this.masterDataPriorityPolicy && this.dataSequenceHandle && new Handle({
          propsData: {
            parent: this,
            klass: this.masterDataPriorityPolicy,
            relations: {
              randomObjectDoHandle: this.dataSequenceHandle
            }
          }
        })
      ]
        .filter(h => h);
    }
  }
};

export default defineReactiveModel(UvcMasterRequestVirtualSequence);
