function void add_resources_to_config_db(string {{ instName }});
{{#each allInstanceVariables}}
{{#if (eq . ../envFullNameVariable)}}
  {{ ../type }}.{{ identifier }} = {{ identifier }};
{{/if}}
{{/each}}
{{#if hasParameters}}
  add_parameters_to_config_db({ {{~instName}}, {{#with (or masterAgent slaveAgent)}}".{{configHandle.identifier}}"{{/with~}} });
{{else if (and isInterfaceHarness hasDutParameters)}}
  add_parameters_to_config_db({{ instName }});
{{/if}}
{{#with accessorHandle}}
  add_{{ identifier }}_to_config_db({{ ../instName }});
{{/with}}
endfunction
