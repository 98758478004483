{{#if hasPreRandomize}}
function void pre_randomize();
  super.pre_randomize();
  {{#if allInstanceVariablesWithSetterCallRequired.length}}

  {{/if}}
  {{> (partial "snippets/check-instance-variables-set")}}
{{#each preRandomizeGetFromConfigDbVariables}}
  {{> (partial "snippets/get-from-config-db") context=. useInstName=(not ../isComponent)}}
{{/each}}
{{#each preRandomizeInstanceVariables ln=true}}
  {{ identifier }} = {{preRandomizeValue}};
{{/each}}
{{#each preRandomizeInitialValues ln=true}}
  {{ name }} = {{defaultValue}};
{{/each}}
endfunction

{{/if}}
