export default function (data, options) {
  if (data === "") {
    return "";
  } else {
    let str = "";
    const codeBlock = data.split("\n");
    for (let c = 0, clen = codeBlock.length; c < clen; c++) {
      str = str + options.fn(codeBlock[c]);
    }
    return str;
  }
}
