import { register } from 'register-service-worker';

/* eslint-disable no-console */

const swUrl = `${import.meta.env.BASE_URL}service-worker.js`;

function registerServiceWorker () {
  if (import.meta.env.VITE_NODE_ENV === "production") {
    register(swUrl, {
      ready () {
        console.log(
          "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
        );
      },
      registered (registration) {
        console.log("Service worker has been registered.");
        setInterval(async () => {
          if (!registration.installing) {
            let offline = false;

            try {
              await fetch(swUrl, {
                method: "HEAD",
                cache: "no-store",
                headers: {
                  cache: "no-store",
                  "cache-control": "no-cache"
                }
              });
            } catch (error) {
              if (error.message === "Failed to fetch") {
                offline = true;
              }
            }

            if (!offline) {
              await registration.update()
                .catch((e) => {
                  if ((e.name === "InvalidStateError") && (e.message === "newestWorker is null")) {
                    console.error(e);
                  } else if ((e.name === "TypeError") && (e.message.match(/^Script .*service-worker.js load failed$/))) {
                    console.error(e);
                  } else if ((e.name === "TypeError") && (e.message.match(/^ServiceWorker script at .* encountered an error during installation.$/))) {
                    console.error(e);
                  } else if ((e.name === "TypeError") && (e.message.match(/^Failed to update a ServiceWorker.* An unknown error occurred when fetching the script.$/))) ; else {
                    throw e;
                  }
                });
            }
          }
        }, 60 * 1000);
      },
      cached () {
        console.log("Content has been cached for offline use.");
      },
      updatefound () {
        console.log("New content is downloading.");
      },
      updated (registration) {
        console.log("New content is available; please refresh.");
        // Interval set in case it fires before listener is created.
        setTimeout(() => {
          document.dispatchEvent(
            new CustomEvent("swupdated", { detail: registration })
          );
        }, 100);
      },
      offline () {
        console.log("No internet connection found. App is running in offline mode.");
      },
      error (error) {
        // When google bot analyzes the page - seen on structured data checking website
        if (!error.message.match(/ServiceWorkerContainer\.wrsParams/)) {
          console.error("Error during service worker registration:", error);
        }
      }
    });
  }
}

export { registerServiceWorker as default };
