import belongsTo from './db/relations/belongs-to.js';

var stripeReadonlyCartMixin = {
  mixins: [
    belongsTo("cart")
  ],
  data () {
    return {
      stripePaymentIntentClientSecret: ""
    };
  }
};

export { stripeReadonlyCartMixin as default };
