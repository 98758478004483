const componentPath = function (vm) {
  const components = [vm];
  let parent = vm.$parent;
  while (parent) {
    components.push(parent);
    parent = parent.$parent;
  }
  return components.reverse()
    .map((c) => c.$options.name)
    .slice(1)
    .join(".");
};

const getException = function (e) {
  const exception = {};
  exception.typeof = (typeof e);
  if (typeof e === "string") {
    exception.message = e;
  } else if ((typeof e === "object") && (e.error !== null)) {
    exception.name = e.name;
    exception.message = e.message;
    // [TODO] e.stack is returning the error message (via app.config.errorHandler). I think its a vue bug, but was unable to root cause.
    exception.stack = e.stack;
    exception.fileName = e.fileName;
    exception.lineNumber = e.lineNumber;
    exception.columnNumber = e.columnNumber;
  } else {
    exception.message = "Could not parse exception of type " + (typeof e);
  }
  return exception;
};

const getExceptionFromErrorEvent = function (evt) {
  const exception = evt.error ? getException(evt.error) : {};
  exception.fileName = evt.filename;
  exception.lineNumber = evt.lineno;
  exception.columnNumber = evt.colno;
  exception.message = exception.message || evt.message;
  return exception;
};

const getErrorBasics = function (handler) {
  const error = {};
  error.userAgent = navigator.userAgent;
  error.handler = handler;
  error.page = window.location.pathname;
  return error;
};

function errorParser (app, sendFn) {
  app.config.errorHandler = function (e, vm, info) {
    const error = getErrorBasics("vue-errorhandler");
    error.componentPath = componentPath(vm);
    error.info = info;
    error.exception = getException(e);
    console.error(error);
    sendFn(error);
  };

  window.addEventListener("error", function (evt) {
    if (evt.message.match(/^ResizeObserver loop/)) {
      console.log(evt);
      evt.stopImmediatePropagation();
    } else {
      const error = getErrorBasics("onerror");
      error.exception = getExceptionFromErrorEvent(evt);
      console.error(error);
      sendFn(error);
    }
    return false;
  });

  window.addEventListener("unhandledrejection", function (evt) {
    const error = getErrorBasics("onunhandledrejection");
    if (evt.reason === undefined) {
      error.exception = getException("Not sure how to parse evt. Here are the keys: " + JSON.stringify(Object.keys(evt)));
    } else {
      error.exception = getException(evt.reason);
    }
    console.error(error);
    sendFn(error);
  });
}

export { errorParser as default };
