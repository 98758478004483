import { defineReactiveModel } from "vue-app-utils";
import Model from "@/models/model";
import Encapsulator from "@/models/mixins/encapsulator";
import AppliesPolicies from "@/models/persistable/mixins/applies-policies";
import { path } from "@/utils/node-VITE_APP_PLATFORM";
import classDefinitionHbs from "@/handlebars/class-definition.hbs";
import handlebarsRuntimeOptions from "@/handlebars/runtime-options";

const componentNames = [
  "uvm_component",
  "uvm_test",
  "uvm_env",
  "uvm_agent",
  "uvm_monitor",
  "uvm_scoreboard",
  "uvm_driver",
  "uvm_push_driver",
  "uvm_sequencer",
  "uvm_push_sequencer",
  "uvm_random_stimulus",
  "uvm_subscriber"
];
const objectNames = [
  "uvm_object",
  "uvm_report_object",
  "uvm_transaction",
  "uvm_sequence_item",
  "uvm_sequence_base",
  "uvm_sequence"
];

const Class = {
  extends: Model,
  mixins: [
    Encapsulator,
    AppliesPolicies
  ],
  data () {
    return {
      panel: null
    };
  },
  created () {
    this.isClass = true;
    this.virtual = false;
    this.computed = true;
    this.prependedDeclarationPartials = [];
    this.appendedDeclarationPartials = [];
    this.constructorPartials = [];
    this.partialInheritance = [];

    this.prereqPromises = [
      new Promise(resolve => {
        this.superclassResolve = resolve;
      })
    ];

    const unwatch = this.$watch("superclass", (value) => {
      if (value) {
        this.superclassResolve();
        this.$nextTick(() => { unwatch(); });
      }
    }, { immediate: true });
  },
  computed: {
    // superclass () {
    //   return null;
    // },
    hasUvmUtilsMacro () {
      return !this.superclassesAndMe.some(c => {
        return c.uvmUtilsMacro === false;
      });
    },
    code () {
      return classDefinitionHbs(this, handlebarsRuntimeOptions).slice(0, -1);
    },
    forwardTypedefs () {
      return [];
    },
    typeDeclaration () {
      if (this.parameters.length) {
        const parameterList = this.parameters.map((p) => {
          const t = p.rootType || p.type;
          const name = t ? [t, p.name].join(" ") : p.name;
          return [name, p.defaultValue].join("=");
        });
        return `${this.type}#(${parameterList.join(", ")})`;
      } else {
        return this.type;
      }
    },
    thisType () {
      if (this.parameters.length) {
        const parameterList = this.parameters.map(p => p.name);
        return `${this.type}#(${parameterList.join(", ")})`;
      } else {
        return this.type;
      }
    },
    parameters () {
      return [];
    },
    superclasses () {
      if (this.superclass) {
        return [this.superclass].concat(this.superclass.superclasses);
      } else {
        return [];
      }
    },
    superclassesAndMe () {
      return [this].concat(this.superclasses);
    },
    // [TODO] At this point I can test if I really need to have _c at the end of class names. Need them for policies at least. If not, I can also change constraints from _crv to _c.
    subclasses () {
      return this.project.classesBySuperclassUniqueId[this.uniqueId] || [];
    },
    deepSubclasses () {
      return this.subclasses.concat(this.subclasses.map(s => s.deepSubclasses).flat());
    },
    deepSubclassesAndMe () {
      return [this].concat(this.deepSubclasses);
    },
    deepSubclassesByType () {
      const subclassesByType = {};
      this.deepSubclasses.forEach((c) => {
        subclassesByType[c.type] = c;
      });
      return subclassesByType;
    },
    policies () {
      return this.rootProject.package.allPolicies.filter(c => this.uniqueId === c.subject.uniqueId);
    },
    policiesAndMe () {
      return [this].concat(this.policies);
    },
    isFirstLevelComponent () {
      if (this.superclass) {
        return componentNames.includes(this.superclass.rootType || this.superclass.type);
      } else {
        return false;
      }
    },
    isComponent () {
      return !!(this.superclass && this.superclass.isComponent);
    },
    isFirstLevelObject () {
      if (this.superclass) {
        return objectNames.includes(this.superclass.rootType || this.superclass.type);
      } else {
        return false;
      }
    },
    isObject () {
      if (this.isComponent) {
        return false;
      } else {
        return !!(this.superclass && this.superclass.isObject);
      }
    },
    isConfig () {
      return !!(this.superclass && this.superclass.isConfig);
    },
    isEnvConfig () {
      return !!(this.superclass && this.superclass.isEnvConfig);
    },
    isAgent () {
      return !!(this.superclass && this.superclass.isAgent);
    },
    isEnv () {
      return !!(this.superclass && this.superclass.isEnv);
    },
    isSequenceItem () {
      return !!(this.superclass && this.superclass.isSequenceItem);
    },
    isSequence () {
      return !!(this.superclass && this.superclass.isSequence);
    },
    isVirtualSequence () {
      return !!(this.superclass && this.superclass.isVirtualSequence);
    },
    isSequencer () {
      return !!(this.superclass && this.superclass.isSequencer);
    },
    isPolicy () {
      return !!(this.superclass && this.superclass.isPolicy);
    },
    isTest () {
      return !!(this.superclass && this.superclass.isTest);
    },
    isBfm () {
      return !!(this.superclass && this.superclass.isBfm);
    },
    isUvmAnalysisImp () {
      return !!(this.superclass && this.superclass.isUvmAnalysisImp);
    },
    isUvmAnalysisExport () {
      return !!(this.superclass && this.superclass.isUvmAnalysisExport);
    },
    isUvmTlmAnalysisFifo () {
      return !!(this.superclass && this.superclass.isUvmTlmAnalysisFifo);
    },
    isUvmAnalysisPort () {
      return !!(this.superclass && this.superclass.isUvmAnalysisPort);
    },
    isUvcPhaseFilter () {
      return !!(this.superclass && this.superclass.isUvcPhaseFilter);
    },
    isComparator () {
      return !!(this.superclass && this.superclass.isComparator);
    },
    uvmSubscriber () {
      return this.getSuperclassByRootType("uvm_subscriber");
    },
    abstractConstraint () {
      return this.getSuperclassByRootType("policy_base_c");
    },
    allReachableInstanceVariables () {
      return this.superclassesAndMe
        .slice()
        .reverse()
        .map((s) => s.allInstanceVariables)
        .flat();
    },
    handlesWithSetters () {
      return this.allReachableInstanceVariables.filter(iv => iv.hasSetter);
    },
    reachableRandomInstanceVariables () {
      return this.superclassesAndMe
        .slice()
        .reverse()
        .map((s) => s.randomInstanceVariables)
        .flat();
    },
    reachableIterationCharacter () {
      const klass = this.superclassesAndMe.find(s => s.iterationCharacter);
      return klass
        ? klass.iterationCharacter
        : null;
    },
    hasPolicies () {
      return this.hasRandomInstanceVariables;
    },
    hasRandomInstanceVariables () {
      if (this.randomInstanceVariables.length) {
        return true;
      } else if (this.superclass) {
        return this.superclass.hasRandomInstanceVariables;
      } else {
        return false;
      }
    },
    allConstraints () {
      return this.allEnabledAndDisabledConstraints
        .filter(constraint => constraint.instanceVariable && constraint.instanceVariable.isRandom);
    },
    constraintInstanceVariableIds () {
      const ids = {};
      for (const constraint of this.constraints) {
        ids[constraint.instanceVariable.id] = true;
      }
      return ids;
    },
    filteredComputedConstraints () {
      return this.computedConstraints.filter(computedConstraint => {
        return !this.constraintInstanceVariableIds[computedConstraint.instanceVariable.id];
      });
    },
    undeletedConstraints () {
      return this.constraints.filter(constraint => !constraint.deleted);
    },
    allEnabledAndDisabledConstraints () {
      return this.filteredComputedConstraints.concat(this.undeletedConstraints);
    },
    computedConstraints () {
      return [];
    },
    constraints () {
      return [];
    },
    useParamUtils () {
      if (this.superclasses.find(superclass => superclass.virtual) || (this.parameters.length > 0)) {
        return true;
      } else {
        return false;
      }
    },
    hasConstructor () {
      return (this.isObject || this.isComponent) && !(this.virtual && this.parameters.length);
    },
    hasPreRandomize () {
      return this.isObject && (
        this.allInstanceVariablesWithSetterCallRequired.length ||
        this.preRandomizeGetFromConfigDbVariables.length ||
        this.preRandomizeInitialValues.length
      );
    },
    hasPostRandomize () {
      return this.allInstanceVariablesWithConstraintRequired.length ||
        this.postRandomizeInstanceVariables.length;
    },
    preRandomizeGetFromConfigDbVariables () {
      return [];
    },
    preRandomizeInitialValues () {
      return [];
    },
    preRandomizeInstanceVariables () {
      return this.allInstanceVariables.filter(iv => iv.preRandomizeValue);
    },
    postRandomizeInstanceVariables () {
      return this.allInstanceVariables.filter(iv => iv.postRandomizeValue);
    },
    partials () {
      const partialInheritance = [];

      const insert = function (insertInfo) {
        insertInfo.name = path.basename(insertInfo.partial);
        const idx = partialInheritance.findIndex((info) => info.name === insertInfo.name);
        if (idx === -1) {
          partialInheritance.push(insertInfo);
        } else {
          partialInheritance.splice(idx, 1, insertInfo);
        }
      };

      if (this.superclass) {
        this.superclass.superclasses
          .slice()
          .reverse()
          .map((superclass) => superclass.partialInheritance)
          .flat()
          .forEach((info) => {
            if (info.for.includes("subclasses")) {
              insert(info);
            }
          });

        this.superclass.partialInheritance
          .forEach((info) => {
            if (info.for.includes("subclasses") || info.for.includes("subclass")) {
              insert(info);
            }
          });
      }

      this.partialInheritance.forEach((info) => {
        if (info.for.includes("me")) {
          insert(info);
        }
      });

      return partialInheritance
        .filter((info) => !this.allSubroutinesAsPartialNames.includes(info.name))
        .map((info) => info.partial);
    },
    reachableRandomObjectDoHandles () {
      return this.superclassesAndMe
        // .slice()
        // .reverse()
        .map((s) => s.randomObjectDoHandles || [])
        .flat();
    },
    randomObjectCreateHandles () {
      return this.allInstanceVariables.filter(iv => iv.klass && iv.klass.isSequenceItem && !iv.setter);
    },
    randomObjectDoHandles () {
      this.randomObjectCreateHandles.forEach(h => {
        if (!h.id) {
          throw new Error("All randoObjectDoHandles need an id: " + h.type + " " + h.name + " in " + h.parent.docType);
        }
      });
      return this.randomObjectCreateHandles;
    },
    getTypeFunction () {
      return `get_type_${this.type}`;
    },
    confirmDestroy () {
      if (this.deepSubclasses.length > 0) {
        return `This will delete ${this.type} and its subclasses ${this.deepSubclasses.map(klass => klass.type).join(", ").replace(/,(?!.*,)/gmi, " and")}.`;
      } else {
        return "";
      }
    }
  },
  methods: {
    getSuperclassByRootType (rootType) {
      return this.superclasses.find((superclass) => superclass.rootType === rootType);
    },
    beforeDirectDestroy () {
      return Promise.all(this.subclasses.map(subclass => {
        return subclass.beforeDirectDestroy().then(() => {
          return subclass.destroy();
        });
      }));
    }
  }
};

export default defineReactiveModel(Class);
