import Enum from "@/utils/enum";

const PortKindEnum = new Enum({
  0: "auto",
  1: "none",
  2: "clock",
  3: "reset",
  4: "addr",
  5: "data",
  6: "id",
  7: "len"
});

export default PortKindEnum;
