{{#each reachableRandomObjectDoHandles delimeter="\n"}}
{{#unless (includes (map ../subroutines "identifier") doName)}}
{{#if (or (includes ../randomObjectDoHandles .) (lookup ../allPolicyHandlesByRandomObjectHandleId [id]))}}
virtual function void {{ doName }}({{ type }} {{ shortName }}{{#each iterationPropertiesList.last.iterationVariables lc=true delimeter=", "}}int {{ . }}{{/each}});
{{#each (lookup ../allPolicyHandlesByRandomObjectHandleId [id])}}
  {{ type }} {{ fullName }} = new("{{ fullName }}");
{{/each}}
{{#if (not (includes ../randomObjectDoHandles .))}}
  {{#if (lookup ../allPolicyHandlesByRandomObjectHandleId [id])}}

  {{/if}}
  super.{{ doName }}({{ shortName }}{{#each iterationPropertiesList.last.iterationVariables lc=true delimeter=", "}}{{ . }}{{/each}});
{{/if}}
{{#each (lookup ../allPolicyHandlesByRandomObjectHandleId [id]) ln=true}}
  {{> (partial "snippets/add-policy") item=.. policyHandle=.}}
{{/each}}
endfunction
{{/if}}
{{/unless}}
{{/each}}
