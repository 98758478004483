import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";

const UvcAbstractProtocolCheckerAccessor = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage")
  ],
  created () {
    this.virtual = true;
    this.configDbKey = "pc_accessor";
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-abstract-protocol-checker-accessor/pure-methods" }
    ];
  },
  computed: {
    superclass () {
      return null;
    },
    type () {
      return `abstract_${this.uvc.rootName}_protocol_checker_accessor_c`;
    }
  }
};

export default defineReactiveModel(UvcAbstractProtocolCheckerAccessor);
