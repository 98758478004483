import { defineReactiveModel } from "vue-app-utils";
import { path } from "@/utils/node-VITE_APP_PLATFORM";
import store from "@/store";

const Lib = {
  mixins: [
  ],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    };
  },
  computed: {
    rootProject () {
      return this;
    },
    // ${cwd}/vip/lib
    collectionDirectory () {
      return path.join(store.getters.topDir, store.getters.user.setting.relVipDir, this.collectionName);
    },
    collectionName () {
      return "libs";
    },
    // comparator
    projectDirectory () {
      return this.name;
    },
    // name () {
    //   return "comparator";
    // },
    rootName () {
      return this.name;
    }
  }
};

export default defineReactiveModel(Lib);
