import { defineReactiveModel, SessionMixin } from "vue-app-utils";
import { IpDataMixin } from "vue-app-utils-web";
import "@/models/get-model";
import Model from "@/models/model";

const Session = defineReactiveModel({
  extends: Model,
  mixins: [
    SessionMixin,
    IpDataMixin
  ]
});

export default new Session();
