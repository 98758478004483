import { defineReactiveModel } from "vue-app-utils";
import EnvUvcInstanceMixin from "@/models/mixins/env-uvc-instance";

const EnvUvcInstance = {
  mixins: [
    EnvUvcInstanceMixin
  ],
  props: {
    user: {
      type: Object,
      required: true
    },
    parent: {
      type: Object,
      required: true
    },
    loadedUvc: {
      type: Object,
      required: true
    },
    master: {
      type: Boolean,
      required: true
    },
    suffix: {
      type: String,
      default: ""
    }
  },
  created () {
    this.id = `static-id-fe9c307-${this.uvcId}`;
    this.env = this.parent;
    this.parameterAssignments = [];
    this.portConnections = [];
  },
  computed: {
    uvc () {
      return this.loadedUvc;
    }
  }
};

export default defineReactiveModel(EnvUvcInstance);
