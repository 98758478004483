import findById from './db/relations/find-by-id.js';
import create from './db/relations/create.js';

const findUserByIdFn = findById("user", "users");
const createUserFn = create("user", "users", "User");

const DEFAULT_DATA = {};

var sessionMixin = {
  created () {
    this.docType = "session";
    this.users = {};
  },
  methods: {
    findOrCreateUserById (id, additionalData = DEFAULT_DATA) {
      const startSnapshot = true;
      return this.findUserById(id, startSnapshot)
        .then(user => {
          if (user) {
            if (additionalData !== DEFAULT_DATA) {
              return user.immediateUpdate(additionalData).then(() => {
                return user;
              });
            } else {
              return user;
            }
          } else {
            const startSnapshot = true;
            const data = {};
            if (import.meta.env.VITE_APP_PLATFORM === "web") {
              data.windowOrigin = window.origin;
            }
            return createUserFn.call(this, id, Object.assign(data, additionalData), startSnapshot)
              .then(user => {
                this.users[id] = user;
                return user;
              });
          }
        });
    },
    findUserById (id) {
      if (this.users[id]) {
        return Promise.resolve(this.users[id]);
      } else {
        const startSnapshot = true;
        return findUserByIdFn.call(this, id, startSnapshot)
          .then(user => {
            this.users[id] = user;
            return user;
          });
      }
    }
  }
};

export { sessionMixin as default };
