import Handle from "@/models/handle";

export default {
  data () {
    return {
      buildFactoryTypeOverrideInfo: [],
      runtimeFactoryTypeOverrideInfo: []
    };
  },
  created () {
    this.configuration = [];
  },
  computed: {
    creatorHandlesForBfmBuildFactoryTypeOverrides () {
      return this.bfmBuildFactoryTypeOverrides.map(pair => {
        return pair.base.rootProject.abstractBfmCreator && new Handle({
          propsData: {
            parent: this,
            klass: pair.base.rootProject.abstractBfmCreator,
            useSpecificNameOverride: true,
            relations: {
              pair
            }
          }
        });
      })
        .filter(h => h);
    },
    bfmBuildFactoryTypeOverrides () {
      return this.allBuildFactoryTypeOverrides.filter(pair => pair.base.isBfm);
    },
    nonBfmBuildFactoryTypeOverrides () {
      return this.allBuildFactoryTypeOverrides.filter(pair => !pair.base.isBfm);
    },
    allBuildFactoryTypeOverrides () {
      return this.computedBuildFactoryTypeOverrides
        .concat(this.buildFactoryTypeOverrides);
    },
    allRuntimeFactoryTypeOverrides () {
      return this.computedRuntimeFactoryTypeOverrides
        .concat(this.runtimeFactoryTypeOverrides);
    },
    buildFactoryTypeOverrides () {
      return this.getFactoryTypeOverrides("buildFactoryTypeOverrideInfo");
    },
    runtimeFactoryTypeOverrides () {
      return this.getFactoryTypeOverrides("runtimeFactoryTypeOverrideInfo");
    },
    computedBuildFactoryTypeOverrides () {
      return [];
    },
    computedRuntimeFactoryTypeOverrides () {
      return [];
    }
  },
  methods: {
    getFactoryTypeOverrides (overrideInfo) {
      return this[overrideInfo].map(info => {
        return {
          base: (this.tb || this.project).allClasses.find(c => c.uniqueId === info.base.uniqueId),
          override: (this.tb || this.project).allClasses.find(c => c.uniqueId === info.override.uniqueId)
        };
      })
        .filter(pair => pair.base && pair.override);
    },
    addBuildFactoryTypeOverride (base, override) {
      this.addFactoryTypeOverride(base, override, "buildFactoryTypeOverrideInfo");
    },
    addRuntimeFactoryTypeOverride (base, override) {
      this.addFactoryTypeOverride(base, override, "runtimeFactoryTypeOverrideInfo");
    },
    addFactoryTypeOverride (base, override, overrideInfo) {
      const data = {};
      data[overrideInfo] = this[overrideInfo].concat({
        base: {
          uniqueId: base.uniqueId
        },
        override: {
          uniqueId: override.uniqueId
        }
      });
      return this.immediateUpdate(data);
    },
    removeBuildFactoryTypeOverride (base, override) {
      this.removeFactoryTypeOverride(base, override, "buildFactoryTypeOverrideInfo");
    },
    removeRuntimeFactoryTypeOverride (base, override) {
      this.removeFactoryTypeOverride(base, override, "runtimeFactoryTypeOverrideInfo");
    },
    removeFactoryTypeOverride (base, override, overrideInfo) {
      const data = {};
      data[overrideInfo] = this[overrideInfo].filter(pair => !(pair.base.uniqueId === base.uniqueId && pair.override.uniqueId === override.uniqueId));
      return this.immediateUpdate(data);
    }
  }
};
