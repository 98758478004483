import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import config from "@/config";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import UvmSequenceItem from "@/models/uvm/uvm-sequence-item";
import Handle from "@/models/handle";
import Variable from "@/models/variable";
import Constraint from "@/models/constraint";
import FieldMacroFlagEnum from "@/utils/field-macro-flag-enum";
import get from "lodash.get";

const UvcSequenceItem = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("sequence-item-base")
  ],
  data () {
    return {
      hasPreTransferDelayVariable: true
    };
  },
  created () {
    this.iterationCharacter = "i";
    this.partialInheritance = [
      // [TODO] started this but its involved but doable to determine sformatf symbols.
      // See mixins/instance-variables.js TODO.
      // { for: ["me"], partial: "uvc-sequence-item/convert2string" }
      { for: ["me"], partial: "uvc-sequence-item/wait-for-resp" },
      { for: ["me"], partial: "uvc-sequence-item/resp-received" },
      { for: ["me"], partial: "uvc-sequence-item/to-index" }
    ];
  },
  computed: {
    superclass () {
      return UvmSequenceItem.singleton();
    },
    type () {
      return `${this.uvc.rootName}_sequence_item_c`;
    },
    computedInstanceVariables () {
      return [
        this.configHandle,
        this.phaseEnumVariable,
        this.killedByResetVariable,
        this.beatIndexVariable,
        this.idVariable,
        this.lenVariable,
        this.lastVariable,
        this.respEvtVariable
      ]
        .filter(o => o);
    },
    computedOverridableInstanceVariables () {
      return [
        this.preTransferDelayVariable
      ]
        .filter(o => o);
    },
    computedConstraints () {
      const constraints = [
        this.preTransferDelayConstraint,
        this.writeConstraint,
        this.lenConstraint
      ]
        .filter(c => c);

      this.masterAndSlaveConstraints.forEach(masterOrSlaveConstraint => {
        if (!constraints.find(constraint => constraint.instanceVariable.id === masterOrSlaveConstraint.instanceVariable.id)) {
          constraints.push(masterOrSlaveConstraint);
        }
      });
      return constraints;
    },
    instanceVariablesByRelatedPort () {
      const ret = {};

      this.almostAllInstanceVariables.forEach(iv => {
        iv.relatedPorts.forEach(relatedPort => {
          if (relatedPort) {
            ret[relatedPort.identifier] = iv;
          }
        });
        if ((iv.relatedPorts.length === 0) && iv.relatedPort) {
          ret[iv.relatedPort.identifier] = iv;
        }
      });

      return ret;
    },
    convertToStringInstanceVariables () {
      return this.allInstanceVariables.filter(iv => iv.convert2string);
    },
    killedByResetVariable () {
      return (this.interface && this.interface.hasResetAndOtherPorts)
        ? new Variable({
          propsData: {
            parent: this,
            type: "bit",
            name: "killed_by_reset",
            fieldMacro: "None",
            convert2stringOverride: false
          }
        })
        : null;
    },
    beatIndexVariable () {
      return this.isPipelined
        ? new Variable({
          propsData: {
            parent: this,
            type: "int",
            name: "beat_index",
            fieldMacro: "None"
          }
        })
        : null;
    },
    phaseEnumVariable () {
      return this.hasPhases
        ? new Handle({
          propsData: {
            id: "static-id-6644332",
            parent: this,
            klass: this.uvcPackage.phaseEnumType,
            isRand: true
          }
        })
        : null;
    },
    preTransferDelayVariable () {
      if (this.hasPreTransferDelayVariable) {
        const staticId = "static-id-de3f432";
        return this.instanceVariablesByOverrideId[staticId] || new Variable({
          propsData: {
            id: staticId,
            parent: this,
            type: "rand int",
            name: "pre_transfer_delay",
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT"),
              FieldMacroFlagEnum.getValue("UVM_NOCOMPARE"),
              FieldMacroFlagEnum.getValue("UVM_NOPRINT"),
              FieldMacroFlagEnum.getValue("UVM_DEC")
            ]
          }
        });
      } else {
        return null;
      }
    },
    preTransferDelayConstraint () {
      return this.preTransferDelayVariable
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.preTransferDelayVariable,
            content: `${this.preTransferDelayVariable.identifier} >= 0;`
          }
        })
        : null;
    },
    idVariable () {
      return (this.isPipelined && get(this, "interface.idMsbMaxParameter"))
        ? new Variable({
          propsData: {
            id: "static-id-d32f422",
            parent: this,
            type: `rand logic [${this.interface.idMsbMaxParameter.name}:0]`,
            name: "id"
          }
        })
        : null;
    },
    dataVariables () {
      return this.instanceVariables.filter(iv => iv.name.match(config.dataRegExp));
    },
    writeVariable () {
      return this.instanceVariables.find(iv => iv.name.match(config.writeRegExp));
    },
    strobeVariable () {
      return this.instanceVariables.find(iv => iv.name.match(config.strobeRegExp));
    },
    lenVariable () {
      return (this.isPipelined && get(this, "interface.lenMsbMaxParameter"))
        ? new Variable({
          propsData: {
            id: "static-id-e3ab4f2",
            parent: this,
            type: `rand logic [${this.interface.lenMsbMaxParameter.name}:0]`,
            name: "len",
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT"),
              FieldMacroFlagEnum.getValue("UVM_DEC")
            ]
          }
        })
        : null;
    },
    respVariable () {
      return this.instanceVariables.find(iv => iv.name.match(/resp/));
    },
    writeConstraint () {
      return (this.writeVariable && (this.dataVariables.length || this.strobeVariable))
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.writeVariable,
            content: [...this.dataVariables, this.strobeVariable].filter(v => v).map(v => `solve ${this.writeVariable.identifier} before ${v.identifier};`).join("\n")
          }
        })
        : null;
    },
    // The reason for redundancy of the manual addr, write, data, etc constraints and the masterAndSlaveConstraints automatically computed is
    // because its possible automatic constraint will not be created if a relatedPort can't be found. This can happen when the placeholder
    // name is not used when creating instance variables.
    // [TODO] should i create computed instance variables instead of giving the user a chance to screw it up by changing the placeholder? It's a little more complicated to manage
    // editing and deleting computed instance variables but I was able to do it for computed constraints, so...
    masterAndSlaveConstraints () {
      if (this.configHandle && get(this, "config.uvcParametersHandle") && this.uvcParameters) {
        const ivs = get(this.sequenceItem, "almostAllInstanceVariables", []).filter(iv => iv.relatedPort && get(iv.relatedPort, "typeInfo.packed.dimensions.length") === 1 && iv.relatedPort.typeInfo.packed.dimensions[0].type === "staticArray" && (iv.relatedPort.typeInfo.packed.dimensions[0].lhs.parameterIdentifiers.length || iv.relatedPort.typeInfo.packed.dimensions[0].rhs.parameterIdentifiers.length));

        return ivs.map(iv => {
          const lhs = iv.relatedPort.typeInfo.packed.dimensions[0].lhs;
          const rhs = iv.relatedPort.typeInfo.packed.dimensions[0].rhs;

          const uvcParams = `${this.configHandle.identifier}.${this.config.uvcParametersHandle.identifier}`;

          let msb = lhs.constantExpression;
          let lsb = rhs.constantExpression;

          try {
            lhs.parameterIdentifiers.forEach(parameterIdentifier => {
              const uvcParamsVariable = this.uvcParameters.findVariable(parameterIdentifier);
              if (!uvcParamsVariable) {
                throw new Error("Could not find variable matching parameter.");
              }
              msb = msb.replace(new RegExp(parameterIdentifier, "g"), `${uvcParams}.${uvcParamsVariable.identifier}`);
            });
            rhs.parameterIdentifiers.forEach(parameterIdentifier => {
              const uvcParamsVariable = this.uvcParameters.findVariable(parameterIdentifier);
              if (!uvcParamsVariable) {
                throw new Error("Could not find variable matching parameter.");
              }
              lsb = lsb.replace(new RegExp(parameterIdentifier, "g"), `${uvcParams}.${uvcParamsVariable.identifier}`);
            });
          } catch {
            return null;
          }

          if (lhs.multipleTerms && ((msb[0] !== "(") || (msb.substr(-1) !== ")"))) {
            msb = "(" + msb + ")";
          }

          if (rhs.multipleTerms && ((lsb[0] !== "(") || (lsb.substr(-1) !== ")"))) {
            lsb = "(" + lsb + ")";
          }

          let content = `(2**(${msb}${(lsb && (lsb !== "0")) ? ` - ${lsb}` : ""}+1))-1`;
          if (iv.isDynamicArray) {
            if (this.package.beatsInBurstMaxParameter) {
              content = `${iv.identifier}.size <= ${this.package.beatsInBurstMaxParameter.name};
foreach (${iv.identifier}[${iv.identifier[0]}]) {
  ${iv.identifier}[${iv.identifier[0]}] <= ${content};
}`;
            } else {
              return null;
            }
          } else {
            content = `${iv.identifier} <= ${content};`;
          }
          return new Constraint({
            propsData: {
              parent: this,
              instanceVariable: iv,
              content
            }
          });
        })
          .filter(c => c);
      } else {
        return [];
      }
    },
    lenConstraint () {
      return (this.lenVariable && this.configHandle && get(this, "config.uvcParametersHandle") && this.uvcParameters)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.lenVariable,
            content: `${this.lenVariable.identifier} <= (2**(${this.configHandle.identifier}.${this.config.uvcParametersHandle.identifier}.${this.uvcParameters.lenMsbVariable.identifier}+1))-1;\n${this.lenVariable.identifier} <= ${this.package.beatsInBurstMaxParameter.name};`
          }
        })
        : null;
    },
    lastVariable () {
      return this.isPipelined
        ? new Variable({
          propsData: {
            id: "static-id-d3ab422",
            parent: this,
            type: "rand bit",
            name: "last",
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT"),
              FieldMacroFlagEnum.getValue("UVM_NOCOMPARE"),
              FieldMacroFlagEnum.getValue("UVM_NOPRINT"),
              FieldMacroFlagEnum.getValue("UVM_BIN")
            ]
          }
        })
        : null;
    },
    respEvtVariable () {
      return (this.hasMaster && this.isPipelinedWrite)
        ? new Variable({
          propsData: {
            parent: this,
            type: "protected uvm_event",
            name: "resp_evt",
            fieldMacro: "None",
            defaultValue: "new(\"resp_evt\")",
            convert2stringOverride: false
          }
        })
        : null;
    }
  }
};

export default defineReactiveModel(UvcSequenceItem);
