import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";

const UvcProtocolCheckerAccessor = {
  extends: Class,
  mixins: [
    UvcGetters,
    Persistable,
    belongsTo("uvcProtocolChecker")
  ],
  created () {
    this.partialInheritance = [
      { for: ["me"], partial: "design-element-accessor/set-print-context" },
      { for: ["me"], partial: "design-element-accessor/update-variables" }
    ];
  },
  computed: {
    superclass () {
      return this.abstractProtocolCheckerAccessor;
    },
    type () {
      return `${this.uvc.rootName}_protocol_checker_accessor_c`;
    }
  }
};

export default defineReactiveModel(UvcProtocolCheckerAccessor);
