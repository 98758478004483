{{#generate dimensionPropertiesList blockNamePrefix}}
  {{> (partial "snippets/testbench-resources") env=env dimensionPropertiesList=dimensionPropertiesList.next blockNamePrefix=blockNamePrefix envInstanceHierarchicalProperties=envInstanceHierarchicalProperties}}
{{else}}

{{#each envInstanceHierarchicalProperties delimeter="\n"}}
initial begin : {{ envInstance.instanceNameUpperCase }}_RESOURCES{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
{{#if (eq ../env envInstance.env)}}
  static string env_full_name = TOP_ENV_FULL_NAME;
{{else}}
  {{#if hasDimensions}}
  automatic string env_full_name = $sformatf("%s{{#each envHandleHier}}.{{ identifier }}{{ envInstance.formatBrackets }}{{/each}}", TOP_ENV_FULL_NAME{{#each verificationAlignedDimensions lc=true delimeter=", "}}{{ . }}{{/each}});
  {{else}}
  automatic string env_full_name = { TOP_ENV_FULL_NAME, "{{#each envHandleHier}}.{{ identifier }}{{/each}}" };
  {{/if}}
{{/if}}

  {{#if (eq ../env envInstance.env)}}
  {{ envInstance.hdlPathWithDimensionVariables }}.{{ envInstance.env.interfaceHarness.type }}.add_resources_to_config_db(env_full_name);
  {{else}}
  `ifdef {{ envInstance.enableDutFunctionalVerificationMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
    {{#each envInstanceHier delimeter="."}}{{ hdlPathWithDimensionVariables }}{{/each}}.{{ envInstance.env.interfaceHarness.type }}.add_resources_to_config_db(env_full_name);
  `else
    uvm_config_db#(bit)::set(null, env_full_name, "{{ envInstance.envConfig.interfaceHarnessBoundVariable.configDbKey }}", 0);
  `endif
  {{/if}}
{{#if envInstance.env.hasProtocolCheckerHarness}}

  `ifdef {{ envInstance.enableDutProtocolCheckersMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
    {{#each envInstanceHier delimeter="."}}{{ hdlPathWithDimensionVariables }}{{/each}}.{{ envInstance.env.protocolCheckerHarness.type }}.add_resources_to_config_db(env_full_name);
  `else
    uvm_config_db#(bit)::set(null, env_full_name, "{{ envInstance.envConfig.protocolCheckerHarnessBoundVariable.configDbKey }}", 0);
  `endif
{{/if}}
{{#if envInstance.env.hasSignalChecker}}

  `ifdef {{ envInstance.enableDutSignalCheckerMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
    {{#each envInstanceHier delimeter="."}}{{ hdlPathWithDimensionVariables }}{{/each}}.{{ envInstance.env.signalChecker.type }}.add_resources_to_config_db(env_full_name);
  `else
    uvm_config_db#(bit)::set(null, env_full_name, "{{ envInstance.envConfig.signalCheckerBoundVariable.configDbKey }}", 0);
  `endif
{{/if}}
end
{{/each}}
{{/generate}}
