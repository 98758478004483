{{#if isPipelinedWrite}}
virtual task write_data_item({{ sequenceItem.type }} item);
  if (!{{ requestItemsHandle.identifier }}.exists(item.{{ sequenceItem.idVariable.identifier }})) begin
    {{ dataItemsHandle.identifier }}[item.{{ sequenceItem.idVariable.identifier }}] = item;
  end else begin
    {{#with requestItemHandle}}{{ type }} {{ identifier }}{{/with}} = {{ requestItemsHandle.identifier }}[item.{{ sequenceItem.idVariable.identifier }}];
    {{ requestItemsHandle.identifier }}.delete(item.{{ sequenceItem.idVariable.identifier }});
    send_response({{ requestItemHandle.identifier }}, item);
  end
endtask
{{/if}}
