import Parser from "@/pegjs/system-verilog.pegjs";
import config from "@/config";
import get from "lodash.get";

export default {
  computed: {
    lowerCaseName () {
      return this.name.toLowerCase();
    },
    baseName () {
      return this.lowerCaseName.replace(/_(m|l)sb$/, "");
    },
    maskName () {
      return `${this.baseName}_mask`;
    },
    isMsb () {
      return !!this.name.match(/_MSB$/);
    },
    isLsb () {
      return !!this.name.match(/_LSB$/);
    },
    relatedMsb () {
      if (this.isLsb) {
        return this.parent.parameters.find((parameter) => {
          return (parameter.isMsb && (parameter.baseName === this.baseName));
        });
      } else {
        return null;
      }
    },
    relatedLsb () {
      if (this.isMsb) {
        return this.parent.parameters.find((parameter) => {
          return (parameter.isLsb && (parameter.baseName === this.baseName));
        });
      } else {
        return null;
      }
    },
    hasMask () {
      return this.isMsb || (this.isLsb && this.relatedMsb);
    },
    minOrMaxValue () {
      if (this.minOrMaxValueOverride) {
        return this.minOrMaxValueOverride;
      } else if (this.isMsb) {
        let value;
        if (this.name.match(config.addressRegExp)) {
          value = "63";
        } else if (this.name.match(config.dataRegExp)) {
          value = "31";
        } else {
          value = "7";
        }
        const num = parseInt(this.defaultValue);
        if (typeof num === "number") {
          return (num > value) ? this.defaultValue : value;
        } else {
          return value;
        }
      } else {
        return "0";
      }
    },
    defaultValue () {
      if (this.defaultValueOverride) {
        return this.defaultValueOverride;
      } else if (get(this, "defaultValueParameter.name")) {
        return this.defaultValueParameter.name;
      } else if (this.isMsb) {
        if (this.name.match(config.addressRegExp)) {
          return "31";
        } else if (this.name.match(config.dataRegExp)) {
          return "31";
        } else {
          return "3";
        }
      } else {
        return "0";
      }
    },
    typeInfo () {
      let info = null;
      try {
        info = Parser.parse(this.type, { startRule: "ParameterType" });
      } catch (e) {
        // continue regardless of error
      }
      return info;
    },
    nameInfo () {
      let info = null;
      try {
        info = Parser.parse(this.name, { startRule: "ParameterIdentifier" });
      } catch (e) {
        // continue regardless of error
      }
      return info;
    },
    rootType () {
      return this.typeInfo && this.typeInfo.rootType;
    },
    isLocalparam () {
      return this.typeInfo && this.typeInfo.isLocalparam;
    },
    identifier () {
      return this.nameInfo && this.nameInfo.identifier;
    },
    // [TODO] is this needed anymore since we're not doing default values in modules/interfaces
    // anymore? Leave here til this is tested in both questa and vcs.
    defaultValueParameter () {
      if (this.defaultValueParameterId) {
        return get(this.parent, "package.parameters", []).find((p) => {
          return p.id === this.defaultValueParameterId;
        });
      }
      return null;
    },
    setterName () {
      return `set_${this.lowerCaseName}`;
    }
  }
};
