import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import UvmSequence from "@/models/uvm/uvm-sequence";
import Handle from "@/models/handle";
import Variable from "@/models/variable";
import FieldMacroFlagEnum from "@/utils/field-macro-flag-enum";
import Constraint from "@/models/constraint";

const UvcSlaveResponseSequenceBase = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("sequence")
  ],
  created () {
    this.master = false;
    this.channel = "response";
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-slave-response-sequence/body" }
    ];
  },
  computed: {
    superclass () {
      if (this.sequenceItem) {
        return UvmSequence.singleton(this.sequenceItem);
      } else {
        return undefined;
      }
    },
    type () {
      const parts = [this.uvc.rootName];
      parts.push(this.protocolRoleWord);
      parts.push("response");
      parts.push("sequence");
      if (this.slaveResponseSequence) {
        parts.push("base");
      }
      parts.push("c");
      return parts.join("_");
    },
    responseWord () {
      return this.isPipelinedRead ? "beat" : "response";
    },
    computedInstanceVariables () {
      return [
        this.configHandle,
        this.requestItemHandle,
        this.dataItemHandle,
        this.responseItemHandle,
        this.responsePrioritiesVariable,
        this.beatIndexVariable
      ]
        .filter(o => o);
    },
    computedConstraints () {
      return [
        this.responsePrioritiesConstraint
      ]
        .filter(c => c);
    },
    preRandomizeInitialValues () {
      return this.randomObjectCreateHandles.map(handle => {
        return {
          name: handle.identifier,
          defaultValue: `${handle.createRandomFunctionName}()`
        };
      });
    },
    randomObjectCreateHandles () {
      return [
        this.responseItemHandle
      ]
        .filter(h => h);
    },
    requestItemHandle () {
      if (this.sequenceItem) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.sequenceItem,
            nameOverride: "request_item",
            setter: true
          }
        });
      } else {
        return undefined;
      }
    },
    dataItemHandle () {
      if (this.sequenceItem && this.isPipelinedWrite) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.sequenceItem,
            nameOverride: "data_item",
            setter: true
          }
        });
      } else {
        return undefined;
      }
    },
    responseItemHandle () {
      if (this.sequenceItem && this.isPipelinedRead) {
        return new Handle({
          propsData: {
            id: "static-id-58a10eb",
            parent: this,
            klass: this.sequenceItem,
            nameOverride: "response_item",
            isRandomRefItem: true,
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT"),
              FieldMacroFlagEnum.getValue("UVM_NOCOMPARE"),
              FieldMacroFlagEnum.getValue("UVM_REFERENCE")
            ]
          }
        });
      } else {
        return undefined;
      }
    },
    sequenceItemHandle () {
      return this.reqHandle;
    },
    reqHandle () {
      if (this.sequenceItem) {
        return new Handle({
          propsData: {
            id: "static-id-5837feb",
            parent: this,
            klass: this.sequenceItem,
            nameOverride: "req",
            priority: this.isPipelinedRead
              ? `${this.responsePrioritiesVariable.identifier}[${this.beatIndexVariable.identifier}]`
              : this.isPipelinedWrite
                ? `${this.responsePrioritiesVariable.identifier}`
                : null
          }
        });
      } else {
        return undefined;
      }
    },
    responsePrioritiesVariable () {
      return this.isPipelined
        ? new Variable({
          propsData: {
            id: "static-id-748fef2",
            parent: this,
            type: "rand int",
            name: this.isPipelinedWrite ? `${this.responseWord}_priority` : `${this.responseWord}_priorities[]`,
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT"),
              FieldMacroFlagEnum.getValue("UVM_NOCOMPARE"),
              FieldMacroFlagEnum.getValue("UVM_NOPRINT")
            ]
          }
        })
        : null;
    },
    responsePrioritiesConstraint () {
      return this.responsePrioritiesVariable
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.responsePrioritiesVariable,
            content: this.isPipelinedWrite
              ? `${this.responsePrioritiesVariable.identifier} >= 0;`
              : `${this.responsePrioritiesVariable.identifier}.size == ${this.responseItemHandle.identifier}.${this.responseItemHandle.klass.lenVariable.identifier};\nforeach (${this.responsePrioritiesVariable.identifier}[b]) {\n  ${this.responsePrioritiesVariable.identifier}[b] >= 0;\n}`
          }
        })
        : null;
    },
    beatIndexVariable () {
      return this.isPipelinedRead
        ? new Variable({
          propsData: {
            // id: "static-id-738eef2",
            parent: this,
            type: "int",
            name: "beat_index",
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT"),
              FieldMacroFlagEnum.getValue("UVM_NOCOMPARE"),
              FieldMacroFlagEnum.getValue("UVM_NOCOPY"),
              FieldMacroFlagEnum.getValue("UVM_NOPRINT")
            ]
          }
        })
        : null;
    },
    randomObjectDoHandles () {
      return [
        this.responseItemHandle,
        this.reqHandle
      ]
        .filter(h => h);
    },
    computedPolicyHandles () {
      return [
        this.reqHandle && this.slaveResponsePolicy && new Handle({
          propsData: {
            parent: this,
            klass: this.slaveResponsePolicy,
            relations: {
              randomObjectDoHandle: this.reqHandle
            }
          }
        }),
        this.responseItemHandle && this.slaveFullResponsePolicy && new Handle({
          propsData: {
            parent: this,
            klass: this.slaveFullResponsePolicy,
            relations: {
              randomObjectDoHandle: this.responseItemHandle
            }
          }
        })
      ]
        .filter(h => h);
    }
  }
};

export default defineReactiveModel(UvcSlaveResponseSequenceBase);
