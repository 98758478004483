import parseSystemVerilog from "@/utils/parse-system-verilog";
import replaceParametersWithIds from "@/utils/replace-parameters-with-ids";

const EncodeTypeAndNameParameters = function (typeParserName, nameParserName) {
  return {
    methods: {
      parentOfParameters () {
        throw new Error("Must override parentOfParameters.");
      },
      parseType (type) {
        const info = parseSystemVerilog(type, typeParserName);
        info.data.encodedType = replaceParametersWithIds(
          info.data.type,
          info.packed ? info.packed.parameterIdentifiers : [],
          this.parentOfParameters().parametersByName
        );
        return info;
      },
      parseName (name) {
        const info = parseSystemVerilog(name, nameParserName);
        info.data.encodedName = replaceParametersWithIds(
          info.data.name,
          info.unpacked.dimensions.map(dimension => [dimension.lhs.parameterIdentifiers, dimension.rhs.parameterIdentifiers]).flat(Infinity),
          this.parentOfParameters().parametersByName
        );
        return info;
      },
      createParametersForList (list) {
        const parameterIdentifiers = list.map(info => {
          const unpackedParameterIdentifiers = info.unpacked.dimensions
            .map(dimension => [dimension.lhs && dimension.lhs.parameterIdentifiers, dimension.rhs && dimension.rhs.parameterIdentifiers])
            .flat(Infinity)
            .filter(i => i);
          return (info.packed ? info.packed.parameterIdentifiers : []).concat(unpackedParameterIdentifiers);
        })
          .flat();
        const uniqueParameterIdentifiers = [...new Set(parameterIdentifiers)];
        return this.createParameters(uniqueParameterIdentifiers).then(() => {
          list.forEach((info) => {
            info.data.encodedType = replaceParametersWithIds(
              info.data.type,
              info.packed ? info.packed.parameterIdentifiers : [],
              this.parentOfParameters().parametersByName
            );
            delete info.data.type;
            info.data.encodedName = replaceParametersWithIds(
              info.data.name,
              info.unpacked.dimensions.map(dimension => [dimension.lhs && dimension.lhs.parameterIdentifiers, dimension.rhs && dimension.rhs.parameterIdentifiers]).flat(Infinity).filter(i => i),
              this.parentOfParameters().parametersByName
            );
            delete info.data.name;
          });
          return list;
        });
      },
      createParametersForType (type) {
        const info = parseSystemVerilog(type, typeParserName);
        return this.createParameters(info.packed ? info.packed.parameterIdentifiers : []);
      },
      createParametersForName (name) {
        const info = parseSystemVerilog(name, nameParserName);
        const unpackedParameterIdentifiers = info.unpacked.dimensions
          .map(dimension => [dimension.lhs && dimension.lhs.parameterIdentifiers, dimension.rhs && dimension.rhs.parameterIdentifiers])
          .flat(Infinity)
          .filter(i => i);
        return this.createParameters(unpackedParameterIdentifiers);
      },
      createParameters (parameterIdentifiers) {
        const promises = parameterIdentifiers.map(identifier => {
          let promise;
          if (!this.parentOfParameters().parametersByName[identifier]) {
            promise = this.parentOfParameters().createParameter({ type: "parameter", name: identifier });
          } else {
            promise = Promise.resolve(false);
          }
          return promise.then((created) => {
            return new Promise((resolve, reject) => {
              const p = this.parentOfParameters();
              (function waitForPropogation () {
                if (p.parametersByName[identifier]) {
                  return resolve(created);
                } else {
                  setTimeout(waitForPropogation, 3);
                }
              })();
            });
          });
        });
        return Promise.all(promises).then(parametersCreated => {
          return parametersCreated.some(created => created);
        });
      }
    }
  };
};

const DecodeTypeAndNameParameters = {
  computed: {
    type () {
      return this.decodeParameterIds(this.encodedType);
    },
    name () {
      return this.decodeParameterIds(this.encodedName);
    }
  },
  methods: {
    parentOfParameters () {
      throw new Error("Must override parentOfParameters.");
    },
    decodeParameterIds (value) {
      let v = value;
      this.parentOfParameters().allParameters.forEach((p) => {
        v = v.replace(new RegExp(`__UVMGEN_ID_${p.id}__`, "g"), p.identifier);
      });
      v = v.replace(/__UVMGEN_ID_(?:(?!__).)+__/g, "");
      return v;
    }
  }
};

export { EncodeTypeAndNameParameters, DecodeTypeAndNameParameters };
