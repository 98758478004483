import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";
import ParameterMixin from "@/models/mixins/parameter";
import MinOrMaxPackageParameter from "@/models/min-or-max-package-parameter";

const Parameter = {
  extends: Model,
  mixins: [
    Persistable,
    ParameterMixin
  ],
  data () {
    return {
      type: "",
      name: "",
      defaultValueOverride: "",
      defaultValueParameterId: "",
      minOrMaxValueOverride: "",
      typeReadonly: false,
      nameReadonly: false
    };
  },
  computed: {
    minOrMaxPackageParameter () {
      if (this.isMsb || this.isLsb) {
        return new MinOrMaxPackageParameter({
          propsData: {
            parameter: this
          }
        });
      } else {
        return null;
      }
    }
  }
};

export default defineReactiveModel(Parameter);
