import { defineReactiveModel, Persistable, hasOne } from "vue-app-utils";
// import Vue from "vue";
import Model from "@/models/model";
import userFileRelations from "@/models/persistable/mixins/user-file-relations-VITE_APP_PLATFORM";
import config from "@/config";
import store from "@/store";
import { path } from "@/utils/node-VITE_APP_PLATFORM";
import get from "lodash.get";
import UvmSequence from "@/models/uvm/uvm-sequence";

const { hasMany: hasManyUserFiles } = userFileRelations;

const envHasOne = (import.meta.env.VITE_APP_PLATFORM === "desktop")
  ? [
    hasOne("uvcStandaloneEnv")
  ]
  : [];

const Uvc = {
  extends: Model,
  mixins: [
    Persistable,
    hasOne("uvcPackage"),
    hasOne("uvcInterface"),
    hasOne("uvcProtocolChecker"),
    hasManyUserFiles("userIncludes", {
      directory: function () {
        return config.userIncludesPath();
      }
    }),
    ...envHasOne
  ],
  data () {
    return {
      name: "",
      isLoaded: false,
      pipelined: "",
      isOfficialClkUvc: false,
      isOfficialRstUvc: false,
      isOfficialSidebandUvc: false,
      hasMasterOverride: null,
      hasSlaveOverride: null,
      hasMonitorOverride: null,
      hasUvcCoverageOverride: null,
      hasRegisterOverride: null,
      hasProtocolCheckerOverride: null,
      hasStandaloneTbOverride: null,
      standaloneEnv: null,
      numDownloads: 0
    };
  },
  created () {
    this.isUvc = true;
  },
  watch: {
    hasStandaloneTb: {
      immediate: true,
      handler (value) {
        if (this.isLoaded) {
          if (value) {
            this.uvcStandaloneEnv; // eslint-disable-line no-unused-expressions
            this.promiseUvcStandaloneEnv.then(standaloneEnv => {
              standaloneEnv.tb; // eslint-disable-line no-unused-expressions
              standaloneEnv.promiseEnvTb.then(() => {
                standaloneEnv.tb.load().then(() => {
                  this.standaloneEnv = standaloneEnv;
                });
              });
            });
          } else {
            this.standaloneEnv = null;
          }
        }
      }
    }
  },
  computed: {
    rootProject () {
      return this;
    },
    project () {
      return this;
    },
    // ${cwd}/vip/uvcs
    collectionDirectory () {
      // [TODO] is this web friendly?
      return path.join(store.getters.topDir, this.user.setting.relVipDir, this.collectionName);
    },
    collectionName () {
      return "uvcs";
    },
    // apb
    projectDirectory () {
      return this.name;
    },
    rootName () {
      return this.name;
    },
    hasName () {
      return !!this.name;
    },
    standaloneTb () {
      return this.standaloneEnv && this.standaloneEnv.tb;
    },
    rootGeneratableFileModels () {
      return this.hasName
        ? [
          this.package,
          this.protocolChecker,
          this.bfmBase,
          this.masterRequestBfm,
          this.masterDataBfm,
          this.masterResponseBfm,
          this.slaveRequestBfm,
          this.slaveDataBfm,
          this.slaveResponseBfm,
          this.monitorBfm,
          this.bfmCreator,
          this.interface,
          ...(this.interface ? this.interface.includes : [])
        ]
          .filter(m => m)
        : [];
    },
    // generating () {
    //   console.log(this.generatableFileModels.length === 0, this.generatableFileModels.some(m => m.generating), JSON.stringify(this.generatableFileModels.map(m => m.generating)));
    //   return (this.generatableFileModels.length === 0) || this.generatableFileModels.some(m => m.generating);
    // },
    generatableFileModels () {
      return this.hasName
        ? [
          ...get(this, "standaloneEnv.generatableFileModels", []),
          get(this, "standaloneEnv.dut"),
          ...get(this, "standaloneTb.generatableFileModels", []),
          ...get(this, "package.generatableFileModels", []),
          ...this.rootGeneratableFileModels.slice(0, -2),
          ...get(this, "package.bfmFormSubclasses", []),
          ...this.rootGeneratableFileModels.slice(-2)
        ]
          .filter(m => m)
        : [];
    },
    allGeneratableFileModels () {
      return this.generatableFileModels.concat(this.generatableFileModelsNotRendered);
    },
    generatableFileModelsNotRendered () {
      const policyLibGeneratableFileModels = this.user.policyLib ? this.user.policyLib.generatableFileModels : [];
      return this.standaloneEnv
        ? this.standaloneEnv.uniqueUvcs
          .filter(uvc => uvc !== this)
          .map(uvc => uvc.generatableFileModels)
          .flat()
          .concat(policyLibGeneratableFileModels)
          .concat((this.standaloneEnv.hasScoreboard && this.user.comparatorLib) ? this.user.comparatorLib.generatableFileModels : [])
          .concat(this.user.softwareLicenseAgreement)
        : policyLibGeneratableFileModels
          .concat(this.user.softwareLicenseAgreement);
    },
    computeGeneratableFileModelsNotRendered () {
      if (this.isLoaded) {
        this.generatableFileModelsNotRendered.forEach(model => {
          model.absoluteFilePath; // eslint-disable-line no-unused-expressions
          model.projectFilePath; // eslint-disable-line no-unused-expressions
          model.fileContents; // eslint-disable-line no-unused-expressions
        });
      }
    },
    bfms () {
      return [
        this.masterRequestBfm && this.masterRequestBfm.deepSubclassesAndMe,
        this.masterDataBfm && this.masterDataBfm.deepSubclassesAndMe,
        this.masterResponseBfm && this.masterResponseBfm.deepSubclassesAndMe,
        this.slaveRequestBfm && this.slaveRequestBfm.deepSubclassesAndMe,
        this.slaveDataBfm && this.slaveDataBfm.deepSubclassesAndMe,
        this.slaveResponseBfm && this.slaveResponseBfm.deepSubclassesAndMe,
        this.monitorBfm && this.monitorBfm.deepSubclassesAndMe
      ]
        .flat()
        .filter(m => m);
    },
    nonUserClasses () {
      return [
        ...this.rootGeneratableFileModels,
        ...get(this, "package.baseGeneratableFileModels", []),
        ...get(this, "package.formClasses", []),
        UvmSequence.singleton(this.sequenceItem),
        UvmSequence.singleton(this.registerSequenceItem)
      ]
        .filter(m => m && m.isClass);
    },
    nonUserClassesByUniqueId () {
      const classesByUniqueId = {};
      this.nonUserClasses.forEach(c => {
        classesByUniqueId[c.uniqueId] = c;
      });
      return classesByUniqueId;
    },
    // For classesByType, subclasses (root.classes) and mixins/test (tb.classes)
    classes () {
      return this.nonUserClasses
        .concat(get(this, "package.userClasses", []));
    },
    classesBySuperclassUniqueId () {
      const ret = {};
      this.classes.forEach(klass => {
        if (klass.superclass && klass.superclass.uniqueId) {
          ret[klass.superclass.uniqueId] = ret[klass.superclass.uniqueId] || [];
          ret[klass.superclass.uniqueId].push(klass);
        }
      });
      return ret;
    },
    // For finding superclass of user-classes. More classes wouldn't be bad so long as type collision guaranteed not to happen.
    classesByType () {
      const classesByType = {};
      this.classes.forEach((c) => {
        classesByType[c.type] = c;
      });
      return classesByType;
    },
    factoryOverrides () {
      return this.generatableFileModels.map(model => {
        if (model.factoryOverride) {
          return {
            base: model,
            override: model.factoryOverride
          };
        } else {
          return null;
        }
      })
        .filter(o => o);
    },
    hasStandaloneTb () {
      if (this.hasStandaloneTbOverride !== null) {
        return this.hasStandaloneTbOverride;
      } else {
        return false; // this.hasMaster;
      }
    },
    package () {
      return this.uvcPackage;
    },
    abstractBfm () {
      return get(this, "package.uvcAbstractBfm");
    },
    abstractBfmCreator () {
      return get(this, "package.uvcAbstractBfmCreator");
    },
    abstractProtocolCheckerAccessor () {
      return this.hasProtocolChecker
        ? get(this, "package.uvcAbstractProtocolCheckerAccessor")
        : null;
    },
    hasUvcParameters () {
      return !!get(this, "interface.parametersSetToConfigDb", []).length;
    },
    uvcParameters () {
      return this.hasUvcParameters
        ? get(this, "package.uvcParameters")
        : null;
    },
    config () {
      return get(this, "package.uvcConfig");
    },
    sequenceItem () {
      return get(this, "package.uvcSequenceItem");
    },
    hasMasterRequestPolicy () {
      return this.hasMaster && this.interface && !this.interface.hasClockOnly;
    },
    masterRequestPolicy () {
      return this.hasMasterRequestPolicy
        ? get(this, "package.uvcMasterRequestPolicy")
        : null;
    },
    masterRequestDefaultPreTransferDelayPolicy () {
      return (this.hasMaster && get(this, "sequenceItem.hasPreTransferDelayVariable"))
        ? get(this, "package.uvcMasterRequestDefaultPreTransferDelayPolicy")
        : null;
    },
    masterDataPolicy () {
      return (this.hasMaster && this.isPipelinedWrite)
        ? get(this, "package.uvcMasterDataPolicy")
        : null;
    },
    masterDataDefaultPreTransferDelayPolicy () {
      return (this.hasMaster && this.isPipelinedWrite && get(this, "sequenceItem.hasPreTransferDelayVariable"))
        ? get(this, "package.uvcMasterDataDefaultPreTransferDelayPolicy")
        : null;
    },
    masterDataSequenceBase () {
      return (this.hasMaster && this.isPipelinedWrite)
        ? get(this, "package.uvcMasterDataSequenceBase")
        : null;
    },
    masterDataSequence () {
      return (this.hasMaster && this.isPipelinedWrite && get(this, "package.uvcSlaveResponseSequence.hasPolicyHandles"))
        ? get(this, "package.uvcMasterDataSequence")
        : null;
    },
    masterDataPriorityPolicy () {
      return (this.hasMaster && this.isPipelinedWrite)
        ? get(this, "package.uvcMasterDataPriorityPolicy")
        : null;
    },
    masterResponsePolicy () {
      return (this.hasMaster && this.isPipelined)
        ? get(this, "package.uvcMasterResponsePolicy")
        : null;
    },
    masterMaxNumTransactionsInProgressPolicy () {
      return (this.hasMaster && this.isPipelined)
        ? get(this, "package.uvcMasterMaxNumTransactionsInProgressPolicy")
        : null;
    },
    masterOutOfOrderBeatPrioritiesPolicy () {
      return (this.hasMaster && this.isPipelinedWrite)
        ? get(this, "package.uvcMasterOutOfOrderBeatPrioritiesPolicy")
        : null;
    },
    masterInOrderBeatPrioritiesPolicy () {
      return (this.hasMaster && this.isPipelinedWrite)
        ? get(this, "package.uvcMasterInOrderBeatPrioritiesPolicy")
        : null;
    },
    masterStarvedBeatPrioritiesPolicy () {
      return (this.hasMaster && this.isPipelinedWrite)
        ? get(this, "package.uvcMasterStarvedBeatPrioritiesPolicy")
        : null;
    },
    slaveRequestPolicy () {
      return this.hasSlave
        ? get(this, "package.uvcSlaveRequestPolicy")
        : null;
    },
    slaveRequestDefaultPreTransferDelayPolicy () {
      return (this.hasSlave && this.isPipelined && get(this, "sequenceItem.hasPreTransferDelayVariable"))
        ? get(this, "package.uvcSlaveRequestDefaultPreTransferDelayPolicy")
        : null;
    },
    slaveDataPolicy () {
      return (this.hasSlaveResponse && this.isPipelinedWrite)
        ? get(this, "package.uvcSlaveDataPolicy")
        : null;
    },
    slaveResponseDefaultPreTransferDelayPolicy () {
      return (this.hasSlaveResponse && get(this, "sequenceItem.hasPreTransferDelayVariable"))
        ? get(this, "package.uvcSlaveResponseDefaultPreTransferDelayPolicy")
        : null;
    },
    slaveResponsePolicy () {
      return this.hasSlaveResponse
        ? get(this, "package.uvcSlaveResponsePolicy")
        : null;
    },
    slaveResponsePriorityPolicy () {
      return (this.hasSlaveResponse && this.isPipelined)
        ? get(this, "package.uvcSlaveResponsePriorityPolicy")
        : null;
    },
    slaveMaxNumTransactionsInProgressPolicy () {
      return (this.hasSlave && this.isPipelined)
        ? get(this, "package.uvcSlaveMaxNumTransactionsInProgressPolicy")
        : null;
    },
    slaveOutOfOrderResponsePrioritiesPolicy () {
      return (this.hasSlave && this.isPipelined)
        ? get(this, "package.uvcSlaveOutOfOrderResponsePrioritiesPolicy")
        : null;
    },
    slaveInOrderResponsePrioritiesPolicy () {
      return (this.hasSlave && this.isPipelined)
        ? get(this, "package.uvcSlaveInOrderResponsePrioritiesPolicy")
        : null;
    },
    slaveStarvedResponsePrioritiesPolicy () {
      return (this.hasSlave && this.isPipelined)
        ? get(this, "package.uvcSlaveStarvedResponsePrioritiesPolicy")
        : null;
    },
    monitor () {
      return this.hasMonitor
        ? get(this, "package.uvcMonitor")
        : null;
    },
    sequencer () {
      return get(this, "package.uvcSequencer");
    },
    coverage () {
      return this.hasUvcCoverage
        ? get(this, "package.uvcCoverage")
        : null;
    },
    masterRequestDriver () {
      return this.hasMaster
        ? get(this, "package.uvcMasterRequestDriver")
        : null;
    },
    masterDataDriver () {
      return (this.hasMaster && this.isPipelinedWrite)
        ? get(this, "package.uvcMasterDataDriver")
        : null;
    },
    masterResponseDriver () {
      return this.hasPipelinedMaster
        ? get(this, "package.uvcMasterResponseDriver")
        : null;
    },
    hasPipelinedMaster () {
      return this.hasMaster && this.isPipelined;
    },
    slaveRequestDriver () {
      return this.hasSlave
        ? get(this, "package.uvcSlaveRequestDriver")
        : null;
    },
    slaveDataDriver () {
      return (this.hasSlave && this.isPipelinedWrite)
        ? get(this, "package.uvcSlaveDataDriver")
        : null;
    },
    slaveResponseDriver () {
      return this.hasPipelinedSlave
        ? get(this, "package.uvcSlaveResponseDriver")
        : null;
    },
    hasPipelinedSlave () {
      return this.hasSlave && this.isPipelined;
    },
    masterAgent () {
      return this.hasMaster
        ? get(this, "package.uvcMasterAgent")
        : null;
    },
    slaveAgent () {
      return this.hasSlave
        ? get(this, "package.uvcSlaveAgent")
        : null;
    },
    masterRequestSequenceBase () {
      return this.hasMaster
        ? get(this, "package.uvcMasterRequestSequenceBase")
        : null;
    },
    masterRequestSequence () {
      return (this.hasMaster && get(this, "package.uvcMasterRequestSequence.hasPolicyHandles"))
        ? get(this, "package.uvcMasterRequestSequence")
        : null;
    },
    masterFullDataPolicy () {
      return (this.hasMaster && this.isPipelinedWrite)
        ? get(this, "package.uvcMasterFullDataPolicy")
        : null;
    },
    masterRefItemPolicy () {
      return (this.hasMaster && this.isPipelinedWrite)
        ? get(this, "package.uvcMasterRefItemPolicy")
        : null;
    },
    masterResponseSequenceBase () {
      return (this.hasMaster && this.isPipelined)
        ? get(this, "package.uvcMasterResponseSequenceBase")
        : null;
    },
    slaveRequestSequenceBase () {
      return this.hasSlave
        ? get(this, "package.uvcSlaveRequestSequenceBase")
        : null;
    },
    slaveRequestSequence () {
      return (this.hasSlave && this.isPipelined && get(this, "package.uvcSlaveRequestSequence.hasPolicyHandles"))
        ? get(this, "package.uvcSlaveRequestSequence")
        : null;
    },
    slaveDataSequenceBase () {
      return (this.hasSlave && this.isPipelinedWrite)
        ? get(this, "package.uvcSlaveDataSequenceBase")
        : null;
    },
    slaveResponseSequenceBase () {
      return this.hasSlaveResponse
        ? get(this, "package.uvcSlaveResponseSequenceBase")
        : null;
    },
    slaveResponseSequence () {
      return (this.hasSlaveResponse && get(this, "package.uvcSlaveResponseSequence.hasPolicyHandles"))
        ? get(this, "package.uvcSlaveResponseSequence")
        : null;
    },
    slaveSequence () {
      return (this.hasSlaveResponse && !this.isPipelined)
        ? get(this, "package.uvcSlaveSequence")
        : null;
    },
    slaveSequenceTop () {
      return this.slaveSequence || this.slaveRequestSequenceBase;
    },
    virtualSequenceBase () {
      return this.isPipelined
        ? get(this, "package.uvcVirtualSequenceBase")
        : null;
    },
    masterVirtualSequence () {
      return (this.hasMaster && this.isPipelined)
        ? get(this, "package.uvcMasterVirtualSequence")
        : null;
    },
    masterRequestVirtualSequence () {
      return (this.hasMaster && this.isPipelinedWrite)
        ? get(this, "package.uvcMasterRequestVirtualSequence")
        : null;
    },
    slaveVirtualSequence () {
      return (this.hasSlave && this.isPipelined)
        ? get(this, "package.uvcSlaveVirtualSequence")
        : null;
    },
    slaveFullResponsePolicy () {
      return (this.hasSlaveResponse && this.isPipelinedRead)
        ? get(this, "package.uvcSlaveFullResponsePolicy")
        : null;
    },
    phaseFilter () {
      return this.hasPhases
        ? get(this, "package.uvcPhaseFilter")
        : null;
    },
    registerAdapter () {
      return this.hasRegisters
        ? get(this, "package.uvcRegisterAdapter")
        : null;
    },
    registerSequenceItem () {
      return this.hasRegisters
        ? get(this, "package.uvcRegisterSequenceItem")
        : null;
    },
    interface () {
      return this.uvcInterface;
    },
    bfmBase () {
      return get(this, "interface.uvcBfmBase");
    },
    masterRequestBfm () {
      return this.hasMaster
        ? get(this, "interface.uvcMasterRequestBfm")
        : null;
    },
    masterDataBfm () {
      return (this.hasMaster && this.isPipelinedWrite)
        ? get(this, "interface.uvcMasterDataBfm")
        : null;
    },
    masterResponseBfm () {
      return this.hasPipelinedMaster
        ? get(this, "interface.uvcMasterResponseBfm")
        : null;
    },
    slaveRequestBfm () {
      return this.hasSlave
        ? get(this, "interface.uvcSlaveRequestBfm")
        : null;
    },
    slaveDataBfm () {
      return (this.hasSlaveResponse && this.isPipelinedWrite)
        ? get(this, "interface.uvcSlaveDataBfm")
        : null;
    },
    slaveResponseBfm () {
      return this.hasPipelinedSlave
        ? get(this, "interface.uvcSlaveResponseBfm")
        : null;
    },
    monitorBfm () {
      return this.hasMonitor
        ? get(this, "interface.uvcMonitorBfm")
        : null;
    },
    bfmCreator () {
      return get(this, "interface.uvcBfmCreator");
    },
    protocolChecker () {
      return this.hasProtocolChecker
        ? this.uvcProtocolChecker
        : null;
    },
    protocolCheckerAccessor () {
      return this.hasProtocolChecker
        ? get(this, "protocolChecker.uvcProtocolCheckerAccessor")
        : null;
    },
    isPipelined () {
      return (this.isPipelinedRead || this.isPipelinedWrite);
    },
    isPipelinedRead () {
      return (this.pipelined === "read");
    },
    isPipelinedWrite () {
      return (this.pipelined === "write");
    },
    hasMaster () {
      if (this.hasMasterOverride !== null) {
        return this.hasMasterOverride;
      } else {
        return this.hasMasterDefault;
      }
    },
    hasMasterDefault () {
      return (
        get(this, "interface.hasMasterPorts") ||
        (
          get(this, "interface.hasClock") &&
          !get(this, "interface.hasSlavePorts")
        )
      );
    },
    hasSlave () {
      if (this.hasSlaveOverride !== null) {
        return this.hasSlaveOverride;
      } else {
        return this.hasSlaveDefault;
      }
    },
    hasSlaveDefault () {
      return (
        get(this, "interface.hasSlavePorts") ||
        (
          get(this, "interface.hasClock") &&
          get(this, "interface.hasMasterPorts")
        ) ||
        (
          get(this, "interface.hasClock") &&
          get(this, "interface.hasReset")
        )
      );
    },
    hasSlaveResponse () {
      return this.hasSlave && get(this, "interface.hasSlavePorts");
    },
    hasMonitor () {
      if (this.hasMonitorOverride !== null) {
        return this.hasMonitorOverride;
      } else {
        return this.hasMasterDefault && this.hasSlaveDefault;
      }
    },
    hasUvcCoverage () {
      if (this.hasUvcCoverageOverride !== null) {
        return this.hasUvcCoverageOverride;
      } else {
        // return get(this, "interface.hasMasterPorts");
        return get(this, "sequenceItem.allInstanceVariables", []).some(iv => iv.coverpoint);
      }
    },
    hasPhases () {
      return get(this, "interface.hasMasterPorts") && get(this, "interface.hasSlavePorts");
    },
    hasRegisters () {
      if (this.hasRegisterOverride !== null) {
        return this.hasRegisterOverride;
      } else {
        return false;
      }
    },
    hasProtocolChecker () {
      if (this.hasProtocolCheckerOverride !== null) {
        return this.hasProtocolCheckerOverride;
      } else {
        return false;
      }
    },
    slaveDriverWord () {
      if (this.interface) {
        if (this.interface.hasSlavePorts) {
          return store.getters.user.setting.responderWord || "";
        } else {
          return store.getters.user.setting.receiverWord || "";
        }
      } else {
        return "";
      }
    },
    generatedLicenseAssets () {
      return this.destroyedLicenseAssets;
    },
    destroyedLicenseAssets () {
      return [
        {
          id: this.id,
          type: this.isPipelined ? "uvcPipelined" : "uvc"
        },
        this.hasStandaloneTb && {
          id: this.id,
          type: "uvcDevTb"
        }
      ]
        .filter(a => a);
    }
  },
  methods: {
    async load (loadTb = true) {
      await this.promiseOnSnapshot;

      await Promise.all([
        // [store.getters.user.setting, store.getters.user.promiseSetting],
        // Trying out the use of the uvc owners settings, vs. the user logged in because user could be unauthenticated, thus no settings.
        [this.user.setting, this.user.promiseSetting],
        [this.interface, this.promiseUvcInterface],
        [this.package, this.promiseUvcPackage],
        [this.protocolChecker, this.promiseUvcProtocolChecker]
      ]
        // .map((pair, index) => {
        //   if (!pair[1]) {
        //     console.log("A: theres a null at", index);
        //   }
        //   return pair[1];
        // }));
        .map(pair => pair[1]));

      await Promise.all([
        [this.interface.parameters, this.interface.promiseParameters],
        [this.interface.ports, this.interface.promisePorts],
        [this.interface.includes, this.interface.promiseIncludes],
        [this.package.parameters, this.package.promiseParameters],
        [this.bfmBase, this.interface.promiseUvcBfmBase],
        [this.bfmCreator, this.interface.promiseUvcBfmCreator],
        [this.abstractBfm, this.package.promiseUvcAbstractBfm],
        [this.abstractBfmCreator, this.package.promiseUvcAbstractBfmCreator],
        [this.abstractProtocolCheckerAccessor, this.package.promiseUvcAbstractProtocolCheckerAccessor],
        [this.config, this.package.promiseUvcConfig],
        [this.sequenceItem, this.package.promiseUvcSequenceItem],
        [this.sequencer, this.package.promiseUvcSequencer],
        [this.virtualSequenceBase, this.package.promiseUvcVirtualSequenceBase],
        [this.registerAdapter, this.package.promiseUvcRegisterAdapter],
        [this.registerSequenceItem, this.package.promiseUvcRegisterSequenceItem]
      ]
        // .map((pair, index) => {
        //   if (!pair[1]) {
        //     console.log("B: theres a null at", index);
        //   }
        //   return pair[1];
        // }));
        .map(pair => pair[1]));

      await Promise.all([
        [this.masterDataSequenceBase, this.package.promiseUvcMasterDataSequenceBase], // prereq: interface.ports
        [this.masterDataSequence, this.package.promiseUvcMasterDataSequence], // prereq: interface.ports
        [this.uvcParameters, this.package.promiseUvcParameters], // prereq: interface.parameters
        [this.config.instanceVariables, this.config.promiseInstanceVariables],
        [this.sequenceItem.instanceVariables, this.sequenceItem.promiseInstanceVariables],
        [this.protocolCheckerAccessor, get(this.protocolChecker, "promiseUvcProtocolCheckerAccessor", null)],
        [this.masterRequestPolicy, this.package.promiseUvcMasterRequestPolicy], // prereqs: interface.ports
        [this.masterRequestDefaultPreTransferDelayPolicy, this.package.promiseUvcMasterRequestDefaultPreTransferDelayPolicy], // prereqs: sequenceItem
        [this.masterDataPolicy, this.package.promiseUvcMasterDataPolicy], // prereqs: interface.ports
        [this.masterDataDefaultPreTransferDelayPolicy, this.package.promiseUvcMasterDataDefaultPreTransferDelayPolicy], // prereqs: interface.ports and sequenceItem
        [this.masterDataPriorityPolicy, this.package.promiseUvcMasterDataPriorityPolicy], // prereqs: interface.ports
        [this.masterResponsePolicy, this.package.promiseUvcMasterResponsePolicy], // prereqs: interface.ports
        [this.masterMaxNumTransactionsInProgressPolicy, this.package.promiseUvcMasterMaxNumTransactionsInProgressPolicy], // prereqs: interface.ports
        [this.masterOutOfOrderBeatPrioritiesPolicy, this.package.promiseUvcMasterOutOfOrderBeatPrioritiesPolicy], // prereqs: interface.ports
        [this.masterInOrderBeatPrioritiesPolicy, this.package.promiseUvcMasterInOrderBeatPrioritiesPolicy], // prereqs: interface.ports
        [this.masterStarvedBeatPrioritiesPolicy, this.package.promiseUvcMasterStarvedBeatPrioritiesPolicy], // prereqs: interface.ports
        [this.slaveRequestPolicy, this.package.promiseUvcSlaveRequestPolicy], // prereqs: interface.ports
        [this.slaveRequestDefaultPreTransferDelayPolicy, this.package.promiseUvcSlaveRequestDefaultPreTransferDelayPolicy], // prereqs: interface.ports, sequenceItem
        [this.slaveDataPolicy, this.package.promiseUvcSlaveDataPolicy], // prereqs: interface.ports
        [this.slaveResponseDefaultPreTransferDelayPolicy, this.package.promiseUvcSlaveResponseDefaultPreTransferDelayPolicy], // prereqs: interface.ports, sequenceItem
        [this.slaveResponsePolicy, this.package.promiseUvcSlaveResponsePolicy], // prereqs: interface.ports
        [this.slaveResponsePriorityPolicy, this.package.promiseUvcSlaveResponsePriorityPolicy], // prereqs: interface.ports
        [this.slaveMaxNumTransactionsInProgressPolicy, this.package.promiseUvcSlaveMaxNumTransactionsInProgressPolicy], // prereqs: interface.ports
        [this.slaveOutOfOrderResponsePrioritiesPolicy, this.package.promiseUvcSlaveOutOfOrderResponsePrioritiesPolicy], // prereqs: interface.ports
        [this.slaveInOrderResponsePrioritiesPolicy, this.package.promiseUvcSlaveInOrderResponsePrioritiesPolicy], // prereqs: interface.ports
        [this.slaveStarvedResponsePrioritiesPolicy, this.package.promiseUvcSlaveStarvedResponsePrioritiesPolicy], // prereqs: interface.ports
        [this.monitor, this.package.promiseUvcMonitor], // prereqs: interface.ports 27
        [this.masterRequestDriver, this.package.promiseUvcMasterRequestDriver], // prereqs: interface.ports
        [this.masterDataDriver, this.package.promiseUvcMasterDataDriver], // prereqs: interface.ports
        [this.masterResponseDriver, this.package.promiseUvcMasterResponseDriver], // prereqs: interface.ports
        [this.slaveRequestDriver, this.package.promiseUvcSlaveRequestDriver], // prereqs: interface.ports
        [this.slaveDataDriver, this.package.promiseUvcSlaveDataDriver], // prereqs: interface.ports
        [this.slaveResponseDriver, this.package.promiseUvcSlaveResponseDriver], // prereqs: interface.ports 33
        [this.masterAgent, this.package.promiseUvcMasterAgent], // prereqs: interface.ports
        [this.slaveAgent, this.package.promiseUvcSlaveAgent], // prereqs: interface.ports
        [this.masterRequestSequenceBase, this.package.promiseUvcMasterRequestSequenceBase], // prereqs: interface.ports
        [this.masterFullDataPolicy, this.package.promiseUvcMasterFullDataPolicy], // prereqs: interface.ports 38
        [this.masterRefItemPolicy, this.package.promiseUvcMasterRefItemPolicy], // prereqs: interface.ports
        [this.masterResponseSequenceBase, this.package.promiseUvcMasterResponseSequenceBase], // prereqs: interface.ports
        [this.slaveRequestSequenceBase, this.package.promiseUvcSlaveRequestSequenceBase], // prereqs: interface.ports
        [this.slaveRequestSequence, this.package.promiseUvcSlaveRequestSequence], // prereqs: interface.ports
        [this.slaveDataSequenceBase, this.package.promiseUvcSlaveDataSequenceBase], // prereqs: interface.ports
        [this.slaveResponseSequenceBase, this.package.promiseUvcSlaveResponseSequenceBase], // prereqs: interface.ports 44
        [this.slaveResponseSequence, this.package.promiseUvcSlaveResponseSequence], // prereqs: interface.ports
        [this.slaveSequence, this.package.promiseUvcSlaveSequence], // prereqs: interface.ports
        [this.masterVirtualSequence, this.package.promiseUvcMasterVirtualSequence], // prereqs: interface.ports 47
        [this.masterRequestVirtualSequence, this.package.promiseUvcMasterRequestVirtualSequence], // prereqs: interface.ports
        [this.slaveVirtualSequence, this.package.promiseUvcSlaveVirtualSequence], // prereqs: interface.ports
        [this.slaveFullResponsePolicy, this.package.promiseUvcSlaveFullResponsePolicy], // prereqs: interface.ports
        [this.phaseFilter, this.package.promiseUvcPhaseFilter], // prereqs: interface.ports 51
        [this.masterResponseBfm, this.interface.promiseUvcMasterResponseBfm], // prereq: interface.ports
        [this.slaveResponseBfm, this.interface.promiseUvcSlaveResponseBfm], // prereq: interface.ports
        [this.masterRequestBfm, this.package.promiseUvcMasterRequestBfm], // prereqs: interface.ports
        [this.masterDataBfm, this.package.promiseUvcMasterDataBfm], // prereqs: interface.ports
        [this.slaveRequestBfm, this.package.promiseUvcSlaveRequestBfm], // prereqs: interface.ports
        [this.slaveDataBfm, this.package.promiseUvcSlaveDataBfm], // prereqs: interface.ports
        [this.monitorBfm, this.package.promiseUvcMonitorBfm] // prereqs: interface.ports 56
      ]
        // .map((pair, index) => {
        //   if (!pair[1]) {
        //     console.log("C: theres a null at", index);
        //   }
        //   return pair[1];
        // }));
        .map(pair => pair[1]));

      await Promise.all([
        [this.masterRequestSequence, this.package.promiseUvcMasterRequestSequence], // prereqs: interface.ports, masterRequestDefaultPreTransferDelayPolicy
        [this.coverage, this.package.promiseUvcCoverage] // prereqs: sequenceItem.instanceVariables
      ]
        // .map((pair, index) => {
        //   if (!pair[1]) {
        //     console.log("D: theres a null at", index);
        //   }
        //   return pair[1];
        // }));
        .map(pair => pair[1]));

      await Promise.all([
        ...this.generatableFileModels.map(model => {
          return [model.instanceVariables, model.promiseInstanceVariables];
        }),
        ...this.generatableFileModels.map(model => {
          return [model.constraints, model.promiseConstraints];
        })
      ]
        // .map((pair, index) => {
        //   if (!pair[1]) {
        //     console.log("D: theres a null at", index);
        //   }
        //   return pair[1];
        // }));
        .map(pair => pair[1]));

      if (loadTb) {
        if (this.hasStandaloneTb) {
          await Promise.all([
            [this.uvcStandaloneEnv, this.promiseUvcStandaloneEnv]
          ]
            // .map((pair, index) => {
            //   if (!pair[1]) {
            //     console.log("D: theres a null at", index);
            //   }
            //   return pair[1];
            // }));
            .map(pair => pair[1]));

          await Promise.all([
            [this.uvcStandaloneEnv.tb, this.uvcStandaloneEnv.promiseEnvTb]
          ]
            // .map((pair, index) => {
            //   if (!pair[1]) {
            //     console.log("D: theres a null at", index);
            //   }
            //   return pair[1];
            // }));
            .map(pair => pair[1]));

          await this.uvcStandaloneEnv.tb.load();
          this.standaloneEnv = this.uvcStandaloneEnv;
        }
      }

      await Promise.all([
        [this.user.formClasses, this.user.promiseFormClasses]
      ]
        // .map((pair, index) => {
        //   if (!pair[1]) {
        //     console.log("D: theres a null at", index);
        //   }
        //   return pair[1];
        // }));
        .map(pair => pair[1]));

      // console.log(this.name, "is loaded");
      this.isLoaded = true;
    },
    async beforeUnmount () {
      await this.promiseOnSnapshot;

      await Promise.all([
        [this.package, this.promiseUvcPackage],
        [this.user.formClasses, this.promiseFormClasses]
      ]
        // .map((pair, index) => {
        //   if (!pair[1]) {
        //     console.log("A: theres a null at", index);
        //   }
        //   return pair[1];
        // }));
        .map(pair => pair[1]));

      await Promise.all(this.package.formClasses.map(formClass => formClass.destroy()));
    }
  }
};

export default defineReactiveModel(Uvc);
