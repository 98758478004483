import Parser from "@/pegjs/system-verilog.pegjs";

// Required properties of object mixing in:
//   type, name
export default {
  props: {
  },
  computed: {
    typeInfo () {
      let info = null;
      try {
        info = Parser.parse(this.type, { startRule: "TypedefDataType" });
      } catch (e) {
        // continue regardless of error
      }
      return info;
    },
    nameInfo () {
      let info = null;
      try {
        info = Parser.parse(this.name, { startRule: "TypeIdentifierWithVariableDimensions" });
      } catch (e) {
        // continue regardless of error
      }
      return info;
    }
  }
};
