import {
  spacePadding as defaultSpacePadding,
  maxColumnWidth as defaultMaxColumnWidth
} from "@/utils/app-config";

export default function ({ propertyLength, maxColumnWidth, collectionMaxLength, spacePadding }) {
  const localMaxColumnWidth = maxColumnWidth || defaultMaxColumnWidth;
  if (propertyLength >= localMaxColumnWidth) {
    return " ";
  } else {
    let width = (collectionMaxLength || propertyLength) + (spacePadding || defaultSpacePadding);
    if (width > localMaxColumnWidth) {
      width = localMaxColumnWidth;
    }
    return " ".repeat(width - propertyLength);
  }
}
