export default function (...args) {
  const array = args[0];
  const delimeter = args[1];

  if (array === undefined) {
    return "";
  }

  return array.join(delimeter);
}
