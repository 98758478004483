import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import GeneratableFile from "@/models/mixins/generatable-file";
import UvmObject from "@/models/uvm/uvm-object";
import Parameter from "@/models/parameter";
import Variable from "@/models/variable";

const PolicyBase = defineReactiveModel({
  extends: Class,
  mixins: [
    GeneratableFile
  ],
  props: {
    parent: {
      type: Object,
      default: null
    },
    parameterClass: {
      type: Object,
      default: null
    }
  },
  created () {
    this.rootType = "policy_base_c";
    this.uvmUtilsMacro = false;
    this.partialInheritance = [
      { for: ["me"], partial: "policy-base/get-type-name" },
      { for: ["me"], partial: "policy-base/set-object" }
    ];
  },
  computed: {
    key () {
      return `${this.rootType}-fjw5wjfo78f5`;
    },
    projectSubDirectory () {
      return "";
    },
    isPolicy () {
      return true;
    },
    rootProject () {
      return this.parent.rootProject;
    },
    project () {
      return this.parent.project;
    },
    parameters () {
      return [
        new Parameter({
          propsData: {
            parent: this,
            type: "parameter type",
            name: "TYPE",
            defaultValueOverride: UvmObject.singleton().type
          }
        })
      ];
    },
    fileName () {
      return `${this.rootType}.svh`;
    },
    superclass () {
      return UvmObject.singleton();
    },
    type () {
      if (this.parameterClass) {
        return `${this.rootType}#(${this.parameterClass.type})`;
      } else {
        return this.rootType;
      }
    },
    computedInstanceVariables () {
      return [
        this.objectVariable
      ];
    },
    objectVariable () {
      return new Variable({
        propsData: {
          parent: this,
          type: "protected TYPE",
          name: "object"
        }
      });
    }
  }
});

const singletons = {};

PolicyBase.singleton = function (parameterClass) {
  if (!parameterClass) { return null; }

  if (singletons[parameterClass.uniqueId] === undefined) {
    singletons[parameterClass.uniqueId] = new PolicyBase({
      propsData: {
        parameterClass
      }
    });
  }

  return singletons[parameterClass.uniqueId];
};

export default PolicyBase;
