import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Handle from "@/models/handle";
import Variable from "@/models/variable";
import get from "lodash.get";

const UvcSlaveVirtualSequence = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("virtual-sequence")
  ],
  created () {
    this.master = false;
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-slave-virtual-sequence/body" },
      { for: ["me"], partial: "uvc-slave-virtual-sequence/receive-requests" },
      { for: ["me"], partial: "uvc-slave-virtual-sequence/receive-data" },
      { for: ["me"], partial: "uvc-slave-virtual-sequence/write-request-item" },
      { for: ["me"], partial: "uvc-slave-virtual-sequence/write-data-item" },
      { for: ["me"], partial: "uvc-slave-virtual-sequence/send-response" }
    ];
  },
  computed: {
    superclass () {
      return this.virtualSequenceBase;
    },
    type () {
      return `${this.uvc.name}_${this.protocolRoleWord}_virtual_sequence_c`;
    },
    computedInstanceVariables () {
      return [
        this.requestItemsHandle,
        this.dataItemsHandle
      ]
        .filter(o => o);
    },
    requestSequenceHandle () {
      const requestSequencerHandle = get(this, "virtualSequenceBase.requestSequencerHandle");
      return this.slaveRequestSequenceBase && requestSequencerHandle && new Handle({
        propsData: {
          id: "static-id-501e43a",
          parent: this,
          klass: this.slaveRequestSequenceBase,
          nameOverride: "req_seq",
          sequencerHandleOverride: requestSequencerHandle
        }
      });
    },
    dataSequenceHandle () {
      const dataSequencerHandle = get(this, "virtualSequenceBase.dataSequencerHandle");
      return this.slaveDataSequenceBase && dataSequencerHandle && new Handle({
        propsData: {
          id: "static-id-1098237",
          parent: this,
          klass: this.slaveDataSequenceBase,
          nameOverride: "data_seq",
          sequencerHandleOverride: dataSequencerHandle
        }
      });
    },
    responseSequenceHandle () {
      const responseSequencerHandle = get(this, "virtualSequenceBase.responseSequencerHandle");
      return this.requestSequenceHandle && this.slaveResponseSequenceBase && responseSequencerHandle && new Handle({
        propsData: {
          id: "static-id-5647f57",
          parent: this,
          klass: this.slaveResponseSequenceBase,
          nameOverride: "rsp_seq",
          createRandomFunctionArgumentHandles: [
            this.requestItemHandle,
            this.dataItemHandle
          ]
            .filter(h => h),
          sequencerHandleOverride: responseSequencerHandle
        }
      });
    },
    createRandomResponseSequenceArgs () {
      const args = {};
      if (this.requestItemHandle && this.requestSequenceHandle) {
        args[this.requestItemHandle.identifier] = `${this.requestSequenceHandle.identifier}.rsp`;
      }
      return args;
    },
    requestItemHandle () {
      return this.sequenceItem && new Handle({
        propsData: {
          parent: this,
          klass: this.sequenceItem,
          nameOverride: "request_item"
        }
      });
    },
    dataItemHandle () {
      return this.sequenceItem && this.isPipelinedWrite && new Handle({
        propsData: {
          parent: this,
          klass: this.sequenceItem,
          nameOverride: "data_item"
        }
      });
    },
    requestItemsHandle () {
      return this.sequenceItem && this.isPipelinedWrite && new Handle({
        propsData: {
          // id: "static-id-822743a",
          parent: this,
          isProt: true,
          klass: this.sequenceItem,
          nameOverride: `request_items[logic [${this.interface.idMsbMaxParameter.name}:0]]`
        }
      });
    },
    dataItemsHandle () {
      return this.sequenceItem && this.isPipelinedWrite && new Handle({
        propsData: {
          // id: "static-id-3948757",
          parent: this,
          isProt: true,
          klass: this.sequenceItem,
          nameOverride: `data_items[logic [${this.interface.idMsbMaxParameter.name}:0]]`
        }
      });
    },
    killedByResetVariable () {
      return new Variable({
        propsData: {
          parent: this,
          type: "bit",
          name: "killed_by_reset"
        }
      });
    },
    computedPolicyHandles () {
      return [
        this.responseSequenceHandle && this.slaveResponsePriorityPolicy && new Handle({
          propsData: {
            parent: this,
            klass: this.slaveResponsePriorityPolicy,
            relations: {
              randomObjectDoHandle: this.responseSequenceHandle
            }
          }
        })
      ]
        .filter(h => h);
    },
    randomObjectCreateHandles () {
      return [
        this.requestSequenceHandle,
        this.dataSequenceHandle,
        this.responseSequenceHandle
      ]
        .filter(o => o);
    },
    randomObjectDoHandles () {
      return this.randomObjectCreateHandles;
    }
  }
};

export default defineReactiveModel(UvcSlaveVirtualSequence);
