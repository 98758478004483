{{#with context}}
{{ configHandle.identifier }}.{{ ../waitForGrantMethodName }}();

if ({{ killedByResetVariable.identifier }}) begin
  {{ configHandle.identifier }}.{{ ../retireGrantMethodName }}();
  break;
end

fork begin
  {{#with ../requestOrDataSequenceHandle}}{{ type }} {{ name }};{{/with}}
{{#unless (and isSlave isPipelinedWrite)}}
  {{#with responseSequenceHandle}}{{ type }} {{ name }};{{/with}}
{{/unless}}

  {{#with ../requestOrDataSequenceHandle}}
  {{> (partial "snippets/start-sequence") context=.}}
  {{/with}}

{{#unless (and isSlave isPipelinedWrite)}}
  {{#with responseSequenceHandle}}
  {{> (partial "snippets/start-sequence") context=. createArgs=../createRandomResponseSequenceArgs}}
  {{/with}}

{{/unless}}
  if ({{#if (and isSlave isPipelinedWrite)}}{{ ../requestOrDataSequenceHandle.identifier }}{{else}}{{ responseSequenceHandle.identifier }}{{/if}}.rsp.{{ sequenceItem.killedByResetVariable.identifier }}) begin
    {{ killedByResetVariable.identifier }} = 1;
{{#if (and isSlave isPipelinedWrite)}}
    {{ configHandle.identifier }}.{{ ../retireGrantMethodName }}();
  end else begin
    {{ ../writeItemMethodName }}({{ ../requestOrDataSequenceHandle.identifier }}.rsp);
{{/if}}
  end
{{#unless (and isSlave isPipelinedWrite)}}

  {{ configHandle.identifier }}.{{ ../retireGrantMethodName }}();
{{/unless}}
end join_none
{{/with}}
