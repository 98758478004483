`ifndef {{ compileGuard }}
`define {{ compileGuard }}

{{#if isInterface}}interface{{else}}module{{/if}} {{ type }}
  {{> partials/snippets/parameter-and-port-declarations}}

  timeunit {{or timeUnit user.setting.defaultTimeUnit}} / {{or timePrecision user.setting.defaultTimePrecision}};

{{#each internalImports tn=true}}
  import {{ type }}::*;
{{/each}}
  {{> partials/encapsulator/instance-variables}}
  {{> partials/encapsulator/setters}}
  {{> partials/encapsulator/getters}}
  {{> partials/encapsulator/partials}}
  {{> partials/encapsulator/subroutines}}
{{#if isInterface}}endinterface{{else}}endmodule{{/if}}

`endif
