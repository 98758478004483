{{#if isPipelinedWrite}}
virtual task monitor_data();
{{#if (not isPipelined)}}
{{#with sequenceItemHandle}}
  {{ type }} {{ identifier }} = {{ type }}::type_id::create("{{ fullName }}", this);
  {{ identifier }}.{{ klass.configHandle.setterName }}({{ ../configHandle.identifier }});
{{/with}}

{{/if}}
  {{> (partial "snippets/call-bfm-with-reset") context=. task=monitorBfm.monitorDataTask}}
endtask
{{/if}}
