virtual task run_phase(uvm_phase phase);
  super.run_phase(phase);

{{#if isPipelinedWrite}}
  fork
    forever begin
      {{ abstractBfmHandle.identifier }}.wait_for_reset_to_deassert();

      {{ bfm.monitorTask.identifier }}();
    end
    forever begin
      {{ abstractBfmHandle.identifier }}.wait_for_reset_to_deassert();

      {{ bfm.monitorDataTask.identifier }}();
    end
  join_none
{{else}}
  forever begin
  {{#if interface.hasResetAndOtherPorts}}
    {{ abstractBfmHandle.identifier }}.wait_for_reset_to_deassert();

  {{/if}}
    {{ bfm.monitorTask.identifier }}();
  end
{{/if}}
endtask
