import { defineReactiveModel, PrivateProfileMixin } from "vue-app-utils";
import Model from "@/models/model";
import { StripePrivateProfileMixin } from "vue-app-utils-web";

const PrivateProfile = {
  extends: Model,
  mixins: [
    PrivateProfileMixin,
    StripePrivateProfileMixin
  ],
  data () {
    return {
      companyName: "",
      companySize: null,
      companyDvSize: "",
      teamDvSize: null,
      benefits: [],
      paymentEmail: "",
      waitlistedTime: 0,
      waitlistApproved: false
    };
  }
};

export default defineReactiveModel(PrivateProfile);
