import { defineReactiveModel, Persistable, EmailMixin } from "vue-app-utils";
import Model from "@/models/model";

const Email = {
  extends: Model,
  mixins: [
    Persistable,
    EmailMixin
  ]
};

export default defineReactiveModel(Email);
