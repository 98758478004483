function insertComment (str, insertAt) {
  if (str.length === 0) {
    return " ".repeat(insertAt) + "//";
  } else {
    return str.slice(0, insertAt) + "// " + str.slice(insertAt);
  }
}

export default function (inline, options) {
  const block = options.fn(this).slice(0, -1);
  if (block.length === 0) {
    return "";
  } else {
    if (inline) {
      const lines = block.split("\n");
      const commentPosition = lines[0].search(/[^ ]/);
      return lines.map(line => insertComment(line, commentPosition)).join("\n") + "\n";
    } else {
      const commentPosition = block.search(/[^ ]/);
      return block.slice(0, commentPosition) + "/* " + block.slice(commentPosition) + " */\n";
    }
  }
}
