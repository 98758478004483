import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";
import { StripeCartResultMixin } from "vue-app-utils-web";

const CartResult = {
  extends: Model,
  mixins: [
    Persistable,
    StripeCartResultMixin
  ],
  data () {
    return {
      licenseDurationInMonths: 0,
      proratedDurationInMonths: 0,
      monthlyPriceWithGlobalAndCustomDiscount: null,
      monthlyPriceWithRenewalDiscount: null,
      priceFullTerm: null,
      priceWithGlobalDiscountFullTerm: null,
      priceWithGlobalAndCustomDiscountFullTerm: null,
      priceWithRenewalDiscountFullTerm: null,
      globalDiscountFullTerm: null,
      customDiscountFullTerm: null,
      renewalDiscountFullTerm: null,
      taxFullTerm: null,
      priceWithTaxFullTerm: null,
      priceProrated: null,
      priceWithGlobalDiscountProrated: null,
      priceWithGlobalAndCustomDiscountProrated: null,
      globalDiscountProrated: null,
      customDiscountProrated: null,
      taxProrated: null,
      priceWithTaxProrated: null,
      autoRenewTimestamp: null,
      licenseTermStartTimestamp: null,
      licenseTermEndTimestamp: null
    };
  },
  computed: {
    durationInMonths () {
      return this.cart.isUpgradeOrder ? this.proratedDurationInMonths : this.licenseDurationInMonths;
    },
    price () {
      return this.cart.isUpgradeOrder ? this.priceProrated : this.priceFullTerm;
    },
    priceWithGlobalDiscount () {
      return this.cart.isUpgradeOrder ? this.priceWithGlobalDiscountProrated : this.priceWithGlobalDiscountFullTerm;
    },
    priceWithGlobalAndCustomDiscount () {
      return this.cart.isUpgradeOrder ? this.priceWithGlobalAndCustomDiscountProrated : this.priceWithGlobalAndCustomDiscountFullTerm;
    },
    priceWithRenewalDiscount () {
      return this.cart.isRenewalOrder && this.priceWithRenewalDiscountFullTerm;
    },
    globalDiscount () {
      return this.cart.isUpgradeOrder ? this.globalDiscountProrated : this.globalDiscountFullTerm;
    },
    customDiscount () {
      return this.cart.isUpgradeOrder ? this.customDiscountProrated : this.customDiscountFullTerm;
    },
    renewalDiscount () {
      return this.cart.isRenewalOrder && this.renewalDiscountFullTerm;
    },
    tax () {
      return this.cart.isUpgradeOrder ? this.taxProrated : this.taxFullTerm;
    },
    priceWithTax () {
      return this.cart.isUpgradeOrder ? this.priceWithTaxProrated : this.priceWithTaxFullTerm;
    },
    licenseTermStartDate () {
      return this.licenseTermStartTimestamp && this.licenseTermStartTimestamp.toDate();
    },
    licenseTermEndDate () {
      return this.licenseTermEndTimestamp && this.licenseTermEndTimestamp.toDate();
    }
  }
};

export default defineReactiveModel(CartResult);
