virtual function void build_phase(uvm_phase phase);
{{#if creatorHandlesForBfmBuildFactoryTypeOverrides.length}}
  uvm_resource_types::rsrc_q_t rq;

{{/if}}
{{#each creatorHandlesForBfmBuildFactoryTypeOverrides tn=true delimeter="\n"}}
{{#if lsadkjfkfljds}}
[TODO] this solution is a hack and relies on the internal variables of uvc_resource_db. It is also heavy handed in that it sets overrides in creators that it does not need
to. For example, if its a master bfm override it only needs to set the override for the master interfaces creator. In the below imp, the override is also set in the
slave creator. This doesn't hurt things, but may not be intuitive. Better would be if I actually figured out paths such as "uvm_test_top.env.apb_master_agent"
and used the uvm_config_db#(creator)::get(this, "env.apb_master_agent", "bfm_creator", bfm_creator). Unfortunately I can't use wildcards with uvm_config_db::get, that
is only for set. The path has to be exact. And this difficulty is why I came up with the heavy handed hack below.

Better imp:

    abstract_apb2_bfm_creator_c apb2_bfm_creator;

    if (!uvm_config_db#(abstract_apb2_bfm_creator_c)::get(this, "env.apb_master_agent", "bfm_creator", apb2_bfm_creator)) begin
      `uvm_fatal(get_type_name(), {"abstract_apb2_bfm_creator_c must be set using the name \"bfm_creator\"."})
    end

    set_type_override_by_type(apb2_bfm_creator.get_type_apb2_master_bfm_c(), apb2_bfm_creator.get_type_apb2_master_bfm2a_c(), .replace(0));

{{/if}}
  rq = uvm_resource_pool::get().ttab[uvm_resource#({{ type }})::my_type];
  for(int i = 0; i < rq.size(); i++) begin
    uvm_resource#({{ type }}) r;
    if ($cast(r, rq.get(i))) begin
      {{ type }} {{ name }} = r.read(this);
      set_type_override_by_type({{ name }}.{{ relations.pair.base.getTypeFunction }}(), {{ name }}.{{ relations.pair.override.getTypeFunction }}(), .replace(0));
    end
  end
{{/each}}
{{#each nonBfmBuildFactoryTypeOverrides tn=true}}
  {{ base.type }}::type_id::set_type_override({{ override.type }}::get_type(), .replace(0));
{{/each}}
  super.build_phase(phase);
{{#each configuration ln=true}}
  m_apb_relay_env_config.m_apb_slv_config.m_protocol_checker_enabled = 0;
{{/each}}
endfunction
