virtual task wait_for_num_clocks(int num_clocks=1);
  repeat (num_clocks) begin
  {{#if hasMonitor}}
    @{{ interface.monitorClockingBlock.name }};
  {{else if hasMaster}}
    @{{ interface.masterClockingBlock.name }};
  {{else}}
    @{{ interface.slaveClockingBlock.name }};
  {{/if}}
  end
endtask
