import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Policy from "@/models/mixins/policy";
import Handle from "@/models/handle";
import Constraint from "@/models/constraint";
import get from "lodash.get";

const UvcMasterResponsePolicy = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    UvcProtocolRoleGetters,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("policy"),
    Policy
  ],
  created () {
    this.master = true;
  },
  computed: {
    subject () {
      return this.sequenceItem;
    },
    type () {
      return `${this.uvc.rootName}_${this.protocolRoleWord}_response_policy_c`;
    },
    computedInstanceVariables () {
      return [
        this.requestItemHandle,
        this.dataItemHandle
      ]
        .filter(o => o);
    },
    requestItemHandle () {
      if (this.sequenceItem) {
        return new Handle({
          propsData: {
            parent: this,
            isProt: true,
            klass: this.sequenceItem,
            nameOverride: "request_item",
            setter: true
          }
        });
      } else {
        return null;
      }
    },
    dataItemHandle () {
      if (this.sequenceItem && this.isPipelinedWrite) {
        return new Handle({
          propsData: {
            parent: this,
            isProt: true,
            klass: this.sequenceItem,
            nameOverride: "data_item",
            setter: true
          }
        });
      } else {
        return null;
      }
    },
    computedConstraints () {
      const constraints = [
        this.phaseConstraint,
        this.preTransferDelayConstraint,
        this.lastConstraint
      ]
        .filter(c => c);

      this.masterConstraints.concat(this.slaveConstraints).forEach(constraint => {
        if (!constraints.find(c => c.instanceVariable.id === constraint.instanceVariable.id)) {
          constraints.push(constraint);
        }
      });
      return constraints;
    },
    preTransferDelayConstraint () {
      return (this.sequenceItem && this.sequenceItem.preTransferDelayVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.preTransferDelayVariable,
            content: `object.${this.sequenceItem.preTransferDelayVariable.identifier} == 0;`
          }
        })
        : null;
    },
    phaseConstraint () {
      return (this.sequenceItem && this.sequenceItem.phaseEnumVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.phaseEnumVariable,
            content: `object.${this.sequenceItem.phaseEnumVariable.identifier} == ${this.sequenceItem.phaseEnumVariable.klass.response.name};`
          }
        })
        : null;
    },
    lastConstraint () {
      return (this.sequenceItem && this.sequenceItem.lastVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.lastVariable,
            content: `object.${this.sequenceItem.lastVariable.identifier} == 0;`
          }
        })
        : null;
    },
    masterConstraints () {
      if (this.requestItemHandle || this.dataItemHandle) {
        const masterIvs = get(this.sequenceItem, "almostAllInstanceVariables", []).filter(iv => iv.relatedPort && iv.isMaster);

        return masterIvs.map(iv => {
          let content;
          if (get(iv, "nameInfo.unpacked.dimensions[0].type") === "dynamicArray") {
            if (this.dataItemHandle) {
              content = `object.${iv.identifier}.size == ${this.dataItemHandle.identifier}.data.size;
foreach (object.${iv.identifier}[${iv.identifier[0]}]) {
  object.${iv.identifier}[${iv.identifier[0]}] == ${this.dataItemHandle.identifier}.${iv.identifier}[${iv.identifier[0]}];
}`;
            } else {
              return null;
            }
          } else {
            if (this.requestItemHandle) {
              content = `object.${iv.identifier} == ${this.requestItemHandle.identifier}.${iv.identifier};`;
            } else {
              return null;
            }
          }

          return new Constraint({
            propsData: {
              parent: this,
              instanceVariable: iv,
              content
            }
          });
        })
          .filter(c => c);
      } else {
        return [];
      }
    },
    slaveConstraints () {
      if (this.sequenceItem) {
        const slaveIvs = get(this.sequenceItem, "almostAllInstanceVariables", []).filter(iv => iv.relatedPort && iv.isSlave);

        return slaveIvs.map(iv => {
          let content;
          if (get(iv, "nameInfo.unpacked.dimensions[0].type") === "dynamicArray") {
            if (this.sequenceItem.lenVariable) {
              content = `object.${iv.identifier}.size == object.${this.sequenceItem.lenVariable.identifier};
foreach (object.${iv.identifier}[${iv.identifier[0]}]) {
  object.${iv.identifier}[${iv.identifier[0]}] == 0;
}`;
            } else {
              return null;
            }
          } else {
            content = `object.${iv.identifier} == 0;`;
          }
          return new Constraint({
            propsData: {
              parent: this,
              instanceVariable: iv,
              content
            }
          });
        })
          .filter(c => c);
      } else {
        return [];
      }
    }
  }
};

export default defineReactiveModel(UvcMasterResponsePolicy);
