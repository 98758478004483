import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";
import VariableCommon from "@/models/mixins/variable-common";
import { DecodeTypeAndNameParameters } from "@/encode-decode-type-and-name-parameters";

const InstanceVariable = {
  extends: Model,
  mixins: [
    Persistable,
    VariableCommon,
    DecodeTypeAndNameParameters
  ],
  data () {
    return {
      encodedType: "",
      encodedName: "",
      defaultValue: "",
      constraintRequired: false,
      fieldMacro: "",
      fieldMacroFlagIds: [],
      setter: null,
      setterOnly: false,
      subEnvConfigHandlesWithoutDimensions: [],
      subEnvConfigHandlesWithDimensions: [],
      setterCallRequired: null,
      appendedSetterArgsOverride: [],
      // prependedSetterPartials: [],
      // setterPartials: [],
      // appendedSetterPartials: [],
      getter: false,
      getWord: "get",
      unsetValueOverride: "",
      coverpointOverride: null,
      convert2stringOverride: null,
      overrideId: ""
    };
  },
  computed: {
    klass () {
      return this.parent.rootProject.classesByType[this.rootType];
    }
  },
  methods: {
    parentOfParameters () {
      const packageInterfaceOrStandalone = this.parent.firstChildOfRoot;
      if (packageInterfaceOrStandalone.docType === "uvcStandaloneEnv") {
        return this.parent.project.package;
      } else if (packageInterfaceOrStandalone.docType === "envTb") {
        return packageInterfaceOrStandalone.package;
      } else {
        return packageInterfaceOrStandalone;
      }
    },
    updateFieldMacro (value) {
      if (this.defaultFieldMacroInfo && this.defaultFieldMacroInfo.macro === value) {
        this.update({ fieldMacro: "" });
      } else {
        this.update({ fieldMacro: value || "" });
      }
    },
    beforeDirectDestroy () {
      const collection = this.dbFns.collection(this.db, "constraints");
      const query = this.dbFns.query(collection, this.dbFns.where("instanceVariableId", "==", this.id));
      return this.dbFns.getDocs(query).then(querySnapshot => {
        const promises = [];
        querySnapshot.forEach(queryDocumentSnapshot => {
          const docRef = queryDocumentSnapshot.ref;
          promises.push(this.dbFns.deleteDoc(docRef));
        });
        return Promise.all(promises);
      });
    }
  }
};

export default defineReactiveModel(InstanceVariable);
