import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Subroutine from "@/models/subroutine";
import uvcReceiveTaskPlaceholderHbs from "@/handlebars/partials/uvc-slave-data-bfm/receive-task-placeholder.hbs";
import uvcDriveReadyTaskPlaceholderHbs from "@/handlebars/partials/uvc-slave-data-bfm/drive-ready-task-placeholder.hbs";
import uvcReceiveDataTaskPlaceholderHbs from "@/handlebars/partials/uvc-slave-data-bfm/receive-data-task-placeholder.hbs";
import handlebarsRuntimeOptions from "@/handlebars/runtime-options";

const UvcSlaveDataBfm = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcInterface"),
    Overridable("bfm")
  ],
  created () {
    this.master = false;
    this.channel = "data";
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-slave-data-bfm/run-phase" }
    ];
  },
  computed: {
    superclass () {
      return this.bfmBase;
    },
    type () {
      const parts = [this.uvc.rootName];
      parts.push(this.protocolRoleWord);
      parts.push("data");
      parts.push("bfm");
      parts.push("c");
      return parts.join("_");
    },
    createMethod () {
      const parts = ["create"];
      if (this.hasSlave) {
        parts.push(this.protocolRoleWord);
      }
      if (this.isPipelined) {
        parts.push("data");
      }
      parts.push("bfm");
      return parts.join("_");
    },
    computedSubroutines () {
      return [
        this.computedUvcSlaveDataBfmReceiveTask,
        this.computedUvcSlaveDataBfmDriveReadyTask,
        this.computedUvcSlaveDataBfmReceiveDataTask
      ];
    },
    uvcSlaveDataBfmReceiveTask () {
      return this.subroutines.find(subroutine => subroutine.overrideId === this.computedUvcSlaveDataBfmReceiveTask.id) || this.computedUvcSlaveDataBfmReceiveTask;
    },
    computedUvcSlaveDataBfmReceiveTask () {
      return new Subroutine({
        propsData: {
          parent: this,
          id: "static-id-2b33cc6",
          hasRequiredIdentifier: false,
          requiredArgs: [this.sequenceItem.type],
          content: uvcReceiveTaskPlaceholderHbs(this, handlebarsRuntimeOptions).slice(0, -1)
        }
      });
    },
    uvcSlaveDataBfmDriveReadyTask () {
      return this.subroutines.find(subroutine => subroutine.overrideId === this.computedUvcSlaveDataBfmDriveReadyTask.id) || this.computedUvcSlaveDataBfmDriveReadyTask;
    },
    computedUvcSlaveDataBfmDriveReadyTask () {
      return new Subroutine({
        propsData: {
          parent: this,
          id: "static-id-84cbe2a",
          hasRequiredIdentifier: false,
          requiredArgs: [],
          content: uvcDriveReadyTaskPlaceholderHbs(this, handlebarsRuntimeOptions).slice(0, -1)
        }
      });
    },
    uvcSlaveDataBfmReceiveDataTask () {
      return this.subroutines.find(subroutine => subroutine.overrideId === this.computedUvcSlaveDataBfmReceiveDataTask.id) || this.computedUvcSlaveDataBfmReceiveDataTask;
    },
    computedUvcSlaveDataBfmReceiveDataTask () {
      return new Subroutine({
        propsData: {
          parent: this,
          id: "static-id-3be4a25",
          hasRequiredIdentifier: false,
          requiredArgs: [],
          content: uvcReceiveDataTaskPlaceholderHbs(this, handlebarsRuntimeOptions).slice(0, -1)
        }
      });
    }
  }
};

export default defineReactiveModel(UvcSlaveDataBfm);
