{{#if isPipelined}}
{{#with sequenceItemHandle}}
virtual function void store_transaction({{ type }} {{ identifier }});
  if ({{ ../activeTransactionsVariable.identifier }}.exists({{ identifier }}.id)) begin
    `uvm_fatal(get_type_name(), $sformatf("Two active transactions with id='h%x.", {{ identifier }}.id))
  end
  {{ ../activeTransactionsVariable.identifier }}[{{ identifier }}.id] = {{ identifier }};
  {{ ../numActiveTransactionsVariable.identifier }}++;
endfunction
{{/with}}
{{/if}}
