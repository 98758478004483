import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Driver from "@/models/persistable/mixins/driver";

const UvcSlaveDataDriver = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    Driver,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage")
  ],
  created () {
    this.master = false;
    this.channel = "data";
    this.partialInheritance = [
      // { for: ["me"], partial: "uvm-driver/receive-data" }
    ];
  },
  computed: {
    isDriverPipelined () {
      return true;
    },
    type () {
      const parts = [this.uvc.rootName];
      if (this.hasSlave) {
        parts.push(this.protocolRoleWord);
      }
      parts.push("data");
      parts.push("receiver");
      parts.push("c");
      return parts.join("_");
    }
  }
};

export default defineReactiveModel(UvcSlaveDataDriver);
