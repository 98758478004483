export default function (data, options) {
  let ret = "";

  if (data === undefined) {
    data = [];
  }

  const renderings = [];
  for (let d = 0; d < (data || []).length; d++) {
    const rendering = options.fn(data[d]);
    if (rendering.trim()) {
      renderings.push(rendering);
    }
  }

  const len = renderings.length;
  if (options.hash.delimeter) {
    for (let r = 0; r < (len - 1); r++) {
      if (renderings[r].length) {
        ret = ret + renderings[r];
        if (ret.slice(-1) === "\n") {
          ret = ret.slice(0, -1) + options.hash.delimeter.replace(/\\n/g, "\n") + "\n";
        } else {
          ret = ret + options.hash.delimeter.replace(/\\n/g, "\n");
        }
      }
    }
    if (len > 0) {
      ret = ret + renderings[len - 1];
    }
  } else {
    for (let r = 0; r < len; r++) {
      ret = ret + renderings[r];
    }
  }

  if (len > 0) {
    if (options.hash.ln) {
      ret = "\n" + ret;
    } else if (options.hash.lc) {
      ret = ", " + ret;
    }
    if (options.hash.tn) {
      ret = ret + "\n";
    } else if (options.hash.tc) {
      ret = ret.slice(0, -1) + ",\n";
    }
  }

  return ret;
}
