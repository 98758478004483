virtual task monitor{{#if isPipelinedWrite}}_request{{/if}}();
{{#if (not isPipelined)}}
{{#with sequenceItemHandle}}
  {{ type }} {{ identifier }} = {{ type }}::type_id::create("{{ fullName }}", this);
  {{ identifier }}.{{ klass.configHandle.setterName }}({{ ../configHandle.identifier }});
{{/with}}

{{/if}}
  {{> (partial "snippets/call-bfm-with-reset") context=. task=monitorBfm.monitorTask}}
endtask
