import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";

const UvcBfmCreator = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcInterface")
  ],
  created () {
    this.partialInheritance = [
      { for: ["me"], partial: "design-element-accessor/set-print-context" },
      { for: ["me"], partial: "uvc-bfm-creator/create-functions" },
      { for: ["me"], partial: "uvc-bfm-creator/get-type-functions" }
    ];
  },
  computed: {
    superclass () {
      return this.abstractBfmCreator;
    },
    type () {
      return `${this.uvc.rootName}_bfm_creator_c`;
    }
  }
};

export default defineReactiveModel(UvcBfmCreator);
