export default function arrayMax (array, property) {
  let len = array.length;
  let max = 0;
  while (len--) {
    if (array[len][property].length > max) {
      max = array[len][property].length;
    }
  }
  return max;
}
