import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Subroutine from "@/models/subroutine";
import uvcReceiveTaskPlaceholderHbs from "@/handlebars/partials/uvc-slave-bfm/receive-task-placeholder.hbs";
import uvcRespondTaskPlaceholderHbs from "@/handlebars/partials/uvc-slave-bfm/respond-task-placeholder.hbs";

import handlebarsRuntimeOptions from "@/handlebars/runtime-options";

const UvcSlaveRequestBfm = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcInterface"),
    Overridable("bfm")
  ],
  created () {
    this.master = false;
    this.channel = "request";
    this.partialInheritance = [
    ];
  },
  computed: {
    superclass () {
      return this.bfmBase;
    },
    type () {
      const parts = [this.uvc.rootName];
      parts.push(this.protocolRoleWord);
      if (this.isPipelined) {
        parts.push("request");
      }
      parts.push("bfm");
      parts.push("c");
      return parts.join("_");
    },
    createMethod () {
      const parts = ["create"];
      parts.push(this.protocolRoleWord);
      if (this.isPipelined) {
        parts.push("request");
      }
      parts.push("bfm");
      return parts.join("_");
    },
    computedSubroutines () {
      return [
        this.computedReceiveTask,
        this.hasSlaveResponse && !this.isPipelined && this.computedRespondTask
      ]
        .filter(s => s);
    },
    receiveTask () {
      return this.subroutines.find(subroutine => subroutine.overrideId === this.computedReceiveTask.id) || this.computedReceiveTask;
    },
    respondTask () {
      return this.subroutines.find(subroutine => subroutine.overrideId === this.computedRespondTask.id) || this.computedRespondTask;
    },
    computedReceiveTask () {
      return new Subroutine({
        propsData: {
          parent: this,
          id: "static-id-ecb8752",
          hasRequiredIdentifier: false,
          requiredArgs: [this.sequenceItem.type],
          content: uvcReceiveTaskPlaceholderHbs(this, handlebarsRuntimeOptions).slice(0, -1)
        }
      });
    },
    computedRespondTask () {
      return new Subroutine({
        propsData: {
          parent: this,
          id: "static-id-2cb4401",
          hasRequiredIdentifier: false,
          requiredArgs: [this.sequenceItem.type],
          content: uvcRespondTaskPlaceholderHbs(this, handlebarsRuntimeOptions).slice(0, -1)
        }
      });
    }
  }
};

export default defineReactiveModel(UvcSlaveRequestBfm);
