export default function (length, nodeFn, ...args) {
  if (length === 0) {
    return null;
  }

  let node = nodeFn(length - 1, ...args);
  const last = node;
  last.last = last;

  for (let d = (length - 2); d >= 0; d--) {
    const level = nodeFn(d, ...args);
    level.next = node;
    level.next.previous = level;
    level.last = last;
    node = level;
  }

  return node;
}
