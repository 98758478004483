import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Policy from "@/models/mixins/policy";
import Constraint from "@/models/constraint";
import get from "lodash.get";

const UvcSlaveRequestPolicy = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("policy"),
    Policy
  ],
  created () {
    this.master = false;
    this.channel = "request";
  },
  computed: {
    subject () {
      return this.sequenceItem;
    },
    type () {
      const parts = [this.uvc.rootName];
      if (this.hasSlave) {
        parts.push(this.protocolRoleWord);
      }
      if (this.hasSlaveResponse) {
        parts.push("request");
      }
      parts.push("policy");
      parts.push("c");
      return parts.join("_");
    },
    computedConstraints () {
      const constraints = [
        this.phaseConstraint,
        this.preTransferDelayConstraint,
        this.lenConstraint,
        this.lastConstraint
      ]
        .filter(c => c);
      this.masterAndSlaveConstraints.forEach(masterOrSlaveConstraint => {
        if (!constraints.find(constraint => constraint.instanceVariable.id === masterOrSlaveConstraint.instanceVariable.id)) {
          constraints.push(masterOrSlaveConstraint);
        }
      });
      return constraints;
    },
    preTransferDelayConstraint () {
      return (!this.isPipelined && this.sequenceItem && this.sequenceItem.preTransferDelayVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.preTransferDelayVariable,
            content: `object.${this.sequenceItem.preTransferDelayVariable.identifier} == 0;`
          }
        })
        : null;
    },
    phaseConstraint () {
      return (this.sequenceItem && this.sequenceItem.phaseEnumVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.phaseEnumVariable,
            content: `object.${this.sequenceItem.phaseEnumVariable.identifier} == ${this.sequenceItem.phaseEnumVariable.klass.request.name};`
          }
        })
        : null;
    },
    masterAndSlaveConstraints () {
      const masterIvs = get(this.sequenceItem, "almostAllInstanceVariables", []).filter(iv => iv.relatedPort && iv.isMaster);
      const slaveIvs = get(this.sequenceItem, "almostAllInstanceVariables", []).filter(iv => iv.relatedPort && iv.isSlave);

      return masterIvs.concat(slaveIvs).map(iv => {
        return new Constraint({
          propsData: {
            parent: this,
            instanceVariable: iv,
            content: `object.${iv.identifier}${iv.isDynamicArray ? ".size" : ""} == 0;`
          }
        });
      });
    },
    lenConstraint () {
      return (this.sequenceItem && this.sequenceItem.lenVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.lenVariable,
            content: `object.${this.sequenceItem.lenVariable.identifier} == 1;`
          }
        })
        : null;
    },
    lastConstraint () {
      return (this.sequenceItem && this.sequenceItem.lastVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.lastVariable,
            content: `object.${this.sequenceItem.lastVariable.identifier} == 0;`
          }
        })
        : null;
    }
  }
};

export default defineReactiveModel(UvcSlaveRequestPolicy);
