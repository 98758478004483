import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import get from "lodash.get";
import config from "@/config";
import { path } from "@/utils/node-VITE_APP_PLATFORM";

const UvcUserClassCoverageTemplate = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile
  ],
  props: {
    parent: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  created () {
    this.userFile = true;
    this.appendedDeclarationPartials = [
      "snippets/covergroup"
    ];
    this.constructorPartials = [
      "snippets/construct-covergroup"
    ];
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-coverage/write" }
    ];
  },
  computed: {
    packageFilePath () {
      return path.join(config.userDir, this.fileName);
    },
    superclass () {
      return this.parent;
    },
    rootProject () {
      return this.parent.rootProject;
    },
    project () {
      return this.parent.project;
    },
    subscribedTransaction () {
      return get(this, "uvmSubscriber.subscribedTransaction");
    }
  }
};

export default defineReactiveModel(UvcUserClassCoverageTemplate);
