class Enum {
  constructor (valueKeyedObject) {
    this.valueKeyed = Object.assign({}, valueKeyedObject);
    this.nameKeyed = {};
    for (const value in this.valueKeyed) {
      const name = this.valueKeyed[value];
      this.nameKeyed[name] = Number(value);
    }
  }

  getValueKeyedObject () {
    return Object.assign({}, this.valueKeyed);
  }

  getValue (name) {
    return this.nameKeyed[name];
  }

  getName (value) {
    return this.valueKeyed[value];
  }

  isValueEqual (value, name) {
    return this.valueKeyed[value] === name;
  }
}

export default Enum;
