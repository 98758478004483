import kebabCase from "lodash.kebabcase";
import persistableModels from "@/models/require-models-VITE_APP_PLATFORM";

const models = {};

// Just for hasOne and hasMany
const getModel = function (itemName) {
  if (!Object.prototype.hasOwnProperty.call(models, itemName)) {
    const moduleName = kebabCase(itemName);
    const pattern = `/${moduleName}.js$`;
    const regexp = new RegExp(pattern);
    const keys = Object.keys(persistableModels);

    const paths = keys.filter((key) => {
      return key.match(regexp);
    });

    if (paths.length !== 1) {
      if (paths.length === 0) {
        throw new Error(`no files matched '${pattern}'. Possible matches: ${keys.toString()}`);
      } else {
        throw new Error(`more than one file matched '${pattern}': ${paths.toString()}`);
      }
    }
    let Model = persistableModels[paths[0]];
    if (Model.default) {
      Model = Model.default;
    }
    models[itemName] = Model;
  }
  return models[itemName];
};

export default getModel;
