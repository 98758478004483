import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import UvmSequence from "@/models/uvm/uvm-sequence";
import Handle from "@/models/handle";

const UvcMasterResponseSequenceBase = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("sequence")
  ],
  created () {
    this.master = true;
    this.channel = "response";
  },
  computed: {
    superclass () {
      return this.sequenceItem
        ? UvmSequence.singleton(this.sequenceItem)
        : null;
    },
    type () {
      const parts = [this.uvc.rootName];
      if (this.hasSlave) {
        parts.push(this.protocolRoleWord);
      }
      if (this.isPipelined) {
        parts.push("response");
      }
      parts.push("sequence");
      parts.push("c");
      return parts.join("_");
    },
    computedInstanceVariables () {
      return [
        this.configHandle,
        this.requestItemHandle,
        this.dataItemHandle
      ]
        .filter(o => o);
    },
    requestItemHandle () {
      if (this.sequenceItem) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.sequenceItem,
            nameOverride: "request_item",
            setter: true
          }
        });
      } else {
        return null;
      }
    },
    dataItemHandle () {
      if (this.sequenceItem && this.isPipelinedWrite) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.sequenceItem,
            nameOverride: "data_item",
            setter: true
          }
        });
      } else {
        return null;
      }
    },
    sequenceItemHandle () {
      return this.superclass && this.superclass.reqHandle;
    },
    randomObjectDoHandles () {
      return [
        this.sequenceItemHandle
      ]
        .filter(h => h);
    },
    computedPolicyHandles () {
      return [
        this.sequenceItemHandle && this.masterResponsePolicy && new Handle({
          propsData: {
            parent: this,
            klass: this.masterResponsePolicy,
            relations: {
              randomObjectDoHandle: this.sequenceItemHandle
            }
          }
        })
      ]
        .filter(h => h);
    }
  }
};

export default defineReactiveModel(UvcMasterResponseSequenceBase);
