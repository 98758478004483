import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import UvmSequence from "@/models/uvm/uvm-sequence";
import Handle from "@/models/handle";

const UvcMasterRequestSequenceBase = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("sequence")
  ],
  created () {
    this.master = true;
    this.channel = "request";
    this.partialInheritance = [
      { for: ["me"], partial: "killed-by-reset" }
    ];
  },
  computed: {
    superclass () {
      return this.sequenceItem
        ? UvmSequence.singleton(this.sequenceItem)
        : null;
    },
    type () {
      const parts = [this.uvc.rootName];
      if (this.hasSlave) {
        parts.push(this.protocolRoleWord);
      }
      if (this.isPipelined) {
        parts.push("request");
      }
      parts.push("sequence");
      if (this.masterRequestSequence) {
        parts.push("base");
      }
      parts.push("c");
      return parts.join("_");
    },
    computedInstanceVariables () {
      return [
        this.configHandle,
        this.refItemHandle
      ]
        .filter(o => o);
    },
    refItemHandle () {
      if (this.sequenceItem && this.isPipelinedWrite) {
        return new Handle({
          propsData: {
            parent: this,
            isProt: true,
            klass: this.sequenceItem,
            nameOverride: "ref_item",
            setter: true
          }
        });
      } else {
        return undefined;
      }
    },
    sequenceItemHandle () {
      return this.superclass && this.superclass.reqHandle;
    },
    randomObjectDoHandles () {
      return [
        this.sequenceItemHandle
      ]
        .filter(h => h);
    },
    computedPolicyHandles () {
      return [
        this.masterRequestPolicy && this.sequenceItemHandle && new Handle({
          propsData: {
            parent: this,
            klass: this.masterRequestPolicy,
            relations: {
              randomObjectDoHandle: this.sequenceItemHandle
            }
          }
        })
      ]
        .filter(h => h);
    }
  }
};

export default defineReactiveModel(UvcMasterRequestSequenceBase);
