import { defineReactiveModel, Persistable, hasOne, hasMany } from "vue-app-utils";
import DesignElement from "@/models/design-element";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Parameter from "@/models/parameter";
import UvmPackage from "@/models/uvm/uvm-package";
import UvmAgent from "@/models/uvm/uvm-agent";
import Handle from "@/models/handle";
import get from "lodash.get";
import groupBy from "lodash.groupby";

const UvcInterface = {
  extends: DesignElement,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    // belongsTo("uvc"),
    hasMany("parameters", {
      orderBy: ["createdAt"]
    }),
    hasMany("ports", {
      orderBy: ["createdAt"]
    }),
    hasOne("uvcBfmBase"),
    hasOne("uvcMasterRequestBfm"),
    hasOne("uvcMasterDataBfm"),
    hasOne("uvcMasterResponseBfm"),
    hasOne("uvcSlaveRequestBfm"),
    hasOne("uvcSlaveDataBfm"),
    hasOne("uvcSlaveResponseBfm"),
    hasOne("uvcMonitorBfm"),
    hasOne("uvcBfmCreator"),
    hasMany("subroutines", {
      orderBy: ["createdAt"]
    }),
    hasMany("includes"),
    Overridable("interface")
  ],
  created () {
    this.isInterface = true;
    this.masterClockingBlock = { name: "mst_cb" };
    this.slaveClockingBlock = { name: "slv_cb" };
    this.monitorClockingBlock = { name: "mon_cb" };
    this.partials = [
      "uvc-interface/clocking-blocks",
      "uvc-interface/includes",
      "design-element/set-print-context",
      "add-resources-to-config-db",
      "add-parameters-to-config-db",
      "add-accessor-to-config-db",
      "snippets/user-includes"
    ];
    this.instName = "inst_name";
  },
  computed: {
    externalImports () {
      // return [this.package];
      return [];
    },
    internalImports () {
      return [UvmPackage, this.package];
    },
    fileName () {
      return `${this.type}.sv`;
    },
    type () {
      return `${this.uvc.rootName}_if`;
    },
    computedParameters () {
      return [
        this.posedgeParameter
      ];
    },
    posedgeParameter () {
      return new Parameter({
        propsData: {
          id: "static-id-6e7b3ee",
          parent: this,
          type: "parameter bit",
          name: "ON_POSEDGE",
          defaultValueOverride: "1"
        }
      });
    },
    computedInstanceVariables () {
      return [
        this.printContextHandle
      ]
        .filter(v => v);
    },
    printContextHandle () {
      if (this.includes.length || this.userIncludes.length) {
        return new Handle({
          propsData: {
            parent: this,
            klass: UvmAgent.singleton(),
            nameOverride: "print_context"
          }
        });
      } else {
        return null;
      }
    },
    parametersHandle () {
      return this.uvcParametersHandle;
    },
    hasParameters () {
      return !!this.parameters.length;
    },
    hasMasterPorts () {
      return !!this.masterPorts.length;
    },
    hasSlavePorts () {
      return !!this.slavePorts.length;
    },
    parametersByName () {
      const parametersByName = {};
      this.parameters.forEach((p) => {
        parametersByName[p.name] = p;
      });
      return parametersByName;
    },
    hasClock () {
      return !!this.clockPort;
    },
    hasClockOnly () {
      return this.hasClock && !this.hasReset && !this.hasMasterPorts && !this.hasSlavePorts;
    },
    hasClockAndOtherPorts () {
      return this.hasClock && (this.hasReset || this.hasMasterPorts || this.hasSlavePorts);
    },
    clockPort () {
      return this.clockPorts[0];
    },
    clockPorts () {
      return this.ports.filter((port) => { return port.isClock; });
    },
    hasReset () {
      return !!this.resetPort;
    },
    hasResetOnly () {
      return this.hasReset && !this.hasMasterPorts && !this.hasSlavePorts;
    },
    hasResetAndOtherPorts () {
      return this.hasReset && (this.hasMasterPorts || this.hasSlavePorts);
    },
    resetPort () {
      return this.resetPorts[0];
    },
    resetPorts () {
      return this.ports.filter((port) => { return port.isReset; });
    },
    dataPort () {
      return this.dataPorts[0];
    },
    dataPorts () {
      return this.ports.filter((port) => { return port.isDataPort; });
    },
    idPort () {
      return this.idPorts[0];
    },
    idPorts () {
      return this.ports.filter((port) => { return port.isId; });
    },
    idMsbParameter () {
      return get(this.idPort, "typeParametersRelatingToMsb", [])[0];
    },
    idMsbMaxParameter () {
      return get(this.idMsbParameter, "minOrMaxPackageParameter");
    },
    lenPort () {
      return this.lenPorts[0];
    },
    lenPorts () {
      return this.ports.filter((port) => { return port.isLen; });
    },
    lenMsbParameter () {
      return get(this.lenPort, "typeParametersRelatingToMsb", [])[0];
    },
    lenMsbMaxParameter () {
      return get(this.lenMsbParameter, "minOrMaxPackageParameter");
    },
    masterPorts () {
      return this.ports.filter(port => port.isMaster);
    },
    masterRequestPorts () {
      return this.masterPorts.filter(port => port.isRequest);
    },
    masterDataPorts () {
      return this.masterPorts.filter(port => port.isData);
    },
    masterResponsePorts () {
      return this.masterPorts.filter(port => port.isResponse);
    },
    slavePorts () {
      return this.ports.filter(port => port.isSlave);
    },
    slaveRequestPorts () {
      return this.slavePorts.filter(port => port.isRequest);
    },
    slaveDataPorts () {
      return this.slavePorts.filter(port => port.isData);
    },
    slaveResponsePorts () {
      return this.slavePorts.filter(port => port.isResponse);
    },
    masterAndSlavePorts () {
      return this.masterPorts.concat(this.slavePorts);
    },
    accessorHandle () {
      return this.bfmCreatorHandle;
    },
    abstractAccessor () {
      return this.abstractBfmCreator;
    },
    commonLeadingCharacters () {
      const portsByFirstChar = groupBy(this.masterAndSlavePorts, "identifier[0]");
      const keys = Object.keys(portsByFirstChar);

      if (keys.length > 3) {
        return [];
      }

      if (keys.every(key => portsByFirstChar[key].length === 1)) {
        return [];
      }

      const ret = {};

      keys.forEach(key => {
        const commonChars = this.commonLeadingCharactersForGroup(portsByFirstChar[key]);

        portsByFirstChar[key].forEach(port => {
          ret[port.identifier] = commonChars;
        });
      });

      return ret;
    },
    allParametersById () {
      const byId = {};
      this.allParameters.forEach(parameter => {
        byId[parameter.id] = parameter;
      });
      return byId;
    },
    portsById () {
      const byId = {};
      this.ports.forEach(port => {
        byId[port.id] = port;
      });
      return byId;
    },
    portsWithDynamicArrayVariables () {
      return (this.isPipelined && this.sequenceItem)
        ? this.slaveResponsePorts.map(port => {
          const instanceVariable = this.sequenceItem.instanceVariablesByRelatedPort[port.identifier];
          return instanceVariable && instanceVariable.isDynamicArray && { port, instanceVariable };
        })
          .filter(iv => iv)
        : [];
    },
    firstDynamicArrayVariable () {
      return this.portsWithDynamicArrayVariables.length
        ? this.portsWithDynamicArrayVariables[0].instanceVariable
        : null;
    }
  },
  methods: {
    commonLeadingCharactersForGroup (ports) {
      const identifiers = ports.map(port => port.identifier.toLowerCase());
      if (identifiers.length < 2) {
        return "";
      }
      let commonChars = "";
      let index = 0;
      let char = identifiers[0][index];
      while (identifiers.every(identifier => identifier[index] === char)) {
        commonChars = commonChars + char;
        index++;
        char = identifiers[0][index];
      }
      return commonChars;
    }
  }
};

export default defineReactiveModel(UvcInterface);
