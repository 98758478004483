import { getFirebaseApp } from './utils/firebase.js';
import { ProviderId, getAuth, signInAnonymously, onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import Analytics from './analytics.js';
import currentTime from './utils/time.js';
import get from 'lodash.get';

const ID = "firebaseui-container";
const REDIRECT_KEY = "auth::redirect";
const TRUST_MET_KEY = "auth::meetsTrustThreashold";
const SIGN_OUT_TIME_KEY = "auth::signOutTime";

const SIGN_IN_OPTIONS = {
  google: {
    provider: ProviderId.GOOGLE
  },
  facebook: {
    provider: ProviderId.FACEBOOK
  },
  twitter: {
    provider: ProviderId.TWITTER
  },
  github: {
    provider: ProviderId.GITHUB
  },
  anonymous: {
    provider: auth.AnonymousAuthProvider.PROVIDER_ID
  }
};

class Auth {
  constructor () {
    this.trustTimerStarted = false;
    const firebaseApp = getFirebaseApp();
    this.auth = getAuth(firebaseApp);
    this.ui = new auth.AuthUI(this.auth);
    this.vue = null;
    if (this.ui.isPendingRedirect()) {
      this.redirectRoute = this.get(REDIRECT_KEY);
    }
    this.router = null;
    this.store = null;
    this.session = null;
    this.dialog = null;
    this.signInOptions = null;
    this.analytics = Analytics.get();
  }

  // Used for e2e testing on staging since firebase-ui anonymous sign-in broken for firebase 9.
  signInForTesting () {
    return signInAnonymously(this.auth);
    // .then(() => {
    //   console.log("success");
    // })
    // .catch((error) => {
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    //   console.log(error);
    // });
  }

  configure (router, store, session, dialog, providers) {
    this.router = router;
    this.store = store;
    this.session = session;
    this.dialog = dialog;
    this.signInOptions = providers.map(provider => SIGN_IN_OPTIONS[provider]);
  }

  onReady (createVue) {
    onAuthStateChanged(this.auth, (authUser) => {
      if (this.vue) {
        // Handling anonymous user manually, it would usually be handled by firebaseui and getUiConfig, but its broken with firebase 9.
        // [TODO] once firebaseui working again, remove this.
        if (authUser && authUser.isAnonymous) {
          this.signIn(authUser).then(isAnonymous => {
            this.redirect(isAnonymous);
          });
        }
      } else {
        if (authUser) {
          this.signIn(authUser).then(() => {
            this.vue = createVue();
          });
        } else {
          this.vue = createVue();
        }
      }
    });
  }

  start () {
    const enableOneTap = (this.trustMet() && this.longEnoughSinceSignOut());
    this.ui.start(this.selector(), this.getUiConfig(enableOneTap));
  }

  restart () {
    this.ui.reset();
    this.start();
  }

  id () {
    return ID;
  }

  selector () {
    return "#" + this.id();
  }

  bodyClicked () {
    if (!this.trustTimerStarted && !this.trustMet()) {
      this.trustTimerStarted = true;
      setTimeout(() => {
        this.set(TRUST_MET_KEY, true);
        this.restart();
      }, 8000);
    }
  }

  set (key, item) {
    localStorage.setItem(key, JSON.stringify(item));
  }

  get (key) {
    return JSON.parse(localStorage.getItem(key));
  }

  remove (key) {
    localStorage.removeItem(key);
  }

  signIn (authUser, authResult = {}) {
    const userProps = (({ displayName, photoURL, isAnonymous }) => ({ displayName, photoURL, isAnonymous }))(authUser);
    userProps.signInMethod = get(authResult, "credential.signInMethod", get(authUser, "providerData[0].providerId", "anonymous"));
    userProps.lastLoggedInAt = currentTime();
    // [TODO] remove betaTester once no longer needed. Auto added for anonymous in order to get e2e testing working.
    if (userProps.isAnonymous) {
      userProps.betaTester = true;
    }
    return this.session.findOrCreateUserById(authUser.uid, userProps).then((user) => {
      this.analytics.login(user, authUser.email, userProps.signInMethod);
      // [TODO] remove after soak
      // user.createOrUpdatePrivateProfile({ email: authUser.email });
      user.findOrCreatePrivateProfile().then(privateProfile => {
        privateProfile.immediateUpdate({ email: authUser.email });
      });
      this.store.commit("setUser", user);
      return userProps.isAnonymous;
    });
  }

  signOut () {
    let goHome = Promise.resolve();
    if (this.router.currentRoute.value.name !== "Home") {
      goHome = this.router.push({ name: "Home" });
    }
    return goHome.then(() => {
      return this.store.getters.user
        ? this.store.getters.user.unsubscribe().then(() => {
          this.store.commit("signOut");
          this.analytics.logout();
          this.set(SIGN_OUT_TIME_KEY, Date.now());
          this.restart();
          return signOut(this.auth);
        })
        : Promise.resolve();
    });
  }

  isSigningInBeforeRedirect () {
    return (Object.keys(this.redirectRoute || {}).length > 0);
  }

  redirect (isAnonymous) {
    if (isAnonymous) {
      this.redirectRoute = this.get(REDIRECT_KEY);
    }
    if (this.redirectRoute) {
      this.router.replace(this.redirectRoute)
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    }
    this.redirectRoute = null;
  }

  getUiConfig (enableOneTap) {
    const config = {
      // signInSuccessUrl: "/",
      callbacks: {
        signInSuccessWithAuthResult: (authResult/* , redirectUrl */) => {
          this.signIn(authResult.user, authResult).then(isAnonymous => {
            this.redirect(isAnonymous);
          });
          // this.router.push(route.params.redirectRoute || { name: "Home" });
          // var user = authUser;
          // var credential = authResult.credential;
          // var isNewUser = authResult.additionalUserInfo.isNewUser;
          // var providerId = authResult.additionalUserInfo.providerId;
          // var operationType = authResult.operationType;
          return false;
        }
      },
      signInOptions: this.signInOptions,
      tosUrl: `https://${__APP_SITE_DOMAIN__}/?dialog=terms-of-service`, // eslint-disable-line no-undef
      privacyPolicyUrl: `https://${__APP_SITE_DOMAIN__}/?dialog=privacy-policy` // eslint-disable-line no-undef
    };
    const googleAuthProviderIndex = config.signInOptions.findIndex((obj) => obj.provider === ProviderId.GOOGLE);
    if (enableOneTap && (googleAuthProviderIndex >= 0) && !this.store.getters.isAuthenticated) {
      config.signInOptions[googleAuthProviderIndex].clientId = import.meta.env.VITE_APP_GOOGLE_OAUTH_CLIENT_ID;
      config.credentialHelper = auth.CredentialHelper.GOOGLE_YOLO;
    }
    return config;
  }

  trustMet () {
    return this.get(TRUST_MET_KEY) || false;
  }

  longEnoughSinceSignOut () {
    const oneDay = (60 * 24);
    return (this.numMinutesSinceSignOut() > oneDay);
  }

  openDialog (redirect = {}, header = "sign-in-and-save-to-the-cloud", paramName = "dialog") {
    if (Object.keys(redirect).length > 0) {
      this.set(REDIRECT_KEY, redirect);
    }
    this.dialog.push(header, paramName);
  }

  numMinutesSinceSignOut () {
    const signOutTime = this.get(SIGN_OUT_TIME_KEY) || 0;
    return (((Date.now() - signOutTime) / 1000) / 60);
  }
}

var Auth$1 = Auth;

export { Auth$1 as default };
