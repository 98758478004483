import { defineReactiveModel, Persistable, hasOne } from "vue-app-utils";
import Model from "@/models/model";

const License = {
  extends: Model,
  mixins: [
    Persistable,
    hasOne("cart")
  ],
  data () {
    return {
      licenseKey: "",
      autoRenewEnabled: false
    };
  },
  computed: {
    status () {
      if (this.active) {
        return "Active";
      } else if (this.processing) {
        return "Processing";
      } else if (this.failed) {
        return "Failed";
      } else {
        return "Expired";
      }
    },
    needsAttention () {
      return (this.autoRenewEnabled && this.user.privateProfile && !!this.user.privateProfile.setupIntentErrorMessage) || (this.cart && this.cart.needsAttention);
    },
    // [TODO] I could put payments under license if i create a blank license at the same times i create paymentIntents
    payments () {
      return this.user.payments.filter(payment => payment.licenseId === this.id);
    },
    activePayments () {
      return this.payments.filter(payment => payment.active);
    },
    active () {
      return (this.activePayments.length > 0);
    },
    processing () {
      return (this.payments.filter(payment => payment.processing).length > 0);
    },
    failed () {
      return this.payments.every(payment => payment.failed);
    },
    durationInMonths () {
      return this.payments[0] && this.payments[0].cart.licenseDurationInMonths;
    },
    startDate () {
      return this.payments[0] && this.payments[0].createdAt.toDate();
    },
    termPayments () {
      return this.payments.filter(payment => !payment.isUpgradeOrder);
    },
    termPaymentProcessing () {
      return this.termPayments.some(payment => payment.processing);
    },
    succeededTermPayments () {
      return this.termPayments.filter(payment => payment.success);
    },
    latestTermPayment () {
      return (this.succeededTermPayments.length > 0)
        ? this.succeededTermPayments[this.succeededTermPayments.length - 1]
        : null;
    },
    endDate () {
      return (this.latestTermPayment)
        ? new Date(this.latestTermPayment.licenseTermEndDate.getTime())
        : null;
    },
    activeParameters () {
      const licenseTotal = {};
      this.activePayments.forEach(payment => {
        payment.cart.quantities.forEach(obj => {
          licenseTotal[obj.id] = licenseTotal[obj.id] || 0;
          licenseTotal[obj.id] = licenseTotal[obj.id] + obj.quantity;
        });
      });
      return licenseTotal;
    }
  }
};

export default defineReactiveModel(License);
