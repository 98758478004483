{{#each randomObjectCreateHandles delimeter="\n"}}
{{#if (and isRandomRefItem (and ../isPipelinedWrite ../isVirtualSequence))}}
virtual task {{ createRandomFunctionName }}({{#each createRandomFunctionArgumentHandles delimeter=", "}}{{ type }} {{ identifier }}{{/each}}{{#if sequencersUsedInFuture}}{{#unless isVirtualSequence}}{{ sequencerHandle.type }} {{ sequencerHandle.shortName }}{{/unless}}{{/if}});
  {{> (partial "snippets/create-object") handle=. declareType=false assignedToInstanceVariable=true}}

  {{ doName }}({{ identifier }});
  {{ klass.configHandle.identifier }}.wait_for_claim_id_grant();
  {{> (partial "snippets/randomize-object") context=. declareType=false}}
  {{ klass.configHandle.identifier }}.claim_id({{ identifier }}.{{ klass.idVariable.identifier }});
endtask
{{else}}
{{#if (or klass.isSequence isRandomRefItem)}}
virtual function {{ type }} {{ createRandomFunctionName }}({{#each createRandomFunctionArgumentHandles delimeter=", "}}{{ type }} {{ identifier }}{{/each}}{{#each iterationPropertiesList.last.iterationVariables lc=createRandomFunctionArgumentHandles.length delimeter=", "}}int {{ . }}{{/each}}{{#if sequencersUsedInFuture}}{{#unless isVirtualSequence}}{{ sequencerHandle.type }} {{ sequencerHandle.shortName }}{{/unless}}{{/if}});
  {{> (partial "snippets/create-object") handle=. declareType=true assignedToInstanceVariable=true}}

  {{ doName }}({{ shortName }}{{#each iterationPropertiesList.last.iterationVariables lc=true delimeter=", "}}{{ . }}{{/each}});
  {{> (partial "snippets/randomize-object") context=. declareType=true}}

  return {{ shortName }};
endfunction
{{else}}
virtual task send_{{identifier}}();
  {{> (partial "snippets/start-item") context=.. reqHandle=.}}
endtask
{{/if}}
{{/if}}
{{/each}}
