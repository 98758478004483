import UvmDriver from "@/models/uvm/uvm-driver";
import Handle from "@/models/handle";

export default {
  data () {
    return {
      pipelined: false
    };
  },
  computed: {
    isDriverPipelined () {
      return this.pipelined;
    },
    superclass () {
      return UvmDriver.singleton(this.sequenceItem);
    },
    computedInstanceVariables () {
      return [
        this.configHandle,
        this.abstractBfmCreatorHandle,
        this.abstractBfmHandle
      ]
        .filter(o => o);
    },
    responseSequenceItemHandle () {
      if (this.sequenceItem) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.sequenceItem,
            nameOverride: this.isDriverPipelined
              ? "rsp_a"
              : "rsp"
          }
        });
      } else {
        return null;
      }
    }
  }
};
