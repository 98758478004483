virtual function void build_phase(uvm_phase phase);
  {{> (partial "snippets/check-instance-variables-set")}}
{{#with abstractBfmHandle}}

  {{ identifier }} = {{ ../abstractBfmCreatorHandle.identifier }}.{{ ../bfm.createMethod }}("{{ identifier }}", this);
  {{ identifier }}.{{ klass.configHandle.setterName }}({{ ../configHandle.identifier }});
{{#if ../isMonitor}}
  {{ identifier }}.{{ ../abstractBfm.monHandle.setterName }}(this);
{{/if}}
{{/with}}
{{#each uvmAnalysisImpHandlesForMonitors ln=true}}
  {{> (partial "snippets/new-object") handle=.}}
{{/each}}
{{#with analysisPortHandle}}

  {{> (partial "snippets/new-object") handle=.}}
{{/with}}
{{#each uvmAnalysisExportHandlesWithoutDimensions ln=true}}
  {{> (partial "snippets/new-object") handle=.}}
{{/each}}
{{#each uvmAnalysisExportHandlesWithDimensions ln=true delimeter="\n"}}
  {{> (partial "snippets/new-object") handle=. iterationPropertiesList=iterationPropertiesList}}
{{/each}}
{{#each uvmTlmAnalysisFifoHandlesWithDimensions ln=true delimeter="\n"}}
  {{> (partial "snippets/new-object") handle=. iterationPropertiesList=iterationPropertiesList}}
{{/each}}
{{#each phaseFilterHandlesWithoutDimensions ln=true}}
  {{> (partial "snippets/create-object") handle=. declareType=false assignedToInstanceVariable=true}}
{{/each}}
{{#each phaseFilterHandlesWithDimensions ln=true delimeter="\n"}}
  {{> (partial "snippets/create-object") handle=. declareType=false assignedToInstanceVariable=true iterationPropertiesList=iterationPropertiesList}}
{{/each}}
{{#with predictorHandle}}

  {{> (partial "snippets/create-object") handle=. declareType=false assignedToInstanceVariable=true}}
{{/with}}
{{#each predictorHandles ln=true delimeter="\n"}}
  {{> (partial "snippets/create-object") handle=. iterationPropertiesList=iterationPropertiesList declareType=false assignedToInstanceVariable=true}}
{{/each}}
{{#each uvmAnalysisImpHandlesForPredictor ln=true}}
  {{> (partial "snippets/new-object") handle=.}}
{{/each}}
{{#each uvmTlmAnalysisFifoHandlesForPredictors ln=true delimeter="\n"}}
  {{> (partial "snippets/new-object") handle=. iterationPropertiesList=iterationPropertiesList}}
{{/each}}
{{#each comparatorHandlesWithoutDimensions ln=true}}
  {{> (partial "snippets/create-object") handle=. declareType=false assignedToInstanceVariable=true}}
{{/each}}
{{#each comparatorHandlesWithDimensions ln=true delimeter="\n"}}
  {{> (partial "snippets/create-object") handle=. declareType=false assignedToInstanceVariable=true iterationPropertiesList=iterationPropertiesList}}
{{/each}}
{{#each uvmAnalysisPortHandlesWithoutDimensions ln=true}}
  {{> (partial "snippets/new-object") handle=.}}
{{/each}}
{{#each uvmAnalysisPortHandlesWithDimensions ln=true delimeter="\n"}}
  {{> (partial "snippets/new-object") handle=. iterationPropertiesList=iterationPropertiesList}}
{{/each}}
endfunction
