virtual function void connect_phase(uvm_phase phase);
  super.connect_phase(phase);

  if ({{ configHandle.identifier }}.has_driver()) begin
  {{#each requestDataAndResponseHandles}}
    {{ driverHandle.identifier }}.seq_item_port.connect({{ sequencerHandle.identifier }}.seq_item_export);
  {{/each}}
  end
{{#if hasMonitor}}

  if ({{ configHandle.identifier }}.has_monitor()) begin
    {{ monitorHandle.identifier }}.{{ monitorHandle.klass.analysisPortHandle.identifier }}.connect({{ analysisPortHandle.identifier }});
  end
{{/if}}
{{#if hasUvcCoverage}}

  if ({{ configHandle.identifier }}.has_coverage()) begin
    {{ monitorHandle.identifier }}.{{ monitorHandle.klass.analysisPortHandle.identifier }}.connect({{ coverageHandle.identifier }}.analysis_export);
  end
{{/if}}
endfunction
