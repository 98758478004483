import { getFirebaseApp } from '../utils/firebase.js';
import { getFirestore } from 'firebase/firestore';

let firestore;

function firestore$1 () {
  if (!firestore) {
    const firebaseApp = getFirebaseApp();
    firestore = getFirestore(firebaseApp);
  }

  return firestore;
}

export { firestore$1 as default };
