import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Policy from "@/models/mixins/policy";
import Constraint from "@/models/constraint";
import get from "lodash.get";

const UvcSlaveDataPolicy = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    UvcProtocolRoleGetters,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("policy"),
    Policy
  ],
  created () {
    this.master = false;
  },
  computed: {
    subject () {
      return this.sequenceItem;
    },
    type () {
      return `${this.uvc.rootName}_${this.protocolRoleWord}_data_policy_c`;
    },
    computedInstanceVariables () {
      return [
      ]
        .filter(o => o);
    },
    computedConstraints () {
      const constraints = [
        this.phaseConstraint,
        this.preTransferDelayConstraint
      ]
        .filter(c => c);

      this.masterAndSlaveConstraints.forEach(masterOrSlaveConstraint => {
        if (!constraints.find(constraint => constraint.instanceVariable.id === masterOrSlaveConstraint.instanceVariable.id)) {
          constraints.push(masterOrSlaveConstraint);
        }
      });
      return constraints;
    },
    phaseConstraint () {
      return get(this, "sequenceItem.phaseEnumVariable.klass.data.name")
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.phaseEnumVariable,
            content: `object.${this.sequenceItem.phaseEnumVariable.identifier} == ${this.sequenceItem.phaseEnumVariable.klass.data.name};`
          }
        })
        : null;
    },
    preTransferDelayConstraint () {
      return (this.sequenceItem && this.sequenceItem.preTransferDelayVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.preTransferDelayVariable,
            content: `object.${this.sequenceItem.preTransferDelayVariable.identifier} == 0;`
          }
        })
        : null;
    },
    masterAndSlaveConstraints () {
      const masterIvs = get(this.sequenceItem, "almostAllInstanceVariables", []).filter(iv => iv.relatedPort && iv.isMaster);
      const slaveIvs = get(this.sequenceItem, "almostAllInstanceVariables", []).filter(iv => iv.relatedPort && iv.isSlave);

      return masterIvs.concat(slaveIvs).map(iv => {
        let content;
        // [TODO] lot of places like this you can use iv.isDynamicArray instead
        if (get(iv, "nameInfo.unpacked.dimensions[0].type") === "dynamicArray") {
          if (this.package.beatsInBurstMaxParameter) {
            content = `object.${iv.identifier}.size == ${this.package.beatsInBurstMaxParameter.name};
foreach (object.${iv.identifier}[${iv.identifier[0]}]) {
  object.${iv.identifier}[${iv.identifier[0]}] == 0;
}`;
          } else {
            return null;
          }
        } else {
          content = `object.${iv.identifier} == 0;`;
        }
        return new Constraint({
          propsData: {
            parent: this,
            instanceVariable: iv,
            content
          }
        });
      })
        .filter(c => c);
    }
  }
};

export default defineReactiveModel(UvcSlaveDataPolicy);
