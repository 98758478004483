import { defineReactiveModel } from "vue-app-utils";
import GeneratableFile from "@/models/mixins/generatable-file";
import Encapsulator from "@/models/mixins/encapsulator";
import userIncludeEncapsulatorHbs from "@/handlebars/user-include-encapsulator.hbs";
import handlebarsRuntimeOptions from "@/handlebars/runtime-options";
import Variable from "@/models/variable";
import config from "@/config";
import { path } from "@/utils/node-VITE_APP_PLATFORM";

const UvcUserIncludeForDesignElementTemplate = {
  mixins: [
    GeneratableFile,
    Encapsulator
  ],
  props: {
    parent: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  created () {
    this.partials = [
      "design-element/update-variables"
    ];
  },
  computed: {
    packageFilePath () {
      return path.join(config.userIncludesPath(), this.fileName);
    },
    fileName () {
      return `${this.type}.svh`;
    },
    fileContents () {
      return userIncludeEncapsulatorHbs(this, handlebarsRuntimeOptions).slice(0, -1);
    },
    computedInstanceVariables () {
      return [
        this.checksEnabledVariable,
        this.assertionsEnabledVariable
      ]
        .filter(o => o);
    },
    checksEnabledVariable () {
      return new Variable({
        propsData: {
          parent: this,
          type: "bit",
          name: "checks_enabled"
          // defaultValue: "UVM_ACTIVE"
        }
      });
    },
    assertionsEnabledVariable () {
      return new Variable({
        propsData: {
          parent: this,
          type: "bit",
          name: "assertions_enabled"
          // defaultValue: "UVM_ACTIVE"
        }
      });
    },
    ports () {
      return this.parent.ports;
    }
  }
};

export default defineReactiveModel(UvcUserIncludeForDesignElementTemplate);
