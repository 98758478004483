import { defineReactiveModel } from "vue-app-utils";
import { db, dbFns } from "@/db-VITE_APP_PLATFORM";
import { currentTime } from "@/utils/time-VITE_APP_PLATFORM";
import store from "@/store";
import getModel from "@/models/get-model";
// import cpb from "@/utils/computed-property-benchmarker";

const Model = {
  beforeCreate: function () {
    this.db = db;
    this.dbFns = dbFns;
    this.$store = store;
    this.currentTime = currentTime;
    this.getModel = getModel;
  }
};

export default defineReactiveModel(Model);

// const origExtend = Model.extend;
// Model.extend = function (prototype) {
//   const pathNames = new Error().stack.toString().split("\n")[2].trimLeft().split(" ")[1].split("/");
//   const className = pathNames[pathNames.length - 1].replace(/.js$/, "");
//   const updatedPrototype = cpb.hooks(className, prototype);
//   return origExtend.call(this, updatedPrototype);
// };
