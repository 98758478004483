import errorParser from './error-parser.js';
import Analytics from './analytics.js';
import bowser from './utils/bowser.js';
import sendFeedback from './send-feedback.js';

const sendAnalytics = function (error) {
  const payload = {};
  payload.description = error.exception.message;
  payload.fatal = true;
  payload.browser = bowser.browserName;
  payload.os = bowser.osName;
  payload.commit = __APP_GIT_VERSION__; // eslint-disable-line no-undef
  Analytics.get().log("exception", payload);
};

const send = function (error, store) {
  const subject = `Exception: ${error.exception.message}`;
  sendFeedback(subject, JSON.stringify(error, null, 2), store, false);
};

const ignoreError = function (error) {
  if (
    (error.exception.message === "Network Error") ||
    (error.exception.message === "ResizeObserver loop completed with undelivered notifications.")
  ) {
    return true;
  } else {
    return false;
  }
};

function errorHandler (app, store) {
  errorParser(app, (error) => {
    if (ignoreError(error)) {
      sendAnalytics(error);
    } else {
      send(error, store);
      sendAnalytics(error);
    }
  });
}

export { errorHandler as default };
