function formatDate (date, options = {}) {
  if (!date) {
    return "??/??/????";
  }

  const day = date.getDate();
  const year = date.getFullYear();

  if (options.short) {
    const month = date.getMonth() + 1;
    return `${month}/${day}/${year}`;
  } else {
    const month = date.toLocaleString("en-US", { month: "long" });
    return `${month} ${day}, ${year}`;
  }
}

export { formatDate as default };
