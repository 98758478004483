import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Variable from "@/models/variable";

const UvcRegisterSequenceItem = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("sequence-item")
  ],
  created () {
    this.constraintPrefix = "reg_";
  },
  computed: {
    superclass () {
      return this.sequenceItem;
    },
    type () {
      return `${this.uvc.rootName}_register_sequence_item_c`;
    },
    computedInstanceVariables () {
      return [
        this.readWriteVariable
      ]
        .filter(o => o);
    },
    readWriteVariable () {
      return new Variable({
        propsData: {
          parent: this,
          type: "uvm_reg_bus_op",
          name: "rw",
          setter: true,
          setterCallRequired: false,
          fieldMacro: "None"
        }
      });
    }
  }
};

export default defineReactiveModel(UvcRegisterSequenceItem);
