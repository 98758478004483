import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import UvmRegAdapter from "@/models/uvm/uvm-reg-adapter";
import Variable from "@/models/variable";
import Subroutine from "@/models/subroutine";
import Overridable from "@/models/persistable/mixins/overridable";
// import uvcBus2RegFunctionPlaceholderHbs from "@/handlebars/partials/uvc-interface/monitor-task-placeholder.hbs";

const UvcRegisterAdapter = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("reg-adapter")
  ],
  created () {
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-register-adapter/reg2bus" },
      { for: ["me"], partial: "do-functions" }
    ];
  },
  computed: {
    superclass () {
      return UvmRegAdapter.singleton();
    },
    type () {
      return `${this.uvc.rootName}_register_adapter_c`;
    },
    computedInstanceVariables () {
      return [
        this.configHandle
      ]
        .filter(o => o);
    },
    constructorInitialValues () {
      return [
        this.supportsByteEnableVariable,
        this.providesResponsesVariable
      ];
    },
    supportsByteEnableVariable () {
      return new Variable({
        propsData: {
          parent: this,
          type: "bit",
          name: "supports_byte_enable",
          defaultValue: "1"
        }
      });
    },
    providesResponsesVariable () {
      return new Variable({
        propsData: {
          parent: this,
          type: "bit",
          name: "provides_responses",
          defaultValue: "1"
        }
      });
    },
    computedSubroutines () {
      return [
        this.computedBus2regFunction
      ]
        .filter(s => s);
    },
    bus2regFunction () {
      return this.subroutines.find(subroutine => subroutine.overrideId === this.computedbus2regFunction.id) || this.computedBus2regFunction;
    },
    computedBus2regFunction () {
      return new Subroutine({
        propsData: {
          parent: this,
          id: "static-id-db2e744",
          requiredArgs: ["uvm_sequence_item", "uvm_reg_bus_op"],
          content: "function void bus2reg();\nendfunction"
          // content: uvcBus2RegFunctionPlaceholderHbs(this).slice(0, -1)
        }
      });
    },
    randomObjectDoHandles () {
      return [
        this.registerSequenceItemHandle
      ]
        .filter(h => h);
    }
  }
};

export default defineReactiveModel(UvcRegisterAdapter);
