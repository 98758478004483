{{#with context}}
{{#if isFirstLevelObject}}
{{#with ../reqHandle}}
{{> (partial "snippets/create-object") handle=. declareType=false assignedToInstanceVariable=(not (eq "req" identifier))}}
{{/with}}

start_item({{ ../reqHandle.identifier }}{{#with sequenceItemHandle.priority}}, {{ . }}{{/with}});
{{ ../reqHandle.doName }}({{ ../reqHandle.identifier }});
{{#if (and isPipelinedRead (and isMaster isRequest))}}
{{ configHandle.identifier }}.wait_for_claim_id_grant();
{{/if}}
{{#with ../reqHandle}}
{{> (partial "snippets/randomize-object") context=. declareType=false}}
{{/with}}
{{#if (and isPipelinedRead (and isMaster isRequest))}}
{{ configHandle.identifier }}.claim_id({{ ../reqHandle.identifier }}.{{ sequenceItem.idVariable.identifier }});
{{/if}}

finish_item({{ ../reqHandle.identifier }});

get_response(rsp);
{{#if (and isPipelined (and isMaster isResponse))}}
{{ configHandle.identifier }}.retire_id(rsp.{{ sequenceItem.idVariable.identifier }});
{{/if}}
{{/if}}
{{/with}}
