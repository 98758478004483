import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import store from "@/store";
import UvmObject from "@/models/uvm/uvm-object.js";
import Handle from "@/models/handle";
import Variable from "@/models/variable";
import Constraint from "@/models/constraint";
import get from "lodash.get";
import FieldMacroFlagEnum from "@/utils/field-macro-flag-enum";

const UvcConfig = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("config")
  ],
  created () {
    this.iterationCharacter = "c";
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-config/has-driver" },
      { for: ["me"], partial: "uvc-config/has-monitor" },
      { for: ["me"], partial: "uvc-config/has-coverage" },
      { for: ["me"], partial: "uvc-config/has-protocol-checks-enabled" },
      { for: ["me"], partial: "uvc-config/get-next-beat-priority-upper-bound" },
      { for: ["me"], partial: "uvc-config/get-beat-priority-lower-bound" },
      { for: ["me"], partial: "uvc-config/get-next-response-priority-upper-bound" },
      { for: ["me"], partial: "uvc-config/get-response-priority-lower-bound" },
      { for: ["me"], partial: "uvc-config/wait-for-request-grant" },
      { for: ["me"], partial: "uvc-config/retire-request-grant" },
      { for: ["me"], partial: "uvc-config/wait-for-data-grant" },
      { for: ["me"], partial: "uvc-config/retire-data-grant" },
      { for: ["me"], partial: "uvc-config/wait-for-claim-id-grant" },
      { for: ["me"], partial: "uvc-config/claim-id" },
      { for: ["me"], partial: "uvc-config/retire-id" }
    ];
  },
  computed: {
    isConfig () {
      return true;
    },
    superclass () {
      return UvmObject.singleton();
    },
    type () {
      return `${this.uvc.rootName}_config_c`;
    },
    computedInstanceVariables () {
      return [
        this.uvcParametersHandle,
        this.activeIdQVariable,
        this.numInProgressTransactionsMaxVariable,
        this.beatPriorityWindowWidthVariable,
        this.responsePriorityWindowWidthVariable,
        this.instNameVariable,
        this.activeStateVariable,
        this.coverageEnabledVariable,
        this.protocolChecksEnabledVariable,
        this.interfaceBoundVariable,
        this.protocolCheckerBoundVariable,
        this.requestGrantsVariable,
        this.dataGrantsVariable,
        this.claimIdGrantVariable,
        this.beatPriorityUpperBoundVariable,
        this.responsePriorityUpperBoundVariable
      ]
        .filter(o => o);
    },
    uvcParametersHandle () {
      if (this.uvc.uvcParameters) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.uvc.uvcParameters,
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT")
            ]
          }
        });
      } else {
        return null;
      }
    },
    computedConstraints () {
      return [
        this.numInProgressTransactionsMaxConstraint,
        this.beatPriorityWindowWidthConstraint,
        this.responsePriorityWindowWidthConstraint
      ]
        .filter(c => c);
    },
    preRandomizeGetFromConfigDbVariables () {
      return [
        this.uvcParametersHandle
      ]
        .filter(c => c);
    },
    activeIdQVariable () {
      return this.isPipelined && new Variable({
        propsData: {
          parent: this,
          type: `bit [${get(this, "interface.idMsbMaxParameter.name", "")}:0]`,
          name: "active_id_q[$]"
        }
      });
    },
    numInProgressTransactionsMaxVariable () {
      return this.isPipelined && new Variable({
        propsData: {
          id: "static-id-ecb2b24",
          parent: this,
          type: "rand int unsigned",
          name: "num_in_progress_transactions_max"
        }
      });
    },
    beatPriorityWindowWidthVariable () {
      return this.isPipelined && new Variable({
        propsData: {
          id: "static-id-bde3001",
          parent: this,
          type: "rand int unsigned",
          name: "beat_priority_window_width"
        }
      });
    },
    responsePriorityWindowWidthVariable () {
      return this.isPipelinedWrite && new Variable({
        propsData: {
          id: "static-id-401e344",
          parent: this,
          type: "rand int unsigned",
          name: "response_priority_window_width"
        }
      });
    },
    instNameVariable () {
      return new Variable({
        propsData: {
          parent: this,
          type: "protected string",
          name: "inst_name",
          setter: true
        }
      });
    },
    activeStateVariable () {
      return new Variable({
        propsData: {
          parent: this,
          type: "protected uvm_active_passive_enum",
          name: "active_state",
          defaultValue: "UVM_ACTIVE",
          setter: true
        }
      });
    },
    coverageEnabledVariable () {
      return this.hasUvcCoverage && new Variable({
        propsData: {
          parent: this,
          type: "protected bit",
          name: "coverage_enabled",
          defaultValue: "1",
          setter: true
        }
      });
    },
    protocolChecksEnabledVariable () {
      return this.hasProtocolChecker && new Variable({
        propsData: {
          parent: this,
          type: "protected bit",
          name: "protocol_checks_enabled",
          defaultValue: "1",
          setter: true,
          getter: true
        }
      });
    },
    interfaceBoundVariable () {
      return new Variable({
        propsData: {
          parent: this,
          type: "protected bit",
          name: "interface_bound",
          defaultValue: "1",
          setter: true,
          prependedSetterPartials: [
            "uvc-config/set-interface-bound-message"
          ],
          getter: true
        }
      });
    },
    protocolCheckerBoundVariable () {
      return this.hasProtocolChecker && new Variable({
        propsData: {
          parent: this,
          type: "protected bit",
          name: "protocol_checker_bound",
          defaultValue: "1",
          setter: true,
          prependedSetterPartials: [
            "uvc-config/protocol-checker-bound-setter-message"
          ],
          getter: true
        }
      });
    },
    requestGrantsVariable () {
      return this.isPipelined && new Variable({
        propsData: {
          parent: this,
          type: "protected semaphore",
          name: "request_grants",
          postRandomizeValue: `new(${this.numInProgressTransactionsMaxVariable.identifier})`
        }
      });
    },
    dataGrantsVariable () {
      return this.isPipelinedWrite && new Variable({
        propsData: {
          parent: this,
          type: "protected semaphore",
          name: "data_grants",
          postRandomizeValue: `new(${this.numInProgressTransactionsMaxVariable.identifier})`
        }
      });
    },
    claimIdGrantVariable () {
      return this.isPipelined && new Variable({
        propsData: {
          parent: this,
          type: "protected semaphore",
          name: "claim_id_grant",
          defaultValue: "new(1)"
        }
      });
    },
    beatPriorityUpperBoundVariable () {
      return this.isPipelined && new Variable({
        propsData: {
          parent: this,
          type: "protected int",
          name: "beat_priority_upper_bound",
          defaultValue: "'h7fff_ffff"
        }
      });
    },
    responsePriorityUpperBoundVariable () {
      return this.isPipelinedWrite && new Variable({
        propsData: {
          parent: this,
          type: "protected int",
          name: "response_priority_upper_bound",
          defaultValue: "'h7fff_ffff"
        }
      });
    },
    numInProgressTransactionsMaxConstraint () {
      return this.isPipelined && new Constraint({
        propsData: {
          parent: this,
          instanceVariable: this.numInProgressTransactionsMaxVariable,
          content: `${this.numInProgressTransactionsMaxVariable.identifier} > 0;\n${this.numInProgressTransactionsMaxVariable.identifier} <= (2**(${get(this, "uvcParametersHandle.identifier", "")}.${get(this, "uvcParametersHandle.klass.idMsbVariable.identifier", "")}+1));`
        }
      });
    },
    beatPriorityWindowWidthConstraint () {
      return this.isPipelined && new Constraint({
        propsData: {
          parent: this,
          instanceVariable: this.beatPriorityWindowWidthVariable,
          content: `${this.beatPriorityWindowWidthVariable.identifier} >= 0;`
        }
      });
    },
    responsePriorityWindowWidthConstraint () {
      return this.isPipelinedWrite && new Constraint({
        propsData: {
          parent: this,
          instanceVariable: this.responsePriorityWindowWidthVariable,
          content: `${this.responsePriorityWindowWidthVariable.identifier} >= 0;`
        }
      });
    }
  },
  methods: {
    createDeclarationName (uvcInstance) {
      let protocolWord;
      if (uvcInstance.isMaster) {
        if (this.hasSlave) {
          protocolWord = store.getters.user.setting.masterWord;
        }
      } else {
        if (this.hasMaster) {
          protocolWord = store.getters.user.setting.slaveWord;
        }
      }
      const name = [
        this.uvc.rootName,
        protocolWord,
        uvcInstance.suffix,
        uvcInstance.hasDimensions ? "cfgs" : "cfg"
      ]
        .filter(str => str)
        .join("_");

      return uvcInstance.unpackedDeclarationBrackets ? `${name}${uvcInstance.unpackedDeclarationBrackets}` : name;
    }
  }
};

export default defineReactiveModel(UvcConfig);
