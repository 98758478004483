import createsUserFilesFromTemplate from "@/models/persistable/mixins/creates-user-files-from-template";
import UserPolicyTemplate from "@/models/user-policy-template";

export default {
  mixins: [
    createsUserFilesFromTemplate("UserPolicy", UserPolicyTemplate)
  ],
  computed: {
    userPolicies () {
      return this.package.userPolicies.filter(p => {
        return p.superclassParameters[0] === this.type;
      });
    }
  }
};
