import pluralize from 'pluralize-esm';

// import debounce from "lodash.debounce";

const data = function (hasManyLazyCollectionName) {
  const data = {};
  data[hasManyLazyCollectionName] = [];
  return data;
};

const createFn = function (hasManyClassName, hasManyCollectionName) {
  return function (additionalData = {}) {
    const { addDoc, collection } = this.dbFns;
    const data = {
      createdAt: this.currentTime(),
      ...additionalData
    };
    if (this.docType !== "user") {
      data.userId = this.user.id;
    }
    data[`${this.docType}Id`] = this.id;

    return this.$store.dispatch(
      "trackTransaction",
      addDoc(collection(this.db, hasManyCollectionName), data)
        .then((docRef) => {
          const afterCreateName = `afterCreate${hasManyClassName}`;
          if (this[afterCreateName]) {
            this[afterCreateName](docRef.id, data);
          }

          return docRef.id;
        })
        .catch((e) => {
          const error = new Error("hasMany::createFn::add: " + e.message);
          error.name = e.name;
          throw error;
        })
    );
  };
};

const lazyOnSnapshot = function (hasManyItemName, hasManyCollectionName, hasManyLazyCollectionName, hasManyLazyBufferCollectionName, hasManyLazyCollectionResolve, hasManySnapshotStartedCollectionName, hasManySnapshotPromiseChainName, options) {
  return function (additionalData = {}) {
    const { collection, onSnapshot, query, where, orderBy } = this.dbFns;
    if (!this[hasManySnapshotStartedCollectionName]) {
      // TODO I think this may be the second time you guard this snapshot
      this[hasManySnapshotStartedCollectionName] = true;
      const HasManyModel = this.getModel(hasManyItemName);
      let userId;
      if (this.docType === "user") {
        userId = this.id;
      } else {
        userId = this.user.id;
      }
      let q = query(collection(this.db, hasManyCollectionName), where("userId", "==", userId));
      if (this.docType !== "user") {
        q = query(q, where(`${this.docType}Id`, "==", this.id));
      }
      if (options.orderBy) {
        options.orderBy.forEach((property) => {
          if (Array.isArray(property)) {
            q = query(q, orderBy(...property));
          } else {
            q = query(q, orderBy(property));
          }
        });
      }
      // const debouncedUpdate = debounce((updatedCollection) => {
      //   console.log(this.name || this.type, hasManyItemName, "updating debouncedUpdate", updatedCollection.length);
      //   this[hasManyLazyCollectionName] = updatedCollection;
      // }, 200);
      const handleSnapshot = (snapshot) => {
        let promises = [];
        const docChanges = snapshot.docChanges();
        for (const docChange of docChanges) {
          if (docChange.type === "added") {
            const propsData = {
              docRef: docChange.doc.ref,
              user: this.user
            };
            propsData[this.docType] = this;
            propsData.parent = this;
            propsData.startSnapshot = true;
            propsData.docType = hasManyItemName;
            const obj = new HasManyModel({ propsData });
            // Currently unused. Commented in case revived.
            // if (options.initFn) {
            //   options.initFn.call(obj);
            // }
            promises = promises.concat([obj.promiseOnSnapshot, ...obj.prereqPromises]);
            this[hasManyLazyBufferCollectionName].splice(docChange.newIndex, 0, obj);
          } else if (docChange.type === "removed") {
            this[hasManyLazyBufferCollectionName][docChange.oldIndex].unsubscribe();
            this[hasManyLazyBufferCollectionName].splice(docChange.oldIndex, 1);
          } else if ((docChange.type === "modified") && (docChange.oldIndex !== docChange.newIndex)) {
            const obj = this[hasManyLazyBufferCollectionName].splice(docChange.oldIndex, 1)[0];
            this[hasManyLazyBufferCollectionName].splice(docChange.newIndex, 0, obj);
          }
        }
        let updatedPromise;
        if (docChanges.length > 0) {
          const updatedCollection = this[hasManyLazyBufferCollectionName].slice(0);
          updatedPromise = Promise.all(promises).then(() => {
            // if (hasManyItemName === "port") {
            //   if ((docChanges.length > 2) || (docChanges[0].type === "removed")) {
            this[hasManyLazyCollectionName] = updatedCollection;

            //   } else {
            //     // I'm not convinced that debouncedUpdate is necessary. This does avoid thrash when a port or iv placeholder is used, but that thrash does not take very long.
            //     // Consider removing debounce.
            //     console.log(this.name || this.type, hasManyItemName, "calling debouncedUpdate", updatedCollection.length);
            //     debouncedUpdate(updatedCollection);
            //   }
            // } else {
            //   this[hasManyLazyCollectionName] = updatedCollection;
            // }
          });
        } else {
          updatedPromise = Promise.resolve();
        }
        return updatedPromise.then(() => {
          const hasManyLazyCollection = this[hasManyLazyCollectionResolve]();
          return hasManyLazyCollection;
        });
        // return updatedPromise.then(() => { resolveCollectionPromise(); });
      };
      this.hasManySnapshotPromiseChain[hasManySnapshotPromiseChainName] = this.hasManySnapshotPromiseChain[hasManySnapshotPromiseChainName] || Promise.resolve();
      // console.log("Starting query onSnapshot() for ", this.docRef.path, " on ", hasManyCollectionName);
      this.onSnapshotCancelHasMany = this.onSnapshotCancelHasMany || {};
      this.onSnapshotCancelHasMany[hasManyCollectionName] = onSnapshot(
        q,
        (snapshot) => {
          this.hasManySnapshotPromiseChain[hasManySnapshotPromiseChainName] = this.hasManySnapshotPromiseChain[hasManySnapshotPromiseChainName].then(() => { return handleSnapshot(snapshot); });
        },
        (error) => {
          // eslint-disable-next-line
          console.error({ hasManyCollectionName, error });
        }
      );
    }
    return this[hasManyLazyCollectionName];
  };
};

const findAllFn = function (hasManyItemName, hasManyCollectionName, defaultOptions) {
  return function (overrideOptions = {}) {
    const { collection, getDocs, query, where, orderBy, limit } = this.dbFns;
    const HasManyModel = this.getModel(hasManyItemName);
    const options = {
      ...defaultOptions,
      ...overrideOptions
    };
    let userId;
    if (this.docType === "user") {
      userId = this.id;
    } else {
      userId = this.user.id;
    }
    let q = query(collection(this.db, hasManyCollectionName), where("userId", "==", userId));
    if (this.docType !== "user") {
      q = query(q, where(`${this.docType}Id`, "==", this.id));
    }
    if (options.orderBy) {
      options.orderBy.forEach((property) => {
        if (Array.isArray(property)) {
          q = query(q, orderBy(...property));
        } else {
          q = query(q, orderBy(property));
        }
      });
    }
    if (options.limit) {
      q = query(q, limit(options.limit));
    }
    // console.log("Starting query findAll() for ", this.docRef.path, " on ", hasManyCollectionName);
    return getDocs(q)
      .then((querySnapshot) => {
        const models = [];
        querySnapshot.forEach((queryDocumentSnapshot) => {
          const docRef = queryDocumentSnapshot.ref;
          const propsData = {
            docRef,
            user: this.user
          };
          propsData[this.docType] = this;
          propsData.parent = this;
          propsData.startSnapshot = false;
          propsData.docType = hasManyItemName;
          const obj = new HasManyModel({ propsData });
          // Currently unused. Commented in case revived.
          // if (options.initFn) {
          //   options.initFn.call(obj);
          // }
          models.push(obj);
        });
        return models;
      })
      .catch((e) => {
        const error = new Error("hasMany::findAllFn::get: " + e.message);
        error.name = e.name;
        throw error;
      });
  };
};

const findFirstOrCreateFn = function (hasManyItemName, hasManyCollectionName, findAllFnName, createFnName) {
  return function (additionalData = {}) {
    return this[findAllFnName]({ limit: 1 })
      .then((models) => {
        if (models.length > 0) {
          return {
            id: models[0].id,
            existed: true
          };
        } else {
          return this[createFnName](additionalData)
            .then((id) => {
              return {
                id,
                existed: false
              };
            });
        }
      });
  };
};

const hasManyForModel = function (hasManyCollectionName, options = {}) {
  const hasManyItemName = pluralize.singular(hasManyCollectionName);
  const hasManyClassName = hasManyItemName.charAt(0).toUpperCase() + hasManyItemName.substr(1);
  const hasManyCollectionNameTitle = hasManyCollectionName.charAt(0).toUpperCase() + hasManyCollectionName.substr(1);
  const hasManySnapshotStartedCollectionName = `snapshotStartedFor${hasManyCollectionNameTitle}`;
  const hasManyLazyCollectionName = `lazy${hasManyCollectionNameTitle}`;
  const hasManyLazyBufferCollectionName = `lazyBuffer${hasManyCollectionNameTitle}`;
  const hasManyLazyCollectionPromise = `promise${hasManyCollectionNameTitle}`;
  const hasManyLazyCollectionResolve = `lazyResolve${hasManyCollectionNameTitle}`;
  const hasManySnapshotPromiseChainName = `promiseChainNameFor${hasManyCollectionNameTitle}`;
  const createFnName = `create${hasManyClassName}`;
  const findAllFnName = `findAll${hasManyCollectionNameTitle}`;
  const findFirstOrCreateFnName = `findFirstOrCreate${hasManyClassName}`;
  const queryOptionsPropertyName = `queryOptionsFor${hasManyClassName}`;

  const methods = {};
  methods[createFnName] = createFn(hasManyClassName, hasManyCollectionName);
  methods[findAllFnName] = findAllFn(hasManyItemName, hasManyCollectionName, options);
  methods[findFirstOrCreateFnName] = findFirstOrCreateFn(hasManyItemName, hasManyCollectionName, findAllFnName, createFnName);

  const computed = {};
  computed[`${hasManyCollectionName}`] = lazyOnSnapshot(hasManyItemName, hasManyCollectionName, hasManyLazyCollectionName, hasManyLazyBufferCollectionName, hasManyLazyCollectionResolve, hasManySnapshotStartedCollectionName, hasManySnapshotPromiseChainName, options);

  return {
    data () {
      return data(hasManyLazyCollectionName);
    },
    beforeCreate () {
      this[queryOptionsPropertyName] = options;
      this.hasManySnapshotPromiseChain = {};
      this[hasManyLazyCollectionPromise] = new Promise(resolve => {
        this[hasManyLazyCollectionResolve] = resolve;
      });
      this[hasManyLazyBufferCollectionName] = [];
      if (this.hasManyCollectionNames === undefined) {
        this.hasManyCollectionNames = [];
      }
      this.hasManyCollectionNames.push(hasManyCollectionName);
    },
    computed,
    methods
  };
};

var hasMany = hasManyForModel;

export { hasMany as default };
