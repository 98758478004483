import debounce from 'lodash.debounce';

const DEBOUNCE_TIME = 2500;

class PersistableUpdate {
  constructor (store, persistable) {
    this.store = store;
    this.persistable = persistable;
    this.docRef = persistable.docRef;
    this.debouncedUpdateFns = {};
  }

  regularUpdate (obj) {
    // console.log(`db.collection("${this.docRef.parent.id}").doc("${this.docRef.id}").update(${JSON.stringify(obj)})`);
    return this.store.dispatch(
      "trackTransaction",
      this.persistable.beforePersistableUpdate(obj).then(beforeObj => {
        return this.persistable.dbFns.updateDoc(this.docRef, beforeObj)
          .catch((e) => {
            const error = new Error("persistable-update::regularUpdate::update: " + e.message);
            error.name = e.name;
            throw error;
          });
      })
        .catch((e) => {
          if (!e.message.match(/^Content validation failed: /)) {
            const error = new Error("persistable-update::beforeUpdate::reject: " + e.message);
            error.name = e.name;
            throw error;
          }
        })
    );
  }

  debouncedUpdate (obj) {
    const keys = Object.keys(obj).sort();
    if (this.debouncedUpdateFns[keys] === undefined) {
      this.debouncedUpdateFns[keys] = debounce((_obj) => {
        this.regularUpdate(_obj);
      }, DEBOUNCE_TIME);
    }
    this.debouncedUpdateFns[keys](obj);
  }

  update (obj) {
    for (const key in obj) {
      this.persistable[key] = obj[key];
    }
    this.store.dispatch(
      "trackTransaction",
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, DEBOUNCE_TIME + 100);
      })
    );
    this.debouncedUpdate(obj);
  }
}

var PersistableUpdate$1 = PersistableUpdate;

export { PersistableUpdate$1 as default };
