import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import UvmComponent from "./uvm-component";

const UvmMonitor = defineReactiveModel({
  extends: Class,
  created () {
    this.isUvmBaseClass = true;
  },
  computed: {
    superclass () {
      return UvmComponent.singleton();
    },
    type () {
      return "uvm_monitor";
    }
  }
});

let singleton;

UvmMonitor.singleton = function () {
  singleton ||= new UvmMonitor();
  return singleton;
};

export default UvmMonitor;
