var stripePaymentMixin = {
  data () {
    return {
      stripePaymentIntent: {},
      stripePaymentMethod: {},
      errorMessage: "",
      cart: {}
    };
  },
  computed: {
    status () {
      if (this.processing) {
        return "Processing";
      } else if (this.failed) {
        return "Failed";
      } else if (this.refunded) {
        return "Refunded";
      } else if (!this.success) {
        return "Error";
      } else if (this.expired) {
        return "Expired";
      } else if (this.active) {
        return "Active";
      } else {
        return "Inactive";
      }
    },
    success () {
      return this.stripePaymentIntent.status === "succeeded";
    },
    failed () {
      return this.stripePaymentIntent.status === "requires_payment_method";
    },
    processing () {
      return this.stripePaymentIntent.status === "processing";
    },
    refunded () {
      // Status is moved to refunded manually in the database
      return this.stripePaymentIntent.status === "refunded";
    }
  }
};

export { stripePaymentMixin as default };
