{{#if allParameters.length}}
#(
{{#each allParameters delimeter=","}}
  {{ type }} {{ name }}={{#if ../isDutLevelDesignElement}}0{{else}}{{ defaultValue }}{{/if}}
{{/each}}
)(
{{else}}
(
{{/if}}
{{#each ports delimeter=","}}
{{#if direction}}
  {{ direction }}{{#if (or (eq direction "input") (eq direction "inout"))}}  {{else}} {{/if}}{{ type }}{{ typeSpacing }}{{ name }}
{{else}}
  {{#if (or ../inputsOnly isInput)}}input{{else if ../inoutsOnly}}inout{{else}}inout{{/if}} {{ type }}{{ typeSpacing }}{{ name }}
{{/if}}
{{/each}}
);
