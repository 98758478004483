import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Policy from "@/models/mixins/policy";
import Constraint from "@/models/constraint";

const UvcSlaveResponseDefaultPreTransferDelayPolicy = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    UvcProtocolRoleGetters,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("policy"),
    Policy
  ],
  data () {
    return {
    };
  },
  created () {
    this.master = false;
  },
  computed: {
    subject () {
      return this.sequenceItem;
    },
    type () {
      const parts = [this.uvc.rootName];
      parts.push(this.protocolRoleWord);
      parts.push("response");
      if (this.isPipelinedRead) {
        parts.push("beat");
      }
      parts.push("default_pre_transfer_delay_policy");
      parts.push("c");
      return parts.join("_");
    },
    computedConstraints () {
      return [
        this.preTransferDelayConstraint
      ]
        .filter(c => c);
    },
    preTransferDelayConstraint () {
      return this.sequenceItem
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.preTransferDelayVariable,
            content: `object.${this.sequenceItem.preTransferDelayVariable.identifier} dist { 0:/65, [1:3]:/34, [4:20]:/1 };`
          }
        })
        : null;
    }
  }
};

export default defineReactiveModel(UvcSlaveResponseDefaultPreTransferDelayPolicy);
