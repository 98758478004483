import Parser from "@/pegjs/system-verilog.pegjs";

const EXPRESSION_UNSET = "expression-unset-73e2f28";

export { EXPRESSION_UNSET };

export default {
  computed: {
    portIdentifier () {
      return this.port ? this.port.identifier : "";
    },
    identifier () {
      if (this.rawExpressionInfo) {
        return this.rawExpressionInfo.identifier;
      } else {
        const match = this.rawExpression.match(/[a-z][a-z0-9_]*/i);
        return match ? match[0] : "";
      }
    },
    unpackedDimensions () {
      return this.rawExpressionInfo ? this.rawExpressionInfo.unpacked.dimensions : [];
    },
    rawExpression () {
      if (this.expressionOverride !== EXPRESSION_UNSET) {
        return this.expressionOverride;
      } else if (this.possibilities.length === 1) {
        if (this.resetHasActiveLowMismatch(this.possibilities[0])) {
          return "~" + this.possibilities[0];
        } else {
          return this.possibilities[0];
        }
      } else if ((this.possibilities.length === 0) && (this.suggestions.length === 1)) {
        if (this.resetHasActiveLowMismatch(this.suggestions[0])) {
          return "~" + this.suggestions[0];
        } else {
          return this.suggestions[0];
        }
      } else {
        return "";
      }
    },
    expression () {
      if (this.unpackedDimensions.length > 0) {
        return `${this.identifier}${this.envUvcInstance.dimensionBrackets(this.envUvcInstance.maxNumDimensions)}`;
      } else {
        return this.rawExpression || "";
      }
    },
    directionIcon () {
      if (this.port) {
        let directionIcon = "mdi-arrow-right-bold";
        if (this.port.isClock || this.port.isReset) {
          if (this.port.isInput) {
            directionIcon = "mdi-arrow-left-bold-outline";
          }
        } else if ((this.envUvcInstance.isMaster && !this.port.isMaster) || (this.envUvcInstance.isSlave && this.port.isMaster)) {
          directionIcon = "mdi-arrow-left-bold-outline";
        }
        return directionIcon;
      } else {
        return "";
      }
    },
    suggestions () {
      if (this.port) {
        if (this.envUvcInstance.isMaster) {
          if (this.port.isClock) {
            if (this.possibilities.length <= 1) {
              if (this.envUvcInstance.suggestedDutClockConnections.length > this.possibilities.length) {
                return this.envUvcInstance.suggestedDutClockConnections;
              } else {
                return this.envUvcInstance.suggestedDutConnections;
              }
            } else {
              return this.possibilities;
            }
          } else if (this.port.isReset) {
            if (this.possibilities.length <= 1) {
              if (this.envUvcInstance.suggestedDutResetConnections.length > this.possibilities.length) {
                return this.envUvcInstance.suggestedDutResetConnections;
              } else {
                return this.envUvcInstance.suggestedDutConnections;
              }
            } else {
              return this.possibilities;
            }
          } else if (this.port.isMaster) {
            if (this.possibilities.length <= 1) {
              return this.envUvcInstance.suggestedDutSlaveConnections;
            } else {
              return this.possibilities;
            }
          } else {
            if (this.possibilities.length <= 1) {
              return this.envUvcInstance.suggestedDutMasterConnections;
            } else {
              return this.possibilities;
            }
          }
        } else {
          if (this.port.isClock) {
            if (this.possibilities.length <= 1) {
              if (this.envUvcInstance.suggestedDutClockConnections.length > this.possibilities.length) {
                return this.envUvcInstance.suggestedDutClockConnections;
              } else {
                return this.envUvcInstance.suggestedDutConnections;
              }
            } else {
              return this.possibilities;
            }
          } else if (this.port.isReset) {
            if (this.possibilities.length <= 1) {
              if (this.envUvcInstance.suggestedDutResetConnections.length > this.possibilities.length) {
                return this.envUvcInstance.suggestedDutResetConnections;
              } else {
                return this.envUvcInstance.suggestedDutConnections;
              }
            } else {
              return this.possibilities;
            }
          } else if (this.port.isMaster) {
            if (this.possibilities.length <= 1) {
              return this.envUvcInstance.suggestedDutMasterConnections;
            } else {
              return this.possibilities;
            }
          } else {
            if (this.possibilities.length <= 1) {
              return this.envUvcInstance.suggestedDutSlaveConnections;
            } else {
              return this.possibilities;
            }
          }
        }
      }
    },
    possibilities () {
      if (this.port) {
        if (this.envUvcInstance.isMaster) {
          if (this.port.isClock) {
            return this.searchForExactMatch(this.envUvcInstance.suggestedDutClockConnections);
          } else if (this.port.isReset) {
            return this.searchForExactMatch(this.envUvcInstance.suggestedDutResetConnections);
          } else if (this.port.isMaster) {
            return this.filterList(this.envUvcInstance.suggestedDutSlaveConnections);
          } else {
            return this.filterList(this.envUvcInstance.suggestedDutMasterConnections);
          }
        } else {
          if (this.port.isClock) {
            return this.searchForExactMatch(this.envUvcInstance.suggestedDutClockConnections);
          } else if (this.port.isReset) {
            return this.searchForExactMatch(this.envUvcInstance.suggestedDutResetConnections);
          } else if (this.port.isMaster) {
            return this.filterList(this.envUvcInstance.suggestedDutMasterConnections);
          } else {
            return this.filterList(this.envUvcInstance.suggestedDutSlaveConnections);
          }
        }
      } else {
        return [];
      }
    },
    rawExpressionInfo () {
      let info = null;
      try {
        info = Parser.parse(this.rawExpression, { startRule: "PortName" });
      } catch (e) {
        // continue regardless of error
      }
      return info;
    }
  },
  methods: {
    searchForExactMatch (connectionNames) {
      const exactMatch = connectionNames.find(connectionName => this.portIdentifier === connectionName);
      return exactMatch ? [exactMatch] : connectionNames;
    },
    resetHasActiveLowMismatch (dutPortName) {
      return this.port.isReset &&
        this.port.isInput &&
        // (this.unpackedDimensions.length === 0) &&
        (
          this.port.activeLow
            ? !this.activeLow(dutPortName)
            : this.activeLow(dutPortName)
        );
    },
    activeLow (portName) {
      return portName.slice(-1) === "n";
    },
    filterList (dutPortNames) {
      const exactMatch = dutPortNames.find(name => name.replace(/_(mst|slv)$/, "") === this.port.identifier);
      return exactMatch
        ? [exactMatch]
        : dutPortNames.filter(name => name.match(new RegExp(this.port.strippedLeadingCharacterIdentifier)));
    }
  }
};
