import { defineReactiveModel, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import RootParameters from "@/models/persistable/mixins/root-parameters";
import Variable from "@/models/variable";
import FieldMacroFlagEnum from "@/utils/field-macro-flag-enum";
import get from "lodash.get";

const UvcParameters = {
  extends: Class,
  mixins: [
    UvcGetters,
    RootParameters,
    belongsTo("uvcPackage")
  ],
  created () {
    this.configDbKey = "params";
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-parameters/set-parameter" },
      { for: ["me"], partial: "uvc-parameters/update-mask" }
    ];
  },
  computed: {
    type () {
      return `${this.uvc.rootName}_parameters_c`;
    },
    computedInstanceVariables () {
      return this.parameterVariables.concat(this.maskVariables);
    },
    parametersSetToConfigDb () {
      return get(this, "interface.parametersSetToConfigDb", []);
    },
    idMsbVariable () {
      return this.parameterVariables
        .find(variable => variable.relations.parameter === get(this, "interface.idMsbParameter"));
    },
    lenMsbVariable () {
      return this.parameterVariables
        .find(variable => variable.relations.parameter === get(this, "interface.lenMsbParameter"));
    },
    maskVariables () {
      return this.msbParameters.map((parameter) => {
        return new Variable({
          propsData: {
            parent: this,
            type: `bit [${parameter.minOrMaxPackageParameter.name}:0]`,
            name: `${parameter.baseName}_mask`,
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT"),
              FieldMacroFlagEnum.getValue("UVM_NOPRINT")
            ]
          }
        });
      });
    },
    msbParameters () {
      return this.parametersSetToConfigDb.filter((parameter) => parameter.isMsb);
    }
  },
  methods: {
    findVariable (parameterIdentifier) {
      return this.parameterVariables
        .find(variable => variable.relations.parameter.identifier === parameterIdentifier);
    }
  }
};

export default defineReactiveModel(UvcParameters);
