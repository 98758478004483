import config from "@/config";
import { fieldMacroFlagOptions } from "@/utils/app-config";
import calculateSpacing from "@/utils/calculate-spacing";
import determineUvmFieldMacro from "@/utils/determine-uvm-field-macro";
import Parser from "@/pegjs/system-verilog.pegjs";
import get from "lodash.get";
import pluralize from "pluralize-esm";

const uvmBinTypes = [
  "bit"
];

const uvmDecTypes = [
  "int", "int unsigned", "integer", "integer unsigned", /* "byte", "byte unsigned", */
  "shortint", "shortint unsigned", "longint", "longint unsigned"
];

// This mixin holds the common properites/methods between persistable and non-persistable variables.
// Required properties of object mixing in:
//   type, name, defaultValue, constraints, fieldMacro, fieldMacroFlagIds
export default {
  props: {
  },
  computed: {
    interface () {
      return this.parent.rootProject.interface;
    },
    relatedPorts () {
      const ports = get(this.interface, "masterAndSlavePorts", []).filter(port => port.sequenceItemVariableNamePlaceholder === this.identifier);
      if ((ports.length === 0) && this.name.match(config.dataRegExp) && this.interface && this.interface.dataPorts.length) {
        return this.interface.dataPorts;
      } else {
        return ports;
      }
    },
    relatedPort () {
      return this.relatedPorts[0];
    },
    isMaster () {
      return this.relatedPort && this.relatedPort.isMaster;
    },
    isSlave () {
      return this.relatedPort && this.relatedPort.isSlave;
    },
    typeInfo () {
      let info = null;
      try {
        info = Parser.parse(this.type, { startRule: "ClassPropertyType" });
      } catch (e) {
        // continue regardless of error
      }
      return info;
    },
    nameInfo () {
      let info = null;
      try {
        info = Parser.parse(this.name, { startRule: "VariableDeclAssignment" });
      } catch (e) {
        // continue regardless of error
      }
      return info;
    },
    rootType () {
      return this.typeInfo && this.typeInfo.rootType;
    },
    identifier () {
      return this.nameInfo && this.nameInfo.identifier;
    },
    isDynamicArray () {
      return this.nameInfo && this.nameInfo.unpacked.dimensions.length && this.nameInfo.unpacked.dimensions[0].type === "dynamicArray";
    },
    isLocalparam () {
      return this.typeInfo && this.typeInfo.isLocalparam;
    },
    isConst () {
      return this.typeInfo && this.typeInfo.isConst;
    },
    isStatic () {
      return this.typeInfo && this.typeInfo.isStatic;
    },
    isRandom () {
      const res = this.typeInfo && (this.typeInfo.isRand || this.typeInfo.isRandc);
      // [TODO] temporary
      if (res && !this.id) {
        throw new Error("Variable", this.identifier, "does not have an id.");
      }
      return res;
    },
    isProtected () {
      return this.typeInfo && this.typeInfo.isProtected;
    },
    defaultCoverpoint () {
      return this.computed ? "" : `${this.identifier} : coverpoint ${this.parent.isCoverage ? "" : "item."}${this.name};`;
    },
    coverpoint () {
      if (this.coverpointOverride === null) {
        return this.parent.isCoverage ? this.defaultCoverpoint : "";
      } else {
        return this.coverpointOverride;
      }
    },
    // [TODO] would need to use a method like determineUvmFieldMacro to figure out convert2string sformatf
    // symbols.
    defaultFieldMacroInfo () {
      if (this.typeInfo && this.nameInfo) {
        return determineUvmFieldMacro(this.typeInfo, this.nameInfo);
      } else {
        return undefined;
      }
    },
    selectedFieldMacroInfo () {
      if (!this.fieldMacro) {
        return this.defaultFieldMacroInfo;
      } else if (this.fieldMacro === "None") {
        return { macro: this.fieldMacro };
      } else {
        const enumMacros = [
          "uvm_field_enum",
          "uvm_field_sarray_enum",
          "uvm_field_array_enum"
        ];
        const aaKeyMacros = [
          "uvm_field_aa_int_key",
          "uvm_field_aa_int_enumkey"
        ];
        const info = {
          macro: this.fieldMacro
        };
        if (this.nameInfo) {
          info.identifier = this.identifier;
        }
        if (enumMacros.includes(this.fieldMacro)) {
          info.arg = "/* Type incompatible with this macro */";
          if (this.typeInfo && this.typeInfo.macroProps && this.typeInfo.macroProps.arg) {
            info.arg = this.typeInfo.macroProps.arg;
          }
        } else if (aaKeyMacros.includes(this.fieldMacro)) {
          info.arg = "/* Variable not an associative array */";
          if (this.nameInfo && this.nameInfo.unpacked.dimensions[0].key) {
            info.arg = this.nameInfo.unpacked.dimensions[0].key.type;
          }
        }
        return info;
      }
    },
    hasFieldMacro () {
      return this.fieldMacroType && (this.fieldMacro !== "None");
    },
    fieldMacroType () {
      if (this.selectedFieldMacroInfo) {
        return this.selectedFieldMacroInfo.macro;
      } else {
        return undefined;
      }
    },
    fieldMacroArgs () {
      if (this.selectedFieldMacroInfo) {
        if (this.selectedFieldMacroInfo.arg) {
          return [this.selectedFieldMacroInfo.arg, this.selectedFieldMacroInfo.identifier];
        } else {
          return [this.selectedFieldMacroInfo.identifier];
        }
      } else {
        return undefined;
      }
    },
    fieldMacroFlags () {
      return this.selectedFieldMacroFlagOptions.map((option) => {
        return option.title;
      });
    },
    selectedFieldMacroFlagOptions () {
      if (this.fieldMacroFlagIds.length === 0) {
        const flags = ["UVM_DEFAULT"];
        if (
          (get(this.typeInfo, "macroProps.type") === "object") &&
          (get(this.nameInfo, "unpacked.dimensions[0].type") !== "queue") &&
          !this.rootType.match(/sequence(_base)?_c$/) &&
          // [TODO] remove this hack when user created typedefs ie. sideband_t are fed to
          // pegjs and no longer identified as objects.
          !this.rootType.match(/_t$/)
        ) {
          if (!this.parent.isPolicy) {
            flags.push("UVM_NOCOMPARE");
            if (!this.rootType.match(/sequencer_c/)) {
              flags.push("UVM_NOPRINT");
            }
          }
          flags.push("UVM_REFERENCE");
        }
        if (this.type === "time") {
          flags.push("UVM_TIME");
        } else if (
          this.typeInfo &&
          (uvmBinTypes.includes(this.typeInfo.rootType)) &&
          (this.typeInfo.packed.numberOfDimensions === 0)
        ) {
          flags.push("UVM_BIN");
        } else if (this.typeInfo && uvmDecTypes.includes(this.typeInfo.rootType)) {
          flags.push("UVM_DEC");
        }
        return flags.map(flag => fieldMacroFlagOptions.find(option => option.title === flag));
      } else {
        return this.fieldMacroFlagIds.map((id) => {
          return fieldMacroFlagOptions.find((options) => {
            return options.value === id;
          });
        });
      }
    },
    typeSpacing () {
      return calculateSpacing({
        propertyLength: this.type.length,
        collectionMaxLength: this.parent.instanceVariableTypeMaxLength
      });
    },
    identifierSpacing () {
      return calculateSpacing({
        propertyLength: this.identifier.length,
        collectionMaxLength: this.parent.instanceVariableNameMaxLength
      });
    },
    hasSetter () {
      return (this.setter === null)
        ? (this.parent.isPolicy && (this.identifier !== "object"))
        : this.setter;
    },
    isDeepSetter () {
      return !!(this.subEnvConfigHandlesWithoutDimensions.length || this.subEnvConfigHandlesWithDimensions.length);
    },
    appendedSetterArgs () {
      return this.appendedSetterArgsOverride.length
        ? this.appendedSetterArgsOverride
        : (this.isDeepSetter ? ["bit deep=0"] : []);
    },
    hasSetterCallRequired () {
      return this.hasSetter && (
        (this.setterCallRequired === null)
          ? this.computedHasSetterCallRequired
          : this.setterCallRequired
      );
    },
    computedHasSetterCallRequired () {
      if (this.unsetValueOverride !== "") {
        return true;
      } else {
        const type = get(this.typeInfo, "macroProps.type");
        return (
          (this.defaultValue === "") &&
          (
            (type === "object") || (type === "string")
          )
        );
      }
    },
    setterName () {
      return `set_${this.identifier}`;
    },
    setterNameInSubenvs () {
      return `${this.setterName}_in_subenvs`;
    },
    hasGetter () {
      return this.getter;
    },
    getterName () {
      return `${this.getWord}_${this.identifier}`;
    },
    doName () {
      return `do_${this.singularIdentifier}`;
    },
    shortName () {
      if (this.shortHandleName) {
        return this.shortHandleName;
      } else if (this.klass) {
        // if (this.klass.isSequenceItem) {
        //   return "item";
        if (this.klass.isVirtualSequence) {
          return "vseq";
        } else if (this.klass.isSequence) {
          return "seq";
        } else if (this.klass.isEnvConfig) {
          return "env_cfg";
        }
      }
      return this.singularIdentifier.replace(/.*_/, "");
    },
    singularIdentifier () {
      return this.identifier
        ? pluralize.singular(this.identifier)
        : "";
    },
    constraintName () {
      return `${this.identifier}_crv`;
    },
    createRandomFunctionName () {
      return `create_random_${this.singularIdentifier}`;
    },
    hasConstraintRequired () {
      return this.constraintRequired;
    },
    unsetValue () {
      if (this.unsetValueOverride) {
        return this.unsetValueOverride;
      } else {
        const type = get(this.typeInfo, "macroProps.type");
        if (type === "object") {
          return "null";
        } else if (type === "string") {
          return "\"\"";
        } else {
          return "0";
        }
      }
    },
    convert2string () {
      return (this.convert2stringOverride === null)
        ? true
        : this.convert2stringOverride;
    }
  }
};
