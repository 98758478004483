import createSelectOptions from "@/utils/create-select-options";
import FieldMacroFlagEnum from "@/utils/field-macro-flag-enum";

const spacePadding = 4;
const maxColumnWidth = 35;
const fieldMacroFlagOptions = createSelectOptions(FieldMacroFlagEnum.getValueKeyedObject());
const uvmFieldMacros = [
  "None",
  "uvm_field_int",
  "uvm_field_object",
  "uvm_field_string",
  "uvm_field_enum", // T
  "uvm_field_real",
  "uvm_field_event",
  "uvm_field_sarray_int",
  "uvm_field_sarray_object",
  "uvm_field_sarray_string",
  "uvm_field_sarray_enum", // T
  "uvm_field_array_int",
  "uvm_field_array_object",
  "uvm_field_array_string",
  "uvm_field_array_enum", // T
  "uvm_field_queue_int",
  "uvm_field_queue_object",
  "uvm_field_queue_string",
  "uvm_field_queue_enum", // T
  "uvm_field_aa_int_string",
  "uvm_field_aa_object_string",
  "uvm_field_aa_string_string",
  "uvm_field_aa_object_int",
  "uvm_field_aa_int_int",
  "uvm_field_aa_int_int_unsigned",
  "uvm_field_aa_int_integer",
  "uvm_field_aa_int_integer_unsigned",
  "uvm_field_aa_int_byte",
  "uvm_field_aa_int_byte_unsigned",
  "uvm_field_aa_int_shortint",
  "uvm_field_aa_int_shortint_unsigned",
  "uvm_field_aa_int_longint",
  "uvm_field_aa_int_longint_unsigned",
  "uvm_field_aa_int_key", // KEY
  "uvm_field_aa_int_enumkey" // KEY
];

export { spacePadding };
export { maxColumnWidth };
export { fieldMacroFlagOptions };
export { uvmFieldMacros };
