import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Model from "@/models/model";
import PortConnectionMixin, { EXPRESSION_UNSET } from "@/models/mixins/port-connection";
import get from "lodash.get";

const PortConnection = {
  extends: Model,
  mixins: [
    Persistable,
    belongsTo("envUvcInstance"),
    PortConnectionMixin
  ],
  data () {
    return {
      portId: "",
      expressionOverride: EXPRESSION_UNSET
    };
  },
  created () {
    this.prereqPromises = [
      new Promise(resolve => {
        this.portResolve = resolve;
      })
    ];

    const unwatch = this.$watch("port", (port) => {
      if (port) {
        this.portResolve(port);
        this.$nextTick(() => { unwatch(); });
      }
    }, { immediate: true });
  },
  computed: {
    port () {
      return get(this.envUvcInstance, "interface.portsById", {})[this.portId];
    }
  }
};

export default defineReactiveModel(PortConnection);
