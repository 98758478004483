import { defineReactiveModel } from "vue-app-utils";
import UvcAgent from "@/models/persistable/uvc/uvc-agent";

const UvcSlaveAgent = {
  extends: UvcAgent,
  created () {
    this.master = false;
  }
};

export default defineReactiveModel(UvcSlaveAgent);
