var emailMixin = {
  data () {
    return {
      to: [],
      from: "",
      replyTo: "",
      delivery: {},
      message: {}
    };
  },
  computed: {
    subject () {
      return this.message.subject;
    },
    text () {
      return this.message.text;
    },
    state () {
      return this.delivery.state;
    }
  }
};

export { emailMixin as default };
