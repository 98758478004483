import parseSystemVerilog from "@/utils/parse-system-verilog";

export default {
  data () {
    return {
      type: "",
      parameters: [],
      ports: [],
      errorMessage: ""
    };
  },
  watch: {
    content: {
      handler: "validateDutContent",
      immediate: true
    }
  },
  computed: {
    hasParameters () {
      return !!this.parameters.length;
    },
    typeUpperCase () {
      return this.type.toUpperCase();
    }
  },
  methods: {
    // [TODO] need to debounce validation of dut content a bit because when you type dut it takes a long time to show input, low priority since env in web is not a thing.
    validateDutContent (value) {
      if (value) {
        try {
          const validatedInfo = this.parseDutContent(value);
          this.type = validatedInfo.type || "";
          this.parameters = validatedInfo.parameterDeclarations || [];
          this.ports = validatedInfo.ports || [];
          if (!this.type) {
            throw new Error("Could not determine module identifier.");
          }
          if (this.ports.length === 0) {
            throw new Error("Could not determine module ports.");
          }
          this.errorMessage = "";
        } catch (e) {
          this.errorMessage = e.message;
        }
        if (this.errorMessage) {
          return this.errorMessage;
        } else {
          return true;
        }
      } else {
        this.type = "";
        this.parameters = [];
        this.ports = [];
        this.errorMessage = "";
        return true;
      }
    },
    parseDutContent (value) {
      return parseSystemVerilog(value, "FirstModuleDeclaration");
    }
  }
};
