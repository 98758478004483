virtual function void build_phase(uvm_phase phase);
  {{ abstractBfmCreatorHandle.type }} {{ abstractBfmCreatorHandle.identifier }};

  {{> (partial "snippets/check-instance-variables-set")}}

  if ({{ configHandle.identifier }}.{{ configHandle.klass.interfaceBoundVariable.getterName }}()) begin
{{#with abstractBfmCreatorHandle}}
    {{> (partial "snippets/get-from-config-db") context=. useInstName=(not ../isComponent)}}
{{/with}}
{{#with interface.printContextHandle}}
    {{ ../abstractBfmCreatorHandle.identifier }}.{{ setterName }}(this);
{{/with}}
  end

  if ({{ configHandle.identifier }}.has_driver()) begin
  {{#each requestDataAndResponseHandles delimeter="\n"}}
    {{ sequencerHandle.identifier }} = {{ sequencerHandle.type }}::type_id::create("{{ sequencerHandle.identifier }}", this);
    {{ sequencerHandle.identifier }}.{{ ../configHandle.setterName }}({{ ../configHandle.identifier }});
  {{#if hasPrioritySequencer}}
    {{ sequencerHandle.identifier }}.set_arbitration(UVM_SEQ_ARB_STRICT_FIFO);
  {{/if}}
    {{ driverHandle.identifier }} = {{ driverHandle.type }}::type_id::create("{{ driverHandle.identifier }}", this);
    {{ driverHandle.identifier }}.{{ ../configHandle.setterName }}({{ ../configHandle.identifier }});
    {{ driverHandle.identifier }}.{{ ../abstractBfmCreatorHandle.setterName }}({{ ../abstractBfmCreatorHandle.identifier }});
  {{/each}}
  end
{{#if hasMonitor}}

  if ({{ configHandle.identifier }}.has_monitor()) begin
    {{ monitorHandle.identifier }} = {{ monitorHandle.type }}::type_id::create("{{ monitorHandle.identifier }}", this);
    {{ monitorHandle.identifier }}.{{ configHandle.setterName }}({{ configHandle.identifier }});
    {{ monitorHandle.identifier }}.{{ abstractBfmCreatorHandle.setterName }}({{ abstractBfmCreatorHandle.identifier }});
    {{ analysisPortHandle.identifier }} = new("{{ analysisPortHandle.identifier }}", this);
  end
{{/if}}
{{#if hasUvcCoverage}}

  if ({{ configHandle.identifier }}.has_coverage()) begin
  {{#with coverageHandle}}
    {{ identifier }} = {{ type }}::type_id::create("{{ identifier }}", this);
    {{ identifier }}.{{ klass.configHandle.setterName }}({{ ../configHandle.identifier }});
  {{/with}}
  end
{{/if}}
{{#if hasProtocolChecker}}

  if ({{ configHandle.identifier }}.{{ configHandle.klass.protocolCheckerBoundVariable.getterName }}()) begin
{{#with abstractProtocolCheckerAccessorHandle}}
    {{ type }} {{ identifier }};

    {{> (partial "snippets/get-from-config-db") context=. isComponent=../isComponent}}
{{/with}}
    {{ abstractProtocolCheckerAccessorHandle.identifier }}.{{ protocolChecker.printContextHandle.setterName }}(this);
    {{ abstractProtocolCheckerAccessorHandle.identifier }}.update_variables({{ configHandle.identifier }});
  end
{{/if}}
endfunction
