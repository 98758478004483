import Modernizr from '../utils/modernizr.js';

// From https://dev.to/drbragg/handling-service-worker-updates-in-your-vue-pwa-1pip
var swUpdateMixin = {
  data () {
    return {
      refreshing: false,
      updateFoundOnInitialLoad: true
    };
  },

  created () {
    if (Modernizr.serviceworker) {
      // Listen for our custom event from register-service-worker.js
      document.addEventListener("swupdated", this.updateAvailable, { once: true });

      navigator.serviceWorker.addEventListener("controllerchange", () => {
        // Prevent multiple refreshes
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });

      setTimeout(() => {
        this.updateFoundOnInitialLoad = false;
      }, 10 * 1000);
    }
  },

  methods: {
    updateAvailable (event) {
      const registration = event.detail;
      this.$store.commit("pushSnackbarMessageProps", {
        message: "New app version available.",
        action: (this.updateFoundOnInitialLoad ? "UPDATING" : "UPDATE"),
        actionFn: () => {
          this.refreshApp(registration);
        },
        secondsBeforeActionIsForced: (this.updateFoundOnInitialLoad ? 3 : (3 * 60)),
        secondsOfCountdownToShow: (this.updateFoundOnInitialLoad ? 3 : 60)
      });
    },

    refreshApp (registration) {
      // Make sure we only send a "skip waiting" message if the SW is waiting
      if (!registration || !registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  }
};

export { swUpdateMixin as default };
