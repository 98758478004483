import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Model from "@/models/model";
import ParameterAssignmentMixin, { EXPRESSION_UNSET } from "@/models/mixins/parameter-assignment";
import get from "lodash.get";

const ParameterAssignment = {
  extends: Model,
  mixins: [
    Persistable,
    belongsTo("envUvcInstance"),
    ParameterAssignmentMixin
  ],
  data () {
    return {
      parameterId: "",
      expressionOverride: EXPRESSION_UNSET
    };
  },
  created () {
    this.prereqPromises = [
      new Promise(resolve => {
        this.parameterResolve = resolve;
      })
    ];

    const unwatch = this.$watch("parameter", (parameter) => {
      if (parameter) {
        this.parameterResolve(parameter);
        this.$nextTick(() => { unwatch(); });
      }
    }, { immediate: true });
  },
  computed: {
    parameter () {
      return get(this.envUvcInstance, "interface.allParametersById", {})[this.parameterId];
    }
  }
};

export default defineReactiveModel(ParameterAssignment);
