virtual task receive_requests();
  bit killed_by_reset;

  forever begin
    {{> (partial "uvc-virtual-sequence/granted-request") context=. waitForGrantMethodName="wait_for_request_grant" retireGrantMethodName="retire_request_grant" writeItemMethodName="write_request_item" requestOrDataSequenceHandle=requestSequenceHandle}}
  end
{{#if isPipelinedWrite}}

  foreach ({{ dataItemsHandle.identifier }}[id]) begin
    {{ configHandle.identifier }}.retire_data_grant();
  end
{{/if}}
endtask
