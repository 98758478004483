// import Handle from "@/models/handle";
// import store from "@/store";

export default {
  computed: {
    isMaster () {
      return !!this.master;
    },
    isSlave () {
      return !this.isMaster;
    },
    isRequest () {
      return this.channel === "request";
    },
    isData () {
      return this.channel === "data";
    },
    isResponse () {
      return this.channel === "response";
    },
    protocolRoleWord () {
      return this.isMaster
        ? (this.uvc.user.setting.masterWord || "")
        : (this.uvc.user.setting.slaveWord || "");
    },
    protocolRoleWordOpposite () {
      return !this.isMaster
        ? (this.uvc.user.setting.masterWord || "")
        : (this.uvc.user.setting.slaveWord || "");
    }
  }
};
