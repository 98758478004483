import hasMany from './db/relations/has-many.js';
import hasOne from './db/relations/has-one.js';

var stripeUserMixin = {
  mixins: [
    hasOne("cart"),
    hasMany("paymentMethods", {
      orderBy: ["createdAt"]
    }),
    hasMany("payments", {
      orderBy: ["createdAt"]
    })
  ],
  computed: {
    allCarts () {
      return [this.cart].filter(c => c);
    }
  }
};

export { stripeUserMixin as default };
