{{#if interface.hasResetAndOtherPorts}}
virtual task initialize_on_reset();
  forever begin
    wait_for_reset();
  {{#if isPipelined}}
    initialize_state();
  {{/if}}
    initialize_signals();
    wait_for_reset_to_deassert();
  end
endtask
{{/if}}
