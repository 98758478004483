`ifndef {{ compileGuard }}
`define {{ compileGuard }}

// -----------------------------
//  VERIFICATION DISABLE MACROS
// -----------------------------
//
// All verification capabilities are enabled by default. The below sections
// describe how to disable them.
//
//
// Global Disables
// ----------------
//
{{#if env.hasEnvInstances}}
// Protocol checkers for top-level interfaces and sub-block interfaces can be
// disabled with the following macro.
{{else}}
// Protocol checkers can be disabled with the following macro.
{{/if}}
//
//   +define+{{ disableAllProtocolCheckersMacroName }}
//
{{#if hasSignalChecker}}
{{#if env.hasEnvInstances}}
// Cross-interface assertions for top-level interfaces and sub-block interfaces
// can be disabled with the following macro.
{{else}}
// Cross-interface assertions can be disabled with the following macro.
{{/if}}
//
//   +define+{{ disableAllSignalCheckersMacroName }}
//
{{/if}}
//
// Top-Level Disables
// -------------------
//
// To disable Protocol Checkers for only top-level interfaces, define the
// following macro.
//
//   +define+{{ envInstance.disableDutProtocolCheckersMacroName }}
//
{{#if hasSignalChecker}}
// Similarly, to disable only top-level DUT assertions, define this macro.
//
//   +define+{{ envInstance.disableDutSignalCheckerMacroName }}
//
{{/if}}
// Note: Functional verification for the DUTs top-level can not be disabled via
// macros. However, specific functional verification services can be disabled via
// the environment configuration object.
//
//
{{#if env.hasEnvInstances}}
// Sub-Block Disables
// -------------------
//
// To disable sub-block verification, define the following macro. This will
// disable functional verification (scoreboards, coverage, etc), protocol
// checkers and environment level assertions for all sub-blocks. Top-level
// verification is unaffected.
//
//   +define+{{ disableVerificationOfSubBlocksMacroName }}
//
//
{{#if env.hasProtocolCheckersInSubEnvs}}
// Protocol checkers for sub-block interfaces can be disabled with the following
// macro. Top-level verification is unaffected.
//
//   +define+{{ disableProtocolCheckersInSubBlocksMacroName }}
//
{{/if}}
{{#if env.hasSignalCheckersInSubEnvs}}
// Cross-interface assertions for sub-block interfaces can be disabled with the
// following macro. Top-level verification is unaffected.
//
//   +define+{{ disableSignalCheckersInSubBlocksMacroName }}
//
{{/if}}
// Functional verification of sub-blocks can be disabled with the following
// macro. Top-level verification is unaffected.
//
//   +define+{{ disableFunctionalVerificationOfSubBlocksMacroName }}
//
//
// Specific Sub-Block Disables/Enables
// ------------------------------------
//
{{#if env.hasProtocolCheckersInSubEnvs}}
// To selectively disable Protocol Checkers for a specific sub-block, use the
// desired sub-block disable macro.
//
{{#each envInstance.envInstanceHierarchicalPropertiesNoTop}}
{{#if envInstance.hasProtocolCheckerHarness}}
//   +define+{{ envInstance.disableDutProtocolCheckersMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
{{/if}}
{{/each}}
//
// Alternatively, after disabling Protocol Checkers for sub-blocks, reenable
// protocol checking for specific sub-blocks using the desired enable macro.
//
//   +define+{{ disableProtocolCheckersInSubBlocksMacroName }}
{{#each envInstance.envInstanceHierarchicalPropertiesNoTop}}
{{#if envInstance.hasProtocolCheckerHarness}}
//   +define+{{ envInstance.enableDutProtocolCheckersMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
{{/if}}
{{/each}}
//
//
{{/if}}
{{#if env.hasSignalCheckersInSubEnvs}}
// To selectively disable a Signal Checker for a specific sub-block, use the
// desired sub-block disable macro.
//
{{#each envInstance.envInstanceHierarchicalPropertiesNoTop}}
{{#if envInstance.hasSignalChecker}}
//   +define+{{ envInstance.disableDutSignalCheckerMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
{{/if}}
{{/each}}
//
// Alternatively, after disabling Signal Checkers for sub-blocks, reenable
// signal checking for specific sub-blocks using the desired enable macro.
//
//   +define+{{ disableSignalCheckersInSubBlocksMacroName }}
{{#each envInstance.envInstanceHierarchicalPropertiesNoTop}}
{{#if envInstance.hasSignalChecker}}
//   +define+{{ envInstance.enableDutSignalCheckerMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
{{/if}}
{{/each}}
//
//
{{/if}}
// To selectively disable Functional Verification for a specific sub-block, use
// the desired sub-block disable macro.
//
{{#each envInstance.envInstanceHierarchicalPropertiesNoTop}}
//   +define+{{ envInstance.disableDutFunctionalVerificationMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
{{/each}}
//
// Alternatively, after disabling Functional Verification for sub-blocks,
// reenable protocol checking for specific sub-blocks using the desired enable
// macro.
//
//   +define+{{ disableFunctionalVerificationOfSubBlocksMacroName }}
{{#each envInstance.envInstanceHierarchicalPropertiesNoTop}}
//   +define+{{ envInstance.enableDutFunctionalVerificationMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
{{/each}}
//
{{/if}}

{{#if env.hasEnvInstances}}
`ifdef {{ disableVerificationOfSubBlocksMacroName }}
{{#if env.hasProtocolCheckersInSubEnvs}}
  `define {{ disableProtocolCheckersInSubBlocksMacroName }}
{{/if}}
{{#if env.hasSignalCheckersInSubEnvs}}
  `define {{ disableSignalCheckersInSubBlocksMacroName }}
{{/if}}
  `define {{ disableFunctionalVerificationOfSubBlocksMacroName }}
`endif

{{/if}}
`ifndef {{ disableAllProtocolCheckersMacroName }}
  {{#if env.hasProtocolCheckersInSubEnvs}}
  `ifndef {{ disableProtocolCheckersInSubBlocksMacroName }}
    {{#each envInstance.envInstanceHierarchicalPropertiesNoTop}}
    {{#if envInstance.hasProtocolCheckerHarness}}
    `ifndef {{ envInstance.disableDutProtocolCheckersMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
      `define {{ envInstance.enableDutProtocolCheckersMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
    `endif
    {{/if}}
    {{/each}}
  `endif

  {{/if}}
  `ifndef {{ envInstance.disableDutProtocolCheckersMacroName }}
    `define {{ envInstance.enableDutProtocolCheckersMacroName }}
  `endif
`endif

{{#if hasSignalChecker}}
`ifndef {{ disableAllSignalCheckersMacroName }}
  {{#if env.hasSignalCheckersInSubEnvs}}
  `ifndef {{ disableSignalCheckersInSubBlocksMacroName }}
    {{#each envInstance.envInstanceHierarchicalPropertiesNoTop}}
    {{#if envInstance.hasSignalChecker}}
    `ifndef {{ envInstance.disableDutSignalCheckerMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
      `define {{ envInstance.enableDutSignalCheckerMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
    `endif
    {{/if}}
    {{/each}}
  `endif

  {{/if}}
  `ifndef {{ envInstance.disableDutSignalCheckerMacroName }}
    `define {{ envInstance.enableDutSignalCheckerMacroName }}
  `endif
`endif

{{/if}}
{{#if env.hasEnvInstances}}
`ifndef {{ disableFunctionalVerificationOfSubBlocksMacroName }}
  {{#each envInstance.envInstanceHierarchicalPropertiesNoTop}}
  `ifndef {{ envInstance.disableDutFunctionalVerificationMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
    `define {{ envInstance.enableDutFunctionalVerificationMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
  `endif
  {{/each}}
`endif

{{/if}}

module tb_top;

  timeunit {{or timeUnit user.setting.defaultTimeUnit}} / {{or timePrecision user.setting.defaultTimePrecision}};

  import uvm_pkg::*;
  `ifdef {{ tb.testParameterPackageMacroName }}
    import `{{ tb.testParameterPackageMacroName }}::*;
  `endif
  import {{ package.type }}::*;

  `include "{{ bind.fileName}}"

{{#with env}}
  {{ dut.type }} #(
    `ifdef {{ ../tb.testParameterPackageMacroName }}
    {{#each dut.parameters delimeter=","}}
    {{#unless isLocalparam}}
      .{{ data.name }} ({{ data.name }})
    {{/unless}}
    {{/each}}
    `endif
  )
    {{ name }} (
    {{#each ../ports delimeter=","}}
      .{{ identifier }}{{ identifierSpacing }}()
    {{/each}}
    );
{{/with}}

{{#if isEdaPlayground}}
  initial begin
    $dumpfile("test.vcd");
    $dumpvars(1, {{ env.dut.type }});
  end

{{/if}}
  initial begin : RUN_TEST
    run_test();
  end

  const string TOP_ENV_FULL_NAME = "uvm_test_top.{{ testBase.envHandle.identifier }}";
{{#each envInstance.generateGroups delimeter="\n\n"}}
  {{> partials/snippets/testbench-resources env=../env dimensionPropertiesList=dimensionPropertiesList blockNamePrefix=blockNamePrefixes.resources envInstanceHierarchicalProperties=envInstanceHierarchicalProperties}}
{{/each}}

endmodule

`endif
