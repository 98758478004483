virtual task respond({{ sequenceItemHandle.type }} {{ sequenceItemHandle.identifier }});
{{#if isPipelinedRead}}
  foreach ({{ sequenceItemHandle.identifier }}.{{ interface.firstDynamicArrayVariable.identifier }}[d]) begin
    wait_for_num_clocks({{ sequenceItemHandle.identifier }}.{{ sequenceItem.preTransferDelayVariable.identifier }});

    {{> (partial "snippets/bfm-drive-slave-ports-todo")}}

    // do begin
    //   @{{ interface.slaveClockingBlock.name }};
    // end while (/* beat invalid */);

    {{> (partial "snippets/bfm-capture-master-ports-todo")}}

    initialize_signals();
  end
{{else}}
  wait_for_num_clocks({{ sequenceItemHandle.identifier }}.{{ sequenceItem.preTransferDelayVariable.identifier }});

  {{> (partial "snippets/bfm-drive-slave-ports-todo")}}

  // do begin
  //   @{{ interface.slaveClockingBlock.name }};
  // end while (/* response invalid */);

  {{#if isPipelinedWrite}}
  {{> (partial "snippets/bfm-capture-master-ports-todo")}}

  {{/if}}
  initialize_signals();
{{/if}}
endtask
