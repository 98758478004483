virtual task receive_data();
  forever begin
    {{ sequenceItemHandle.type }} {{ sequenceItemHandle.identifier }};

    // do begin
    //   @{{ interface.monitorClockingBlock.name }};
    // end while (/* beat invalid */);

    {{ sequenceItemHandle.identifier }} = get_transaction(/* id */);

    {{> (partial "snippets/bfm-capture-master-data-ports-todo")}}

    {{ sequenceItemHandle.identifier }}.{{ sequenceItemHandle.klass.beatIndexVariable.identifier }}++;

    // if ({{ sequenceItemHandle.identifier }}.{{ sequenceItemHandle.klass.lastVariable.identifier }}) begin
    //   {{ sequenceItemHandle.identifier }}.{{ sequenceItemHandle.klass.lenVariable.identifier }} = {{ sequenceItemHandle.identifier }}.{{ sequenceItemHandle.klass.beatIndexVariable.identifier }};
    //   {{ sequenceItemHandle.identifier }}.data = new[{{ sequenceItemHandle.identifier }}.{{ sequenceItemHandle.klass.lenVariable.identifier }}]({{ sequenceItemHandle.identifier }}.data);
    //   {{ abstractBfm.monHandle.name }}.broadcast_item({{ sequenceItemHandle.identifier }});
    //   drop_transaction({{ sequenceItemHandle.identifier }});
    //   write_data_item({{ sequenceItemHandle.identifier }});
    // end
  end
endtask
