import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import UvmSequenceBase from "./uvm-sequence-base";
import Overridable from "@/models/persistable/mixins/overridable";
import Handle from "@/models/handle";

const UvmSequence = defineReactiveModel({
  extends: Class,
  mixins: [
    Overridable("uvm-sequence")
  ],
  props: {
    sequenceItem: {
      type: Object,
      required: true
    }
  },
  created () {
    this.isUvmBaseClass = true;
    this.partialInheritance = [
      { for: ["subclasses"], partial: "uvm-sequence/body" },
      { for: ["subclasses"], partial: "uvm-sequence/start-sequences" },
      { for: ["subclasses"], partial: "create-random-object-functions" },
      { for: ["subclasses"], partial: "do-functions" }
    ];
    this.user = this.sequenceItem.user;
    this.id = `uvm-sequence-id-${this.sequenceItem.id}`;
    this.uniqueId = `uvm-sequence-id-${this.sequenceItem.uniqueId}`;
  },
  computed: {
    isSequence () {
      return true;
    },
    isVirtualSequence () {
      return this.sequenceItem.type === "uvm_sequence_item";
    },
    iterationCharacter () {
      return this.isVirtualSequence ? "v" : "s";
    },
    superclass () {
      return UvmSequenceBase.singleton();
    },
    rootType () {
      return "uvm_sequence";
    },
    type () {
      return `${this.rootType}#(${this.sequenceItem.type})`;
    },
    rootProject () {
      return this.sequenceItem.rootProject;
    },
    project () {
      return this.sequenceItem.project;
    },
    // computedInstanceVariables () {
    //   return [
    //     this.reqHandle
    //   ]
    //     .filter(o => o);
    // },
    reqHandle () {
      return new Handle({
        propsData: {
          id: "static-id-a73fcdb",
          parent: this,
          klass: this.sequenceItem,
          nameOverride: "req"
          // setter: true,
          // convert2stringOverride: false
        }
      });
    }
  }
});

const singletons = {};

UvmSequence.singleton = function (sequenceItem) {
  if (!sequenceItem) { return null; }

  if (singletons[sequenceItem.uniqueId] === undefined) {
    singletons[sequenceItem.uniqueId] = new UvmSequence({
      propsData: {
        sequenceItem
      }
    });
  }

  return singletons[sequenceItem.uniqueId];
};

export default UvmSequence;
