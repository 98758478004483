virtual task drive_ready();
  forever begin
    wait ({{ bfmBase.numActiveTransactionsVariable.identifier }} > 0);

    fork begin
      fork
        forever begin
          {{> (partial "snippets/bfm-drive-master-ports-todo")}}

          // do begin
          //   @{{ interface.masterClockingBlock.name }};
          // end while (/* beat invalid */);
        end
        wait ({{ bfmBase.numActiveTransactionsVariable.identifier }} == 0);
      join_any

      disable fork;
    end join

    initialize_signals();
  end
endtask
