const typeMap = {
  staticArray: "sarray",
  dynamicArray: "array",
  queue: "queue",
  associativeArray: "aa",
  object: "object"
};
const intTypeKeys = [
  "int", "int_unsigned", "integer", "integer_unsigned", "byte", "byte_unsigned",
  "shortint", "shortint_unsigned", "longint", "longint_unsigned"
];

export default function (typeInfo, nameInfo) {
  const hasDimensions = !!nameInfo.unpacked.dimensions.length;
  const hasKey = (hasDimensions && nameInfo.unpacked.dimensions[0].key);
  const macroType = typeInfo.macroProps.type;
  const macroArgType = typeInfo.macroProps.arg || null;
  const macroCollectionType = hasDimensions ? typeMap[nameInfo.unpacked.dimensions[0].type] : null;
  const macroAaKeyType = hasKey && nameInfo.unpacked.dimensions[0].key.macroProps.type;
  const macroAaKeyIntType = hasKey && nameInfo.unpacked.dimensions[0].key.macroProps.intType;
  const identifier = nameInfo.identifier;
  const oneDimensional = nameInfo.unpacked.dimensions.length === 1;

  if (macroType) {
    if (!macroCollectionType) {
      let macro = `uvm_field_${macroType}`;
      if ((macroType === "object") && typeInfo.rootType.match(/_t$/)) {
        macro = "uvm_field_int";
      }
      return {
        macro,
        arg: macroArgType,
        identifier
      };
    } else if (["sarray", "array", "queue"].includes(macroCollectionType)) {
      if ((macroCollectionType === "queue") || oneDimensional) {
        if (["int", "object", "string", "enum"].includes(macroType)) {
          return {
            macro: `uvm_field_${macroCollectionType}_${macroType}`,
            arg: macroArgType,
            identifier
          };
        }
      }
    } else if (macroCollectionType === "aa") {
      if (macroAaKeyType === "string") {
        if (["int", "object", "string"].includes(macroType)) {
          return {
            macro: `uvm_field_aa_${macroType}_string`,
            arg: null,
            identifier
          };
        }
      } else if (macroAaKeyType === "int") {
        if (macroType === "object") {
          if (macroAaKeyIntType === "int") {
            return {
              macro: `uvm_field_aa_object_${macroAaKeyIntType}`,
              arg: null,
              identifier
            };
          }
        } else if (macroType === "int") {
          if (intTypeKeys.includes(macroAaKeyIntType)) {
            return {
              macro: `uvm_field_aa_int_${macroAaKeyIntType}`,
              arg: null,
              identifier
            };
          } else if (macroAaKeyIntType === "key") {
            return {
              macro: "uvm_field_aa_int_key",
              arg: nameInfo.unpacked.dimensions[0].key.type,
              identifier
            };
          } else if (macroAaKeyIntType === "enum") {
            return {
              macro: "uvm_field_aa_int_enumkey",
              arg: nameInfo.unpacked.dimensions[0].key.type,
              identifier
            };
          }
        }
      }
    }
  }
}
