virtual task body();
  super.body();

  {{ refItemHandle.createRandomFunctionName }}();

  fork
    drive_request();
    drive_data();
  join
endtask
