export default function (strings) {
  return strings.sort((x, y) => {
    if (y.length < x.length) {
      return -1;
    } else if (x.length < y.length) {
      return 1;
    } else {
      return 0;
    }
  });
}
