import { hasMany } from "vue-app-utils";
import hasFormClass from "@/models/persistable/mixins/has-form-class";
import HasUserSubclasses from "@/models/persistable/mixins/has-user-subclasses";
import HasUserPolicies from "@/models/persistable/mixins/has-user-policies";
import HasUserIncludes from "@/models/persistable/mixins/has-user-includes";

export default function (type) {
  if (type === "uvm-sequence") {
    return {
      mixins: [
        hasFormClass("subclasses")
        // hasFormClass("policies"),
        // HasUserSubclasses,
        // HasUserPolicies
      ]
    };
  } else if (type === "sequence-item-base") {
    return {
      mixins: [
        hasMany("instanceVariables", {
          orderBy: ["createdAt"]
        }),
        hasMany("constraints"),
        hasMany("subroutines", {
          orderBy: ["createdAt"]
        }),
        // hasFormClass("subclasses"), // Use policies instead of subclassing
        hasFormClass("policies"),
        // HasUserSubclasses,
        HasUserPolicies
      ]
    };
  } else if (type === "sequence-item") {
    return {
      mixins: [
        hasMany("instanceVariables", {
          orderBy: ["createdAt"]
        }),
        hasMany("constraints"),
        // hasMany("subroutines", { // Wait for a need
        //   orderBy: ["createdAt"]
        // })
        // hasFormClass("subclasses"), // Always use mixins/policies.
        hasFormClass("policies"),
        // HasUserSubclasses, // Always use mixins/policies.
        HasUserPolicies
      ]
    };
  } else if (type === "sequence") {
    return {
      mixins: [
        hasMany("instanceVariables", {
          orderBy: ["createdAt"]
        }),
        // hasMany("constraints"),
        hasMany("subroutines", {
          orderBy: ["createdAt"]
        }),
        hasFormClass("subclasses"),
        hasFormClass("policies"),
        HasUserSubclasses,
        HasUserPolicies
      ]
    };
  } else if (type === "virtual-sequence") {
    return {
      mixins: [
        hasMany("instanceVariables", {
          orderBy: ["createdAt"]
        }),
        // hasMany("constraints"),
        hasMany("subroutines", {
          orderBy: ["createdAt"]
        }),
        hasFormClass("subclasses"),
        hasFormClass("policies"),
        HasUserSubclasses,
        HasUserPolicies
      ]
    };
  } else if (type === "config") { // uvcConfig, envConfig
    return {
      mixins: [
        hasMany("instanceVariables", {
          orderBy: ["createdAt"]
        }),
        hasMany("constraints"),
        hasMany("subroutines", {
          orderBy: ["createdAt"]
        }),
        hasFormClass("subclasses"),
        hasFormClass("policies"),
        HasUserSubclasses,
        HasUserPolicies
      ]
    };
  } else if (type === "object") { // uvcParameters
    return {
      mixins: [
        hasMany("instanceVariables", {
          orderBy: ["createdAt"]
        }),
        // hasMany("constraints"),
        hasMany("subroutines", {
          orderBy: ["createdAt"]
        }),
        hasFormClass("subclasses"),
        // hasFormClass("policies"), // Not currently supporting policies when instantiation happens in component.
        HasUserSubclasses
        // HasUserPolicies
      ]
    };
  } else if (type === "test") {
    return {
      mixins: [
        hasMany("instanceVariables", { // Wait for a need
          orderBy: ["createdAt"]
        }),
        // hasMany("constraints"),
        hasMany("subroutines", {
          orderBy: ["createdAt"]
        }),
        hasFormClass("subclasses"),
        // hasFormClass("policies"),
        HasUserSubclasses
        // HasUserPolicies
      ]
    };
  } else if (type === "component") { // uvcCoverage, envPredictor, envCoverage
    return {
      mixins: [
        hasMany("instanceVariables", {
          orderBy: ["createdAt"]
        }),
        // hasMany("constraints"),
        hasMany("subroutines", {
          orderBy: ["createdAt"]
        }),
        // hasFormClass("subclasses"), // Can not add cover_group w/ form at this time.
        // hasFormClass("policies"), // Not currently supported in components
        HasUserSubclasses
        // HasUserPolicies // Not currently supported in components
      ]
    };
  } else if (type === "bfm-base") {
    return {
      mixins: [
        hasMany("instanceVariables", {
          orderBy: ["createdAt"]
        }),
        // hasMany("constraints"),
        hasMany("subroutines", {
          orderBy: ["createdAt"]
        })
        // hasFormClass("subclasses"), // Can't subclass because mst/slv/mon inherit
        // hasFormClass("policies"), // Policies don't make sense on components
        // HasUserSubclasses // Can't subclass because mst/slv/mon inherit
        // HasUserPolicies
      ]
    };
  } else if (type === "bfm") {
    return {
      mixins: [
        hasMany("instanceVariables", {
          orderBy: ["createdAt"]
        }),
        // hasMany("constraints"),
        hasMany("subroutines", {
          orderBy: ["createdAt"]
        }),
        hasFormClass("subclasses"),
        // hasFormClass("subclasses"),
        // hasFormClass("policies"), // Policies don't make sense on components
        HasUserSubclasses
        // HasUserPolicies
      ]
    };
  } else if (type === "reg-adapter") {
    return {
      mixins: [
        // hasMany("instanceVariables", {
        //   orderBy: ["createdAt"]
        // }),
        // hasMany("constraints"),
        hasMany("subroutines", {
          orderBy: ["createdAt"]
        })
        // hasFormClass("subclasses"),
        // hasFormClass("subclasses"),
        // hasFormClass("policies"), // Policies don't make sense on components
        // HasUserSubclasses
        // HasUserPolicies
      ]
    };
  } else if (type === "form-class") {
    return {
      mixins: [
        hasMany("instanceVariables", {
          orderBy: ["createdAt"]
        }),
        hasMany("constraints"),
        hasMany("subroutines", {
          orderBy: ["createdAt"]
        }),
        hasFormClass("subclasses"),
        hasFormClass("policies"), // Used for sequence subclasses
        HasUserSubclasses,
        HasUserPolicies
      ]
    };
  } else if (type === "policy") {
    return {
      mixins: [
        hasMany("instanceVariables", {
          orderBy: ["createdAt"]
        }),
        hasMany("constraints"),
        hasMany("subroutines", {
          orderBy: ["createdAt"]
        })
        // hasFormClass("subclasses") // could be use case but disabling for now.
        // hasFormClass("policies"), // Doesn't make sense to make a policy of a policy.
        // HasUserSubclasses // probably need this too. wait for a need or think it out?
        // HasUserPolicies
      ]
    };
  } else if (type === "user-class") { // user files
    return {
      mixins: [
        // hasFormClass("subclasses"),
        // hasFormClass("policies"),
        HasUserSubclasses, // It depends on the object type which of these makes sense.
        HasUserPolicies
      ]
    };
  } else if ((type === "interface") || (type === "design-element")) {
    return {
      mixins: [
        hasMany("instanceVariables", {
          orderBy: ["createdAt"]
        }),
        // hasMany("constraints"),
        hasMany("subroutines", {
          orderBy: ["createdAt"]
        }),
        // hasFormClass("subclasses"), // None of these make sense
        // hasFormClass("policies"),
        // HasUserSubclasses,
        // HasUserPolicies,
        HasUserIncludes
      ]
    };
  } else {
    throw new Error(`"${type}" is not an Overridable type.`);
  }
}
