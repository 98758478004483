import { nextTick, watch, shallowReactive, computed } from 'vue';

const NOOP = () => {};
const isString = (s) => typeof s === "string";
const isArray = (ary) => Array.isArray(ary);
const isFunction = (fn) => typeof fn === "function";
const isObject = (obj) => obj !== null && typeof obj === "object";

function createPathGetter (ctx, path) {
  const segments = path.split(".");
  return () => {
    let cur = ctx;
    for (let i = 0; i < segments.length && cur; i++) {
      cur = cur[segments[i]];
    }
    return cur;
  };
}

function createWatcher (
  raw,
  ctx,
  publicThis,
  key
) {
  const getter = key.includes(".")
    ? createPathGetter(publicThis, key)
    : () => publicThis[key];
  if (isString(raw)) {
    const handler = ctx[raw].bind(publicThis);
    watch(getter, handler);
  } else if (isFunction(raw)) {
    watch(getter, raw.bind(publicThis));
  } else if (isObject(raw)) {
    if (isArray(raw)) {
      raw.forEach(r => createWatcher(r, ctx, publicThis, key));
    } else {
      const handler = isFunction(raw.handler)
        ? raw.handler.bind(publicThis)
        : ctx[raw.handler].bind(publicThis);
      watch(getter, handler, raw);
    }
  } else {
    console.error(`Invalid watch option: "${key}"`, raw);
  }
}

function Base (propsData) {
}

Base.prototype.$nextTick = nextTick;

Base.prototype.$watch = function (source, callback, options = {}) {
  const getter = source.includes(".")
    ? createPathGetter(this, source)
    : () => this[source];
  return watch(getter, callback.bind(this), options);
};

function getProps (model, prop) {
  let props = [];

  if (model.extends) {
    props = props.concat(getProps(model.extends._definition, prop));
  }

  if (model.mixins) {
    props = props.concat(model.mixins.map(mixin => getProps(mixin, prop)).flat());
  }

  if (model[prop]) {
    props = props.concat(model[prop]);
  }

  return props;
}

let uid = 0;

function defineReactiveModel (model) {
  const props = Object.assign({}, ...getProps(model, "props"));
  const beforeCreateFns = getProps(model, "beforeCreate");
  const dataFns = getProps(model, "data");
  const computedProps = Object.assign({}, ...getProps(model, "computed"));
  const watches = Object.assign({}, ...getProps(model, "watch"));
  const createdFns = getProps(model, "created");
  const methods = Object.assign({}, ...getProps(model, "methods"));

  function ComponentClass (options = {}) {
    this._uid = uid++;

    const propsData = options.propsData || {};

    for (const key in props) {
      if (key in propsData) {
        this[key] = propsData[key];
      } else if (props[key].required) {
        throw new Error(`prop required: ${key}`);
      } else {
        this[key] = isFunction(props[key].default) ? props[key].default.call(this) : props[key].default;
      }
    }

    beforeCreateFns.forEach(fn => {
      fn.call(this);
    });

    const datas = [];
    dataFns.forEach(fn => {
      datas.push(fn.call(this));
    });

    const data = Object.assign({}, ...datas);
    for (const key in data) {
      if (isArray(data[key])) {
        data[key] = shallowReactive(data[key]);
      }
    }
    const shallowReactiveData = shallowReactive(data);
    for (const key in data) {
      Object.defineProperty(this, key, {
        configurable: true,
        enumerable: true,
        get: () => shallowReactiveData[key],
        set: (val) => {
          shallowReactiveData[key] = val;
        }
      });
    }

    for (const key in computedProps) {
      const opt = computedProps[key];
      const get = isFunction(opt)
        ? opt.bind(this)
        : isFunction(opt.get)
          ? opt.get.bind(this)
          : NOOP;
      const set =
        !isFunction(opt) && isFunction(opt.set)
          ? opt.set.bind(this)
          : NOOP;
      const c = computed({
        get,
        set
      });
      Object.defineProperty(this, key, {
        enumerable: true,
        configurable: true,
        get: () => c.value,
        set: v => {
          c.value = v;
        }
      });
    }

    for (const key in watches) {
      createWatcher(watches[key], this, this, key);
    }

    createdFns.forEach(fn => {
      fn.call(this);
    });
  }

  for (const key in methods) {
    ComponentClass.prototype[key] = methods[key];
  }

  Object.setPrototypeOf(ComponentClass.prototype, Base.prototype);
  ComponentClass._definition = model;
  return ComponentClass;
}

export { defineReactiveModel as default };
