import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import UvmSequence from "@/models/uvm/uvm-sequence";
import UvmSequenceItem from "@/models/uvm/uvm-sequence-item";
import Handle from "@/models/handle";

const TbVirtualSequenceBase = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("virtual-sequence")
  ],
  created () {
    this.partialInheritance = [
      { for: ["me"], partial: "set-sequencers" }
    ];
  },
  computed: {
    superclass () {
      return UvmSequence.singleton(UvmSequenceItem.singleton());
    },
    type () {
      return `${this.uvc.name}_uvc_virtual_sequence_base_c`;
    },
    computedInstanceVariables () {
      return [
        this.configHandle,
        ...this.sequencerHandlesWithoutDimensions
      ]
        .filter(o => o);
    },
    sequencerHandlesWithoutDimensions () {
      return [
        this.requestSequencerHandle,
        this.dataSequencerHandle,
        this.responseSequencerHandle
      ]
        .filter(h => h);
    },
    virtualSequenceBaseHandle () {
      return new Handle({
        propsData: {
          parent: this,
          klass: this
        }
      });
    },
    requestSequencerHandle () {
      return new Handle({
        propsData: {
          parent: this,
          klass: this.sequencer,
          nameOverride: "req_seqr"
        }
      });
    },
    dataSequencerHandle () {
      return this.isPipelinedWrite && new Handle({
        propsData: {
          parent: this,
          klass: this.sequencer,
          nameOverride: "data_seqr"
        }
      });
    },
    responseSequencerHandle () {
      return new Handle({
        propsData: {
          parent: this,
          klass: this.sequencer,
          nameOverride: "rsp_seqr"
        }
      });
    }
  }
};

export default defineReactiveModel(TbVirtualSequenceBase);
