import { Persistable } from "vue-app-utils";
// import store from "@/store";
// import debounce from "lodash.debounce";

// const DEBOUNCE_TIME = 2500;

export default {
  mixins: [
    Persistable
  ],
  props: {
    // parent: {
    //   type: Object,
    //   required: true
    // },
    // watcher: {
    //   type: Object,
    //   required: true
    // },
    fileNameOverride: {
      type: String,
      required: true
    }
  },
  created () {
    // this.allowCRUD = true;

    // this.debouncedWrite = debounce((data) => {
    //   this.immediateUpdate(data);
    // }, DEBOUNCE_TIME);

    // this.readFile();

    // this.onSnapshotCancelProperties = this.watcher;
    // this.onSnapshotCancelProperties.on("change", (absoluteFilePath) => {
    //   if (this.absoluteFilePath !== absoluteFilePath) {
    //     // [TODO] remove after soak
    //     throw new Error(`${this.absoluteFilePath} !== ${absoluteFilePath}`);
    //   }
    //   this.readFile();
    // });
  },
  data () {
    return {
      content: ""
    };
  },
  computed: {
    // [TODO] remove after soak
    id () {
      throw new Error("not implemented");
      // return this.docRef.id;
    },
    // [TODO] remove after soak
    key () {
      throw new Error("not implemented");
      // return hashCode(this.docRef.parent.id).toString(36) + "-" + this.id;
    },
    rootProject () {
      return this.parent.rootProject;
    },
    project () {
      return this.parent.project;
    }
  },
  methods: {
    // readFile () {
    //   return fs.promises.readFile(this.absoluteFilePath, { encoding: "utf8" })
    //     .then((content) => {
    //       this.content = content;
    //       this.validateContent(content);
    //     });
    // },
    // update (obj) {
    //   if (this.allowCRUD) {
    //     store.dispatch(
    //       "trackTransaction",
    //       new Promise((resolve) => {
    //         setTimeout(() => {
    //           resolve();
    //         }, DEBOUNCE_TIME + 100);
    //       })
    //     );
    //     this.debouncedWrite(obj);
    //   }
    // },
    // immediateUpdate (obj) {
    //   if (this.allowCRUD) {
    //     const newLine = obj.content.charAt(obj.content.length - 1) === "\n"
    //       ? ""
    //       : "\n";
    //     store.dispatch(
    //       "trackTransaction",
    //       fs.promises.writeFile(this.absoluteFilePath, obj.content + newLine)
    //     );
    //   } else {
    //     return Promise.resolve();
    //   }
    // },
    // destroy () {
    //   return fs.promises.unlink(this.absoluteFilePath);
    // },
    // unsubscribe () {
    //   this.allowCRUD = false;
    //   return this.onSnapshotCancelProperties.close();
    // }
  }
};
