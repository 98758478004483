import Enum from "@/utils/enum";

const fieldMacroNonRadixFlags = {
  0: "UVM_ALL_ON",
  1: "UVM_DEFAULT",
  2: "UVM_NOCOPY",
  3: "UVM_NOCOMPARE",
  4: "UVM_NOPRINT",
  5: "UVM_NOPACK",
  6: "UVM_REFERENCE",
  7: "UVM_PHYSICAL",
  8: "UVM_ABSTRACT",
  9: "UVM_READONLY"
};
const fieldMacroRadixFlags = {
  10: "UVM_BIN",
  11: "UVM_DEC",
  12: "UVM_UNSIGNED",
  13: "UVM_OCT",
  14: "UVM_HEX",
  15: "UVM_STRING",
  16: "UVM_TIME"
};

const FieldMacroFlagEnum = new Enum(
  Object.assign({}, fieldMacroNonRadixFlags, fieldMacroRadixFlags)
);

export default FieldMacroFlagEnum;
