import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";

const UvmObject = defineReactiveModel({
  extends: Class,
  data () {
    return {
      type: "uvm_object"
    };
  },
  created () {
    this.isUvmBaseClass = true;
    this.partialInheritance = [
      { for: ["subclasses"], partial: "uvm-object/add-policy" },
      { for: ["subclasses"], partial: "uvm-object/pre-randomize" }
    ];
  },
  computed: {
    isObject () {
      return true;
    }
  }
});

let singleton;

UvmObject.singleton = function () {
  singleton ||= new UvmObject();
  return singleton;
};

export default UvmObject;
