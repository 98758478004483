import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";

const UvmAnalysisPort = defineReactiveModel({
  extends: Class,
  props: {
    sequenceItem: {
      type: Object,
      required: true
    }
  },
  created () {
    this.iterationCharacter = "p";
    this.isUvmBaseClass = true;
  },
  computed: {
    isUvmAnalysisPort () {
      return true;
    },
    type () {
      return `uvm_analysis_port#(${this.sequenceItem.type})`;
    }
  }
});

const singletons = {};

UvmAnalysisPort.singleton = function (sequenceItem) {
  if (!sequenceItem) { return null; }

  if (singletons[sequenceItem.uniqueId] === undefined) {
    singletons[sequenceItem.uniqueId] = new UvmAnalysisPort({
      propsData: {
        sequenceItem
      }
    });
  }

  return singletons[sequenceItem.uniqueId];
};

export default UvmAnalysisPort;
