{{#if hasPostRandomize}}
function void post_randomize();
{{#unless isFirstLevelObject}}
  super.post_randomize();

{{/unless}}
{{#with allInstanceVariablesWithConstraintRequired}}
  {{> (partial "snippets/check-instance-variables-constrained")}}
{{/with}}
{{#each postRandomizeInstanceVariables ln=allInstanceVariablesWithConstraintRequired.length}}
  {{ identifier }} = {{postRandomizeValue}};
{{/each}}
endfunction

{{/if}}
