import Bowser from 'bowser';

const bowser = Bowser.getParser(window.navigator.userAgent);

let os = bowser.getOSName();
if (bowser.getOSVersion()) {
  os = os + " " + bowser.getOSVersion();
}

var bowser$1 = {
  platform: bowser.getPlatformType(true),
  isMobile: (bowser.getPlatformType(true) === "mobile"),
  osName: bowser.getOSName(true),
  os,
  browserName: bowser.getBrowserName(true),
  browser: bowser.getBrowserName() + " " + bowser.getBrowserVersion() + " (" + bowser.getPlatformType() + ")"
};

export { bowser$1 as default };
