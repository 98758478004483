import Handle from "@/models/handle";
import Variable from "@/models/variable";
import FieldMacroFlagEnum from "@/utils/field-macro-flag-enum";
import store from "@/store";
import get from "lodash.get";

export default {
  computed: {
    env () {
      if (this.parent.docType === "env" || this.parent.docType === "uvcStandaloneEnv") {
        return this.parent;
      } else {
        return this.parent.env;
      }
    },
    rootProject () {
      return this.env && this.env.rootProject;
    },
    project () {
      return this.env;
    },
    hasStandaloneTb () {
      return this.rootProject && this.rootProject.hasStandaloneTb;
    },
    isEdaPlayground () {
      return store.getters.user.setting.edaPlayground;
    },
    dut () {
      return this.env.dut;
    },
    hasDutParameters () {
      return this.env.dut && this.env.dut.hasParameters;
    },
    hasScoreboard () {
      return this.env.hasScoreboard;
    },
    hasEnvCoverage () {
      return this.env.hasEnvCoverage;
    },
    hasEnvParameters () {
      return this.env.hasEnvParameters;
    },
    hasProtocolCheckerHarness () {
      return this.env.hasProtocolCheckerHarness;
    },
    hasSignalChecker () {
      return this.env.hasSignalChecker;
    },
    // [TODO] probably remove after questa and vcs tested w/o default params
    defaultParameterPackage () {
      return this.env.defaultParameterPackage;
    },
    interfaceHarness () {
      return this.env.interfaceHarness;
    },
    protocolCheckerHarness () {
      return this.env.protocolCheckerHarness;
    },
    signalChecker () {
      return this.env.signalChecker;
    },
    package () {
      return this.env.package;
    },
    uvcInstances () {
      return this.env.uvcInstances;
    },
    envInstances () {
      return this.env.envInstances;
    },
    deepEnvInstances () {
      return this.env.deepEnvInstances;
    },
    clkUvcInstances () {
      return this.env.clkUvcInstances;
    },
    rstUvcInstances () {
      return this.env.rstUvcInstances;
    },
    clkFrequencyPolicies () {
      return this.env.clkFrequencyPolicies;
    },
    clkSequenceBases () {
      return this.env.clkSequenceBases;
    },
    clkSequences () {
      return this.env.clkSequences;
    },
    rstIntervalPolicies () {
      return this.env.rstIntervalPolicies;
    },
    rstPulseLengthPolicies () {
      return this.env.rstPulseLengthPolicies;
    },
    rstInitialSequenceBases () {
      return this.env.rstInitialSequenceBases;
    },
    rstInitialSequences () {
      return this.env.rstInitialSequences;
    },
    rstMidSimSequenceBases () {
      return this.env.rstMidSimSequenceBases;
    },
    rstMidSimSequences () {
      return this.env.rstMidSimSequences;
    },
    mainNumTransactionsPolicies () {
      return this.env.mainNumTransactionsPolicies;
    },
    abstractSignalCheckerAccessor () {
      return this.env.abstractSignalCheckerAccessor;
    },
    signalCheckerAccessor () {
      return this.env.signalCheckerAccessor;
    },
    envParameters () {
      return this.env.envParameters;
    },
    envConfig () {
      return this.env.envConfig;
    },
    defaultEnvConfig () {
      return this.env.defaultEnvConfig;
    },
    inOrderEnvConfig () {
      return this.env.inOrderEnvConfig;
    },
    starvedEnvConfig () {
      return this.env.starvedEnvConfig;
    },
    predictor () {
      return this.env.predictor;
    },
    scoreboard () {
      return this.env.scoreboard;
    },
    coverage () {
      return this.env.coverage;
    },
    envEnv () {
      return this.env.envEnv;
    },
    envParametersPath () {
      return this.envParametersFullPath;
    },
    envParametersFullPath () {
      return `${get(this, "envConfigHandle.identifier")}.${get(this, "envConfig.envParametersHandle.identifier")}`;
    },
    envParametersHandle () {
      if (this.envParameters) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.envParameters,
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT")
            ]
          }
        });
      } else {
        return null;
      }
    },
    envConfigHandle () {
      if (this.envConfig) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.envConfig,
            setter: true
          }
        });
      } else {
        return null;
      }
    },
    scoreboardHandle () {
      if (this.scoreboard) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.scoreboard
          }
        });
      } else {
        return null;
      }
    },
    coverageHandle () {
      if (this.coverage) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.coverage
          }
        });
      } else {
        return null;
      }
    },
    abstractSignalCheckerAccessorHandle () {
      if (this.abstractSignalCheckerAccessor) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.abstractSignalCheckerAccessor
          }
        });
      } else {
        return null;
      }
    },
    signalCheckerAccessorHandle () {
      if (this.signalCheckerAccessor) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.signalCheckerAccessor
          }
        });
      } else {
        return null;
      }
    },
    envFullNameVariable () {
      return new Variable({
        propsData: {
          parent: this,
          type: "string",
          name: "env_full_name"
        }
      });
    }
  }
};
