{{#with context}}
{{#if (and isMonitor isPipelined)}}
forever begin
{{#with sequenceItemHandle}}
  {{ type }} {{ identifier }} = {{ type }}::type_id::create("{{ fullName }}", this);
  {{ identifier }}.{{ klass.configHandle.setterName }}({{ ../configHandle.identifier }});
{{/with}}
  {{ abstractBfmHandle.identifier }}.{{ ../task.identifier }}({{ responseSequenceItemHandle.identifier }});
end
{{else}}
{{ abstractBfmHandle.identifier }}.{{ ../task.identifier }}({{ responseSequenceItemHandle.identifier }});
{{/if}}
{{/with}}
