export default function (dimensionPropertiesList, blockNamePrefix, options) {
  if (dimensionPropertiesList) {
    const content = [];
    const lhs = dimensionPropertiesList.lhs;
    const rhs = dimensionPropertiesList.rhs;
    const start = rhs.multipleTerms
      ? `(${rhs.constantExpression})`
      : (rhs.constantExpression || "0");
    const end = lhs.multipleTerms
      ? `(${lhs.constantExpression})`
      : lhs.constantExpression;
    const multiDimensions = !!(dimensionPropertiesList.previous || dimensionPropertiesList.next);
    const blockName = multiDimensions ? `${blockNamePrefix}_${dimensionPropertiesList.dimensionVariable.toUpperCase()}` : blockNamePrefix;
    content.push(`for (genvar ${dimensionPropertiesList.dimensionVariable} = ${start}; ${dimensionPropertiesList.dimensionVariable} < ${end}; ${dimensionPropertiesList.dimensionVariable}++) begin : ${blockName}`);
    content.push(options.fn(this).slice(0, -1));
    content.push("end\n");

    return content.join("\n");
  } else {
    return options.inverse(this);
  }
}
