{{#generate dimensionPropertiesList blockNamePrefix}}
  {{> (partial "snippets/bind-generate-block") env=env dimensionPropertiesList=dimensionPropertiesList.next blockNamePrefix=blockNamePrefix envInstanceHierarchicalProperties=envInstanceHierarchicalProperties}}
{{else}}

{{#each envInstanceHierarchicalProperties delimeter="\n\n"}}
// {{ envInstance.instanceNameUpperCase }} Bind statements{{#if parentEnvInstancesNoTop.length}} (in {{#each parentEnvInstancesNoTop delimeter="/"}}{{ instanceNameUpperCase }}{{/each}}){{/if}}

{{#if (eq ../env envInstance.env)}}
{{> (partial "snippets/bind-statement") harness=envInstance.interfaceHarness envInstanceHierarchicalPropertiesObject=.}}
{{else}}
`ifdef {{ envInstance.enableDutFunctionalVerificationMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
  {{> (partial "snippets/bind-statement") harness=envInstance.interfaceHarness dimensionPropertiesList=dimensionPropertiesList envInstanceHierarchicalPropertiesObject=.}}
`endif
{{/if}}
{{#if envInstance.hasProtocolCheckerHarness}}

`ifdef {{ envInstance.enableDutProtocolCheckersMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
  {{> (partial "snippets/bind-statement") harness=envInstance.protocolCheckerHarness dimensionPropertiesList=dimensionPropertiesList envInstanceHierarchicalPropertiesObject=.}}
`endif
{{/if}}
{{#if envInstance.hasSignalChecker}}

`ifdef {{ envInstance.enableDutSignalCheckerMacroName }}{{#if parentEnvInstancesNoTop.length}}_IN{{#each parentEnvInstancesNoTop}}_{{ instanceNameUpperCase }}{{/each}}{{/if}}
  {{> (partial "snippets/bind-statement") harness=envInstance.signalChecker dimensionPropertiesList=dimensionPropertiesList envInstanceHierarchicalPropertiesObject=.}}
`endif
{{/if}}
{{/each}}
{{/generate}}
