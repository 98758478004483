import { defineReactiveModel } from "vue-app-utils";
import GeneratableFile from "@/models/mixins/generatable-file";
import PersistableUserFile from "@/models/persistable/mixins/persistable-user-file-VITE_APP_PLATFORM";
import store from "@/store";

const UserInclude = {
  mixins: [
    GeneratableFile,
    PersistableUserFile
  ],
  data () {
    return {
    };
  },
  beforeCreate () {
    this.$store = store;
  },
  created () {
    this.computed = false;
    this.userFile = true;
  },
  computed: {
  },
  methods: {
    validateContent (content) {
    }
  }
};

export default defineReactiveModel(UserInclude);
