import packageDefinitionHbs from "@/handlebars/package-definition.hbs";
import handlebarsRuntimeOptions from "@/handlebars/runtime-options";

const Package = {
  mixins: [
  ],
  data () {
    return {
      timeUnit: "",
      timePrecision: ""
    };
  },
  created () {
  },
  computed: {
    fileName () {
      return `${this.type}.sv`;
    },
    code () {
      return packageDefinitionHbs(this, handlebarsRuntimeOptions).slice(0, -1);
    },
    allSequences () {
      return this.baseGeneratableFileModels.filter(m => m.isSequence).concat(this.formSequences);
    },
    allPolicies () {
      return this.baseGeneratableFileModels
        .filter(m => m.isPolicy)
        .concat(this.formPolicies)
        .filter(m => m.subject);
    },
    userSubclasses () {
      return this.userClasses.filter(userClass => userClass.isSubclass);
    }
  },
  methods: {
    insertBefore (originalArray, insertArray, fn) {
      const insertIndex = originalArray.findIndex(fn);
      return originalArray
        .slice(0, insertIndex)
        .concat(insertArray)
        .concat(originalArray.slice(insertIndex));
    }
    // [TODO] Leaving here to soak in case there is a need for mulitple package inserts.
    // insertBefore2 (originalArray, insertFns) {
    //   let newArray = originalArray;
    //   insertFns.forEach(([insertArray, fn]) => {
    //     const insertIndex = newArray.findIndex(fn);
    //     newArray = newArray
    //       .slice(0, insertIndex)
    //       .concat(insertArray)
    //       .concat(newArray.slice(insertIndex));
    //   });
    //   return newArray;
    // }
  }
};

export default Package;
