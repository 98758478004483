export default {
  state: () => {
    return {
      onPremiseDialogTitle: "",
      onPremiseDialogParagraph: ""
    };
  },
  getters: {
    onPremiseDialogTitle: state => state.onPremiseDialogTitle,
    onPremiseDialogParagraph: state => state.onPremiseDialogParagraph,
    topDir: () => ""
  },
  mutations: {
    setOnPremiseDialogTitle: function (state, value) {
      state.onPremiseDialogTitle = value;
    },
    setOnPremiseDialogParagraph: function (state, value) {
      state.onPremiseDialogParagraph = value;
    }
  }
};
