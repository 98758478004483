{{#each localparamInstanceVariables tn=true}}
{{ type }} {{ name }} = {{defaultValue}};
{{/each~}}

{{#each constStaticInstanceVariables tn=true}}
{{ type }} {{ name }} = {{defaultValue}};
{{/each~}}

{{#each constInstanceVariables tn=true}}
{{ type }} {{ name }} = {{defaultValue}};
{{/each~}}

{{#each staticInstanceVariables tn=true}}
{{ type }} {{ name }} = {{defaultValue}};
{{/each~}}

{{#each remainingInstanceVariablePairs tn=true}}
{{#if (call .. "addNewlineInInstanceVariableDeclarations" previous current)}}

{{/if}}
{{#with current}}
{{ type }}{{ typeSpacing }}{{ name }};
{{/with}}
{{/each~}}

{{#each randomInstanceVariables tn=true}}
{{ type }}{{ typeSpacing }}{{ name }};
{{/each~}}

{{#each protectedInstanceVariablePairs tn=true}}
{{#if (call .. "addNewlineInInstanceVariableDeclarations" previous current)}}

{{/if}}
{{#with current}}
{{ type }}{{ typeSpacing }}{{ name }};
{{/with}}
{{/each~}}

{{#with policiesQueueVariable}}
{{ type }} {{ name }};

{{/with}}
