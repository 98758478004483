import { defineReactiveModel } from "vue-app-utils";
import PortConnectionMixin, { EXPRESSION_UNSET } from "@/models/mixins/port-connection";
import CreatePersistableOnUpdate from "@/models/mixins/create-persistable-on-update";

const PortConnection = {
  mixins: [
    PortConnectionMixin,
    CreatePersistableOnUpdate
  ],
  props: {
    parent: {
      type: Object,
      required: true
    },
    port: {
      type: Object,
      required: true
    },
    expressionOverride: {
      type: String,
      default: EXPRESSION_UNSET
    }
  },
  created () {
    this.computed = true;
    this.envUvcInstance = this.parent;
  },
  methods: {
    createPersistable (data) {
      return this.envUvcInstance.createPortConnection({
        portId: this.port.id,
        ...data
      })
        .then(id => {
          return new Promise(resolve => {
            this.findPersistable(id, resolve);
          });
        });
    },
    findPersistable (id, resolve) {
      const persistable = this.envUvcInstance.portConnections.find(portConnection => portConnection.id === id);
      if (persistable) {
        resolve(persistable);
      } else {
        setTimeout(() => {
          this.findPersistable(id, resolve);
        }, 10);
      }
    }
  }
};

export default defineReactiveModel(PortConnection);
