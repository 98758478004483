import hasOne from './db/relations/has-one.js';
import hasMany from './db/relations/has-many.js';

var userMixin = {
  props: {
    user: {
      type: Object,
      required: false
    },
    parent: {
      type: Object,
      required: false
    }
  },
  mixins: [
    hasOne("privateProfile"),
    hasOne("setting"),
    hasMany("emails", {
      orderBy: ["createdAt"]
    })
  ],
  data () {
    return {
      displayName: "",
      photoURL: "",
      isAnonymous: false,
      lastLoggedInAt: null,
      emailedAt: null,
      numEmails: 0,
      unsubscribedToEmails: false,
      signInMethod: ""
    };
  },
  computed: {
    daysSinceEmailed () {
      return this.daysSince(this.emailedAt);
    },
    emailedInLastSevenWeeks () {
      return (this.daysSinceEmailed < (7 * 7));
    },
    emailedInLastSixMonths () {
      return (this.daysSinceEmailed < (365 / 2));
    }
  },
  methods: {
    onCreatePrivateProfile (data) {
      return Promise.resolve({ private: true, ...data });
    },
    daysSince (firestoreDate) {
      if (firestoreDate && firestoreDate.toDate) {
        const now = new Date(Date.now());
        return (((((now.getTime() - firestoreDate.toDate().getTime()) / 1000) / 60) / 60) / 24);
      } else {
        return Infinity;
      }
    }
  }
};

export { userMixin as default };
