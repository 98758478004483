import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import UvmMonitor from "@/models/uvm/uvm-monitor";
import UvmAnalysisPort from "@/models/uvm/uvm-analysis-port";
import Handle from "@/models/handle";

const UvcMonitor = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage")
  ],
  created () {
    this.partialInheritance = [
      { for: ["me"], partial: "build-phase" },
      { for: ["me"], partial: "uvc-monitor/run-phase" },
      { for: ["me"], partial: "uvc-monitor/monitor" },
      { for: ["me"], partial: "uvc-monitor/monitor-data" },
      { for: ["me"], partial: "uvc-monitor/broadcast-item" }
    ];
    this.isMonitor = true;
  },
  computed: {
    superclass () {
      return UvmMonitor.singleton();
    },
    monitorTask () {
      return this.monitorBfm.monitorTask;
    },
    type () {
      return `${this.uvc.rootName}_monitor_c`;
    },
    bfm () {
      return this.monitorBfm;
    },
    computedInstanceVariables () {
      return [
        this.configHandle,
        this.abstractBfmCreatorHandle,
        this.abstractBfmHandle,
        this.analysisPortHandle
      ]
        .filter(o => o);
    },
    analysisPort () {
      return this.sequenceItem
        ? UvmAnalysisPort.singleton(this.sequenceItem)
        : null;
    },
    responseSequenceItemHandle () {
      if (this.sequenceItem) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.sequenceItem
          }
        });
      } else {
        return null;
      }
    }
  }
};

export default defineReactiveModel(UvcMonitor);
