import PolicyBase from "@/models/policy-base";
// import calculateConstraintPrefix from "@/utils/calculate-constraint-prefix";

export default {
  created () {
  },
  computed: {
    superclass () {
      return this.subject && PolicyBase.singleton(this.subject);
    },
    constraintPrefix () {
      return "";
      // return calculateConstraintPrefix(this.type, this.subject)
      //   .replace("_constraint", "");
    },
    classToSearchForInstanceVariables () {
      return this.subject;
    },
    forwardTypedefs () {
      return [
        this.subject && this.subject.isSequence && this.subject
        // this.subject && this.subject.isVirtualSequence && this.subject
      ]
        .filter(o => o);
    }
  }
};
