import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Handle from "@/models/handle";
import get from "lodash.get";

const UvcSlaveResponseSequence = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("sequence")
  ],
  created () {
    this.master = false;
    this.channel = "response";
  },
  computed: {
    superclass () {
      return this.slaveResponseSequenceBase;
    },
    type () {
      const parts = [this.uvc.rootName];
      if (this.hasSlave) {
        parts.push(this.protocolRoleWord);
      }
      parts.push("response");
      parts.push("sequence");
      parts.push("c");
      return parts.join("_");
    },
    computedPolicyHandles () {
      return [
        get(this, "slaveResponseSequenceBase.sequenceItemHandle") && this.slaveResponseDefaultPreTransferDelayPolicy && new Handle({
          propsData: {
            parent: this,
            klass: this.slaveResponseDefaultPreTransferDelayPolicy,
            relations: {
              randomObjectDoHandle: this.slaveResponseSequenceBase.sequenceItemHandle
            }
          }
        })
      ]
        .filter(h => h);
    }
  }
};

export default defineReactiveModel(UvcSlaveResponseSequence);
