export default function (previous, current) {
  if (!previous) {
    return false;
  }
  // if (current.type === "apb_relay_sideband_virtual_sequence_c") {
  //   if (previous.virtual && !current.virtual) {
  //     console.log("virtual");
  //   } else if (!previous.isSequenceItem && current.isSequenceItem) {
  //     console.log("sequence1");
  //   } else if (previous.isSequenceItem && !current.isSequenceItem) {
  //     console.log("sequence2");
  //   } else if (!previous.userFile && current.userFile) {
  //     console.log("userfil");
  //   } else if (previous.isMixin && !current.isMixin) {
  //     console.log("mixin");
  //   }
  // }
  return (
    (previous.virtual && !current.virtual) ||
    (!previous.isPolicy && current.isPolicy) ||
    (previous.isPolicy && !current.isPolicy) ||
    (!previous.isSequenceItem && current.isSequenceItem) ||
    (previous.isSequenceItem && !current.isSequenceItem) ||
    ((!previous.userFile && current.userFile) && (!previous.isPolicy && !current.isPolicy)) ||
    (previous.isMixin && !current.isMixin)
  );
}
