{{#generate dimensionPropertiesList blockNamePrefix}}
  {{> (partial "harness/generate-initial") harness=harness dimensionPropertiesList=dimensionPropertiesList.next blockNamePrefix=blockNamePrefix interfaceBlockNamePrefix=interfaceBlockNamePrefix uvcInstanceAndAgentHandles=uvcInstanceAndAgentHandles}}
{{else}}
initial begin
  wait ({{ harness.envFullNameVariable.identifier }} != "");

{{#*inline "blockName"}}
{{#if dimensionPropertiesList}}{{ blockNamePrefix }}{{ dimensionPropertiesList.blockNameSuffix }}[{{ dimensionPropertiesList.dimensionVariable }}].{{> blockName dimensionPropertiesList=dimensionPropertiesList.next blockNamePrefix=blockNamePrefix}}{{/if~}}
{{/inline}}
{{#*inline "agentFullName"}}
{{#if dimensionPropertiesList}}, {{ dimensionPropertiesList.dimensionVariable }}{{#if dimensionPropertiesList.rhs.constantExpression}} - {{#if dimensionPropertiesList.rhs.multipleTerms}}({{ dimensionPropertiesList.rhs.constantExpression }}){{else}}{{ dimensionPropertiesList.rhs.constantExpression }}{{/if}}{{/if}}{{> agentFullName dimensionPropertiesList=dimensionPropertiesList.next}}{{/if~}}
{{/inline}}
{{#each uvcInstanceAndAgentHandles}}
  {{> blockName dimensionPropertiesList=uvcInstance.dimensionPropertiesList blockNamePrefix=../interfaceBlockNamePrefix}}{{or (and ../harness.isInterfaceHarness uvcInstance.interfaceInstanceName) uvcInstance.protocolCheckerInstanceName}}.add_resources_to_config_db({{#if uvcInstance.hasDimensions}}$sformatf("%s.{{ agentHandle.identifier }}{{ agentHandle.uvcInstance.formatBrackets }}", {{ ../harness.envFullNameVariable.identifier }}{{> agentFullName dimensionPropertiesList=uvcInstance.dimensionPropertiesList}}){{else}}{ {{ ../harness.envFullNameVariable.identifier }}, ".{{ agentHandle.identifier }}" }{{/if}});
{{/each}}
end
{{/generate}}
