import ProtocolRoleGetters from "@/models/mixins/protocol-role-getters";
import Handle from "@/models/handle";
import UvmSequence from "@/models/uvm/uvm-sequence";
import get from "lodash.get";

export default {
  mixins: [
    ProtocolRoleGetters
  ],
  computed: {
    virtualSequence () {
      if (this.isMaster) {
        return this.masterVirtualSequence;
      } else {
        return this.slaveVirtualSequence;
      }
    },
    sequence () {
      if (this.isMaster) {
        return this.masterRequestSequenceBase;
      } else {
        return this.slaveSequenceTop;
      }
    },
    sequences () {
      return this.sequenceItem
        ? UvmSequence.singleton(this.sequenceItem).deepSubclasses
        : [];
    },
    requestSequenceBase () {
      return this.isMaster
        ? this.masterRequestSequenceBase
        : this.slaveRequestSequenceBase;
    },
    requestSequence () {
      return this.isMaster
        ? this.masterRequestSequence
        : this.slaveRequestSequence;
    },
    dataSequenceBase () {
      return this.isMaster
        ? this.masterDataSequenceBase
        : this.slaveDataSequenceBase;
    },
    dataSequence () {
      return this.isMaster
        ? this.masterDataSequence
        : this.slaveDataSequence;
    },
    responseSequenceBase () {
      return this.isMaster
        ? this.masterResponseSequenceBase
        : this.slaveResponseSequenceBase;
    },
    responseSequence () {
      return this.isMaster
        ? null
        : this.slaveResponseSequence;
    },
    agent () {
      if (this.isMaster) {
        return this.masterAgent;
      } else {
        return this.slaveAgent;
      }
    },
    requestDriver () {
      if (this.isMaster) {
        return this.masterRequestDriver;
      } else {
        return this.slaveRequestDriver;
      }
    },
    dataDriver () {
      if (this.isMaster) {
        return this.masterDataDriver;
      } else {
        return this.slaveDataDriver;
      }
    },
    responseDriver () {
      if (this.isMaster) {
        return this.masterResponseDriver;
      } else {
        return this.slaveResponseDriver;
      }
    },
    bfm () {
      if (this.isMaster) {
        if (this.isRequest) {
          return this.masterRequestBfm;
        } else if (this.isData) {
          return this.masterDataBfm;
        } else {
          return this.masterResponseBfm;
        }
      } else {
        if (this.isRequest) {
          return this.slaveRequestBfm;
        } else if (this.isData) {
          return this.slaveDataBfm;
        } else {
          return this.slaveResponseBfm;
        }
      }
    },
    driveTaskName () {
      if (this.isMaster) {
        if (this.isRequest) {
          return "drive_request";
        } else if (this.isData) {
          return "drive_data";
        } else {
          return "receive_response";
        }
      } else {
        if (this.isRequest) {
          return "receive_request";
        } else if (this.isData) {
          return "receive_data";
        } else {
          return "send_response";
        }
      }
    },
    bfmTask () {
      if (this.isMaster) {
        if (this.isRequest) {
          return get(this, "masterRequestBfm.driveTask");
        } else if (this.isData) {
          return get(this, "masterDataBfm.pipelinedDriveTask");
        } else {
          return get(this, "masterResponseBfm.receiveResponseTask");
        }
      } else {
        if (this.isRequest) {
          return get(this, "slaveRequestBfm.receiveTask");
        } else if (this.isData) {
          return get(this, "slaveDataBfm.uvcSlaveDataBfmReceiveTask");
        } else {
          return get(this, "slaveResponseBfm.pipelinedRespondTask");
        }
      }
    },
    ports () {
      if (this.isMaster) {
        if (this.isPipelined) {
          if (this.isRequest) {
            return get(this, "interface.masterRequestPorts", []);
          } else if (this.isData) {
            return get(this, "interface.masterDataPorts", []);
          } else {
            return get(this, "interface.masterResponsePorts", []);
          }
        } else {
          return get(this, "interface.masterPorts", []);
        }
      } else {
        if (this.isPipelined) {
          if (this.isRequest) {
            return get(this, "interface.slaveRequestPorts", []);
          } else if (this.isData) {
            return get(this, "interface.slaveDataPorts", []);
          } else {
            return get(this, "interface.slaveResponsePorts", []);
          }
        } else {
          return get(this, "interface.slavePorts", []);
        }
      }
    },
    clockingBlock () {
      if (this.isMaster) {
        return get(this, "interface.masterClockingBlock");
      } else {
        return get(this, "interface.slaveClockingBlock");
      }
    },
    requestDriverHandle () {
      if (this.requestDriver) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.requestDriver
          }
        });
      } else {
        return undefined;
      }
    },
    dataDriverHandle () {
      if (this.dataDriver) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.dataDriver
          }
        });
      } else {
        return undefined;
      }
    },
    responseDriverHandle () {
      if (this.responseDriver) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.responseDriver
          }
        });
      } else {
        return undefined;
      }
    }
  }
};
