{{#if hasConstructor}}
{{#if isComponent}}
function new(string name="{{ thisType }}", uvm_component parent=null);
  super.new(name, parent);
{{else}}
function new(string name="{{ thisType }}");
  super.new(name);
{{/if}}
{{#each (filter allInstanceVariables "defaultValue")}}
  {{#unless isRandom}}
  {{ identifier }}{{identifierSpacing}}= {{defaultValue}};
  {{/unless}}
{{/each}}
{{#each constructorInitialValues ln=true}}
  {{ identifier }} = {{defaultValue}};
{{/each}}
{{#each constructorPartials}}

  {{> (partial .) ..}}
{{/each}}
endfunction

{{/if}}
