{{#with virtualSequenceBaseHandle}}
virtual function void set_sequencers({{ type }} {{ shortName }});
{{/with}}
{{#each sequencerHandlesWithoutDimensions}}
  {{> (partial "snippets/assign-sequencers") virtualSequenceSequencerPath=(join (toArray ../virtualSequenceBaseHandle.shortName identifier) ".") handle=.}}
{{/each}}
{{#each sequencerHandlesWithDimensions ln=sequencerHandlesWithoutDimensions.length delimeter="\n"}}
  {{> (partial "snippets/assign-sequencers") virtualSequenceSequencerPath=(join (toArray ../virtualSequenceBaseHandle.shortName identifier) ".") handle=. iterationPropertiesList=iterationPropertiesList}}
{{/each}}
endfunction
{{#each setSequencersForPipelinedUvcsInfo ln=true delimeter="\n"}}
{{#with virtualSequenceHandle}}
virtual function void {{ setSequencersFunctionName }}({{ type }} {{ shortName }}{{#each iterationPropertiesList.last.iterationVariables lc=true delimeter=", "}}int {{ . }}{{/each}});
{{/with}}
{{#with sequencerHandles.request}}
  {{ ../virtualSequenceHandle.shortName }}.{{ ../virtualSequenceHandle.klass.superclass.requestSequencerHandle.identifier }} = {{ identifier }}{{ ../virtualSequenceHandle.iterationPropertiesList.last.brackets }};
{{/with}}
{{#with sequencerHandles.data}}
  {{ ../virtualSequenceHandle.shortName }}.{{ ../virtualSequenceHandle.klass.superclass.dataSequencerHandle.identifier }} = {{ identifier }}{{ ../virtualSequenceHandle.iterationPropertiesList.last.brackets }};
{{/with}}
{{#with sequencerHandles.response}}
  {{ ../virtualSequenceHandle.shortName }}.{{ ../virtualSequenceHandle.klass.superclass.responseSequencerHandle.identifier }} = {{ identifier }}{{ ../virtualSequenceHandle.iterationPropertiesList.last.brackets }};
{{/with}}
endfunction
{{/each}}
