import GeneratableFile from "@/models/mixins/generatable-file";
import { Persistable } from "vue-app-utils";
import Overridable from "@/models/persistable/mixins/overridable";
import UvmObject from "@/models/uvm/uvm-object.js";
import Variable from "@/models/variable";
import sortByLength from "@/utils/sort-by-length";
import get from "lodash.get";

export default {
  mixins: [
    GeneratableFile,
    Persistable,
    Overridable("object")
  ],
  computed: {
    superclass () {
      return UvmObject.singleton();
    },
    parameterVariables () {
      return this.parametersSetToConfigDb.map((parameter) => {
        let type = parameter.rootType || "logic [31:0]";
        const parameterIdentifiers = get(parameter, "typeInfo.packed.parameterIdentifiers", []);
        sortByLength(parameterIdentifiers).forEach(parameterIdentifier => {
          if (this.paramsByName[parameterIdentifier]) {
            const minOrMaxName = get(this.paramsByName[parameterIdentifier], "minOrMaxPackageParameter.name");
            if (minOrMaxName) {
              type = type.replace(new RegExp(parameterIdentifier), minOrMaxName);
            }
          }
        });
        return new Variable({
          propsData: {
            parent: this,
            type,
            name: parameter.lowerCaseName,
            relations: {
              parameter
            }
          }
        });
      });
    },
    parameterVariablesByParameterName () {
      const byName = {};
      this.parameterVariables.forEach((p) => {
        byName[p.relations.parameter.name] = p;
      });
      return byName;
    },
    paramsByName () {
      const byName = {};
      this.parametersSetToConfigDb.forEach((p) => {
        byName[p.name] = p;
      });
      return byName;
    }
  }
};
