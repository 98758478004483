// Capture/sample:
{{#each interface.slaveResponsePorts}}
  {{#with (lookup ../sequenceItem.instanceVariablesByRelatedPort identifier )}}
    {{#if (not (eq identifier "id"))}}
//   {{ ../../sequenceItemHandle.identifier }}.{{ identifier }}{{#if isDynamicArray}}[{{ ../../sequenceItemHandle.identifier }}.{{ ../../sequenceItemHandle.klass.beatIndexVariable.identifier }}]{{/if}} = {{ ../identifier }};
    {{/if}}
  {{else}}
//   {{ identifier }}
  {{/with}}
{{/each}}
