{{#each bfmsWithSubclasses delimeter="\n"}}
function uvm_object_wrapper {{ getTypeFunction }}();
  return {{ type }}::get_type();
endfunction
{{/each}}
{{#each bfmDeepSubclasses ln=true delimeter="\n"}}
function uvm_object_wrapper {{ getTypeFunction }}();
  return {{ type }}::get_type();
endfunction
{{/each}}
