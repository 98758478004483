import get from 'lodash.get';

var storeModule = {
  state: () => {
    return {
      sideNavOpen: false,
      user: null,
      openTransactions: 0,
      zeroTransactionsPromise: null,
      zeroTransactionsResolver: null,
      onZeroTransactions: function () { },
      snackbarMessageProps: [],
      dragItemId: "",
      dragElement: null
    };
  },
  getters: {
    isSideNavOpen: state => state.sideNavOpen,
    user: state => state.user,
    settings: state => get(state, "user.setting"),
    isAuthenticated: state => !!state.user,
    hasOpenTransactions: state => !!state.openTransactions,
    waitForZeroTransactions: (state) => {
      if (state.zeroTransactionsPromise) {
        return state.zeroTransactionsPromise;
      } else {
        return Promise.resolve();
      }
    },
    snackbarMessageProps: state => state.snackbarMessageProps,
    dragItemId: state => state.dragItemId,
    dragElement: state => state.dragElement
  },
  mutations: {
    setSideNavOpen: function (state, { open }) {
      state.sideNavOpen = open;
    },
    toggleSideNavOpen: function (state) {
      state.sideNavOpen = !state.sideNavOpen;
    },
    setUser: function (state, user) {
      state.user = user;
    },
    signOut: function (state) {
      state.user = null;
    },
    startTransaction: function (state) {
      state.openTransactions++;
      if (state.openTransactions === 1) {
        state.zeroTransactionsPromise = new Promise((resolve) => {
          state.zeroTransactionsResolver = resolve;
        });
      }
    },
    endTransaction: function (state) {
      state.openTransactions--;
      if (state.openTransactions === 0) {
        state.zeroTransactionsResolver();
        state.onZeroTransactions();
      }
    },
    setOnZeroTransactions: function (state, fn) {
      state.onZeroTransactions = fn;
    },
    pushSnackbarMessageProps: function (state, messageProps) {
      state.snackbarMessageProps.splice(state.snackbarMessageProps.length, 0, messageProps);
    },
    shiftSnackbarMessageProps: function (state) {
      state.snackbarMessageProps = state.snackbarMessageProps.slice(1);
    },
    setDragItemId: function (state, dragItemId) {
      state.dragItemId = dragItemId;
    },
    setDragElement: function (state, dragElement) {
      state.dragElement = dragElement;
    }
  },
  actions: {
    trackTransaction: function (context, promise) {
      context.commit("startTransaction");
      return promise
        .then((ret) => {
          context.commit("endTransaction");
          return ret;
        })
        .catch((e) => {
          context.commit("endTransaction");
          throw e;
        });
    }
  }
};

export { storeModule as default };
