import { defineReactiveModel, Persistable, hasOne } from "vue-app-utils";
import DesignElement from "@/models/design-element";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import UvcUserIncludeForDesignElementTemplate from "@/models/uvc/uvc-user-include-for-design-element-template";
import UvmPackage from "@/models/uvm/uvm-package";
import UvmAgent from "@/models/uvm/uvm-agent";
import Handle from "@/models/handle";
import get from "lodash.get";

const UvcProtocolChecker = {
  extends: DesignElement,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    Overridable("design-element"),
    // belongsTo("uvc"),
    hasOne("uvcProtocolCheckerAccessor")
  ],
  data () {
    return {
      contentOverride: ""
    };
  },
  created () {
    this.inputsOnly = true;
    this.partials = [
      "uvc-protocol-checker/accessor-class",
      "design-element/set-print-context",
      "add-resources-to-config-db",
      "add-accessor-to-config-db",
      "snippets/inner-content",
      "snippets/user-includes"
    ];
    this.userIncludeTemplate = UvcUserIncludeForDesignElementTemplate;
    this.instName = "inst_name";
    this.getterName = "get_protocol_checks_enabled";
  },
  computed: {
    externalImports () {
      // return [this.package];
      return [];
    },
    content () {
      if (this.contentOverride) {
        return this.contentOverride;
      } else {
        const Template = this.userIncludeTemplate;
        const template = new Template({
          propsData: {
            parent: this,
            type: "Assertions"
            // type: [this.includeFileNamePrefix, data.fileNameSuffix]
            //   .filter(s => s)
            //   .join("_")
          }
        });

        return template.fileContents;
      }
    },
    internalImports () {
      return [UvmPackage, this.package];
    },
    allParameters () {
      return get(this, "interface.allParameters", []);
    },
    ports () {
      return get(this, "interface.ports", []);
    },
    fileName () {
      return `${this.type}.sv`;
    },
    type () {
      return `${this.uvc.rootName}_protocol_checker`;
    },
    computedInstanceVariables () {
      return [
        this.printContextHandle
      ]
        .filter(o => o);
    },
    printContextHandle () {
      return new Handle({
        propsData: {
          parent: this,
          klass: UvmAgent.singleton(),
          nameOverride: "print_context"
        }
      });
    },
    accessorHandle () {
      return this.protocolCheckerAccessorHandle;
    },
    abstractAccessor () {
      return this.package.uvcAbstractProtocolCheckerAccessor;
    }
  }
};

export default defineReactiveModel(UvcProtocolChecker);
