import { defineReactiveModel, Persistable, SettingsMixin } from "vue-app-utils";
import Model from "@/models/model";

const Setting = {
  extends: Model,
  mixins: [
    Persistable,
    SettingsMixin
  ],
  data () {
    return {
      // simulator: "synopsys",
      edaPlayground: false,
      masterWord: "master",
      slaveWord: "slave",
      monitorWord: "monitor",
      responderWord: "responder",
      receiverWord: "receiver",
      defaultTimeUnit: "1ns",
      defaultTimePrecision: "1ns",
      relVipDir: "vip"
    };
  },
  created () {
    // this.simulators = {
    //   "Synopsys VCS": "synopsys",
    //   "Mentor Graphics Questa": "mentor",
    //   "Cadence Xcelium": "cadence"
    // };
  },
  computed: {
    // isCadence () {
    //   return this.simulator === "cadence";
    // }
  }
};

export default defineReactiveModel(Setting);
