import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import GeneratableFile from "@/models/mixins/generatable-file";
import outOfOrderComparatorDefinitionHbs from "@/handlebars/out-of-order-comparator-definition.hbs";
import handlebarsRuntimeOptions from "@/handlebars/runtime-options";
import UvmObject from "@/models/uvm/uvm-object";
import UvmComponent from "@/models/uvm/uvm-component";
import Parameter from "@/models/parameter";

const OutOfOrderComparator = defineReactiveModel({
  extends: Class,
  mixins: [
    GeneratableFile
  ],
  props: {
    parent: {
      type: Object,
      default: null
    },
    sequenceItem: {
      type: Object,
      default: null
    }
  },
  created () {
    this.rootType = "out_of_order_comparator_c";
    this.iterationCharacter = "c";
    // this.partialInheritance = [
    //   { for: ["me"], partial: "policy-base/set-object" }
    // ];
  },
  computed: {
    key () {
      return `${this.rootType}-39gghwgh54w89`;
    },
    isComparator () {
      return true;
    },
    rootProject () {
      // return (this.parent || this.sequenceItem).rootProject;
      return this.parent.rootProject;
    },
    project () {
      // return (this.parent || this.sequenceItem).project;
      return this.parent.project;
    },
    code () {
      return outOfOrderComparatorDefinitionHbs(this, handlebarsRuntimeOptions).slice(0, -1);
    },
    parameters () {
      return [
        new Parameter({
          propsData: {
            parent: this,
            type: "parameter type",
            name: "TYPE",
            defaultValueOverride: UvmObject.singleton().type
          }
        })
      ];
    },
    fileName () {
      return `${this.rootType}.svh`;
    },
    superclass () {
      return UvmComponent.singleton();
    },
    type () {
      if (this.sequenceItem) {
        return `${this.rootType}#(${this.sequenceItem.type})`;
      } else {
        return this.rootType;
      }
    }
  }
});

const singletons = {};

OutOfOrderComparator.singleton = function (sequenceItem) {
  if (sequenceItem === undefined) { return null; }

  if (singletons[sequenceItem.uniqueId] === undefined) {
    singletons[sequenceItem.uniqueId] = new OutOfOrderComparator({
      propsData: {
        sequenceItem
      }
    });
  }

  return singletons[sequenceItem.uniqueId];
};

export default OutOfOrderComparator;
