import { isNavigationFailure, NavigationFailureType } from 'vue-router';

class Dialog {
  constructor (router) {
    this.router = router;
  }

  names (paramName = "dialog") {
    if (this.router.currentRoute.value.query[paramName] === undefined) {
      return [];
    } else if (typeof this.router.currentRoute.value.query[paramName] === "string") {
      return [this.router.currentRoute.value.query[paramName]];
    } else {
      return this.router.currentRoute.value.query[paramName].slice();
    }
  }

  push (name, paramName = "dialog") {
    if (name.match(/^\//)) {
      return this.router.push({ path: name })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    } else {
      const names = this.names(paramName);
      const updatedDialogParams = {};
      names.push(name);
      updatedDialogParams[paramName] = names;
      this.router.replace({
        query: Object.assign({}, this.router.currentRoute.value.query, updatedDialogParams)
      })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    }
  }

  pop (paramName = "dialog") {
    const names = this.names(paramName);
    const updatedDialogParams = {};
    names.pop();
    updatedDialogParams[paramName] = names;
    this.router.replace({
      query: Object.assign({}, this.router.currentRoute.value.query, updatedDialogParams)
    })
      .catch((e) => {
        if (!e.message.match(/navigation guard/)) {
          throw e;
        }
      });
  }

  override (name, paramName = "dialog") {
    const updatedDialogParams = {};
    updatedDialogParams[paramName] = name;
    this.router.replace({
      query: Object.assign({}, this.router.currentRoute.value.query, updatedDialogParams)
    })
      .catch((e) => {
        if (!(isNavigationFailure(e, NavigationFailureType.duplicated) || isNavigationFailure(e, NavigationFailureType.aborted))) {
          throw e;
        }
      });
  }

  close (paramName = "dialog") {
    const query = { ...this.router.currentRoute.value.query };
    if (paramName in query) {
      delete query[paramName];
      return this.router.replace({ query })
        .catch((e) => {
          if (!isNavigationFailure(e, NavigationFailureType.cancelled)) {
            throw e;
          }
        });
    } else {
      return Promise.resolve();
    }
  }
}

var Dialog$1 = Dialog;

export { Dialog$1 as default };
