import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";
import { StripeReadonlyProfileMixin } from "vue-app-utils-web";

const ReadonlyProfile = {
  extends: Model,
  mixins: [
    Persistable,
    StripeReadonlyProfileMixin
  ],
  data () {
    return {
      hasActiveLicense: false
    };
  }
};

export default defineReactiveModel(ReadonlyProfile);
