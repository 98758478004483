import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import UvmSubscriber from "@/models/uvm/uvm-subscriber";
import UvmAnalysisPort from "@/models/uvm/uvm-analysis-port";
import Parameter from "@/models/parameter";

const UvcPhaseFilter = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage")
  ],
  created () {
    this.iterationCharacter = "p";
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-phase-filter/build-phase" },
      { for: ["me"], partial: "uvc-phase-filter/write" }
    ];
  },
  computed: {
    isUvcPhaseFilter () {
      return true;
    },
    superclass () {
      return this.subscribedTransaction
        ? UvmSubscriber.singleton(this.subscribedTransaction)
        : null;
    },
    subscribedTransaction () {
      return this.sequenceItem;
    },
    type () {
      return `${this.uvc.rootName}_phase_filter_c`;
    },
    parameters () {
      const phaseEnumVariable = this.sequenceItem.phaseEnumVariable;
      return phaseEnumVariable
        ? [
          new Parameter({
            propsData: {
              parent: this,
              type: "parameter " + phaseEnumVariable.rootType,
              name: phaseEnumVariable.name.toUpperCase(),
              defaultValueOverride: phaseEnumVariable.klass.unspecified.name
            }
          })
        ]
        : [];
    },
    computedInstanceVariables () {
      return [
        this.analysisPortHandle
      ]
        .filter(o => o);
    },
    analysisPort () {
      return this.sequenceItem
        ? UvmAnalysisPort.singleton(this.sequenceItem)
        : null;
    }
  }
};

export default defineReactiveModel(UvcPhaseFilter);
