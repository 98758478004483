import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Policy from "@/models/mixins/policy";
import Variable from "@/models/variable";
import Constraint from "@/models/constraint";

const UvcMasterDataPriorityPolicy = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("policy"),
    Policy
  ],
  created () {
    this.master = true;
  },
  computed: {
    subject () {
      return this.masterDataSequenceBase;
    },
    type () {
      return `${this.uvc.rootName}_${this.protocolRoleWord}_data_priority_policy_c`;
    },
    computedInstanceVariables () {
      return [
        this.configHandle,
        this.beatPriorityUpperBoundVariable,
        this.beatPriorityLowerBoundVariable
      ]
        .filter(v => v);
    },
    beatPriorityUpperBoundVariable () {
      return this.configHandle && new Variable({
        propsData: {
          parent: this,
          type: "protected int",
          name: "beat_priority_upper_bound",
          setter: false,
          preRandomizeValue: `${this.configHandle.identifier}.get_next_beat_priority_upper_bound()`
        }
      });
    },
    beatPriorityLowerBoundVariable () {
      return this.configHandle && new Variable({
        propsData: {
          parent: this,
          type: "protected int",
          name: "beat_priority_lower_bound",
          setter: false,
          preRandomizeValue: `${this.configHandle.identifier}.get_beat_priority_lower_bound()`
        }
      });
    },
    computedConstraints () {
      return [
        this.beatPrioritiesConstraint
      ]
        .filter(c => c);
    },
    beatPrioritiesConstraint () {
      return this.masterDataSequenceBase
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.masterDataSequenceBase.beatPrioritiesVariable,
            content: `foreach (object.${this.masterDataSequenceBase.beatPrioritiesVariable.identifier}[p]) {\n  object.${this.masterDataSequenceBase.beatPrioritiesVariable.identifier}[p] inside { [${this.beatPriorityLowerBoundVariable.identifier}:${this.beatPriorityUpperBoundVariable.identifier}] };\n}`
          }
        })
        : null;
    }
  }
};

export default defineReactiveModel(UvcMasterDataPriorityPolicy);
