import { defineReactiveModel } from "vue-app-utils";
import Model from "@/models/model";
import Encapsulator from "@/models/mixins/encapsulator";
import designElementDefinitionHbs from "@/handlebars/design-element-definition.hbs";
import handlebarsRuntimeOptions from "@/handlebars/runtime-options";
import arrayMax from "@/utils/array-max";

const DesignElement = {
  extends: Model,
  mixins: [
    Encapsulator
  ],
  data () {
    return {
      timeUnit: "",
      timePrecision: ""
    };
  },
  computed: {
    code () {
      return designElementDefinitionHbs(this, handlebarsRuntimeOptions).slice(0, -1);
    },
    allParameters () {
      return this.parameters
        .concat(this.computedParameters || []);
    },
    parametersSetToConfigDb () {
      return this.parameters.filter(parameter => !parameter.isLocalparam);
    },
    allNonLocalparams () {
      return this.allParameters.filter(parameter => !parameter.isLocalparam);
    },
    portsTypeMaxLength () {
      return arrayMax(this.ports, "type");
    },
    portsNameMaxLength () {
      return arrayMax(this.ports, "name");
    }
  }
};

export default defineReactiveModel(DesignElement);
