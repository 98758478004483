import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import UvmComponent from "./uvm-component";

const UvmDriver = defineReactiveModel({
  extends: Class,
  props: {
    sequenceItem: {
      type: Object,
      required: true
    }
  },
  created () {
    this.isUvmBaseClass = true;
    this.partialInheritance = [
      { for: ["subclass"], partial: "build-phase" },
      { for: ["subclass"], partial: "uvm-driver/run-phase" },
      { for: ["subclass"], partial: "uvm-driver/drive-task" }
    ];
  },
  computed: {
    superclass () {
      return UvmComponent.singleton();
    },
    type () {
      return `uvm_driver#(${this.sequenceItem.type})`;
    }
  }
});

const singletons = {};

UvmDriver.singleton = function (sequenceItem) {
  if (!sequenceItem) { return null; }

  if (singletons[sequenceItem.uniqueId] === undefined) {
    singletons[sequenceItem.uniqueId] = new UvmDriver({
      propsData: {
        sequenceItem
      }
    });
  }

  return singletons[sequenceItem.uniqueId];
};

export default UvmDriver;
