{{#each msbParameters delimeter="\n"}}
virtual function void update_{{ maskName }}();
{{#if relatedLsb}}
  bit [{{ minOrMaxPackageParameter.name }}:0] {{ maskName }}_lsb = ((1'b1 << {{ relatedLsb.lowerCaseName }}) - 1);
  {{ maskName }} = ((1'b1 << ({{ lowerCaseName }} + 1)) - 1);
  {{ maskName }} &= ~{{ maskName }}_lsb;
{{else}}
  {{ maskName }} = ((1'b1 << ({{ lowerCaseName }} + 1)) - 1);
{{/if}}
endfunction
{{/each}}
