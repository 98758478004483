{{#if hasMonitor}}
{{#if isPipelinedWrite}}
virtual task monitor_request({{ sequenceItemHandle.type }} {{ sequenceItemHandle.identifier }});
  `uvm_fatal(get_type_name(), "Method not implemented.")
endtask

virtual task monitor_data({{ sequenceItemHandle.type }} {{ sequenceItemHandle.identifier }});
  `uvm_fatal(get_type_name(), "Method not implemented.")
endtask
{{else}}
virtual task monitor({{ sequenceItemHandle.type }} {{ sequenceItemHandle.identifier }});
  `uvm_fatal(get_type_name(), "Method not implemented.")
endtask
{{/if}}
{{/if}}
