import uvmgenHeaderHbs from "@/handlebars/uvmgen-header.hbs";
import uvmgenHeaderForUserFilesHbs from "@/handlebars/uvmgen-header-for-user-files.hbs";
import handlebarsRuntimeOptions from "@/handlebars/runtime-options";
import { path } from "@/utils/node-VITE_APP_PLATFORM";
import config from "@/config";

export default {
  props: {
    projectSubDirectoryOverride: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      generating: true
    };
  },
  // [TODO] bad performance
  // created () {
  //   setTimeout(() => {
  //     this.$watch("fileContents", () => {
  //       this.generating = true;
  //       if (this.timer) {
  //         clearTimeout(this.timer);
  //       }
  //       this.timer = setTimeout(() => {
  //         this.generating = false;
  //       }, 500);
  //     });
  //   }, 1000);
  // },
  computed: {
    key () {
      throw new Error(this.absoluteFilePath, " does not have 'key' defined.");
    },
    // ${cwd}/vip/uvcs
    collectionDirectory () {
      return path.join(this.$store.getters.topDir, this.rootProject.user.setting.relVipDir, this.collectionName);
    },
    collectionName () {
      return this.rootProject.collectionName;
    },
    // ie. apb
    projectDirectory () {
      return this.rootProject.projectDirectory;
    },
    // ie. tb or env
    subProjectDirectory () {
      return this.project.subProjectDirectory || "";
    },
    // ie. user or user/seqs or ""
    projectSubDirectory () {
      if (this.projectSubDirectoryOverride) {
        return this.projectSubDirectoryOverride;
      } else if (this.isSequence) {
        return config.sequencesDir;
      } else if (this.isPolicy) {
        return config.policiesDir;
      } else if (this.isInclude) {
        return config.includesDir;
      } else {
        return "";
      }
    },
    // ${cwd}/vip/uvcs/apb/
    absoluteDirPath () {
      return path.dirname(this.absoluteFilePath);
    },
    // ${cwd}/vip/uvcs/apb/apb_pkg.sv
    absoluteFilePath () {
      return path.join(
        this.collectionDirectory,
        this.projectFilePath
      );
    },
    // ie tb/seqs
    relativeDirPath () {
      return path.join(this.subProjectDirectory, this.projectSubDirectory);
    },
    // apb/apb_sequencer_c.svh or apb/user/seqs/apb_master_manual_sequence_c.svh
    projectFilePath () {
      return path.join(this.projectDirectory, this.subProjectDirectory, this.packageFilePath);
    },
    // apb_sequencer_c.svh or user/seqs/apb_master_manual_sequence_c.svh
    packageFilePath () {
      return path.join(this.projectSubDirectory, this.fileName);
    },
    fileName () {
      return this.fileNameOverride || `${this.type}.svh`;
    },
    fileHeader () {
      if (this.userFile) {
        return this.headerForWriteableFiles();
      } else {
        return this.headerForReadOnlyFiles();
      }
    },
    compileGuard () {
      return `__${this.fileName.toUpperCase().replace(".", "_")}__`;
    },
    fileContents () {
      return this.fileHeader + this.code;
    },
    numLines () {
      return this.fileContents.split("\n").length;
    },
    commentSymbol () {
      return "//";
    }
  },
  methods: {
    headerForWriteableFiles () {
      return uvmgenHeaderForUserFilesHbs({
        isWeb: this.$store.getters.isWeb,
        isDesktop: this.$store.getters.isDesktop,
        year: new Date().getFullYear(),
        commentSymbol: this.commentSymbol
      }, handlebarsRuntimeOptions);
    },
    headerForReadOnlyFiles () {
      return uvmgenHeaderHbs({
        isWeb: this.$store.getters.isWeb,
        isDesktop: this.$store.getters.isDesktop,
        user: this.$store.getters.user,
        year: new Date().getFullYear(),
        commentSymbol: this.commentSymbol
      }, handlebarsRuntimeOptions);
    }
  }
};
