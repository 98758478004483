import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Policy from "@/models/mixins/policy";
import Handle from "@/models/handle";
import Variable from "@/models/variable";
import Constraint from "@/models/constraint";
import get from "lodash.get";

const UvcSlaveResponsePolicy = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("policy"),
    Policy
  ],
  created () {
    this.master = false;
    this.channel = "response";
  },
  computed: {
    subject () {
      return this.sequenceItem;
    },
    type () {
      return this.isPipelinedRead
        ? `${this.uvc.rootName}_${this.protocolRoleWord}_response_beat_policy_c`
        : `${this.uvc.rootName}_${this.protocolRoleWord}_response_policy_c`;
    },
    computedInstanceVariables () {
      return [
        this.requestItemHandle,
        this.dataItemHandle,
        this.responseItemHandle,
        this.beatIndexVariable
      ]
        .filter(o => o);
    },
    requestItemHandle () {
      if (this.sequenceItem && !this.isPipelinedRead) {
        return new Handle({
          propsData: {
            parent: this,
            isProt: true,
            klass: this.sequenceItem,
            nameOverride: "request_item",
            setter: true
          }
        });
      } else {
        return undefined;
      }
    },
    dataItemHandle () {
      if (this.sequenceItem && this.isPipelinedWrite) {
        return new Handle({
          propsData: {
            parent: this,
            isProt: true,
            klass: this.sequenceItem,
            nameOverride: "data_item",
            setter: true
          }
        });
      } else {
        return undefined;
      }
    },
    responseItemHandle () {
      if (this.sequenceItem && this.isPipelinedRead) {
        return new Handle({
          propsData: {
            parent: this,
            isProt: true,
            klass: this.sequenceItem,
            nameOverride: "response_item",
            setter: true
          }
        });
      } else {
        return undefined;
      }
    },
    beatIndexVariable () {
      return this.isPipelinedRead
        ? new Variable({
          propsData: {
            parent: this,
            type: "protected int",
            name: "beat_index",
            setter: true
          }
        })
        : null;
    },
    computedConstraints () {
      const constraints = [
        this.phaseConstraint,
        this.dataConstraint,
        this.lenConstraint,
        this.lastConstraint
      ]
        .filter(c => c);

      this.masterConstraints.concat(this.slaveConstraints).forEach(constraint => {
        if (!constraints.find(c => c.instanceVariable.id === constraint.instanceVariable.id)) {
          constraints.push(constraint);
        }
      });
      return constraints;
    },
    phaseConstraint () {
      return (this.sequenceItem && this.sequenceItem.phaseEnumVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.phaseEnumVariable,
            content: `object.${this.sequenceItem.phaseEnumVariable.identifier} == ${this.sequenceItem.phaseEnumVariable.klass.response.name};`
          }
        })
        : null;
    },
    dataConstraint () {
      return (this.sequenceItem && this.sequenceItem.dataVariables.length && this.requestItemHandle && this.sequenceItem.writeVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.dataVariables[0],
            content: `if (object.${this.sequenceItem.writeVariable.identifier}) {\n  object.${this.sequenceItem.dataVariables[0].identifier} == ${this.requestItemHandle.identifier}.${this.sequenceItem.dataVariables[0].identifier};\n}`
          }
        })
        : null;
    },
    masterConstraints () {
      if (this.requestItemHandle || this.responseItemHandle) {
        const masterIvs = get(this.sequenceItem, "almostAllInstanceVariables", []).filter(iv => iv.relatedPort && iv.isMaster);

        return masterIvs.map(iv => {
          let content;
          if (get(iv, "nameInfo.unpacked.dimensions[0].type") === "dynamicArray") {
            if (this.sequenceItem && this.sequenceItem.lenVariable && this.requestItemHandle && this.dataItemHandle) {
              content = `object.${iv.identifier}.size == ${this.requestItemHandle.identifier}.${this.sequenceItem.lenVariable.identifier};
foreach (object.${iv.identifier}[${iv.identifier[0]}]) {
  object.${iv.identifier}[${iv.identifier[0]}] == ${this.dataItemHandle.identifier}.${iv.identifier}[${iv.identifier[0]}];
}`;
            } else {
              return null;
            }
          } else {
            content = `object.${iv.identifier} == ${(this.requestItemHandle || this.responseItemHandle).identifier}.${iv.identifier};`;
          }
          return new Constraint({
            propsData: {
              parent: this,
              instanceVariable: iv,
              content
            }
          });
        })
          .filter(c => c);
      } else {
        return [];
      }
    },
    slaveConstraints () {
      if (this.sequenceItem && this.sequenceItem.lenVariable && this.responseItemHandle && this.beatIndexVariable) {
        const slaveIvs = get(this.sequenceItem, "almostAllInstanceVariables", []).filter(iv => iv.relatedPort && iv.isSlave && get(iv, "nameInfo.unpacked.dimensions[0].type") === "dynamicArray");

        return slaveIvs.map(iv => {
          return new Constraint({
            propsData: {
              parent: this,
              instanceVariable: iv,
              content: `object.${iv.identifier}.size == object.${this.sequenceItem.lenVariable.identifier};
foreach (object.${iv.identifier}[${iv.identifier[0]}]) {
  object.${iv.identifier}[${iv.identifier[0]}] == ${this.responseItemHandle.identifier}.${iv.identifier}[${this.beatIndexVariable.identifier} + ${iv.identifier[0]}];
}`
            }
          });
        });
      } else {
        return [];
      }
    },
    lenConstraint () {
      return (this.sequenceItem && this.sequenceItem.lenVariable && !this.isPipelinedWrite)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.lenVariable,
            content: `object.${this.sequenceItem.lenVariable.identifier} == 1;`
          }
        })
        : null;
    },
    lastConstraint () {
      return (this.sequenceItem && this.sequenceItem.lastVariable && ((this.responseItemHandle && this.sequenceItem.lenVariable) || this.requestItemHandle))
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.lastVariable,
            content: this.responseItemHandle
              ? `object.${this.sequenceItem.lastVariable.identifier} == (${this.responseItemHandle.identifier}.${this.sequenceItem.lenVariable.identifier} == (${this.beatIndexVariable.identifier} + object.${this.sequenceItem.lenVariable.identifier}));`
              : `object.${this.sequenceItem.lastVariable.identifier} == 0;`
          }
        })
        : null;
    }
  }
};

export default defineReactiveModel(UvcSlaveResponsePolicy);
