import { addDoc, collection, getDoc, doc } from 'firebase/firestore';
import bowser from './utils/bowser.js';
import firestore from './db/firestore.js';
import currentTime from './utils/time.js';

const userName = function (store) {
  if (!store.getters.isAuthenticated) {
    return "Unauthenticated user";
  } else if (store.getters.user.isAnonymous) {
    return "Anonymous user";
  } else {
    return store.getters.user.displayName;
  }
};

const getUserEmail = function (store) {
  if (!store.getters.isAuthenticated) {
    return Promise.resolve(`not-authenticated@${__APP_SITE_DOMAIN__}`); // eslint-disable-line no-undef
  } else if (store.getters.user.isAnonymous) {
    return Promise.resolve(`anonymous@${__APP_SITE_DOMAIN__}`); // eslint-disable-line no-undef
  } else {
    const db = firestore();
    return getDoc(doc(collection(db, "privateProfiles"), userId(store)))
      .then((doc) => {
        if (!doc.exists()) {
          return `private-profile-not-found@${__APP_SITE_DOMAIN__}`; // eslint-disable-line no-undef
        } else {
          // noreply can happen on a page refresh when they are anonymous. For some reason user.isAnonymous == false at the start.
          return (doc.data() && doc.data().email) || `noreply@${__APP_SITE_DOMAIN__}`; // eslint-disable-line no-undef
        }
      }); // Can not catch and rethrow here because it causes an infinite loop in the error-handler.
  }
};

const userId = function (store) {
  if (!store.getters.isAuthenticated) {
    return "id not set";
  } else {
    return store.getters.user.id;
  }
};

const userData = function (store) {
  const name = `${userName(store)} (${userId(store)})`;
  return name + "\n" +
    bowser.browser + " on " + bowser.os;
};

const sendFeedback = function (subject, message, store, notifySnackbar = true) {
  const payload = {
    userId: userId(store),
    createdAt: currentTime(),
    private: true,
    to: [`${import.meta.env.VITE_APP_SEND_MAIL_RECIPIENT}`],
    from: `${userName(store)} <user@${__APP_SITE_DOMAIN__}>`, // eslint-disable-line no-undef
    message: {
      subject: `${__APP_SITE_NAME__} (${__APP_GIT_VERSION__}) - ${subject}`, // eslint-disable-line no-undef
      text: message + "\n\n" + userData(store)
    }
  };
  return getUserEmail(store).then((email) => {
    const db = firestore();
    payload.replyTo = email;
    return addDoc(collection(db, "emails"), payload)
      .then(() => {
        return new Promise((resolve) => {
          if (notifySnackbar) {
            setTimeout(() => {
              store.commit("pushSnackbarMessageProps", {
                message: "Message sent. Thank you."
              });
              resolve();
            }, 1000);
          } else {
            resolve();
          }
        });
      }); // Can not catch and rethrow here because it causes an infinite loop in the error-handler.
  });
};

var sendFeedback$1 = sendFeedback;

export { sendFeedback$1 as default };
