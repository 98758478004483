import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";
import SubroutineMixin from "@/models/mixins/subroutine";

// Change computed sub from uniqueId...actually maybe you don't want to change it because the sorting of subroutines, and the override of a computed subroutine is based on uniqueId. A neccessary
// thing because there are hasOne tasks. I think... look at where you wanted to remove uniqueId and reassess.
const Subroutine = {
  extends: Model,
  mixins: [
    Persistable,
    SubroutineMixin
  ],
  data () {
    return {
      content: "",
      overrideId: ""
    };
  },
  created () {
    this.computed = false;

    if (!this.content) {
      const unwatch = this.$watch("content", (content) => {
        this.validateContent(content);
        this.$nextTick(() => { unwatch(); });
      });
    }
  },
  computed: {
    todo () {
      return "Add subroutine";
    },
    placeholder () {
      return this.overriddenSubroutine ? this.overriddenSubroutine.placeholder : "";
    },
    mandatory () {
      return (this.overriddenSubroutine && this.overriddenSubroutine.mandatory);
    },
    overriddenSubroutine () {
      return this.parent.computedSubroutines.find(computedSubroutine => computedSubroutine.id === this.overrideId);
    },
    requiredIdentifier () {
      return this.overriddenSubroutine ? this.overriddenSubroutine.requiredIdentifier : "";
    },
    requiredArgs () {
      return (this.overriddenSubroutine && this.overriddenSubroutine.requiredArgs);
    }
  },
  methods: {
    beforePersistableUpdate (obj) {
      const res = this.validateContent(obj.content);
      if (res === true) {
        return Promise.resolve(obj);
      } else {
        return Promise.reject(new Error("Content validation failed: " + res));
      }
    }
  }
};

export default defineReactiveModel(Subroutine);
