import pluralize from "pluralize-esm";

const singletons = {};

// This is needed even if you're not going to support changing project of a form class.
// For instance, when you create a policy for a uvc-config in an env.
export default function (collectionName) {
  if (!(collectionName in singletons)) {
    const computed = {};
    const methods = {};

    const titlizedCollectionName = collectionName.charAt(0).toUpperCase() + collectionName.substr(1);
    const formCollectionName = `form${titlizedCollectionName}`;

    const itemName = pluralize.singular(collectionName);
    const titlizedItemName = itemName.charAt(0).toUpperCase() + itemName.substr(1);
    const formItemName = `form${titlizedItemName}`;
    const titlizedFormItemName = formItemName.charAt(0).toUpperCase() + formItemName.substr(1);
    const createFormItemName = `create${titlizedFormItemName}`;

    computed[formCollectionName] = function () {
      return this.user.formClasses.filter(p => {
        return (p.formType === itemName) && (p.subject === this);
      });
    };

    methods[createFormItemName] = function (data, rootProject = null) {
      const r = rootProject || this.rootProject;
      const p = rootProject || this.project;
      const user = r.user;
      return user.createFormClass({
        formType: itemName,
        subjectUserId: this.user.id,
        subjectRootCollection: this.rootProject.collectionName,
        subjectRootId: this.rootProject.id,
        subjectProjectUniqueId: this.project.uniqueId,
        subjectUniqueId: this.uniqueId,
        rootCollection: r.collectionName,
        rootId: r.id,
        projectUniqueId: p.uniqueId,
        ...data
      });
    };

    singletons[collectionName] = {
      computed,
      methods
    };
  }

  return singletons[collectionName];
}
