import { defineReactiveModel, Persistable, hasOne, hasMany } from "vue-app-utils";
import Model from "@/models/model";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvmSequence from "@/models/uvm/uvm-sequence";
import GeneratableFile from "@/models/mixins/generatable-file";
import Package from "@/models/mixins/package";
import userFileRelations from "@/models/persistable/mixins/user-file-relations-VITE_APP_PLATFORM";
import config from "@/config";
import EnumType from "@/models/enum-type";
import Parameter from "@/models/parameter";
import get from "lodash.get";
import uvmPackage from "@/models/uvm/uvm-package";

const { hasMany: hasManyUserFiles } = userFileRelations;

const UvcPackage = {
  extends: Model,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Package,
    Persistable,
    // belongsTo("uvc"),
    hasMany("parameters", {
      orderBy: ["createdAt"]
    }),
    hasMany("typedefs"),
    hasOne("uvcAbstractBfm"),
    hasOne("uvcAbstractBfmCreator"),
    hasOne("uvcAbstractProtocolCheckerAccessor"),
    hasOne("uvcParameters"),
    hasOne("uvcConfig"),
    hasOne("uvcMasterMaxNumTransactionsInProgressPolicy"),
    hasOne("uvcMasterInOrderBeatPrioritiesPolicy"),
    hasOne("uvcMasterOutOfOrderBeatPrioritiesPolicy"),
    hasOne("uvcMasterStarvedBeatPrioritiesPolicy"),
    hasOne("uvcSlaveMaxNumTransactionsInProgressPolicy"),
    hasOne("uvcSlaveInOrderResponsePrioritiesPolicy"),
    hasOne("uvcSlaveOutOfOrderResponsePrioritiesPolicy"),
    hasOne("uvcSlaveStarvedResponsePrioritiesPolicy"),
    hasOne("uvcSequenceItem"),
    hasOne("uvcMasterRequestPolicy"),
    hasOne("uvcMasterRequestDefaultPreTransferDelayPolicy"),
    hasOne("uvcMasterFullDataPolicy"),
    hasOne("uvcMasterDataPolicy"),
    hasOne("uvcMasterDataDefaultPreTransferDelayPolicy"),
    hasOne("uvcMasterRefItemPolicy"),
    hasOne("uvcMasterResponsePolicy"),
    hasOne("uvcSlaveRequestPolicy"),
    hasOne("uvcSlaveRequestDefaultPreTransferDelayPolicy"),
    hasOne("uvcSlaveDataPolicy"),
    hasOne("uvcSlaveFullResponsePolicy"),
    hasOne("uvcSlaveResponsePolicy"),
    hasOne("uvcSlaveResponseDefaultPreTransferDelayPolicy"),
    hasOne("uvcMonitor"),
    hasOne("uvcSequencer"),
    hasOne("uvcCoverage"),
    hasOne("uvcMasterRequestDriver"),
    hasOne("uvcMasterDataDriver"),
    hasOne("uvcMasterResponseDriver"),
    hasOne("uvcMasterAgent"),
    hasOne("uvcMasterRequestSequenceBase"),
    hasOne("uvcMasterRequestSequence"),
    hasOne("uvcMasterDataSequenceBase"),
    hasOne("uvcMasterDataSequence"),
    hasOne("uvcMasterResponseSequenceBase"),
    hasOne("uvcSlaveRequestDriver"),
    hasOne("uvcSlaveDataDriver"),
    hasOne("uvcSlaveResponseDriver"),
    hasOne("uvcSlaveAgent"),
    hasOne("uvcSlaveRequestSequenceBase"),
    hasOne("uvcSlaveRequestSequence"),
    hasOne("uvcSlaveDataSequenceBase"),
    hasOne("uvcSlaveResponseSequenceBase"),
    hasOne("uvcSlaveResponseSequence"),
    hasOne("uvcMasterDataPriorityPolicy"),
    hasOne("uvcSlaveResponsePriorityPolicy"),
    hasOne("uvcSlaveSequence"),
    hasOne("uvcVirtualSequenceBase"),
    hasOne("uvcMasterRequestVirtualSequence"),
    hasOne("uvcMasterVirtualSequence"),
    hasOne("uvcSlaveVirtualSequence"),
    hasOne("uvcPhaseFilter"),
    hasOne("uvcRegisterAdapter"),
    // [TODO] turn into policy
    hasOne("uvcRegisterSequenceItem"),
    hasManyUserFiles("userClasses", {
      directory: function () {
        return config.userDir;
      },
      ignoreDirectories: [
        config.includesDir
      ]
    })
  ],
  data () {
    return {
      beatsInBurstMaxParameterValue: "256"
    };
  },
  computed: {
    type () {
      return `${this.uvc.rootName}_pkg`;
    },
    externalIncludes () {
      return [
        this.user.policyLib.package
      ];
    },
    staticImports () {
      return [
        uvmPackage,
        this.user.policyLib.package
      ];
    },
    allParameters () {
      return this.minAndMaxParameters
        .concat(this.computedParameters)
        .concat(this.parameters);
    },
    computedParameters () {
      return [
        this.beatsInBurstMaxParameter
      ]
        .filter(p => p);
    },
    beatsInBurstMaxParameter () {
      return this.isPipelined
        ? new Parameter({
          propsData: {
            parent: this,
            type: "parameter",
            name: `${this.uvc.rootName.toUpperCase()}_BEATS_IN_BURST_MAX`,
            defaultValueOverride: this.beatsInBurstMaxParameterValue
          }
        })
        : null;
    },
    minAndMaxParameters () {
      return get(this, "interface.parameters", [])
        .map(parameter => parameter.minOrMaxPackageParameter)
        .filter(parameter => parameter);
    },
    parametersByName () {
      const parametersByName = {};
      this.allParameters.forEach((p) => {
        parametersByName[p.name] = p;
      });
      return parametersByName;
    },
    baseGeneratableFileModels () {
      return [
        this.abstractBfm,
        this.abstractBfmCreator,
        this.abstractProtocolCheckerAccessor,
        this.uvc.uvcParameters,
        this.config,
        this.masterMaxNumTransactionsInProgressPolicy,
        this.masterInOrderBeatPrioritiesPolicy,
        this.masterOutOfOrderBeatPrioritiesPolicy,
        this.masterStarvedBeatPrioritiesPolicy,
        this.slaveMaxNumTransactionsInProgressPolicy,
        this.slaveInOrderResponsePrioritiesPolicy,
        this.slaveOutOfOrderResponsePrioritiesPolicy,
        this.slaveStarvedResponsePrioritiesPolicy,
        this.sequenceItem,
        this.registerSequenceItem,
        this.masterRequestPolicy,
        this.masterRequestDefaultPreTransferDelayPolicy,
        this.masterFullDataPolicy,
        this.masterDataPolicy,
        this.masterDataDefaultPreTransferDelayPolicy,
        this.masterRefItemPolicy,
        this.masterResponsePolicy,
        this.slaveRequestPolicy,
        this.slaveRequestDefaultPreTransferDelayPolicy,
        this.slaveDataPolicy,
        this.slaveFullResponsePolicy,
        this.slaveResponsePolicy,
        this.slaveResponseDefaultPreTransferDelayPolicy,
        this.sequencer,
        this.monitor,
        this.coverage,
        this.masterRequestDriver,
        this.masterDataDriver,
        this.masterResponseDriver,
        this.masterAgent,
        this.slaveRequestDriver,
        this.slaveDataDriver,
        this.slaveResponseDriver,
        this.slaveAgent,
        this.phaseFilter,
        this.registerAdapter,
        this.masterRequestSequenceBase,
        this.masterRequestSequence,
        this.masterDataSequenceBase,
        this.masterDataSequence,
        this.masterResponseSequenceBase,
        this.slaveRequestSequenceBase,
        this.slaveRequestSequence,
        this.slaveDataSequenceBase,
        this.slaveResponseSequenceBase,
        this.slaveResponseSequence,
        this.masterDataPriorityPolicy,
        this.slaveResponsePriorityPolicy,
        this.slaveSequence,
        this.virtualSequenceBase,
        this.masterRequestVirtualSequence,
        this.masterVirtualSequence,
        this.slaveVirtualSequence
      ]
        .filter(m => m);
    },
    generatableFileModels () {
      return [
        // ...baseWithPolicies,
        ...this.insertBefore(this.baseGeneratableFileModels, this.formPolicies, m => m.isSequencer),
        ...this.nonBfmFormSubclasses,
        ...this.formSequences
        // ...this.formSubclasses,
      ];
    },
    modelPairs () {
      let previous = null;
      return this.insertBefore(this.generatableFileModels, this.userPolicies, m => m.isSequencer)
        .concat(this.nonBfmUserSubclasses)
        .concat(this.userSequences)
        // .concat(this.userPolicies)
        .map(current => {
          const ret = {
            previous,
            current
          };
          previous = current;
          return ret;
        });
    },
    formClasses () {
      return this.user.formClasses.filter(f => f.projectUniqueId === this.project.uniqueId);
    },
    formSubclasses () {
      return this.formClasses.filter(s => s.isSubclass && !!s.superclass);
    },
    formSequences () {
      return this.formClasses.filter(s => s.isSequence && !!s.superclass && ((s.isMaster && this.uvc.hasMaster) || (s.isSlave && this.uvc.hasSlave)));
    },
    formPolicies () {
      return this.formClasses.filter(s => s.isPolicy && !!s.subject && ((s.isMaster && this.uvc.hasMaster) || (s.isSlave && this.uvc.hasSlave)));
    },
    masterPolicies () {
      return this.allPolicies.filter(c => c.subject && (c.isMaster && this.hasMaster));
    },
    slavePolicies () {
      return this.allPolicies.filter(c => c.subject && (!c.isMaster && this.hasSlave));
    },
    formConfigPolicies () {
      return this.formPolicies.filter(c => c.subject && c.subject.isConfig);
    },
    masterSequences () {
      return this.allSequences.filter(c => c.isMaster && this.hasMaster);
    },
    slaveSequences () {
      return this.allSequences.filter(c => (!c.isMaster && this.hasSlave) && c !== UvmSequence.singleton(this.sequenceItem));
    },
    userSequences () {
      return this.userClasses.filter(userClass => userClass.isSequence);
    },
    userPolicies () {
      return this.userClasses.filter(userClass => userClass.isPolicy);
    },
    bfmUserSubclasses () {
      return this.bfmBase
        ? this.userSubclasses.filter((s) => {
          return !!s.superclasses.find((s) => s.type === this.bfmBase.type);
        })
        : [];
    },
    bfmFormSubclasses () {
      return this.bfmBase
        ? this.formSubclasses.filter((s) => {
          return !!s.superclasses.find((s) => s.type === this.bfmBase.type);
        })
        : [];
    },
    nonBfmFormSubclasses () {
      return this.bfmBase
        ? this.formSubclasses.filter((s) => {
          return !s.superclasses.find((s) => s.type === this.bfmBase.type);
        })
        : [];
    },
    nonBfmUserSubclasses () {
      return this.bfmBase
        ? this.userSubclasses.filter((s) => {
          return !s.superclasses.find((s) => s.type === this.bfmBase.type);
        })
        : [];
    },
    enumTypes () {
      return [
        this.phaseEnumType
      ]
        .filter((o) => o);
    },
    phaseEnumType () {
      return this.hasPhases
        ? new EnumType({
          propsData: {
            type: `${this.uvc.rootName}_phase_e`,
            namedValues: [
              {
                identifier: "unspecified",
                name: `${this.uvc.rootName.toUpperCase()}_PHASE_UNSPECIFIED`
              },
              {
                identifier: "request",
                name: `${this.uvc.rootName.toUpperCase()}_PHASE_REQUEST`
              },
              this.isPipelinedWrite && {
                identifier: "data",
                name: `${this.uvc.rootName.toUpperCase()}_PHASE_DATA`
              },
              {
                identifier: "response",
                name: `${this.uvc.rootName.toUpperCase()}_PHASE_RESPONSE`
              }
            ]
              .filter(v => v)
          }
        })
        : null;
    }
  }
};

export default defineReactiveModel(UvcPackage);
