import createLinkedList from "@/utils/create-linked-list";

export default {
  computed: {
    unpackedDimensions () {
      return [];
    },
    isMultiDimensional () {
      return this.numDimensions > 1;
    },
    hasDimensions () {
      return !!this.numDimensions;
    },
    numDimensions () {
      return this.unpackedDimensions.length;
    },
    unpackedDeclarationBrackets () {
      if (this.hasDimensions) {
        const brackets = [];
        for (let n = 0; n < this.numDimensions; n++) {
          brackets.push("[]");
        }
        return brackets.join("");
      } else {
        return "";
      }
    },
    formatBrackets () {
      return "[%0d]".repeat(this.numDimensions);
    },
    dimensionVariables () {
      return this.iterationVariables("d");
    },
    dimensionPropertiesList () {
      return createLinkedList(this.numDimensions, this.dimensionProperties.bind(this));
    }
  },
  methods: {
    dimensionProperties (index) {
      const dimension = this.unpackedDimensions[index];

      return {
        dimensionVariable: this.dimensionVariables[index],
        blockNameSuffix: this.isMultiDimensional ? `_${this.dimensionVariables[index].toUpperCase()}` : "",
        ...dimension
      };
    },
    iterationVariables (valuePrefix) {
      if (this.hasDimensions) {
        const variables = [];
        if (this.numDimensions === 1) {
          variables.push(`${valuePrefix}`);
        } else {
          for (let n = 0; n < this.numDimensions; n++) {
            variables.push(`${valuePrefix}${n}`);
          }
        }
        return variables;
      } else {
        return [];
      }
    },
    dimensionKey () {
      return this.unpackedDimensions
        .map(i => (i.lhs.constantExpression + "-" + i.rhs.constantExpression).replace(/\s/g, ""));
    }
  }
};
