import bowser from './utils/bowser.js';

const getIpData = function () {
  return fetch("https://extreme-ip-lookup.com/json/")
  // fetch("http://ip-api.com/json")
    .then(res => {
      return res.json();
    })
    .then(response => {
      const additionalData = {};
      if (response.city) {
        additionalData.city = response.city;
      }
      if (response.region) {
        additionalData.region = response.region;
      }
      if (response.regionName) {
        additionalData.regionName = response.regionName;
      }
      if (response.country) {
        additionalData.country = response.country;
      }
      if (response.countryCode) {
        additionalData.countryCode = response.countryCode;
      }
      return additionalData;
    });
};

const mergeIpData = function (data) {
  return getIpData()
    .then((ipData) => {
      return {
        ...data,
        ...ipData
      };
    })
    .catch((e) => {
      console.error(`IP lookup failed: ${e.message}`);
      return {
        ...data
      };
    });
};

var ipDataMixin = {
  methods: {
    getData (additionalData = {}) {
      return {
        os: bowser.os,
        browser: bowser.browser,
        ...additionalData
      };
    },
    // [TODO] remove after soak. writing to a document on a find is only used here and adds complication when unauthenticated user want to read public docs.
    // onFindUser: function (existingData) {
    //   const additionalData = this.getData();
    //   if (existingData.city) {
    //     return Promise.resolve(additionalData);
    //   } else {
    //     return mergeIpData(additionalData);
    //   }
    // },
    onCreateUser: function (additionalData) {
      return mergeIpData(this.getData(additionalData));
    }
  }
};

export { ipDataMixin as default };
