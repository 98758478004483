import partials from "@/handlebars/partials-VITE_APP_PLATFORM";

const helpers = {};

const handlebarHelpers = import.meta.globEager("@/handlebars/helpers/**/*.js");
for (const key in handlebarHelpers) {
  const helperName = key.match(/([a-zA-Z0-9]+).js$/)[1];
  helpers[helperName] = handlebarHelpers[key].default;
}

export default {
  // allowProtoMethodsByDefault: true,
  allowProtoPropertiesByDefault: true,
  helpers,
  partials
};
