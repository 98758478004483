{{#each allInstanceVariablesWithSetter delimeter="\n" tn=true}}
virtual function void {{ setterName }}({{ rootType }} {{ name }}{{#each appendedSetterArgs lc=true}}{{ . }}{{/each}});
  {{#each prependedSetterPartials delimeter="\n"}}
  {{> (partial .) handle=.. context=../..}}
  {{/each}}
  {{#if setterPartials.length}}
  {{#each setterPartials delimeter="\n" tn=isDeepSetter}}
  {{> (partial .) handle=.. context=../..}}
  {{/each}}
  {{else}}
  {{#unless setterOnly}}
  this.{{ identifier }} = {{ identifier }};
  {{#if isDeepSetter}}

  {{/if}}
  {{/unless}}
  {{/if}}
  {{#each appendedSetterPartials delimeter="\n"}}
  {{> (partial .) handle=.. context=../..}}
  {{/each}}
  {{#if isDeepSetter}}
  if (deep) begin
    {{ setterNameInSubenvs }}({{ identifier }});
  end
  {{/if}}
endfunction
{{#if isDeepSetter}}

virtual function void {{ setterNameInSubenvs }}({{ rootType }} {{ identifier }}_in_subenvs);
  {{#each subEnvConfigHandlesWithoutDimensions}}
  {{#with (lookup klass.setterVariables ../identifier)}}
  {{> (partial "env-config/call-setter") handle=.. setterName=../../setterName variableName=(join (toArray ../../identifier "_in_subenvs") "") deep=isDeepSetter}}
  {{/with}}
  {{/each}}
  {{#each subEnvConfigHandlesWithDimensions ln=subEnvConfigHandlesWithoutDimensions.length delimeter="\n"}}
  {{#with (lookup klass.setterVariables ../identifier)}}
  {{> (partial "env-config/call-setter") handle=.. iterationPropertiesList=../iterationPropertiesList setterName=../../setterName variableName=(join (toArray ../../identifier "_in_subenvs") "") deep=isDeepSetter}}
  {{/with}}
  {{/each}}
endfunction
{{/if}}
{{/each}}
