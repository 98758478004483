import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Policy from "@/models/mixins/policy";
import Handle from "@/models/handle";
import Constraint from "@/models/constraint";
import get from "lodash.get";

const UvcMasterFullDataPolicy = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("policy"),
    Policy
  ],
  created () {
    this.master = true;
  },
  computed: {
    subject () {
      return this.sequenceItem;
    },
    type () {
      return `${this.uvc.rootName}_${this.protocolRoleWord}_data_policy_c`;
    },
    computedInstanceVariables () {
      return [
        this.refItemHandle
      ]
        .filter(o => o);
    },
    refItemHandle () {
      if (this.sequenceItem) {
        return new Handle({
          propsData: {
            parent: this,
            isProt: true,
            klass: this.sequenceItem,
            nameOverride: "ref_item",
            setter: true
          }
        });
      } else {
        return undefined;
      }
    },
    computedConstraints () {
      const constraints = [
        this.phaseConstraint,
        this.preTransferDelayConstraint,
        this.lastConstraint
      ]
        .filter(c => c);

      this.masterConstraints.concat(this.slaveConstraints).forEach(constraint => {
        if (!constraints.find(c => c.instanceVariable.id === constraint.instanceVariable.id)) {
          constraints.push(constraint);
        }
      });
      return constraints;
    },
    phaseConstraint () {
      return get(this, "sequenceItem.phaseEnumVariable.klass.data.name")
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.phaseEnumVariable,
            content: `object.${this.sequenceItem.phaseEnumVariable.identifier} == ${this.sequenceItem.phaseEnumVariable.klass.data.name};`
          }
        })
        : null;
    },
    preTransferDelayConstraint () {
      return (this.sequenceItem && this.sequenceItem.preTransferDelayVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.preTransferDelayVariable,
            content: `object.${this.sequenceItem.preTransferDelayVariable.identifier} == 0;`
          }
        })
        : null;
    },
    lastConstraint () {
      return (this.sequenceItem && this.sequenceItem.lastVariable)
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.sequenceItem.lastVariable,
            content: `object.${this.sequenceItem.lastVariable.identifier} == 0;`
          }
        })
        : null;
    },
    masterConstraints () {
      if (this.refItemHandle) {
        const masterIvs = get(this.sequenceItem, "almostAllInstanceVariables", []).filter(iv => iv.relatedPort && iv.isMaster);

        return masterIvs.map(iv => {
          let content;

          if (get(iv, "nameInfo.unpacked.dimensions[0].type") === "dynamicArray") {
            if (this.sequenceItem && this.sequenceItem.lenVariable) {
              content = `object.${iv.identifier}.size == ${this.refItemHandle.identifier}.${this.sequenceItem.lenVariable.identifier};`;
            } else {
              return null;
            }
          } else {
            content = `object.${iv.identifier} == ${this.refItemHandle.identifier}.${iv.identifier};`;
          }

          return new Constraint({
            propsData: {
              parent: this,
              instanceVariable: iv,
              content
            }
          });
        })
          .filter(c => c);
      } else {
        return [];
      }
    },
    slaveConstraints () {
      if (this.sequenceItem) {
        const slaveIvs = get(this.sequenceItem, "almostAllInstanceVariables", []).filter(iv => iv.relatedPort && iv.isSlave && !(get(iv, "nameInfo.unpacked.dimensions[0].type") === "dynamicArray"));

        return slaveIvs.map(iv => {
          return new Constraint({
            propsData: {
              parent: this,
              instanceVariable: iv,
              content: `object.${iv.identifier} == 0;`
            }
          });
        });
      } else {
        return [];
      }
    }
  }
};

export default defineReactiveModel(UvcMasterFullDataPolicy);
