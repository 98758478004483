import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import UvmSequence from "@/models/uvm/uvm-sequence";
import Handle from "@/models/handle";
import Variable from "@/models/variable";
import FieldMacroFlagEnum from "@/utils/field-macro-flag-enum";
import Constraint from "@/models/constraint";
import get from "lodash.get";

const UvcMasterDataSequenceBase = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("sequence")
  ],
  created () {
    this.master = true;
    this.channel = "data";
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-master-data-sequence-base/body" }
    ];
  },
  computed: {
    superclass () {
      if (this.sequenceItem) {
        return UvmSequence.singleton(this.sequenceItem);
      } else {
        return undefined;
      }
    },
    type () {
      const parts = [this.uvc.rootName];
      parts.push(this.protocolRoleWord);
      parts.push("data");
      parts.push("sequence");
      if (this.masterDataSequence) {
        parts.push("base");
      }
      parts.push("c");
      return parts.join("_");
    },
    computedInstanceVariables () {
      return [
        this.configHandle,
        this.refItemHandle,
        this.dataItemHandle,
        this.beatPrioritiesVariable,
        this.beatIndexVariable
      ]
        .filter(o => o);
    },
    computedConstraints () {
      return [
        this.beatPrioritiesConstraint
      ]
        .filter(c => c);
    },
    preRandomizeInitialValues () {
      return this.randomObjectCreateHandles.map(handle => {
        return {
          name: handle.identifier,
          defaultValue: `${handle.createRandomFunctionName}()`
        };
      });
    },
    randomObjectCreateHandles () {
      return [
        this.dataItemHandle
      ]
        .filter(h => h);
    },
    refItemHandle () {
      if (this.sequenceItem) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.sequenceItem,
            nameOverride: "ref_item",
            setter: true
          }
        });
      } else {
        return undefined;
      }
    },
    dataItemHandle () {
      if (this.sequenceItem && this.isPipelined) {
        return new Handle({
          propsData: {
            id: "static-id-0037feb",
            parent: this,
            klass: this.sequenceItem,
            nameOverride: "data_item",
            isRandomRefItem: true,
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT"),
              FieldMacroFlagEnum.getValue("UVM_NOCOMPARE"),
              FieldMacroFlagEnum.getValue("UVM_REFERENCE")
            ]
          }
        });
      } else {
        return undefined;
      }
    },
    sequenceItemHandle () {
      return this.reqHandle;
    },
    reqHandle () {
      if (this.sequenceItem) {
        return new Handle({
          propsData: {
            id: "static-id-5c37feb",
            parent: this,
            klass: this.sequenceItem,
            nameOverride: "req",
            priority: this.isPipelined
              ? `${this.beatPrioritiesVariable.identifier}[${this.beatIndexVariable.identifier}]`
              : null
          }
        });
      } else {
        return undefined;
      }
    },
    beatPrioritiesVariable () {
      return this.isPipelined
        ? new Variable({
          propsData: {
            id: "static-id-7487ef2",
            parent: this,
            type: "rand int",
            name: "beat_priorities[]",
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT"),
              FieldMacroFlagEnum.getValue("UVM_NOCOMPARE"),
              FieldMacroFlagEnum.getValue("UVM_NOPRINT")
            ]
          }
        })
        : null;
    },
    beatPrioritiesConstraint () {
      return (this.beatPrioritiesVariable && get(this, "dataItemHandle.klass.lenVariable"))
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.beatPrioritiesVariable,
            content: `${this.beatPrioritiesVariable.identifier}.size == ${this.dataItemHandle.identifier}.${this.dataItemHandle.klass.lenVariable.identifier};\nforeach (${this.beatPrioritiesVariable.identifier}[b]) {\n  ${this.beatPrioritiesVariable.identifier}[b] >= 0;\n}`
          }
        })
        : null;
    },
    beatIndexVariable () {
      return this.isPipelined
        ? new Variable({
          propsData: {
            // id: "static-id-74efef2",
            parent: this,
            type: "int",
            name: "beat_index",
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT"),
              FieldMacroFlagEnum.getValue("UVM_NOCOMPARE"),
              FieldMacroFlagEnum.getValue("UVM_NOCOPY"),
              FieldMacroFlagEnum.getValue("UVM_NOPRINT")
            ]
          }
        })
        : null;
    },
    randomObjectDoHandles () {
      return [
        this.dataItemHandle,
        this.reqHandle
      ]
        .filter(h => h);
    },
    computedPolicyHandles () {
      return [
        this.reqHandle && this.masterDataPolicy && new Handle({
          propsData: {
            parent: this,
            klass: this.masterDataPolicy,
            relations: {
              randomObjectDoHandle: this.reqHandle
            }
          }
        }),
        this.dataItemHandle && this.masterFullDataPolicy && new Handle({
          propsData: {
            parent: this,
            klass: this.masterFullDataPolicy,
            relations: {
              randomObjectDoHandle: this.dataItemHandle
            }
          }
        })
      ]
        .filter(h => h);
    }
  }
};

export default defineReactiveModel(UvcMasterDataSequenceBase);
