import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";
import { StripePaymentMethodMixin } from "vue-app-utils-web";

const PaymentMethod = {
  extends: Model,
  mixins: [
    Persistable,
    StripePaymentMethodMixin
  ]
};

export default defineReactiveModel(PaymentMethod);
