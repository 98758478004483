import { addDoc, collection } from 'firebase/firestore';
import firestore from './db/firestore.js';
import currentTime from './utils/time.js';

const sendMail = function (to, replyTo, from, subject, message, store, snackbarMessage = "", action = "", userId = "") {
  const payload = {
    userId: userId || (store.getters.isAuthenticated ? store.getters.user.id : "not-authenticated"),
    createdAt: currentTime(),
    private: true,
    to: [to],
    bcc: [replyTo],
    replyTo,
    from,
    message: {
      subject,
      text: message
    }
  };
  const db = firestore();
  return addDoc(collection(db, "emails"), payload)
    .then(() => {
      return new Promise((resolve) => {
        if (snackbarMessage) {
          setTimeout(() => {
            const data = {
              message: snackbarMessage
            };
            if (action) {
              data.action = action;
            }
            store.commit("pushSnackbarMessageProps", data);
            resolve();
          }, 1000);
        } else {
          resolve();
        }
      });
    });
};

var sendMail$1 = sendMail;

export { sendMail$1 as default };
