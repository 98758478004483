export default function (ary, property, options) {
  let ret = "";

  if (ary === undefined) {
    return null;
  }

  for (let i = 0; i < ary.length; i++) {
    if (property in ary[i]) {
      ret = options.fn(ary[i][property]);
    }
  }

  return ret;
}
