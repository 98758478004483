import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Subroutine from "@/models/subroutine";
import handlebarsRuntimeOptions from "@/handlebars/runtime-options";
import uvcMonitorTaskPlaceholderHbs from "@/handlebars/partials/uvc-monitor-bfm/monitor-task-placeholder.hbs";
import uvcMonitorDataTaskPlaceholderHbs from "@/handlebars/partials/uvc-monitor-bfm/monitor-data-task-placeholder.hbs";
import uvcReceiveDataTaskPlaceholderHbs from "@/handlebars/partials/uvc-monitor-bfm/receive-data-task-placeholder.hbs";
import uvcReceiveResponsesTaskPlaceholderHbs from "@/handlebars/partials/uvc-monitor-bfm/receive-responses-task-placeholder.hbs";

const UvcMonitorBfm = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcInterface"),
    Overridable("bfm")
  ],
  created () {
    this.isMonitor = true;
    this.protocolRoleWord = "monitor";
    this.createMethod = "create_monitor_bfm";
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-monitor-bfm/run-phase" }
    ];
  },
  computed: {
    superclass () {
      return this.bfmBase;
    },
    type () {
      return `${this.uvc.rootName}_monitor_bfm_c`;
    },
    computedSubroutines () {
      return [
        this.computedMonitorTask,
        this.isPipelinedWrite && this.computedMonitorDataTask,
        this.isPipelinedWrite && this.computedReceiveDataTask,
        this.isPipelinedWrite && this.computedMonitorBfmReceiveResponsesTask
      ]
        .filter(s => s);
    },
    monitorTask () {
      return this.subroutines.find(subroutine => subroutine.overrideId === this.computedMonitorTask.id) || this.computedMonitorTask;
    },
    monitorDataTask () {
      return this.subroutines.find(subroutine => subroutine.overrideId === this.computedMonitorDataTask.id) || this.computedMonitorDataTask;
    },
    receiveDataTask () {
      return this.subroutines.find(subroutine => subroutine.overrideId === this.computedReceiveDataTask.id) || this.computedReceiveDataTask;
    },
    monitorBfmReceiveResponsesTask () {
      return this.subroutines.find(subroutine => subroutine.overrideId === this.computedMonitorBfmReceiveResponsesTask.id) || this.computedMonitorBfmReceiveResponsesTask;
    },
    computedMonitorTask () {
      return new Subroutine({
        propsData: {
          parent: this,
          id: "static-id-4bed299",
          hasRequiredIdentifier: false,
          requiredArgs: [this.sequenceItem.type],
          content: uvcMonitorTaskPlaceholderHbs(this, handlebarsRuntimeOptions).slice(0, -1)
        }
      });
    },
    computedMonitorDataTask () {
      return new Subroutine({
        propsData: {
          parent: this,
          id: "static-id-8bb67fe",
          hasRequiredIdentifier: false,
          requiredArgs: [this.sequenceItem.type],
          content: uvcMonitorDataTaskPlaceholderHbs(this, handlebarsRuntimeOptions).slice(0, -1)
        }
      });
    },
    computedReceiveDataTask () {
      return new Subroutine({
        propsData: {
          parent: this,
          id: "static-id-277eab1",
          hasRequiredIdentifier: false,
          requiredArgs: [],
          content: uvcReceiveDataTaskPlaceholderHbs(this, handlebarsRuntimeOptions).slice(0, -1)
        }
      });
    },
    computedMonitorBfmReceiveResponsesTask () {
      return new Subroutine({
        propsData: {
          parent: this,
          id: "static-id-0096ccb",
          hasRequiredIdentifier: false,
          requiredArgs: [],
          content: uvcReceiveResponsesTaskPlaceholderHbs(this, handlebarsRuntimeOptions).slice(0, -1)
        }
      });
    }
  }
};

export default defineReactiveModel(UvcMonitorBfm);
