{{#if isPipelinedWrite}}
virtual task receive_data();
  bit killed_by_reset;

  forever begin
    {{> (partial "uvc-virtual-sequence/granted-request") context=. waitForGrantMethodName="wait_for_data_grant" retireGrantMethodName="retire_data_grant" writeItemMethodName="write_data_item" requestOrDataSequenceHandle=dataSequenceHandle}}
  end

  foreach ({{ requestItemsHandle.identifier }}[id]) begin
    {{ configHandle.identifier }}.retire_request_grant();
  end
endtask
{{/if}}
