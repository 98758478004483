import Handle from "@/models/handle";
import FieldMacroFlagEnum from "@/utils/field-macro-flag-enum";

// [TODO] PERFORMANCE: uvc-getters, env-getters, tb-getters are all computed properties and I'm thinking they should be methods that access uvc computed properties.
// Because otherwise each object has its own cached copy of each getter.
// This could be the case for other mixins that have computed properties and are proliferated to different classes.
// [TODO] consider removing handles from getters
export default {
  computed: {
    uvc () {
      return this.root;
    },
    rootProject () {
      return this.uvc;
    },
    project () {
      return this.uvc;
    },
    isPipelined () {
      return this.uvc && this.uvc.isPipelined;
    },
    isPipelinedRead () {
      return this.uvc && this.uvc.isPipelinedRead;
    },
    isPipelinedWrite () {
      return this.uvc && this.uvc.isPipelinedWrite;
    },
    hasStandaloneTb () {
      return this.uvc && this.uvc.hasStandaloneTb;
    },
    standaloneTb () {
      return this.uvc && this.uvc.standaloneTb;
    },
    standaloneEnv () {
      return this.uvc && this.uvc.standaloneEnv;
    },
    hasMaster () {
      return this.uvc && this.uvc.hasMaster;
    },
    hasSlave () {
      return this.uvc && this.uvc.hasSlave;
    },
    hasSlaveResponse () {
      return this.uvc && this.uvc.hasSlaveResponse;
    },
    hasMonitor () {
      return this.uvc && this.uvc.hasMonitor;
    },
    hasUvcCoverage () {
      return this.uvc && this.uvc.hasUvcCoverage;
    },
    hasPhases () {
      return this.uvc && this.uvc.hasPhases;
    },
    hasRegisters () {
      return this.uvc && this.uvc.hasRegisters;
    },
    hasProtocolChecker () {
      return this.uvc && this.uvc.hasProtocolChecker;
    },
    package () {
      return this.uvc && this.uvc.package;
    },
    abstractBfm () {
      return this.uvc && this.uvc.abstractBfm;
    },
    abstractBfmCreator () {
      return this.uvc && this.uvc.abstractBfmCreator;
    },
    abstractProtocolCheckerAccessor () {
      return this.uvc && this.uvc.abstractProtocolCheckerAccessor;
    },
    uvcParameters () {
      return this.uvc && this.uvc.uvcParameters;
    },
    config () {
      return this.uvc && this.uvc.config;
    },
    sequenceItem () {
      return this.uvc && this.uvc.sequenceItem;
    },
    masterRequestPolicy () {
      return this.uvc && this.uvc.masterRequestPolicy;
    },
    masterRequestDefaultPreTransferDelayPolicy () {
      return this.uvc && this.uvc.masterRequestDefaultPreTransferDelayPolicy;
    },
    masterDataPolicy () {
      return this.uvc && this.uvc.masterDataPolicy;
    },
    masterFullDataPolicy () {
      return this.uvc && this.uvc.masterFullDataPolicy;
    },
    masterRefItemPolicy () {
      return this.uvc && this.uvc.masterRefItemPolicy;
    },
    masterDataPriorityPolicy () {
      return this.uvc && this.uvc.masterDataPriorityPolicy;
    },
    masterDataDefaultPreTransferDelayPolicy () {
      return this.uvc && this.uvc.masterDataDefaultPreTransferDelayPolicy;
    },
    masterDataSequenceBase () {
      return this.uvc && this.uvc.masterDataSequenceBase;
    },
    masterDataSequence () {
      return this.uvc && this.uvc.masterDataSequence;
    },
    masterResponsePolicy () {
      return this.uvc && this.uvc.masterResponsePolicy;
    },
    masterMaxNumTransactionsInProgressPolicy () {
      return this.uvc && this.uvc.masterMaxNumTransactionsInProgressPolicy;
    },
    masterOutOfOrderBeatPrioritiesPolicy () {
      return this.uvc && this.uvc.masterOutOfOrderBeatPrioritiesPolicy;
    },
    masterInOrderBeatPrioritiesPolicy () {
      return this.uvc && this.uvc.masterInOrderBeatPrioritiesPolicy;
    },
    masterStarvedBeatPrioritiesPolicy () {
      return this.uvc && this.uvc.masterStarvedBeatPrioritiesPolicy;
    },
    hasMasterRequestPolicy () {
      return this.uvc && this.uvc.hasMasterRequestPolicy;
    },
    slaveRequestPolicy () {
      return this.uvc && this.uvc.slaveRequestPolicy;
    },
    slaveRequestDefaultPreTransferDelayPolicy () {
      return this.uvc && this.uvc.slaveRequestDefaultPreTransferDelayPolicy;
    },
    slaveDataPolicy () {
      return this.uvc && this.uvc.slaveDataPolicy;
    },
    slaveResponseDefaultPreTransferDelayPolicy () {
      return this.uvc && this.uvc.slaveResponseDefaultPreTransferDelayPolicy;
    },
    slaveResponsePolicy () {
      return this.uvc && this.uvc.slaveResponsePolicy;
    },
    slaveResponsePriorityPolicy () {
      return this.uvc && this.uvc.slaveResponsePriorityPolicy;
    },
    slaveMaxNumTransactionsInProgressPolicy () {
      return this.uvc && this.uvc.slaveMaxNumTransactionsInProgressPolicy;
    },
    slaveOutOfOrderResponsePrioritiesPolicy () {
      return this.uvc && this.uvc.slaveOutOfOrderResponsePrioritiesPolicy;
    },
    slaveInOrderResponsePrioritiesPolicy () {
      return this.uvc && this.uvc.slaveInOrderResponsePrioritiesPolicy;
    },
    slaveStarvedResponsePrioritiesPolicy () {
      return this.uvc && this.uvc.slaveStarvedResponsePrioritiesPolicy;
    },
    monitor () {
      return this.uvc && this.uvc.monitor;
    },
    sequencer () {
      return this.uvc && this.uvc.sequencer;
    },
    coverage () {
      return this.uvc && this.uvc.coverage;
    },
    masterRequestDriver () {
      return this.uvc && this.uvc.masterRequestDriver;
    },
    masterDataDriver () {
      return this.uvc && this.uvc.masterDataDriver;
    },
    masterResponseDriver () {
      return this.uvc && this.uvc.masterResponseDriver;
    },
    hasPipelinedMaster () {
      return this.uvc && this.uvc.hasPipelinedMaster;
    },
    slaveRequestDriver () {
      return this.uvc && this.uvc.slaveRequestDriver;
    },
    slaveDataDriver () {
      return this.uvc && this.uvc.slaveDataDriver;
    },
    slaveResponseDriver () {
      return this.uvc && this.uvc.slaveResponseDriver;
    },
    hasPipelinedSlave () {
      return this.uvc && this.uvc.hasPipelinedSlave;
    },
    masterAgent () {
      return this.uvc && this.uvc.masterAgent;
    },
    slaveAgent () {
      return this.uvc && this.uvc.slaveAgent;
    },
    masterRequestSequenceBase () {
      return this.uvc && this.uvc.masterRequestSequenceBase;
    },
    masterRequestSequence () {
      return this.uvc && this.uvc.masterRequestSequence;
    },
    masterResponseSequenceBase () {
      return this.uvc && this.uvc.masterResponseSequenceBase;
    },
    slaveRequestSequenceBase () {
      return this.uvc && this.uvc.slaveRequestSequenceBase;
    },
    slaveRequestSequence () {
      return this.uvc && this.uvc.slaveRequestSequence;
    },
    slaveDataSequenceBase () {
      return this.uvc && this.uvc.slaveDataSequenceBase;
    },
    slaveResponseSequenceBase () {
      return this.uvc && this.uvc.slaveResponseSequenceBase;
    },
    slaveResponseSequence () {
      return this.uvc && this.uvc.slaveResponseSequence;
    },
    slaveSequence () {
      return this.uvc && this.uvc.slaveSequence;
    },
    slaveSequenceTop () {
      return this.uvc && this.uvc.slaveSequenceTop;
    },
    virtualSequenceBase () {
      return this.uvc && this.uvc.virtualSequenceBase;
    },
    masterVirtualSequence () {
      return this.uvc && this.uvc.masterVirtualSequence;
    },
    masterRequestVirtualSequence () {
      return this.uvc && this.uvc.masterRequestVirtualSequence;
    },
    slaveVirtualSequence () {
      return this.uvc && this.uvc.slaveVirtualSequence;
    },
    slaveFullResponsePolicy () {
      return this.uvc && this.uvc.slaveFullResponsePolicy;
    },
    phaseFilter () {
      return this.uvc && this.uvc.phaseFilter;
    },
    registerAdapter () {
      return this.uvc && this.uvc.registerAdapter;
    },
    registerSequenceItem () {
      return this.uvc && this.uvc.registerSequenceItem;
    },
    interface () {
      return this.uvc && this.uvc.interface;
    },
    protocolChecker () {
      return this.uvc && this.uvc.protocolChecker;
    },
    // [TODO] there is also a bfms computed property in uvc.js. consolidate.
    bfms () {
      return [
        this.masterRequestBfm,
        this.masterDataBfm,
        this.masterResponseBfm,
        this.slaveRequestBfm,
        this.slaveDataBfm,
        this.slaveResponseBfm,
        this.monitorBfm
      ]
        .filter(o => o);
    },
    bfmsWithSubclasses () {
      return this.bfms.filter(bfm => bfm.subclasses.length > 0);
    },
    bfmDeepSubclasses () {
      return this.bfms.map(bfm => bfm.deepSubclasses).flat();
    },
    bfmBase () {
      return this.uvc && this.uvc.bfmBase;
    },
    masterRequestBfm () {
      return this.uvc && this.uvc.masterRequestBfm;
    },
    masterDataBfm () {
      return this.uvc && this.uvc.masterDataBfm;
    },
    masterResponseBfm () {
      return this.uvc && this.uvc.masterResponseBfm;
    },
    slaveRequestBfm () {
      return this.uvc && this.uvc.slaveRequestBfm;
    },
    slaveDataBfm () {
      return this.uvc && this.uvc.slaveDataBfm;
    },
    slaveResponseBfm () {
      return this.uvc && this.uvc.slaveResponseBfm;
    },
    monitorBfm () {
      return this.uvc && this.uvc.monitorBfm;
    },
    bfmCreator () {
      return this.uvc && this.uvc.bfmCreator;
    },
    protocolCheckerAccessor () {
      return this.uvc && this.uvc.protocolCheckerAccessor;
    },
    uvcParametersHandle () {
      if (this.uvcParameters) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.uvcParameters,
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT")
            ]
          }
        });
      } else {
        return null;
      }
    },
    configHandle () {
      if (this.config) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.config,
            setter: true,
            convert2stringOverride: false,
            fieldMacroFlagIds: [
              FieldMacroFlagEnum.getValue("UVM_DEFAULT"),
              FieldMacroFlagEnum.getValue("UVM_NOCOMPARE"),
              FieldMacroFlagEnum.getValue("UVM_NOPRINT"),
              FieldMacroFlagEnum.getValue("UVM_REFERENCE")
            ]
          }
        });
      } else {
        return null;
      }
    },
    coverageHandle () {
      if (this.coverage) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.coverage
          }
        });
      } else {
        return null;
      }
    },
    analysisPortHandle () {
      if (this.analysisPort) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.analysisPort,
            nameOverride: "ap"
          }
        });
      } else {
        return null;
      }
    },
    abstractBfmHandle () {
      if (this.abstractBfm) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.abstractBfm
          }
        });
      } else {
        return null;
      }
    },
    abstractBfmCreatorHandle () {
      if (this.abstractBfmCreator) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.abstractBfmCreator,
            setter: true
          }
        });
      } else {
        return null;
      }
    },
    abstractProtocolCheckerAccessorHandle () {
      if (this.abstractProtocolCheckerAccessor) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.abstractProtocolCheckerAccessor
          }
        });
      } else {
        return null;
      }
    },
    sequenceItemHandle () {
      if (this.sequenceItem) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.sequenceItem
          }
        });
      } else {
        return null;
      }
    },
    subscribedTransactionHandle () {
      if (this.subscribedTransaction) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.subscribedTransaction
          }
        });
      } else {
        return null;
      }
    },
    monitorHandle () {
      if (this.monitor) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.monitor
          }
        });
      } else {
        return null;
      }
    },
    requestSequencerHandle () {
      if (this.sequencer) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.sequencer,
            nameOverride: this.isPipelined ? "req_seqr" : "seqr"
          }
        });
      } else {
        return null;
      }
    },
    dataSequencerHandle () {
      if (this.sequencer && this.isPipelinedWrite) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.sequencer,
            nameOverride: "data_seqr"
          }
        });
      } else {
        return null;
      }
    },
    responseSequencerHandle () {
      if (this.sequencer && this.isPipelined) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.sequencer,
            nameOverride: "rsp_seqr"
          }
        });
      } else {
        return null;
      }
    },
    registerSequenceItemHandle () {
      if (this.registerSequenceItem) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.registerSequenceItem
          }
        });
      } else {
        return null;
      }
    },
    protocolCheckerAccessorHandle () {
      if (this.protocolCheckerAccessor) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.protocolCheckerAccessor
          }
        });
      } else {
        return null;
      }
    },
    bfmCreatorHandle () {
      if (this.bfmCreator) {
        return new Handle({
          propsData: {
            parent: this,
            klass: this.bfmCreator
          }
        });
      } else {
        return null;
      }
    }
  }
};
