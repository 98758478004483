import { defineReactiveModel } from "vue-app-utils";
import EnumTypeMixin from "@/models/mixins/enum-type";
// import InstanceVariable from "@/models/mixins/instance-variable";

// [TODO] swap out for typedef where the parser gives names and values. Have to add a way
// to mimic the computed properties on top of typedef.
const EnumType = {
  mixins: [
    EnumTypeMixin
    // InstanceVariable
  ],
  props: {
    type: {
      type: String,
      required: true
    },
    namedValues: {
      type: Array,
      required: true
    }
  },
  computed: {
    unspecified () {
      return this.findNamedValue("unspecified");
    },
    request () {
      return this.findNamedValue("request");
    },
    data () {
      return this.findNamedValue("data");
    },
    response () {
      return this.findNamedValue("response");
    },
    reset () {
      return this.findNamedValue("reset");
    }
  },
  methods: {
    findNamedValue (identifier) {
      return this.namedValues.find((nv) => nv.identifier === identifier);
    }
  }
};

export default defineReactiveModel(EnumType);
