const belongsTo = function (belongsToName) {
  const props = {};

  props[belongsToName] = {
    type: Object,
    required: true
  };

  return {
    props
  };
};
var belongsTo$1 = belongsTo;

export { belongsTo$1 as default };
