import belongsTo from './db/relations/belongs-to.js';

var stripeCartResultMixin = {
  mixins: [
    belongsTo("cart")
  ],
  data () {
    return {
      errorMessage: "",
      paymentIntentUpdatedAt: null,
      paymentIntentErrorMessage: ""
    };
  },
  computed: {
    needsAttention () {
      return !!this.paymentIntentErrorMessage;
    }
  }
};

export { stripeCartResultMixin as default };
