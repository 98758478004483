import { getFirebaseApp } from './utils/firebase.js';
import { getStorage } from 'firebase/storage';

let storage;

function storage$1 () {
  if (!storage) {
    storage = getStorage(getFirebaseApp());
  }
  return storage;
}

export { storage$1 as default };
