import { getFirebaseApp } from './utils/firebase.js';
import { getAnalytics, setAnalyticsCollectionEnabled, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import debounce from 'lodash.debounce';

class Analytics {
  constructor () {
    this.ga = getAnalytics(getFirebaseApp());
    setAnalyticsCollectionEnabled(this.ga, JSON.parse(import.meta.env.VITE_APP_ENABLE_ANALYTICS));
    this.debouncedUpdateFns = {};
  }

  log (eventName, options) {
    logEvent(this.ga, eventName, options);
  }

  debouncedLog (eventName, options, debounceSeconds = 15) {
    if (this.debouncedUpdateFns[eventName] === undefined) {
      this.debouncedUpdateFns[eventName] = debounce((opts) => {
        this.log(eventName, opts);
      }, debounceSeconds * 1000);
    }
    this.debouncedUpdateFns[eventName](options);
  }

  logout () {
    this.log("logout");
    setUserId(this.ga, null);
    setUserProperties(this.ga, {});
  }

  login (user, email, signInMethod) {
    setUserId(this.ga, user.id);
    setUserProperties(this.ga, {
      displayName: user.displayName,
      email
    });
    this.log("login", { method: signInMethod });
  }
}

let singleton;

Analytics.get = function () {
  if (!singleton) {
    singleton = new Analytics();
  }
  return singleton;
};

var Analytics$1 = Analytics;

export { Analytics$1 as default };
