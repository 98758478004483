virtual task receive_responses();
  {{ sequenceItemHandle.type }} {{ sequenceItemHandle.identifier }};

  forever begin
    // do begin
    //   @{{ interface.monitorClockingBlock.name }};
    // end while (/* response invalid */);

    {{ sequenceItemHandle.identifier }} = get_response_item(/* id */);

    {{> (partial "snippets/bfm-capture-slave-response-ports-todo")}}

    {{ abstractBfm.monHandle.name }}.broadcast_item({{ sequenceItemHandle.identifier }});
    drop_response_item({{ sequenceItemHandle.identifier }});
  end
endtask
