import hasOne from './db/relations/has-one.js';

var stripePrivateProfileMixin = {
  mixins: [
    hasOne("readonlyProfile")
  ],
  data () {
    return {
      defaultPaymentMethodId: "",
      setupIntentErrorMessage: "",
      microdepositType: ""
    };
  },
  computed: {
    usingMicrodepositDescriptorCode () {
      return this.microdepositType === "descriptor_code";
    },
    usingMicrodepositAmounts () {
      return this.microdepositType === "amounts";
    }
  }
};

export { stripePrivateProfileMixin as default };
