import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import UvmObject from "./uvm-object";

const UvmRegAdapter = defineReactiveModel({
  extends: Class,
  created () {
    this.isUvmBaseClass = true;
  },
  computed: {
    superclass () {
      return UvmObject.singleton();
    },
    type () {
      return "uvm_reg_adapter";
    }
  }
});

let singleton;

UvmRegAdapter.singleton = function () {
  singleton ||= new UvmRegAdapter();
  return singleton;
};

export default UvmRegAdapter;
