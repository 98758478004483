import PersistableUpdate from './persistable-update.js';
import hashCode from './hash-code.js';
import isEqual from 'lodash.isequal';
import pluralize from 'pluralize-esm';

var Persistable = {
  props: {
    docRef: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    parent: {
      type: Object,
      required: true
    },
    startSnapshot: {
      type: Boolean,
      required: false,
      default: false
    },
    docType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      userId: "", // only used to discover ownership during destroy of hasOne which uses overrideId. [TODO] not loving
      createdAt: null,
      private: false,
      writable: false
    };
  },
  created () {
    const { onSnapshot } = this.dbFns;
    this.allowCRUD = true;
    this.prereqPromises = [];
    this.onSnapshotCancelHasMany = this.onSnapshotCancelHasMany || {};
    if (this.startSnapshot) {
      let resolve;
      this.promiseOnSnapshot = new Promise(_resolve => { resolve = _resolve; });
      this.onSnapshotCancelProperties = onSnapshot(this.docRef, (doc) => {
        // TODO delete field does not update model.
        const data = doc.data();
        for (const key in data) {
          if (typeof data[key] === "object") {
            if (!isEqual(this[key], data[key])) {
              this[key] = data[key];
            }
          } else {
            this[key] = data[key];
          }
        }
        resolve();
      },
      (error) => {
        // eslint-disable-next-line
        console.error({ path: this.docRef.path, error });
      });
    }

    this.persistableUpdate = new PersistableUpdate(this.$store, this);
  },
  computed: {
    id () {
      return this.docRef.id;
    },
    uniqueId () {
      return this.docType + "-" + this.id;
    },
    key () {
      return hashCode(this.docRef.parent.id).toString(36) + "-" + this.id;
    },
    isUser () {
      return this.docType === "user";
    },
    root () {
      if (this.parent.isUser) {
        return this;
      } else {
        return this.parent.root;
      }
    },
    firstChildOfRoot () {
      if (this.parent === this.root) {
        return this;
      } else {
        return this.parent.firstChildOfRoot;
      }
    }
  },
  methods: {
    beforePersistableUpdate (obj) {
      return Promise.resolve(obj);
    },
    update (obj) {
      if (this.allowCRUD) {
        this.persistableUpdate.update(obj);
      }
    },
    immediateUpdate (obj) {
      if (this.allowCRUD) {
        return this.persistableUpdate.regularUpdate(obj);
      } else {
        return Promise.resolve();
      }
    },
    destroy () {
      const { getDoc, deleteDoc } = this.dbFns;
      const promises = [];

      // TODO Gonna keep this code for a while until unsubscribe in query.onSnapshot proves
      // to be a robust solution.
      // (this.hasManyCollectionNames || []).forEach((hasManyCollectionName) => {
      //   if (this.onSnapshotCancelHasMany[hasManyCollectionName]) {
      //     console.log("Calling onSnapshotCancelHasMany[", hasManyCollectionName, "]() for ", this.docRef.path);
      //     this.onSnapshotCancelHasMany[hasManyCollectionName]();
      //   }
      // });

      // TODO Gonna keep this code for a while until unsubscribe in query.onSnapshot proves
      // to be a robust solution.
      // (this.hasManyCollectionNames || []).forEach((hasManyCollectionName) => {
      //   // TODO remove duplication of constructing these strings with has-many
      //   const hasManyCollectionNameTitle = hasManyCollectionName.charAt(0).toUpperCase() + hasManyCollectionName.substr(1);
      //   const hasManyLazyCollectionName = `lazy${hasManyCollectionNameTitle}`;
      //   this[hasManyLazyCollectionName].forEach((model) => {
      //     promises.push(model.unsubscribe());
      //   });
      // });

      return this.$store.getters.waitForZeroTransactions.then(() => {
        promises.push(this.beforeUnmount ? this.beforeUnmount() : Promise.resolve());

        return Promise.all(promises).then(() => {
          const promises2 = [];

          (this.hasManyCollectionNames || []).forEach((hasManyCollectionName) => {
            // TODO remove duplication of constructing these strings with has-many
            const hasManyCollectionNameTitle = hasManyCollectionName.charAt(0).toUpperCase() + hasManyCollectionName.substr(1);
            // [TODO] is this guard needed here? hopefully can remove from everywhere. verify once unit test adds destroy/unsubscribe
            if ((hasManyCollectionNameTitle !== "UserIncludes") && (hasManyCollectionNameTitle !== "UserClasses")) {
              promises2.push(this[`findAll${hasManyCollectionNameTitle}`]()
                .then((models) => {
                  const promises2 = [];
                  models.forEach((model) => {
                    promises2.push(model.destroy());
                  });
                  return Promise.all(promises2);
                }));
            }
          });

          (this.hasOneItemNames || []).forEach((hasOneItemName) => {
            // TODO remove duplication of constructing these strings with has-one
            const hasOneClassName = hasOneItemName.charAt(0).toUpperCase() + hasOneItemName.substr(1);
            const hasOneLazyName = "lazy" + hasOneClassName;
            if (this[hasOneLazyName]) {
              if (this[`optsFor${hasOneClassName}`].overrideId) {
                if (this[hasOneLazyName].userId === this.userId) {
                  promises2.push(this[hasOneLazyName].destroy());
                }
              } else {
                promises2.push(this[hasOneLazyName].destroy());
              }
            } else {
              promises2.push(this[`find${hasOneClassName}ById`](this[`optsFor${hasOneClassName}`].overrideId || this.id)
                .then((model) => {
                  if (model) {
                    // console.log(`find${hasOneClassName}ById used to destroy unfetched model.`);
                    if (this[`optsFor${hasOneClassName}`].overrideId) {
                      return getDoc(model.docRef).then(docSnapshot => {
                        if (docSnapshot.data().userId === this.userId) {
                          return model.destroy();
                        } else {
                          return Promise.resolve();
                        }
                      });
                    } else {
                      return model.destroy();
                    }
                  }
                }));
            }
          });

          return Promise.all(promises2).then(() => {
            // TODO Gonna keep this code for a while until unsubscribe in query.onSnapshot proves
            // to be a robust solution.
            // if (this.onSnapshotCancelProperties) {
            //   console.log("Calling onSnapshotCancelProperties() for ", this.docRef.path);
            //   this.onSnapshotCancelProperties();
            // }
            // console.log("destroying ", this.docRef.path);
            return this.$store.dispatch(
              "trackTransaction",
              deleteDoc(this.docRef)
                .catch((e) => {
                  const error = new Error("persistable::destroy::delete: " + e.message + ` (${this.docRef.path})`);
                  error.name = e.name;
                  throw error;
                })
            );
          });
        });
      });
    },
    unsubscribe () {
      this.allowCRUD = false;
      return this.$store.getters.waitForZeroTransactions.then(() => {
        const promises = [];

        (this.hasManyCollectionNames || []).forEach((hasManyCollectionName) => {
          // [TODO] is this guard needed here? hopefully can remove from everywhere. verify once unit test adds destroy/unsubscribe
          if ((hasManyCollectionName !== "userIncludes") && (hasManyCollectionName !== "userClasses")) {
            if (this.onSnapshotCancelHasMany[hasManyCollectionName]) {
              // console.log("Calling onSnapshotCancelHasMany[", hasManyCollectionName, "]() for ", this.docRef.path);
              this.onSnapshotCancelHasMany[hasManyCollectionName]();
            }
          }
        });

        (this.hasManyCollectionNames || []).forEach((hasManyCollectionName) => {
          // [TODO] is this guard needed here? hopefully can remove from everywhere. verify once unit test adds destroy/unsubscribe
          // if ((hasManyCollectionName !== "userIncludes") && (hasManyCollectionName !== "userClasses")) {
          if (this.onSnapshotCancelHasMany[hasManyCollectionName]) {
            [].concat(this[hasManyCollectionName]).forEach((hasManyCollection) => {
              promises.push(hasManyCollection.unsubscribe());
            });
          }
        });

        (this.hasOneItemNames || []).forEach((hasOneItemName) => {
          // TODO remove duplication of constructing these strings with has-one
          const hasOneClassName = hasOneItemName.charAt(0).toUpperCase() + hasOneItemName.substr(1);
          const hasOnePromiseName = "promise" + hasOneClassName;

          if (this[hasOnePromiseName]) {
            promises.push(this[hasOnePromiseName]
              .then((model) => {
                return model.unsubscribe();
              }));
          }
        });

        return Promise.all(promises)
          .then(() => {
            this.onSnapshotCancelProperties();
          });
      });
    },
    // [TODO] can destroy, unsubscribe and exportData be based on a single function called, lets say, visit?
    exportData () {
      const { getDoc } = this.dbFns;

      // console.log("exporting ", this.docRef.path);
      return this.$store.dispatch(
        "trackTransaction",
        getDoc(this.docRef)
          .then(doc => {
            return doc.data();
          })
          .catch((e) => {
            const error = new Error("persistable::exportData::getDoc: " + e.message);
            error.name = e.name;
            throw error;
          })
      )
        .then(data => {
          const promises = [];

          const dataTrimmed = Object.assign({}, data);
          delete dataTrimmed.createdAt;
          delete dataTrimmed.userId;

          const instruction = {
            docType: this.docType,
            collection: pluralize(this.docType),
            id: this.id
          };
          if (Object.keys(dataTrimmed).length > 0) {
            instruction.data = dataTrimmed;
          }

          // [TODO] need to handle time
          (this.hasOneItemNames || []).forEach((hasOneItemName) => {
            // TODO remove duplication of constructing these strings with has-one
            const hasOneClassName = hasOneItemName.charAt(0).toUpperCase() + hasOneItemName.substr(1);
            promises.push(this[`find${hasOneClassName}ById`](this[`optsFor${hasOneClassName}`].overrideId || this.id)
              .then((model) => {
                if (model) {
                  // console.log(`find${hasOneClassName}ById used to exportData unfetched model.`);
                  if (this[`optsFor${hasOneClassName}`].overrideId) {
                    return getDoc(model.docRef).then(docSnapshot => {
                      if (docSnapshot.data().userId === this.userId) {
                        return model.exportData()
                          .then((i) => {
                            instruction.hasOne = instruction.hasOne || [];
                            instruction.hasOne.push(i);
                          });
                      } else {
                        return Promise.resolve();
                      }
                    });
                  } else {
                    return model.exportData()
                      .then((i) => {
                        instruction.hasOne = instruction.hasOne || [];
                        instruction.hasOne.push(i);
                      });
                  }
                }
              }));
          });

          (this.hasManyCollectionNames || []).forEach((hasManyCollectionName) => {
            // TODO remove duplication of constructing these strings with has-many
            const hasManyCollectionNameTitle = hasManyCollectionName.charAt(0).toUpperCase() + hasManyCollectionName.substr(1);
            // [TODO] is this guard needed here? hopefully can remove from everywhere. verify once unit test adds destroy/unsubscribe
            if ((hasManyCollectionNameTitle !== "UserIncludes") && (hasManyCollectionNameTitle !== "UserClasses")) {
              promises.push(this[`findAll${hasManyCollectionNameTitle}`]()
                .then((models) => {
                  const promises2 = [];
                  models.forEach((model) => {
                    const p = model.exportData()
                      .then((i) => {
                        instruction.hasMany = instruction.hasMany || [];
                        instruction.hasMany.push(i);
                      });
                    promises2.push(p);
                  });
                  return Promise.all(promises2);
                }));
            }
          });

          return Promise.all(promises)
            .then(() => {
              return instruction;
            });
        });
    },
    determineIndexes () {
      let indexes = [];

      const promises = [];

      (this.hasManyCollectionNames || []).forEach((hasManyCollectionName) => {
        // [TODO] is this guard needed here? hopefully can remove from everywhere. verify once unit test adds destroy/unsubscribe
        if ((hasManyCollectionName !== "userIncludes") && (hasManyCollectionName !== "userClasses")) {
          // TODO remove duplication of constructing these strings with has-many
          const hasManyItemName = pluralize.singular(hasManyCollectionName);
          const hasManyClassName = hasManyItemName.charAt(0).toUpperCase() + hasManyItemName.substr(1);
          const HasManyModel = this.getModel(hasManyItemName);
          const propsData = {
            docRef: { id: "fake-id-for-determine-indexes" },
            user: {},
            parent: this,
            startSnapshot: false,
            docType: hasManyItemName
          };
          propsData[this.docType] = this;
          const model = new HasManyModel({ propsData });
          const fields = [
            {
              fieldPath: "userId",
              order: "ASCENDING"
            }
          ];
          if (this.docType !== "user") {
            fields.push({
              fieldPath: `${this.docType}Id`,
              order: "ASCENDING"
            });
          }
          const queryOptions = `queryOptionsFor${hasManyClassName}`;
          if (this[queryOptions].orderBy) {
            this[queryOptions].orderBy.forEach((property) => {
              if (Array.isArray(property)) {
                if (property.length !== 1) {
                  throw new Error("Was not expecting array with more than one element: ", JSON.stringify(property));
                } else {
                  property.forEach(p => {
                    fields.push({
                      fieldPath: p,
                      order: "ASCENDING"
                    });
                  });
                }
              } else {
                fields.push({
                  fieldPath: property,
                  order: "ASCENDING"
                });
              }
            });
          }
          const index = {
            collectionGroup: hasManyCollectionName,
            queryScope: "COLLECTION",
            fields
          };
          indexes.push(index);
          promises.push(
            model.determineIndexes().then(hasManyIndexes => {
              indexes = indexes.concat(hasManyIndexes);
              return indexes;
            })
          );
        }
      });

      (this.hasOneItemNames || []).forEach((hasOneItemName) => {
        const HasOneModel = this.getModel(hasOneItemName);
        const propsData = {
          docRef: { id: "fake-id-for-determine-indexes" },
          user: {},
          parent: this,
          startSnapshot: false,
          docType: hasOneItemName
        };
        propsData[this.docType] = this;
        const model = new HasOneModel({ propsData });
        promises.push(
          model.determineIndexes().then(hasOneIndexes => {
            indexes = indexes.concat(hasOneIndexes);
            return indexes;
          })
        );
      });

      return Promise.all(promises).then(() => {
        return indexes;
      });
    }
  }
};

export { Persistable as default };
