import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import UvmComponent from "./uvm-component";

const UvmSequencer = defineReactiveModel({
  extends: Class,
  props: {
    sequenceItem: {
      type: Object,
      required: true
    }
  },
  created () {
    this.isUvmBaseClass = true;
    this.rootType = "uvm_sequencer";
  },
  computed: {
    isSequencer () {
      return true;
    },
    superclass () {
      return UvmComponent.singleton();
    },
    type () {
      return `${this.rootType}#(${this.sequenceItem.type})`;
    }
  }
});

const singletons = {};

UvmSequencer.singleton = function (sequenceItem) {
  if (!sequenceItem) { return null; }

  if (singletons[sequenceItem.uniqueId] === undefined) {
    singletons[sequenceItem.uniqueId] = new UvmSequencer({
      propsData: {
        sequenceItem
      }
    });
  }

  return singletons[sequenceItem.uniqueId];
};

export default UvmSequencer;
