import { defineReactiveModel } from "vue-app-utils";
import GeneratableFile from "@/models/mixins/generatable-file";
import config from "@/config";
import { path } from "@/utils/node-VITE_APP_PLATFORM";

const UserIncludeTemplate = {
  mixins: [
    GeneratableFile
  ],
  props: {
    parent: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    packageFilePath () {
      return path.join(config.userIncludesPath(), this.fileName);
    },
    fileName () {
      return `${this.type}.svh`;
    },
    fileContents () {
      return `// ${this.type}\n\n// <TODO>`;
    }
  }
};

export default defineReactiveModel(UserIncludeTemplate);
