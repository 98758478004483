import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Variable from "@/models/variable";
import get from "lodash.get";

const UvcBfmBase = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcInterface"),
    Overridable("bfm-base")
  ],
  created () {
    this.virtual = true;
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-bfm-base/run-phase" },
      { for: ["me"], partial: "uvc-bfm-base/wait-for-num-clocks" },
      { for: ["me"], partial: "uvc-bfm-base/wait-for-reset" },
      { for: ["me"], partial: "uvc-bfm-base/wait-for-reset-to-deassert" },
      { for: ["me"], partial: "uvc-bfm-base/initialize-on-reset" },
      { for: ["me"], partial: "uvc-bfm-base/initialize-signals-base" },
      { for: ["subclass"], partial: "uvc-bfm-base/initialize-signals" },
      { for: ["me"], partial: "uvc-bfm-base/initialize-state" },
      { for: ["me"], partial: "uvc-bfm-base/store-transaction" },
      { for: ["me"], partial: "uvc-bfm-base/get-transaction" },
      { for: ["me"], partial: "uvc-bfm-base/drop-transaction" }
    ];
  },
  computed: {
    superclass () {
      return this.abstractBfm;
    },
    type () {
      return `${this.uvc.rootName}_bfm_base_c`;
    },
    computedInstanceVariables () {
      return [
        this.activeTransactionsVariable,
        this.numActiveTransactionsVariable
      ]
        .filter(o => o);
    },
    activeTransactionsVariable () {
      return this.isPipelined
        ? new Variable({
          propsData: {
            parent: this,
            type: this.sequenceItem.type,
            name: `active_transactions[logic [${get(this.interface, "idMsbParameter.name", "")}:0]]`
          }
        })
        : null;
    },
    numActiveTransactionsVariable () {
      return this.isPipelined
        ? new Variable({
          propsData: {
            parent: this,
            type: "int",
            name: "num_active_transactions"
          }
        })
        : null;
    }
  }
};

export default defineReactiveModel(UvcBfmBase);
