virtual task monitor{{#if isPipelinedWrite}}_request{{/if}}({{ sequenceItemHandle.type }} {{ sequenceItemHandle.identifier }});
{{#if isPipelined}}
  {{#if isPipelinedRead}}
  {{ sequenceItemHandle.klass.type }} req;
  {{ sequenceItemHandle.klass.type }} rsp;

  req = {{ sequenceItemHandle.identifier }};

  // do begin
  //   @{{ interface.monitorClockingBlock.name }};
  // end while (/* transaction invalid */);

  // Capture/sample:
  {{#each interface.masterRequestPorts}}
  //   {{#with (lookup ../sequenceItem.instanceVariablesByRelatedPort identifier )}}req.{{ identifier }} = {{/with}}{{ identifier }};
  {{/each}}

  req.{{ sequenceItem.phaseEnumVariable.identifier }} = {{ sequenceItem.phaseEnumVariable.klass.request.name }};

  {{ abstractBfm.monHandle.name }}.broadcast_item(req);

  if (!$cast(rsp, req.clone())) begin
    `uvm_fatal(get_type_name(), "Could not cast.")
  end

  rsp.{{ sequenceItem.phaseEnumVariable.identifier }} = {{ sequenceItem.phaseEnumVariable.klass.response.name }};

  {{#each interface.portsWithDynamicArrayVariables}}
  // rsp.{{ instanceVariable.identifier }} = new [rsp.len];
  {{/each}}

  fork
    begin
      foreach (rsp.{{ interface.firstDynamicArrayVariable.identifier }}[d]) begin
        // do begin
        //   @{{ interface.monitorClockingBlock.name }};
        // end while (/* beat invalid or not beat for rsp.{{ sequenceItem.idVariable.identifier }} */);

        {{#each interface.portsWithDynamicArrayVariables}}
        // rsp.{{ instanceVariable.identifier }}[d] = {{ port.identifier }};
        {{/each}}
      end
      {{ abstractBfm.monHandle.name }}.broadcast_item(rsp);
    end
  join_none
  {{else}}
  {{ sequenceItemHandle.identifier }}.{{ sequenceItem.phaseEnumVariable.identifier }} = {{ sequenceItem.phaseEnumVariable.klass.request.name }};

  // do begin
  //   @{{ interface.monitorClockingBlock.name }};
  // end while (/* transaction invalid */);

  // if (request_items.exists(/* id */) || response_items.exists(/* id */)) begin
  //   `uvm_fatal(get_type_name(), $sformatf("Two active request channel transactions with id='h%x.", /* id */))
  // end

  // Capture/sample:
  {{#each interface.masterRequestPorts}}
  //   {{#with (lookup ../sequenceItem.instanceVariablesByRelatedPort identifier )}}req.{{ identifier }} = {{/with}}{{ identifier }};
  {{/each}}

  {{ abstractBfm.monHandle.name }}.broadcast_item({{ sequenceItemHandle.identifier }});
  // write_request_item({{ sequenceItemHandle.identifier }});
  {{/if}}
{{else}}
  {{ sequenceItemHandle.type }} rsp_item;

  // do begin
  //   @{{ interface.monitorClockingBlock.name }};
  // end while (/* request invalid */);

  {{ sequenceItemHandle.identifier }}.{{ sequenceItem.phaseEnumVariable.identifier }} = {{ sequenceItem.phaseEnumVariable.klass.request.name }};

  {{> (partial "snippets/bfm-capture-master-ports-todo")}}

  {{ abstractBfm.monHandle.name }}.broadcast_item({{ sequenceItemHandle.identifier }});

  // while (/* response invalid */) begin
  //   @mon_cb;
  // end

  if (!$cast(rsp_item, {{ sequenceItemHandle.identifier }}.clone())) begin
    `uvm_fatal(get_type_name(), "Could not cast.")
  end

  rsp_item.{{ sequenceItem.phaseEnumVariable.identifier }} = {{ sequenceItem.phaseEnumVariable.klass.response.name }};

  {{> (partial "snippets/bfm-capture-slave-ports-todo") context=. itemIdentifier="rsp_item"}}

  `uvm_info(get_type_name(), { "\n", rsp_item.sprint() }, UVM_HIGH)
  {{ abstractBfm.monHandle.name }}.broadcast_item(rsp_item);
{{/if}}
endtask
