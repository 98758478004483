{{#if isPipelined}}
virtual task run_phase(uvm_phase phase);
  super.run_phase(phase);

  fork
    {{ driveReadyTask.identifier }}();
    {{ receiveResponsesTask.identifier }}();
  join_none
endtask
{{/if}}
