import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Handle from "@/models/handle";
import get from "lodash.get";

const UvcSlaveRequestSequence = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("sequence")
  ],
  created () {
    this.master = false;
    this.channel = "request";
  },
  computed: {
    superclass () {
      return this.slaveRequestSequenceBase;
    },
    type () {
      const parts = [this.uvc.rootName];
      if (this.hasSlave) {
        parts.push(this.protocolRoleWord);
      }
      if (this.isPipelined) {
        parts.push("request");
      }
      parts.push("sequence");
      parts.push("c");
      return parts.join("_");
    },
    computedPolicyHandles () {
      return [
        get(this, "slaveRequestSequenceBase.sequenceItemHandle") && this.slaveRequestDefaultPreTransferDelayPolicy && new Handle({
          propsData: {
            parent: this,
            klass: this.slaveRequestDefaultPreTransferDelayPolicy,
            relations: {
              randomObjectDoHandle: this.slaveRequestSequenceBase.sequenceItemHandle
            }
          }
        })
      ]
        .filter(h => h);
    }
  }
};

export default defineReactiveModel(UvcSlaveRequestSequence);
