import config from "@/config";
import calculateSpacing from "@/utils/calculate-spacing";
import Parser from "@/pegjs/system-verilog.pegjs";
import PortKindEnum from "@/utils/port-kind-enum";
import get from "lodash.get";

export default {
  computed: {
    isClock () {
      if (PortKindEnum.isValueEqual(this.kind, "auto")) {
        return !this.isReset && !!this.name.match(config.clockRegExp);
      } else {
        return PortKindEnum.isValueEqual(this.kind, "clock");
      }
    },
    isReset () {
      if (PortKindEnum.isValueEqual(this.kind, "auto")) {
        return !!this.name.match(config.resetRegExp);
      } else {
        return PortKindEnum.isValueEqual(this.kind, "reset");
      }
    },
    isAddr () {
      if (PortKindEnum.isValueEqual(this.kind, "auto")) {
        return !this.isClock && !this.isReset && !!this.name.match(config.addressRegExp);
      } else {
        return PortKindEnum.isValueEqual(this.kind, "addr");
      }
    },
    isDataPort () {
      if (PortKindEnum.isValueEqual(this.kind, "auto")) {
        return !this.isClock && !this.isReset && !this.isAddr && !!this.name.match(config.dataRegExp);
      } else {
        return PortKindEnum.isValueEqual(this.kind, "data");
      }
    },
    isLen () {
      if (PortKindEnum.isValueEqual(this.kind, "auto")) {
        return !this.isClock && !this.isReset && !this.isAddr && !this.isDataPort && !!this.name.match(config.lenRegExp);
      } else {
        return PortKindEnum.isValueEqual(this.kind, "len");
      }
    },
    isId () {
      if (PortKindEnum.isValueEqual(this.kind, "auto")) {
        return !this.isClock && !this.isReset && !this.isAddr && !this.isDataPort && !this.isLen && !!this.name.match(config.idRegExp);
      } else {
        return PortKindEnum.isValueEqual(this.kind, "id");
      }
    },
    isInput () {
      return (this.isClock && this.parent.hasClockAndOtherPorts) ||
        (this.isReset && this.parent.hasResetAndOtherPorts);
    },
    isMaster () {
      return !this.isClock && !this.isReset && this.master;
    },
    isSlave () {
      return !this.isClock && !this.isReset && !this.master;
    },
    isRequest () {
      return this.channel === "request";
    },
    isData () {
      return this.channel === "data";
    },
    isResponse () {
      return this.channel === "response";
    },
    activeLow () {
      return this.name.slice(-1) === "n";
    },
    typeInfo () {
      let info = null;
      try {
        info = Parser.parse(this.type, { startRule: "PortHeader" });
      } catch (e) {
        // continue regardless of error
      }
      return info;
    },
    nameInfo () {
      let info = null;
      try {
        info = Parser.parse(this.name, { startRule: "PortName" });
      } catch (e) {
        // continue regardless of error
      }
      return info;
    },
    identifier () {
      return this.nameInfo && this.nameInfo.identifier;
    },
    typeSpacing () {
      return calculateSpacing({
        propertyLength: this.type.length,
        collectionMaxLength: this.parent.portsTypeMaxLength,
        spacePadding: 1
      });
    },
    identifierSpacing () {
      return calculateSpacing({
        propertyLength: this.identifier.length,
        collectionMaxLength: this.parent.portsNameMaxLength
      });
    },
    sequenceItemVariableNamePlaceholder () {
      let name = this.name.replace(this.identifier, this.identifier.toLowerCase()).replace(this.parent.commonLeadingCharacters[this.identifier], "");
      this.nameInfo.unpacked.dimensions.forEach(dimension => {
        ["lhs", "rhs"].forEach(side => {
          dimension[side].parameterIdentifiers.forEach(parameterIdentifier => {
            const uvcInterfaceParam = this.parent.parametersByName[parameterIdentifier];
            if (uvcInterfaceParam) {
              const msbParam = uvcInterfaceParam.minOrMaxPackageParameter;
              if (msbParam) {
                name = name.replace(parameterIdentifier, msbParam.name);
              }
            }
          });
        });
      });
      return name;
    },
    strippedLeadingCharacterIdentifier () {
      return this.identifier.toLowerCase().replace(this.parent.commonLeadingCharacters[this.identifier], "");
    },
    // rand logic [AXIR_ARADDR_MSB_MAX:AXIR_ARADDR_LSB_MIN] addr;
    // rand logic [AXIR_ARLEN_MSB_MAX:0] len;
    // rand logic [AXIR_RDATA_MSB_MAX:0] data;
    // rand logic [AXIR_RRESP_MSB_MAX:0] resp;
    //
    // "rand logic [AXIR_ARADDR_MSB_MAX:0] addr",
    // "rand logic [AXIR_RDATA_MSB_MAX:0] data[]",
    // "rand logic [AXIR_RRESP_MSB_MAX:0] resp[]"
    sequenceItemVariablePlaceholder () {
      if (get(this.parent.sequenceItem, "computedInstanceVariables", []).find(iv => iv.name === this.sequenceItemVariableNamePlaceholder)) {
        return "";
      } else if (get(this, "typeInfo.packed.numberOfDimensions", 0) > 0) {
        let type = this.typeInfo.data.type.replace(/^wire\s*/, "logic ");
        this.typeInfo.packed.parameterIdentifiers.forEach(parameterIdentifier => {
          const uvcInterfaceParam = this.parent.parametersByName[parameterIdentifier];
          if (uvcInterfaceParam) {
            const msbParam = uvcInterfaceParam.minOrMaxPackageParameter;
            if (msbParam) {
              type = type.replace(parameterIdentifier, msbParam.name);
            }
          }
        });
        const dynamicArray = (
          (this.parent.isPipelined && this.sequenceItemVariableNamePlaceholder.match(config.dataRegExp)) ||
          (this.parent.isPipelinedRead && this.sequenceItemVariableNamePlaceholder.match(config.respRegExp))
        )
          ? "[]"
          : "";
        return `rand ${type} ${this.sequenceItemVariableNamePlaceholder}${dynamicArray}`;
      } else if (!this.name.match(/valid|vld|enable|en|ready|rdy/i)) {
        return `rand logic ${this.sequenceItemVariableNamePlaceholder}`;
      }
    }
  }
};
