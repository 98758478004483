import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import UvmComponent from "./uvm-component";

const UvmSubscriber = defineReactiveModel({
  extends: Class,
  props: {
    subscribedTransaction: {
      type: Object,
      required: true
    }
  },
  created () {
    this.isUvmBaseClass = true;
    this.partialInheritance = [
      { for: ["subclasses"], partial: "uvm-subscriber/write" }
    ];
    this.rootType = "uvm_subscriber";
  },
  computed: {
    superclass () {
      return UvmComponent.singleton();
    },
    type () {
      return `${this.rootType}#(${this.subscribedTransaction.type})`;
    }
  }
});

const singletons = {};

UvmSubscriber.singleton = function (subscribedTransaction) {
  if (subscribedTransaction === undefined) { return null; }

  if (singletons[subscribedTransaction.uniqueId] === undefined) {
    singletons[subscribedTransaction.uniqueId] = new UvmSubscriber({
      propsData: {
        subscribedTransaction
      }
    });
  }

  return singletons[subscribedTransaction.uniqueId];
};

export default UvmSubscriber;
