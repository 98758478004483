`uvm_component_{{#if useParamUtils}}param_{{/if}}utils({{ thisType }})
{{#if parameters.length}}
{{#if EVERYONE_IS_USING_UVM_1800}}
`uvm_type_name_decl("{{ thisType }}")
{{else}}

static function string type_name();
  return "{{ thisType }}";
endfunction

virtual function string get_type_name();
  return "{{ thisType }}";
endfunction
{{/if}}
{{/if}}
