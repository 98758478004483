import UserIncludeTemplate from "@/models/user-include-template";

export default {
  data () {
    return {
    };
  },
  computed: {
    includeFileNamePrefix () {
      return this.type;
    },
    userIncludesFileNameRegExp () {
      return new RegExp(`^${this.includeFileNamePrefix}`);
    },
    userIncludes () {
      return this.rootProject.userIncludes.filter((include) => {
        return include.fileName.match(this.userIncludesFileNameRegExp);
      });
    }
  },
  methods: {
    createUserInclude (data) {
      const Template = this.userIncludeTemplate || UserIncludeTemplate;
      const template = new Template({
        propsData: {
          parent: this,
          type: `${this.includeFileNamePrefix}_${data.fileNameSuffix}`
          // type: [this.includeFileNamePrefix, data.fileNameSuffix]
          //   .filter(s => s)
          //   .join("_")
        }
      });

      return this.rootProject.createUserInclude({
        packageFilePath: template.packageFilePath,
        fileContentsFn: () => {
          return template.fileContents;
        }
      });
    }
  }
};
