import Parser from "@/pegjs/system-verilog.pegjs";

export default function (value, rule, options = {}) {
  let result;
  try {
    result = Parser.parse(value, { startRule: rule, ...options });
  } catch (e) {
    if (e.location) {
      try {
        let location = "";

        if (value.match("\n")) {
          location = `line ${e.location.start.line} `;
        }
        location = location + `char ${e.location.start.column}`;

        if (e.found) {
          e.message = `Not expecting ${JSON.stringify(e.found)} at ${location}.`;
        } else if (e.message.match(/but end of input found.$/)) {
          e.message = `Not expecting end of input at ${location}.`;
        } else {
          console.error(e); // eslint-disable-line no-console
        }
      } catch (e2) {
        console.error(e); // eslint-disable-line no-console
        console.error(e2); // eslint-disable-line no-console
      }
    }

    throw e;
  }
  return result;
}
