export default function (handlePath, iterationPropertiesList, ...args) {
  const newDynamicArray = (args.length > 1) ? args[0] : true;
  const dimensionIndexNeeded = (args.length > 2) ? args[1] : true;
  const options = args[args.length - 1];
  const isTopIteration = iterationPropertiesList && !iterationPropertiesList.previous;
  if (iterationPropertiesList && (newDynamicArray || isTopIteration)) {
    const content = [];
    const isInnerIteration = !isTopIteration;
    const render = options.fn(this).slice(0, -1);

    if (newDynamicArray) {
      content.push(`${handlePath}${isInnerIteration ? iterationPropertiesList.previous.brackets : ""} = new[${iterationPropertiesList.newExpression}];`);
      if (render) {
        if (isInnerIteration && dimensionIndexNeeded) {
          content.push(`for (int ${iterationPropertiesList.iterationVariable} = 0; ${iterationPropertiesList.iterationVariable} < ${handlePath}${iterationPropertiesList.previous.brackets}.size(); ${iterationPropertiesList.iterationVariable}++) begin`);
        } else {
          content.push(`foreach (${handlePath}${iterationPropertiesList.brackets}) begin`);
        }
        content.push(render);
        content.push("end");
      }
    } else {
      content.push(`foreach (${handlePath}[${iterationPropertiesList.last.iterationVariables.join(", ")}]) begin`);
      content.push(render);
      content.push("end");
    }

    return content.join("\n") + "\n";
  } else {
    return options.inverse(this);
  }
}
