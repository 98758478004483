virtual task run_phase(uvm_phase phase);
  super.run_phase(phase);

  forever begin
    {{ driveTaskName }}();
  {{#if (and isSlave (and interface.hasSlavePorts (not isPipelined)))}}
    send_response();
  {{/if}}
  end
endtask
