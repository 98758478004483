virtual task body();
{{#if (not isFirstLevelObject)}}
  super.body();
{{else if (not (or isVirtualSequence randomObjectCreateHandles.length))}}
  {{> (partial "snippets/start-item") context=. reqHandle=superclass.reqHandle}}
{{/if}}
{{#each randomObjectCreateHandles ln=(not isFirstLevelObject) delimeter="\n"}}
{{#if klass.isSequence}}
  {{> (partial "snippets/start-sequence") context=.}}
{{else}}
  send_{{ identifier }}();
{{/if}}
{{/each}}
endtask
