import get from 'lodash.get';

var stripePaymentMethodMixin = {
  data () {
    return {
      stripePaymentMethodId: "",
      stripePaymentMethod: {},
      stripeSetupIntent: {},
      errorMessage: ""
    };
  },
  computed: {
    isLoaded () {
      if (this.isUsBankAccount) {
        return this.usBankAccountDescription;
      } else {
        return this.cardDescription && this.exp;
      }
    },
    isCard () {
      return get(this.stripePaymentMethod, "type", "") === "card";
    },
    isUsBankAccount () {
      return get(this.stripePaymentMethod, "type", "") === "us_bank_account";
    },
    description () {
      return this.isCard ? this.cardDescription : this.usBankAccountDescription;
    },
    cardDescription () {
      if (this.debitOrCredit && this.last4) {
        return this.debitOrCredit + " ending in " + this.last4;
      } else {
        return "";
      }
    },
    usBankAccountDescription () {
      if (this.usBankAccountType && this.last4) {
        return this.usBankAccountType + " account ending in " + this.last4;
      } else {
        return "";
      }
    },
    shortDescription () {
      return this.isCard ? this.cardShortDescription : this.usBankAccountShortDescription;
    },
    cardShortDescription () {
      return `${this.brand} (** ${this.last4})`;
    },
    usBankAccountShortDescription () {
      return `${this.usBankAccountType} (**${this.last4})`;
    },
    genericDescription () {
      return this.isCard ? this.cardGenericDescription : this.usBankAccountGenericDescription;
    },
    cardGenericDescription () {
      return "Card";
    },
    usBankAccountGenericDescription () {
      return "Account";
    },
    last4 () {
      return this.isCard ? this.cardLast4 : this.usBankAccountLast4;
    },
    cardLast4 () {
      return get(this.stripePaymentMethod, "card.last4", "");
    },
    usBankAccountLast4 () {
      return get(this.stripePaymentMethod, "us_bank_account.last4", "");
    },
    usBankAccountType () {
      const type = get(this.stripePaymentMethod, "us_bank_account.account_type", "");
      if (type) {
        return type.slice(0, 1).toUpperCase() + type.slice(1);
      } else {
        return type;
      }
    },
    brand () {
      return get(this.stripePaymentMethod, "card.brand", "").toUpperCase();
    },
    debitOrCredit () {
      const funding = get(this.stripePaymentMethod, "card.funding", "");
      if (funding) {
        return funding.charAt(0).toUpperCase() + funding.slice(1) + " card";
      } else {
        return funding;
      }
    },
    exp () {
      return this.isCard ? this.cardExp : this.usBankAccountExp;
    },
    cardExp () {
      return get(this.stripePaymentMethod, "card.exp_month", "") + "/" + get(this.stripePaymentMethod, "card.exp_year", "").toString().slice(2);
    },
    usBankAccountExp () {
      return "n/a";
    }
  }
};

export { stripePaymentMethodMixin as default };
