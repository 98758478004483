virtual task body();
  super.body();

{{#if isPipelinedWrite}}
  fork
    receive_requests();
    receive_data();
  join
{{else}}
  receive_requests();
{{/if}}
endtask
