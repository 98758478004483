class out_of_order_comparator_c#(type TYPE=int) extends uvm_component;

  `uvm_analysis_imp_decl(_expected)
  `uvm_analysis_imp_decl(_actual)

  uvm_analysis_imp_expected#(TYPE, out_of_order_comparator_c#(TYPE)) expected_export;
  uvm_analysis_imp_actual#(TYPE, out_of_order_comparator_c#(TYPE)) actual_export;
  uvm_analysis_port#(TYPE) ap;

  protected TYPE expected_items[int];
  protected TYPE actual_items[int];

  protected int num_matches;
  protected int num_mismatches;

  protected bit no_compare_error_enabled;

  `uvm_component_param_utils(out_of_order_comparator_c#(TYPE))

  function new(string name="out_of_order_comparator_c", uvm_component parent=null);
    super.new(name, parent);
  endfunction

  virtual function void build_phase(uvm_phase phase);
    expected_export = new("expected_export", this);
    actual_export = new("actual_export", this);
    ap = new("ap", this);

    no_compare_error_enabled = 1;
  endfunction

  virtual function void write_expected(TYPE t);
    if (actual_items.exists(t.to_index())) begin
      compare_transactions(t, actual_items[t.to_index()]);
      actual_items.delete(t.to_index());
    end else begin
      expected_items[t.to_index()] = t;
    end
  endfunction

  virtual function void write_actual(TYPE t);
    if (expected_items.exists(t.to_index())) begin
      compare_transactions(expected_items[t.to_index()], t);
      expected_items.delete(t.to_index());
    end else begin
      actual_items[t.to_index()] = t;
    end
  endfunction

  virtual function void compare_transactions(TYPE expected_item, TYPE actual_item);
    if(!actual_item.compare(expected_item)) begin
      num_mismatches++;
      `uvm_error_begin(get_type_name(), "Actual does not match expected.")
        `uvm_message_add_object(actual_item, "Actual")
        `uvm_message_add_object(expected_item, "Expected")
      `uvm_error_end
    end else begin
      num_matches++;
      `uvm_info(get_type_name(), { "Actual matches expected: ", actual_item.convert2string() }, UVM_MEDIUM)
      ap.write(actual_item);
    end
  endfunction

  virtual function void reset();
    expected_items.delete();
    actual_items.delete();
  endfunction

  virtual function void check_phase(uvm_phase phase);
    super.check_phase(phase);

    foreach (expected_items[i]) begin
      `uvm_error(get_type_name(), { "Actual transaction missing. expected={", expected_items[i].convert2string(), "}" })
    end

    foreach (actual_items[i]) begin
      `uvm_error(get_type_name(), { "Extra actual transaction found. actual={", actual_items[i].convert2string(), "}" })
    end

    if (no_compare_error_enabled) begin
      if ((num_matches + num_mismatches) == 0) begin
        `uvm_error(get_type_name(), "Did not compare any transactions.")
      end
    end
  endfunction

  virtual function void set_no_compare_error_enabled(bit enable);
    no_compare_error_enabled = enable;
  endfunction

  virtual function string convert2string();
    return $sformatf("num_matches=%0d, num_mismatches=%0d", num_matches, num_mismatches);
  endfunction

endclass
