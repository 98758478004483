import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Driver from "@/models/persistable/mixins/driver";

const UvcMasterRequestDriver = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    Driver,
    GeneratableFile,
    Persistable,
    belongsTo("uvcPackage")
  ],
  created () {
    this.master = true;
    this.channel = "request";
    this.partialInheritance = [
      // { for: ["me"], partial: "uvc-master-request-driver/drive-request" }
    ];
  },
  computed: {
    type () {
      const parts = [this.uvc.rootName];
      if (this.hasSlave) {
        parts.push(this.protocolRoleWord);
      }
      if (this.isPipelined) {
        parts.push("request");
        parts.push("driver");
      } else {
        parts.push("driver");
      }
      parts.push("c");
      return parts.join("_");
    }
  }
};

export default defineReactiveModel(UvcMasterRequestDriver);
