import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import UvmObject from "./uvm-object";

const UvmSequenceItem = defineReactiveModel({
  extends: Class,
  created () {
    this.isUvmBaseClass = true;
    this.id = "uvm-sequence-item-id";
  },
  computed: {
    isSequenceItem () {
      return true;
    },
    superclass () {
      return UvmObject.singleton();
    },
    type () {
      return "uvm_sequence_item";
    }
  }
});

let singleton;

UvmSequenceItem.singleton = function () {
  singleton ||= new UvmSequenceItem();
  return singleton;
};

export default UvmSequenceItem;
