import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Subroutine from "@/models/subroutine";
import uvcReceiveResponseTaskPlaceholderHbs from "@/handlebars/partials/uvc-master-response-bfm/receive-response-task-placeholder.hbs";
import uvcDriveReadyTaskPlaceholderHbs from "@/handlebars/partials/uvc-master-response-bfm/drive-ready-task-placeholder.hbs";
import uvcReceiveResponsesTaskPlaceholderHbs from "@/handlebars/partials/uvc-master-response-bfm/receive-responses-task-placeholder.hbs";
import handlebarsRuntimeOptions from "@/handlebars/runtime-options";

const UvcMasterResponseBfm = {
  extends: Class,
  mixins: [
    UvcGetters,
    UvcProtocolRoleGetters,
    GeneratableFile,
    Persistable,
    belongsTo("uvcInterface"),
    Overridable("bfm")
  ],
  created () {
    this.master = true;
    this.channel = "response";
    this.partialInheritance = [
      { for: ["me"], partial: "uvc-master-response-bfm/run-phase" }
    ];
  },
  computed: {
    superclass () {
      return this.bfmBase;
    },
    type () {
      const parts = [this.uvc.rootName];
      if (this.hasMaster && this.hasSlave) {
        parts.push(this.protocolRoleWord);
      }
      parts.push("response");
      parts.push("bfm");
      parts.push("c");
      return parts.join("_");
    },
    createMethod () {
      const parts = ["create"];
      if (this.hasSlave) {
        parts.push(this.protocolRoleWord);
      }
      if (this.isPipelined) {
        parts.push("response");
      }
      parts.push("bfm");
      return parts.join("_");
    },
    computedSubroutines () {
      return [
        this.computedReceiveResponseTask,
        this.computedDriveReadyTask,
        this.computedReceiveResponsesTask
      ];
    },
    receiveResponseTask () {
      return this.subroutines.find(subroutine => subroutine.overrideId === this.computedReceiveResponseTask.id) || this.computedReceiveResponseTask;
    },
    computedReceiveResponseTask () {
      return new Subroutine({
        propsData: {
          parent: this,
          id: "static-id-d3a299b",
          hasRequiredIdentifier: false,
          requiresUserOverride: false,
          requiredArgs: [this.sequenceItem.type],
          content: uvcReceiveResponseTaskPlaceholderHbs(this, handlebarsRuntimeOptions).slice(0, -1)
        }
      });
    },
    driveReadyTask () {
      return this.subroutines.find(subroutine => subroutine.overrideId === this.computedDriveReadyTask.id) || this.computedDriveReadyTask;
    },
    computedDriveReadyTask () {
      return new Subroutine({
        propsData: {
          parent: this,
          id: "static-id-ebee34c",
          hasRequiredIdentifier: false,
          requiredArgs: [],
          content: uvcDriveReadyTaskPlaceholderHbs(this, handlebarsRuntimeOptions).slice(0, -1)
        }
      });
    },
    receiveResponsesTask () {
      return this.subroutines.find(subroutine => subroutine.overrideId === this.computedReceiveResponsesTask.id) || this.computedReceiveResponsesTask;
    },
    computedReceiveResponsesTask () {
      return new Subroutine({
        propsData: {
          parent: this,
          id: "static-id-da3b197",
          hasRequiredIdentifier: false,
          requiredArgs: [],
          content: uvcReceiveResponsesTaskPlaceholderHbs(this, handlebarsRuntimeOptions).slice(0, -1)
        }
      });
    }
  }
};

export default defineReactiveModel(UvcMasterResponseBfm);
