import hasOne from './db/relations/has-one.js';

var stripeCartMixin = {
  mixins: [
    hasOne("cartResult"),
    hasOne("readonlyCart")
  ],
  data () {
    return {};
  },
  computed: {
    result () {
      return this.cartResult;
    },
    needsAttention () {
      return this.result && this.result.needsAttention;
    }
  }
};

export { stripeCartMixin as default };
