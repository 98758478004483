import { defineReactiveModel, Persistable } from "vue-app-utils";
import Model from "@/models/model";
import PortMixin from "@/models/mixins/port";
import { DecodeTypeAndNameParameters } from "@/encode-decode-type-and-name-parameters";
import PortKindEnum from "@/utils/port-kind-enum";

const Port = {
  extends: Model,
  mixins: [
    PortMixin,
    DecodeTypeAndNameParameters,
    Persistable
  ],
  data () {
    return {
      encodedType: "",
      encodedName: "",
      defaultValue: "'x",
      kind: PortKindEnum.getValue("auto"),
      master: true,
      channel: "request"
    };
  },
  computed: {
    typeParametersRelatingToMsb () {
      return this.typeParameters().filter(parameter => parameter.isMsb);
    },
    typeParametersRelatingToLsb () {
      return this.typeParameters().filter(parameter => parameter.isLsb);
    }
  },
  methods: {
    parentOfParameters () {
      return this.parent;
    },
    typeParameters () {
      return this.parentOfParameters().parameters.filter((parameter) => {
        return this.encodedType.match(`__UVMGEN_ID_${parameter.id}__`);
      });
    }
  }
};

export default defineReactiveModel(Port);
