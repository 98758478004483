import Persistable from './db/persistable.js';

var privateProfileMixin = {
  mixins: [
    Persistable
  ],
  data () {
    return {
      email: ""
    };
  }
};

export { privateProfileMixin as default };
