import { defineReactiveModel, Persistable, belongsTo } from "vue-app-utils";
import Class from "@/models/class";
import UvcGetters from "@/models/mixins/uvc-getters";
import UvcProtocolRoleGetters from "@/models/mixins/uvc-protocol-role-getters";
import GeneratableFile from "@/models/mixins/generatable-file";
import Overridable from "@/models/persistable/mixins/overridable";
import Policy from "@/models/mixins/policy";
import Constraint from "@/models/constraint";
import get from "lodash.get";

const UvcSlaveMaxNumTransactionsInProgressPolicy = {
  extends: Class,
  mixins: [
    UvcGetters,
    GeneratableFile,
    UvcProtocolRoleGetters,
    Persistable,
    belongsTo("uvcPackage"),
    Overridable("policy"),
    Policy
  ],
  created () {
    this.master = false;
  },
  computed: {
    subject () {
      return this.config;
    },
    type () {
      return `${this.uvc.rootName}_${this.protocolRoleWord}_max_num_transactions_in_progress_policy_c`;
    },
    computedConstraints () {
      return [
        this.numTransactionsInProgressMaxConstraint
      ]
        .filter(c => c);
    },
    numTransactionsInProgressMaxConstraint () {
      return this.config
        ? new Constraint({
          propsData: {
            parent: this,
            instanceVariable: this.config.numInProgressTransactionsMaxVariable,
            content: `object.${this.config.numInProgressTransactionsMaxVariable.identifier} == (2**(object.${get(this, "uvcParametersHandle.identifier", "")}.${get(this, "uvcParametersHandle.klass.idMsbVariable.identifier", "")}+1));`
          }
        })
        : null;
    }
  }
};

export default defineReactiveModel(UvcSlaveMaxNumTransactionsInProgressPolicy);
