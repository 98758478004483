import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";
import UvmSequenceItem from "./uvm-sequence-item";

const UvmSequenceBase = defineReactiveModel({
  extends: Class,
  created () {
    this.isUvmBaseClass = true;
    this.id = "uvm-sequence-base-id";
  },
  computed: {
    isSequenceItem () {
      return true;
    },
    superclass () {
      return UvmSequenceItem.singleton();
    },
    type () {
      return "uvm_sequence_base";
    }
  }
});

let singleton;

UvmSequenceBase.singleton = function () {
  singleton ||= new UvmSequenceBase();
  return singleton;
};

export default UvmSequenceBase;
