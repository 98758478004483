import get from "lodash.get";

export default {
  computed: {
    name () {
      if (!this.instanceVariable) {
        throw new Error(`Could not find instanceVariable for ${this.parent && this.parent.type} "${this.content}"`);
      }
      return this.overrideName || `${get(this, "parent.constraintPrefix", "")}${this.instanceVariable.constraintName}`;
    }
  }
};
