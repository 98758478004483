import { defineReactiveModel } from "vue-app-utils";
import Class from "@/models/class";

const UvcAbstractBfm = defineReactiveModel({
  extends: Class,
  created () {
    this.isUvmBaseClass = true;
    this.partialInheritance = [
      { for: ["subclasses"], partial: "uvm-component/build-phase" },
      { for: ["subclasses"], partial: "uvm-component/connect-phase" },
      { for: ["subclasses"], partial: "uvm-component/end-of-elaboration-phase" },
      { for: ["subclasses"], partial: "uvm-component/run-phase" }
    ];
  },
  computed: {
    isComponent () {
      return true;
    },
    type () {
      return "uvm_component";
    }
  }
});

let singleton;

UvcAbstractBfm.singleton = function () {
  singleton ||= new UvcAbstractBfm();
  return singleton;
};

export default UvcAbstractBfm;
