import { defineReactiveModel } from "vue-app-utils";
import Model from "@/models/model";
import GeneratableFile from "@/models/mixins/generatable-file";
import Package from "@/models/mixins/package";
import uvmPackage from "@/models/uvm/uvm-package";
import InOrderComparator from "@/models/in-order-comparator";
import OutOfOrderComparator from "@/models/out-of-order-comparator";

const ComparatorPackage = {
  extends: Model,
  mixins: [
    GeneratableFile,
    Package
  ],
  props: {
    user: {
      type: Object,
      required: true
    },
    parent: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    };
  },
  computed: {
    key () {
      return `${this.type}-79845f09h8w3g5h`;
    },
    rootProject () {
      return this.parent;
    },
    project () {
      return this.parent;
    },
    type () {
      return "comparator_pkg";
    },
    staticImports () {
      return [
        uvmPackage
      ];
    },
    baseGeneratableFileModels () {
      return [
        this.inOrderComparator,
        this.outOfOrderComparator
      ]
        .filter(m => m);
    },
    generatableFileModels () {
      return [
        ...this.baseGeneratableFileModels
      ]
        .filter(m => m);
    },
    modelPairs () {
      let previous = null;
      return this.generatableFileModels
        .map(current => {
          const ret = {
            previous,
            current
          };
          previous = current;
          return ret;
        });
    },
    inOrderComparator () {
      return new InOrderComparator({
        propsData: {
          parent: this
        }
      });
    },
    outOfOrderComparator () {
      return new OutOfOrderComparator({
        propsData: {
          parent: this
        }
      });
    }
  }
};

export default defineReactiveModel(ComparatorPackage);
